import React from 'react';
import { ContainedLabel } from '@attentive/picnic';
import {
  JobStatus,
  JobStatusColorVariantMap,
} from '../../../../constants/EventDestinations/jobTypes';
import { statusLabelStyling } from './styling';

interface StatusPillLabelProps {
  status: JobStatus;
}
const StatusPillLabel: React.FunctionComponent<StatusPillLabelProps> = ({ status }) => (
  <ContainedLabel variant={JobStatusColorVariantMap[status]} css={statusLabelStyling}>
    {status}
  </ContainedLabel>
);

export default StatusPillLabel;
