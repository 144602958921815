/**
 * @generated SignedSource<<ff97478601f25b5addf24f3fb9016ce2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleArchivePollInput = {
  companyId: string;
  pollId: string;
  shouldArchive: boolean;
};
export type ToggleArchivePollMutation$variables = {
  input: ToggleArchivePollInput;
};
export type ToggleArchivePollMutation$data = {
  readonly toggleArchivePoll: {
    readonly success: boolean;
  } | null;
};
export type ToggleArchivePollMutation = {
  response: ToggleArchivePollMutation$data;
  variables: ToggleArchivePollMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleArchivePollPayload",
    "kind": "LinkedField",
    "name": "toggleArchivePoll",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToggleArchivePollMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToggleArchivePollMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4406c26dc5a55f16e5f10bc0992a7144",
    "id": null,
    "metadata": {},
    "name": "ToggleArchivePollMutation",
    "operationKind": "mutation",
    "text": "mutation ToggleArchivePollMutation(\n  $input: ToggleArchivePollInput!\n) {\n  toggleArchivePoll(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "772d24788ea7f76c210a00c963691c8d";

export default node;
