import { useForm } from '@attentive/picnic';
import React, { useEffect, useState } from 'react';
import { StyledFormField, StyledFormLabel } from '../../../../common/StyledFormComponents';
import { NodeDropdown } from './gql/NodeDropdown';
import { NodeDropdown_fragment$data } from './gql/__generated__/NodeDropdown_fragment.graphql';

export const CreateMappingUuidInput: React.FC = () => {
  const { setFieldValue, values } = useForm();
  const { sourceUuid, targetUuid } = values;
  const [nodes, setNodes] = useState<NodeDropdown_fragment$data | null>(null);

  useEffect(() => {
    if (nodes) {
      const _nodes = nodes.eventDestinations?.nodesByTag?.edges;
      const sourceMeta = _nodes?.find((edge) => edge.node.uuid === sourceUuid)?.node.metadata;
      const targetMeta = _nodes?.find((edge) => edge.node.uuid === targetUuid)?.node.metadata;

      if (sourceMeta) {
        setFieldValue('sourceDetails', sourceMeta);
      }

      if (targetMeta) {
        setFieldValue('targetDetails', targetMeta);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, sourceUuid, targetUuid]);

  return (
    <>
      <StyledFormField name="sourceUuid">
        <StyledFormLabel>Source UUID</StyledFormLabel>
        <NodeDropdown
          _data={{ get: nodes, set: setNodes }}
          filter="SOURCE"
          uuid={sourceUuid}
          setUuid={(uuid) => setFieldValue('sourceUuid', uuid)}
        />
      </StyledFormField>
      <StyledFormField name="targetUuid">
        <StyledFormLabel>Target UUID</StyledFormLabel>
        <NodeDropdown
          filter="TARGET"
          uuid={targetUuid}
          setUuid={(uuid) => setFieldValue('targetUuid', uuid)}
        />
      </StyledFormField>
    </>
  );
};
