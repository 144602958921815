import React from 'react';
import { Box, Select, Heading, Text } from '@attentive/picnic';
import { DECIMAL_PRECISION_OPTIONS } from '../../../../../constants/PollSurveys/settingsConstants';

interface DecimalPrecisionProps {
  initialDecimalPrecision: number;
  updateRequestField: (field: string, value: number) => void;
}
const DecimalPrecision: React.FunctionComponent<DecimalPrecisionProps> = ({
  initialDecimalPrecision,
  updateRequestField,
}) => {
  const [decimalPrecision, setDecimalPrecision] = React.useState<number>(initialDecimalPrecision);

  const handleChange = (value: number) => {
    setDecimalPrecision(value);
    updateRequestField('decimalPrecision', value);
  };
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$space4' }}>
      <Heading variant="sm">Decimal Precision</Heading>
      <Text css={{ opacity: '0.6' }}>
        Choose your preferred decimal precision when viewing poll results. This option will also
        affect the results when fetching via the API.
      </Text>
      <Select css={{ maxWidth: '30%' }} onChange={handleChange} value={decimalPrecision}>
        {DECIMAL_PRECISION_OPTIONS.map((option: number) => (
          <Select.Item key={option} value={option}>
            {' '}
            {option}{' '}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
};

export default DecimalPrecision;
