import React from 'react';
import { Box, Heading, Text } from '@attentive/picnic';

interface SubscriberCountProps {
  audienceCount: number;
}

export const SubscriberCount: React.FunctionComponent<SubscriberCountProps> = ({
  audienceCount,
}) => {
  return (
    <Box>
      <Heading variant="lg">Audience Size</Heading>
      <Text css={{ mt: '$space4' }} variant="lede">
        {audienceCount} participants
      </Text>
    </Box>
  );
};
