import React from 'react';
import { Box, Button, Heading, TextArea } from '@attentive/picnic';

interface MessageEditProps {
  label: string;
  value: string;
  onSave: (v: string) => void;
}

const MessageEdit: React.FunctionComponent<MessageEditProps> = ({ label, value, onSave }) => {
  const [submitMessage, setSubmitMessage] = React.useState<string>(value);
  const [canSaveSubmit, setCanSaveSubmit] = React.useState<boolean>(false);

  const handleSubmitChange = (newMessage: string) => {
    if (newMessage === value) {
      setCanSaveSubmit(false);
    } else {
      setCanSaveSubmit(true);
    }
    setSubmitMessage(newMessage);
  };

  const handleSave = () => {
    if (canSaveSubmit) {
      onSave(submitMessage);
      setCanSaveSubmit(false);
    }
  };

  return (
    <Box css={{ mt: '$space4' }}>
      <Heading variant="sm">{label}</Heading>
      <TextArea value={submitMessage} onChange={(e) => handleSubmitChange(e.target.value)} />
      <Box css={{ display: 'flex', justifyContent: 'flex-end', mt: '$space2' }}>
        <Button
          size="small"
          disabled={!canSaveSubmit}
          variant="secondary"
          onClick={() => handleSubmitChange(value)}
        >
          Cancel
        </Button>
        <Button size="small" disabled={!canSaveSubmit} css={{ ml: '$space4' }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};
export default MessageEdit;
