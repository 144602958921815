import React from 'react';
import { Grid, Text } from '@attentive/picnic';

import { parse } from './utils';
import { jsonHeaderStyling } from './styles';

const JsonDetails: React.FunctionComponent<{ title: string; json?: string }> = ({
  title,
  json,
}) => {
  return (
    <Grid.Cell>
      <Text variant="caption" css={jsonHeaderStyling}>
        {title}
      </Text>
      <pre>{parse(json)}</pre>
    </Grid.Cell>
  );
};

export default JsonDetails;
