import React from 'react';
import { ContainedLabel } from '@attentive/picnic';
import { CustomActionStatus } from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsTypes';

interface PillLableProps {
  state: CustomActionStatus | undefined;
}

const getDisplayName = (value: CustomActionStatus | undefined): string => {
  switch (value) {
    case CustomActionStatus.ACTIVE:
      return 'Active';
    case CustomActionStatus.INACTIVE:
      return 'Inactive';
    default:
      return value || 'UNKNOWN';
  }
};

const CustomActionPillLabel: React.FunctionComponent<PillLableProps> = ({ state }) => (
  <ContainedLabel
    variant={state === CustomActionStatus.ACTIVE ? 'decorative1' : 'neutral'}
    css={{ mr: '$space4' }}
  >
    {getDisplayName(state)}
  </ContainedLabel>
);

export default CustomActionPillLabel;
