/**
 * @generated SignedSource<<abaafb3959f42f8ec90b8188ade79b93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollSortingField = "POLL_SORTING_FIELD_NAME" | "POLL_SORTING_FIELD_UPDATED" | "%future added value";
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type PollsFilterV2 = {
  includedStates?: ReadonlyArray<PollState> | null;
  searchTerm?: string | null;
  showDeleted?: boolean | null;
};
export type PollSorting = {
  sortBy: PollSortingField;
  sortDirection: SortDirection;
};
export type PollsConnectedTableQuery_query_refetchable$variables = {
  after?: string | null;
  before?: string | null;
  companyId: string;
  filter?: PollsFilterV2 | null;
  first?: number | null;
  last?: number | null;
  sorting?: PollSorting | null;
};
export type PollsConnectedTableQuery_query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PollsConnectedTableQuery_query">;
};
export type PollsConnectedTableQuery_query_refetchable = {
  response: PollsConnectedTableQuery_query_refetchable$data;
  variables: PollsConnectedTableQuery_query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sorting"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "sorting",
    "variableName": "sorting"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PollsConnectedTableQuery_query_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PollsConnectedTableQuery_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PollsConnectedTableQuery_query_refetchable",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "PollsConnection",
                "kind": "LinkedField",
                "name": "pollsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Poll",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uuid",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GetPollKeywordsResponse",
                            "kind": "LinkedField",
                            "name": "poll_keywords",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PollKeyword",
                                "kind": "LinkedField",
                                "name": "pollKeywords",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "keyword",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "altMatches",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "deleted",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalResponses",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "closedMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duplicateVoteMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submitMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "filter",
                  "sorting"
                ],
                "handle": "connection",
                "key": "PollsConnectedTableQuery_query_pollsV2",
                "kind": "LinkedHandle",
                "name": "pollsV2"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3935aadfcebc9e7fe1e49a3f7d49903c",
    "id": null,
    "metadata": {},
    "name": "PollsConnectedTableQuery_query_refetchable",
    "operationKind": "query",
    "text": "query PollsConnectedTableQuery_query_refetchable(\n  $after: String\n  $before: String\n  $companyId: ID!\n  $filter: PollsFilterV2\n  $first: Int\n  $last: Int\n  $sorting: PollSorting\n) {\n  ...PollsConnectedTableQuery_query\n}\n\nfragment PollsConnectedTableQuery_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      name\n      internalId\n      id\n      pollsV2(first: $first, after: $after, last: $last, before: $before, filter: $filter, sorting: $sorting) {\n        edges {\n          node {\n            name\n            uuid\n            id\n            poll_keywords {\n              pollKeywords {\n                id\n                keyword\n                description\n                altMatches\n                deleted\n              }\n            }\n            state\n            description\n            totalResponses\n            closedMessage\n            duplicateVoteMessage\n            submitMessage\n            isArchived\n            updatedAt\n            __typename\n          }\n          cursor\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5ea0c3f5e4fce88e506eb29ae9e48df";

export default node;
