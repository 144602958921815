/**
 * @generated SignedSource<<cf9e9ad8d41e7bc2434e8975e725ac96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventReplayEventType = "EMAIL_SAVE" | "JOIN" | "JOURNEY_POSTBACK" | "MESSAGE_LINK_CLICK" | "MESSAGE_RECEIPT" | "SUBSCRIPTION_ALREADY_SUBSCRIBED" | "SUBSCRIPTION_OPTED_OUT" | "SUBSCRIPTION_OPT_IN_COMPLETED" | "SUBSCRIPTION_OPT_IN_STARTED" | "USER_PROPERTY_SET" | "%future added value";
export type CreateEventReplayInput = {
  companyId: string;
  description: string;
  eventRangeEnd: SerializedDateTime;
  eventRangeStart: SerializedDateTime;
  eventTypes?: ReadonlyArray<EventReplayEventType> | null;
  title: string;
};
export type EventReplayCreatePageTacticalMfeMutation$variables = {
  input: CreateEventReplayInput;
};
export type EventReplayCreatePageTacticalMfeMutation$data = {
  readonly createEventReplay: {
    readonly eventReplay: {
      readonly id: string;
      readonly title: string | null;
    };
  } | null;
};
export type EventReplayCreatePageTacticalMfeMutation = {
  response: EventReplayCreatePageTacticalMfeMutation$data;
  variables: EventReplayCreatePageTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateEventReplayPayload",
    "kind": "LinkedField",
    "name": "createEventReplay",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventReplay",
        "kind": "LinkedField",
        "name": "eventReplay",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventReplayCreatePageTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventReplayCreatePageTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "58f1ee141285b3b3d95a321bcd066bed",
    "id": null,
    "metadata": {},
    "name": "EventReplayCreatePageTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation EventReplayCreatePageTacticalMfeMutation(\n  $input: CreateEventReplayInput!\n) {\n  createEventReplay(input: $input) {\n    eventReplay {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dfcb8f4aac59dbb2b7e6b37c660d2d4";

export default node;
