import React from 'react';
import { Box } from '@attentive/picnic';
import { JobRecord, JobStatus } from '../../../../../../constants/EventDestinations/jobTypes';
import SetupSchedule from './SetupSchedule';
import SetupUnschedule from './SetupUnschedule';

const ScheduleJob: React.FunctionComponent<{
  job: JobRecord;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
}> = ({ job, setErrorMsg }) => {
  const isActive = job.status === JobStatus.ACTIVE;
  if (job.internalId) {
    return (
      <Box css={{ py: '$space4' }}>
        {isActive ? (
          <SetupUnschedule
            taskId={job.internalId}
            lastRun={job.lastRunDate?.toDateString() ?? 'never'}
          />
        ) : (
          <SetupSchedule taskId={job.internalId} setErrorMsg={setErrorMsg} />
        )}
      </Box>
    );
  }
  return <Box></Box>;
};

export default ScheduleJob;
