/**
 * @generated SignedSource<<28fb5fe8df2647c32a71e6093dd5c249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VendorCompanyCardConnectedTableQuery_query$data = {
  readonly company: {
    readonly id?: string;
    readonly internalId?: number;
    readonly name?: string;
    readonly vendorCompanyCards?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly id: string;
          readonly updated: SerializedDateTime;
          readonly uuid: string;
          readonly vendorCampaignId: string;
          readonly vendorOfferId: string;
        };
      }>;
      readonly totalCount: number;
    } | null;
  } | null;
  readonly " $fragmentType": "VendorCompanyCardConnectedTableQuery_query";
};
export type VendorCompanyCardConnectedTableQuery_query$key = {
  readonly " $data"?: VendorCompanyCardConnectedTableQuery_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"VendorCompanyCardConnectedTableQuery_query">;
};

import VendorCompanyCardConnectedTableQuery_query_refetchable_graphql from './VendorCompanyCardConnectedTableQuery_query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "company",
  "vendorCompanyCards"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "sorting"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": VendorCompanyCardConnectedTableQuery_query_refetchable_graphql
    }
  },
  "name": "VendorCompanyCardConnectedTableQuery_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": "vendorCompanyCards",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filter",
                  "variableName": "filter"
                },
                {
                  "kind": "Variable",
                  "name": "sorting",
                  "variableName": "sorting"
                }
              ],
              "concreteType": "VendorCompanyCardConnection",
              "kind": "LinkedField",
              "name": "__VendorCompanyCardConnectedTableQuery_query_vendorCompanyCards_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VendorCompanyCardEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "VendorCompanyCard",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "uuid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "vendorCampaignId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "vendorOfferId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "updated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d5b67f1b0a9ee2e8d1384e8a50f0b654";

export default node;
