import React from 'react';

import InitializeWithNameForm from '../../../../components/EventDestinations/Jobs/CreateJob/InitializeWithNameForm';
import CreateMappingForm from '../../../../components/EventDestinations/Jobs/CreateJob/CreateMappings/CreateMappingsForm';
import ReviewNewJob from '../../../../components/EventDestinations/Jobs/CreateJob/ReviewNewJob';
import { POST_MAP_INITIAL_VALUES_FRAGMENT } from '../../../../components/EventDestinations/Jobs/utils/formUtils';
import { useCurrentCompanyId } from '@attentive/acore-utils';

enum CreateJobStep {
  InitializeWithName,
  CreateMappings,
  Complete,
}

const EventDestinationCreatePage: React.FunctionComponent = () => {
  const [step, setStep] = React.useState<CreateJobStep>(CreateJobStep.InitializeWithName);
  const [taskUuid, setTaskUuid] = React.useState<string>('');
  const companyId = useCurrentCompanyId();

  const handleInitializeNameFormSubmit = (uuid: string) => {
    setStep(CreateJobStep.CreateMappings);
    setTaskUuid(uuid);
  };

  const handleCreateMappingFormSubmit = () => {
    setStep(CreateJobStep.Complete);
  };

  const getPageForStep = (currentStep: CreateJobStep) => {
    switch (currentStep) {
      case CreateJobStep.InitializeWithName:
        return <InitializeWithNameForm handleSubmit={handleInitializeNameFormSubmit} />;
      case CreateJobStep.CreateMappings:
        return taskUuid ? (
          <CreateMappingForm
            config={{
              taskId: taskUuid,
              companyId,
              ...POST_MAP_INITIAL_VALUES_FRAGMENT,
            }}
            handleSubmit={handleCreateMappingFormSubmit}
          />
        ) : null;
      case CreateJobStep.Complete:
        return <ReviewNewJob taskUuid={taskUuid} />;
      default:
        return <InitializeWithNameForm handleSubmit={handleInitializeNameFormSubmit} />;
    }
  };

  return getPageForStep(step);
};

export default EventDestinationCreatePage;
