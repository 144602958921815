import React from 'react';
import { Box, PicnicCss, Table } from '@attentive/picnic';
import { EventIntegrationEstimates } from '../../constants/EventReplay/eventReplayTypes';
import { EventReplayStatus } from '@attentive/data/types';

interface EventReplayTypesTableProps {
  estimatedIntegrationCounts: { [key: string]: EventIntegrationEstimates };
  status?: string;
}

const EventReplayTypesTable: React.FunctionComponent<EventReplayTypesTableProps> = ({
  estimatedIntegrationCounts,
  status,
}) => {
  const eventTypes = Object.keys(estimatedIntegrationCounts);

  const shouldBeDisabled = (): boolean => {
    return status !== EventReplayStatus.Completed;
  };

  const headerCss = (): PicnicCss | undefined => {
    if (shouldBeDisabled()) {
      return { color: '$textDisabled' };
    }
    return;
  };

  return (
    <Box>
      <Table columns={[3, 3, 1, 1, 1]}>
        <Table.Body>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell>Vendor</Table.HeaderCell>
              <Table.HeaderCell>Event Type(s)</Table.HeaderCell>
              <Table.HeaderCell>Estimated Count</Table.HeaderCell>
              <Table.HeaderCell css={headerCss()}>Actual Count</Table.HeaderCell>
              <Table.HeaderCell css={headerCss()}>Completed</Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>
          {eventTypes.map((evtType) => {
            const estimatedIntegrationCount = estimatedIntegrationCounts[evtType];
            const estimates = estimatedIntegrationCount.eventEstimates ?? {};
            return Object.keys(estimates).map((event) => (
              <Table.BodyRow key={event}>
                <Table.BodyCell>{estimatedIntegrationCount.vendor}</Table.BodyCell>
                <Table.BodyCell>{event}</Table.BodyCell>
                <Table.BodyCell>{estimates[event]}</Table.BodyCell>
                <Table.BodyCell>{shouldBeDisabled() ? '' : estimates[event]}</Table.BodyCell>
                <Table.BodyCell>{shouldBeDisabled() ? '' : 'Fri Feb 18, 2022'}</Table.BodyCell>
              </Table.BodyRow>
            ));
          })}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default EventReplayTypesTable;
