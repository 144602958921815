import React from 'react';

import { Box, Grid, Heading, Text } from '@attentive/picnic';
import { EdsPerformanceStatusCodeReport } from './types';
import { parseRollupCounts } from './utils';
import { rollupCountStyles } from './styles';

const RollupTotals: React.FunctionComponent<{ reports: EdsPerformanceStatusCodeReport[] }> = ({
  reports,
}) => {
  const rollupCounts = parseRollupCounts(reports);

  return (
    <Grid columns={3}>
      <Grid.Cell>
        <Box css={rollupCountStyles}>
          <Text variant="lede">{rollupCounts.topic_status_code_200.toLocaleString()}</Text>
          <Heading variant="sm">Successful Requests</Heading>
        </Box>
      </Grid.Cell>
      <Grid.Cell>
        <Box css={rollupCountStyles}>
          <Text variant="lede">{rollupCounts.topic_status_code_400.toLocaleString()}</Text>
          <Heading variant="sm">Invalid Requests</Heading>
        </Box>
      </Grid.Cell>
      <Grid.Cell>
        <Box css={rollupCountStyles}>
          <Text variant="lede">{rollupCounts.topic_status_code_429.toLocaleString()}</Text>
          <Heading variant="sm">Rate Limited</Heading>
        </Box>
      </Grid.Cell>
      <Grid.Cell>
        <Box css={{ ...rollupCountStyles, mt: '$space8' }}>
          <Text variant="lede">{rollupCounts.topic_status_code_404.toLocaleString()}</Text>
          <Heading variant="sm">Subscriber Not Found</Heading>
        </Box>
      </Grid.Cell>
      <Grid.Cell>
        <Box css={{ ...rollupCountStyles, mt: '$space8' }}>
          <Text variant="lede">{rollupCounts.topic_status_code_403.toLocaleString()}</Text>
          <Heading variant="sm">Subscriber Inactive</Heading>
        </Box>
      </Grid.Cell>
      <Grid.Cell>
        <Box css={{ ...rollupCountStyles, mt: '$space8' }}>
          <Text variant="lede">{rollupCounts.topic_status_code_405.toLocaleString()}</Text>
          <Heading variant="sm">Subscriber Opted Out</Heading>
        </Box>
      </Grid.Cell>
    </Grid>
  );
};

export default RollupTotals;
