/**
 * @generated SignedSource<<dce4d13dd7ec3e322c81aea0c9ac1f3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeDropdown_refetchable$variables = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  tag: string;
};
export type NodeDropdown_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NodeDropdown_fragment">;
};
export type NodeDropdown_refetchable = {
  response: NodeDropdown_refetchable$data;
  variables: NodeDropdown_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tag"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "tag",
    "variableName": "tag"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NodeDropdown_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NodeDropdown_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NodeDropdown_refetchable",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EventDestinationNodeConnection",
            "kind": "LinkedField",
            "name": "nodesByTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventDestinationNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventDestinationNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uuid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tags",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metadata",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "tag"
            ],
            "handle": "connection",
            "key": "NodeDropdownQuery_nodesByTag",
            "kind": "LinkedHandle",
            "name": "nodesByTag"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "248316c29d09370d23c542dbb7fb0018",
    "id": null,
    "metadata": {},
    "name": "NodeDropdown_refetchable",
    "operationKind": "query",
    "text": "query NodeDropdown_refetchable(\n  $after: String\n  $before: String\n  $first: Int\n  $last: Int\n  $tag: String!\n) {\n  ...NodeDropdown_fragment\n}\n\nfragment NodeDropdown_fragment on Query {\n  eventDestinations {\n    nodesByTag(tag: $tag, first: $first, after: $after, last: $last, before: $before) {\n      edges {\n        node {\n          uuid\n          name\n          tags\n          metadata\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2db7408e0f2f6d30531d4ffe018c966a";

export default node;
