import { format } from 'date-fns';
import { RunReportV2 } from '../../../pages/EventDestinations/graphql/WithEventDestinationJobRunReportsV2';
import {
  INITIAL_ROLLUP_COUNTS,
  PerformanceBarDatum,
  RollupCounts,
} from '../Jobs/TabPanels/Performance/types';

const groupRunReports = (runReports: RunReportV2[]): { [key: string]: RunReportV2[] } => {
  const runObject: { [key: string]: RunReportV2[] } = {};
  runReports.forEach((report) => {
    if (runObject[report.runUuid]) {
      runObject[report.runUuid] = [...runObject[report.runUuid], report];
    } else {
      runObject[report.runUuid] = [report];
    }
  });
  return runObject;
};

const buildDataPoints = (runObject: { [key: string]: RunReportV2[] }) => {
  const performanceData: PerformanceBarDatum[] = [];

  const runUuids = Object.keys(runObject);
  runUuids.forEach((runUuid) => {
    const runs = runObject[runUuid];
    const statusCodeCounts: { [key: string]: number } = {};
    runs.forEach((run) => {
      statusCodeCounts[run.statusCode] = run.value;
    });
    const dataPoint: PerformanceBarDatum = {
      date: format(runs[0].start, 'MM/dd hh:mm a'),
      success: statusCodeCounts.topic_status_code_200 || 0,
      rate_limited: statusCodeCounts.topic_status_code_429 || 0,
      invalid_request: statusCodeCounts.topic_status_code_400 || 0,
      subscriber_inactive: statusCodeCounts.topic_status_code_403 || 0,
      subscriber_not_found: statusCodeCounts.topic_status_code_404 || 0,
      subscriber_optedout: statusCodeCounts.topic_status_code_405 || 0,
    };
    performanceData.push(dataPoint);
  });

  return performanceData;
};

export const parseRunsToDatum = (runReports: RunReportV2[]) => {
  runReports.sort((a, b) => a.epochSeconds - b.epochSeconds);
  const runObject: { [key: string]: RunReportV2[] } = groupRunReports(runReports);

  return buildDataPoints(runObject);
};

export const calculateRollups = (data: PerformanceBarDatum[]): RollupCounts => {
  const rollupData = { ...INITIAL_ROLLUP_COUNTS };
  data.forEach((dataPoint) => {
    rollupData.topic_status_code_200 += dataPoint.success;
    rollupData.topic_status_code_400 += dataPoint.invalid_request;
    rollupData.topic_status_code_403 += dataPoint.subscriber_inactive;
    rollupData.topic_status_code_404 += dataPoint.subscriber_not_found;
    rollupData.topic_status_code_405 += dataPoint.subscriber_optedout;
    rollupData.topic_status_code_429 += dataPoint.rate_limited;
  });
  return rollupData;
};
