import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Text, Box, Icon, Heading, Breadcrumbs, TabGroup } from '@attentive/picnic';

import EventReplayTypesTable from './EventReplayTypesTable';
import EventReplayTypesTableIntegrations from './EventReplayTypesTableIntegrations';
import EventReplayRunDialog from './EventReplayRunDialog';
import StatusPillLabel from './StatusPillLabel';
import { EventReplayStatus } from '@attentive/data/types';
import { dateToStringLongForm } from './utils/dateFormatter';
import { parseQueryResponse } from './utils/eventReplayMapper';
import { useEventReplayByCompanyIdsTacticalMfeQueryData } from '../../pages/EventReplay/EventReplayByCompanyIdsTacticalMfeQuery';
import { Paths } from '../../constants/routes';

interface EventReplayDetailsProps {
  requestId?: string;
}

enum TAB_TYPE {
  OVERVIEW = 'OVERVIEW',
  BY_INTEGRATION = 'BY_INTEGRATION',
}

const EventReplayDetails: React.FunctionComponent<EventReplayDetailsProps> = ({ requestId }) => {
  const queryData = useEventReplayByCompanyIdsTacticalMfeQueryData();
  const request = parseQueryResponse(queryData)?.find((d) => d.id === requestId);
  const title = request?.title;
  const status = request?.status;
  const description = request?.description;
  const startDate = dateToStringLongForm(request?.startDate);
  const endDate = dateToStringLongForm(request?.endDate);
  const estimatedCounts = request?.estimatedEventCounts ? request.estimatedEventCounts : {};
  const estimatedIntegrationCounts = request?.estimatedIntegrationEventCounts
    ? request.estimatedIntegrationEventCounts
    : {};

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<string>(TAB_TYPE.OVERVIEW);

  return (
    <Box css={{ width: '100%' }}>
      <Breadcrumbs>
        <Breadcrumbs.Item as={Link} to={Paths.EventReplayPage}>
          Event Replay
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>{title}</Breadcrumbs.Item>
      </Breadcrumbs>
      <Box
        css={{
          mt: '$space4',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box css={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
          <Heading css={{ mb: '$space4' }}>{title}</Heading>
          <Box css={{ ml: '$space4', mt: '$space2' }}>
            {status ? <StatusPillLabel status={status} /> : null}
          </Box>
        </Box>
        <div>
          <Button
            variant="primary"
            css={{ display: 'flex', alignItems: 'center' }}
            disabled={status !== EventReplayStatus.Pending}
            onClick={() => setIsDialogOpen(true)}
          >
            <Icon name="CirclePlay" size="small" css={{ mr: '$space2' }} />
            Run
          </Button>
        </div>
      </Box>
      <Box>
        <Text>
          Replay events from {startDate} to {endDate}
        </Text>
        <Text>{description}</Text>
      </Box>
      <Box css={{ mt: '$space6' }}>
        <TabGroup value={tab} onValueChange={setTab}>
          <TabGroup.List css={{ borderBottom: '$borderWidths$borderWidth1 solid $borderDefault' }}>
            <TabGroup.Tab value={TAB_TYPE.OVERVIEW}>Overview</TabGroup.Tab>
            <TabGroup.Tab value={TAB_TYPE.BY_INTEGRATION}>By Integration</TabGroup.Tab>
          </TabGroup.List>
          <TabGroup.Panel value={TAB_TYPE.OVERVIEW}>
            <EventReplayTypesTable estimatedCounts={estimatedCounts} status={status} />
          </TabGroup.Panel>
          <TabGroup.Panel value={TAB_TYPE.BY_INTEGRATION}>
            <EventReplayTypesTableIntegrations
              estimatedIntegrationCounts={estimatedIntegrationCounts}
              status={status}
            />
          </TabGroup.Panel>
        </TabGroup>
      </Box>
      <EventReplayRunDialog
        showDialog={isDialogOpen}
        toggleShowDialog={() => setIsDialogOpen(!isDialogOpen)}
        eventCount={request?.estimatedCountSum}
        replayId={request?.id}
      />
    </Box>
  );
};

export default EventReplayDetails;
