import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DropdownMenu, IconButton } from '@attentive/picnic';
import { EventReplayStatus } from '@attentive/data/types';

import { EventReplayRequest } from '../../constants/EventReplay/eventReplayTypes';

interface EventReplayListItemMenu {
  request: EventReplayRequest;
  showRunDialog: () => void;
  showEventCountDialog: () => void;
}

const EventReplayListItemMenu: React.FunctionComponent<EventReplayListItemMenu> = ({
  request,
  showRunDialog,
  showEventCountDialog,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <IconButton
          iconName="DotsVertical"
          iconColor="default"
          description="Segment action menu"
          size="small"
          data-disable-focus
          data-segmentation-id="action-menu-toggle"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end">
        <DropdownMenu.Item
          disabled={request.status !== EventReplayStatus.Pending}
          onSelect={showRunDialog}
        >
          Run
        </DropdownMenu.Item>
        <DropdownMenu.Item onSelect={showEventCountDialog}>Event Counts</DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <RouterLink to={`/tactical/event-replay/${request.companyId}/${request.id}`}>
            More Info
          </RouterLink>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

export default EventReplayListItemMenu;
