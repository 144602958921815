import React from 'react';
import { LoadingPlaceholder, Box, Heading, Text } from '@attentive/picnic';
import {
  customActionsOverlayStyle,
  customActionsOverlayTextStyle,
  customActionsRowStyle,
} from './customActionsStyles';

export interface CustomActionsEmptyListProps {
  loading: boolean;
}

const CustomActionsEmptyOverlay = () => (
  <Box css={customActionsOverlayStyle}>
    <Box css={customActionsOverlayTextStyle}>
      <Heading variant="sm">No Custom Actions</Heading>
      <Text>
        You haven’t added any custom actions — <br />
        why not create one now?
      </Text>
    </Box>
  </Box>
);

const CustomActionsEmptyListComponent: React.FunctionComponent<CustomActionsEmptyListProps> = ({
  loading,
}) => {
  const rows = Array.from({ length: loading ? 5 : 3 }, (_, k) => k + 1);
  return (
    <Box css={{ position: 'relative' }}>
      {!loading && <CustomActionsEmptyOverlay />}
      {rows.map((n) => (
        <Box key={`row-${n}`} css={customActionsRowStyle}>
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LoadingPlaceholder
              css={{
                height: '$size4',
                width: '120px',
                mb: '$space2',
              }}
            />
            <LoadingPlaceholder
              css={{
                height: '$size3',
                width: '160px',
              }}
            />
          </Box>
          <LoadingPlaceholder
            css={{
              height: '$size5',
              width: '80px',
              borderRadius: '$radiusMax',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CustomActionsEmptyListComponent;
