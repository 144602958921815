/**
 * @generated SignedSource<<95e304ddfb62b6131cf2c6e484bd9a42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertPollKeywordInput = {
  companyId: string;
  pollId: string;
  pollKeywords?: ReadonlyArray<PollKeywordInput> | null;
};
export type PollKeywordInput = {
  altMatches?: ReadonlyArray<string> | null;
  deleted: boolean;
  description: string;
  keyword: string;
  keywordId?: string | null;
};
export type AddKeywordDialogUpsertKeywordTacticalMfeMutation$variables = {
  input: UpsertPollKeywordInput;
};
export type AddKeywordDialogUpsertKeywordTacticalMfeMutation$data = {
  readonly upsertPollKeyword: {
    readonly success: boolean;
  } | null;
};
export type AddKeywordDialogUpsertKeywordTacticalMfeMutation = {
  response: AddKeywordDialogUpsertKeywordTacticalMfeMutation$data;
  variables: AddKeywordDialogUpsertKeywordTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertPollKeywordPayload",
    "kind": "LinkedField",
    "name": "upsertPollKeyword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddKeywordDialogUpsertKeywordTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddKeywordDialogUpsertKeywordTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9b538a0235908a9afe0b9b56f1eb8726",
    "id": null,
    "metadata": {},
    "name": "AddKeywordDialogUpsertKeywordTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation AddKeywordDialogUpsertKeywordTacticalMfeMutation(\n  $input: UpsertPollKeywordInput!\n) {\n  upsertPollKeyword(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "568a07051c67e2ddc55adc186ff58ca9";

export default node;
