/**
 * @generated SignedSource<<2738143f9235e55bb84ef82c82741c48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobsTablePaginated_fragment$data = {
  readonly company: {
    readonly displayName: string;
    readonly internalId: number;
  } | null;
  readonly description: string;
  readonly id: string;
  readonly internalId: string;
  readonly jobType: string;
  readonly lastExecutedRun: {
    readonly context: any | null;
    readonly parentTaskId: string | null;
    readonly runDate: SerializedDateTime | null;
    readonly scheduledDate: SerializedDateTime;
    readonly status: string;
    readonly taskId: string;
    readonly uuid: string;
  } | null;
  readonly name: string;
  readonly nextRun: SerializedDateTime | null;
  readonly previousRun: SerializedDateTime | null;
  readonly sourceInfo: string | null;
  readonly targetInfo: string | null;
  readonly " $fragmentType": "JobsTablePaginated_fragment";
};
export type JobsTablePaginated_fragment$key = {
  readonly " $data"?: JobsTablePaginated_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobsTablePaginated_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobsTablePaginated_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previousRun",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nextRun",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobHistory",
      "kind": "LinkedField",
      "name": "lastExecutedRun",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentTaskId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "runDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scheduledDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "context",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobRecord",
  "abstractKey": null
};
})();

(node as any).hash = "dc5307f2d739e27b7628a3fd69cff6e7";

export default node;
