import React from 'react';
import { Box, Heading, Text, ContainedLabel } from '@attentive/picnic';
import { format } from 'date-fns';
import EndpointCardMenu from './EndpointCardMenu';
import { EdsNode } from '../../../../constants/EventDestinations/endpointTypes';

const StatusPillLabel: React.FunctionComponent<{ enabled: boolean }> = ({ enabled }) => {
  const status = enabled ? 'Active' : 'Draft';
  const variant = enabled ? 'success' : 'neutral';
  return <ContainedLabel variant={variant}>{status}</ContainedLabel>;
};

const EndpointCardHeader: React.FunctionComponent<{ node: EdsNode }> = ({ node }) => {
  return (
    <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <StatusPillLabel enabled={node.enabled} />
        <Box css={{ mt: '$space3', mb: '$space4' }}>
          <Heading
            variant="sm"
            css={{ textOverflow: 'ellipsis', whitespace: 'nowrap', overflow: 'hidden' }}
          >
            {node.name}
          </Heading>
          <Text variant="caption">Created {format(node.created, 'MMM d, y')}</Text>
        </Box>
      </Box>
      <EndpointCardMenu node={node} />
    </Box>
  );
};

export default EndpointCardHeader;
