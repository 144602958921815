import React from 'react';
import { Link } from 'react-router-dom';
import { ToastType, useToast } from '@attentive/acore-utils';
import { Box, Button, Select, Heading, Text, Icon } from '@attentive/picnic';

import { TacticalAPI } from '../../utils/tacticalAPI';
import { logError } from '../../utils/logger';
import { Paths } from '../../constants/routes';

import KeywordItem from '../../components/PurchaseKeywordList/KeywordItem';
import KeywordsEmptyList from '../../components/PurchaseKeywordList/KeywordsEmptyList';
import { KeywordActions } from '../../constants/Keywords/keywordActions';
import {
  PurchaseKeywordState,
  PurchaseKeywordAction,
  KeywordFilter,
  KeywordFilterNone,
  KeywordStatus,
  Keyword,
} from '../../constants/Keywords/keywordTypes';

const PU_KEYWORD_FILTER_MIN_WIDTH = '200px';

const initialState = {
  keywords: [],
  filteredKeywords: [],
  loading: false,
  filter: KeywordStatus.ACTIVE,
};

const baseURL = '/keywords';

const filterKeywords = (keywords: Keyword[], filter: string): Keyword[] => {
  if (filter === KeywordFilterNone.VALUE) {
    return keywords;
  }
  return keywords.filter((keyword) => keyword.state === filter);
};

const sortKeywordsbyDate = (keywords: Keyword[]): Keyword[] => {
  return keywords.sort((a, b) => {
    const aDate = a.lastUpdated ? a.lastUpdated : '';
    const bDate = b.lastUpdated ? b.lastUpdated : '';
    return Date.parse(bDate) - Date.parse(aDate);
  });
};

// Declared outside of the component to prevent duplicate calls
const reducer = (
  state: PurchaseKeywordState,
  action: PurchaseKeywordAction
): PurchaseKeywordState => {
  const createToast = action.createToast;
  switch (action.type) {
    case KeywordActions.FETCH_ALL_KEYWORDS_REQUEST:
      // GMRU: GET /keywords
      TacticalAPI.get<Keyword[]>(baseURL)
        .then((response) =>
          action.payload.dispatch({
            type: KeywordActions.FETCH_ALL_KEYWORDS_SUCCESS,
            payload: response.body,
            createToast,
          })
        )
        .catch((e) =>
          action.payload.dispatch({
            type: KeywordActions.FETCH_ALL_KEYWORDS_FAILURE,
            payload: e,
            createToast,
          })
        );
      if (action.payload?.silent) {
        return state;
      }
      return { ...state, loading: true };
    case KeywordActions.FETCH_ALL_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: sortKeywordsbyDate(action.payload),
        filteredKeywords: filterKeywords(action.payload, state.filter),
        loading: false,
      };
    case KeywordActions.FETCH_ALL_KEYWORDS_FAILURE:
      logError(action.payload instanceof Error ? action.payload : new Error(action.payload));
      createToast({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Unable to retrieve keywords.',
      });
      return { ...state, loading: false };
    case KeywordActions.FILTER_KEYWORDS: {
      const filteredKeywords = filterKeywords(state.keywords, action.payload);
      return { ...state, filteredKeywords, filter: action.payload };
    }
    case KeywordActions.ENABLE_KEYWORD_REQUEST:
    case KeywordActions.DISABLE_KEYWORD_REQUEST: {
      const newKeywordState =
        action.type === KeywordActions.ENABLE_KEYWORD_REQUEST
          ? KeywordStatus.ACTIVE
          : KeywordStatus.INACTIVE;
      const keyword: Keyword = { ...action.payload, state: newKeywordState, loading: true };
      // GMRU: PUT /keywords/{keywordId}
      TacticalAPI.put(`${baseURL}/${keyword.id}`, keyword)
        .then((response) => {
          if (response.status !== 200) {
            logError(new Error(JSON.stringify(response)));
            createToast({
              type: ToastType.Error,
              title: 'Something went wrong.',
              text: `Unable to ${
                action.type === KeywordActions.ENABLE_KEYWORD_REQUEST ? 'activate' : 'deactivate'
              } keyword.`,
            });
          }
        })
        .catch((err) => {
          logError(err);
          createToast({
            type: ToastType.Error,
            title: 'Something went wrong.',
            text: `Unable to ${
              action.type === KeywordActions.ENABLE_KEYWORD_REQUEST ? 'activate' : 'deactivate'
            } keyword.`,
          });
        });
      return { ...state, updateKeyword: keyword };
    }
  }
  return state;
};

const PurchaseKeywordList: React.FunctionComponent = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [createToast] = useToast();

  const KeywordFilterName = {
    [KeywordFilterNone.VALUE]: 'All Statuses',
    [KeywordStatus.ACTIVE]: 'Active',
    [KeywordStatus.INACTIVE]: 'Inactive',
  };

  React.useEffect(() => {
    dispatch({
      type: KeywordActions.FETCH_ALL_KEYWORDS_REQUEST,
      payload: { dispatch },
      createToast,
    });
  }, [createToast]);

  React.useEffect(() => {
    if (state.updateKeyword) {
      const keyword = state.updateKeyword;
      // GMRU: PUT /keywords/{keywordId}
      TacticalAPI.put(`${baseURL}/${keyword.id}`, keyword)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: KeywordActions.FETCH_ALL_KEYWORDS_REQUEST,
              payload: { dispatch, silent: true },
              createToast,
            });
          } else {
            createToast({
              type: ToastType.Error,
              title: 'Something went wrong.',
              text: `Unable to ${
                keyword.state === KeywordStatus.ACTIVE ? 'activate' : 'deactivate'
              } keyword.`,
            });
          }
        })
        .catch((err) => {
          logError(err);
          createToast({
            type: ToastType.Error,
            title: 'Something went wrong.',
            text: `Unable to ${
              keyword.state === KeywordStatus.ACTIVE ? 'activate' : 'deactivate'
            } keyword.`,
          });
        });
    }
  }, [state.updateKeyword, createToast]);

  return (
    <Box css={{ width: '100%' }}>
      <Box css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
          <Heading css={{ mb: '$space4' }}>Keywords</Heading>
          <Text>Add or edit Text to Buy keywords</Text>
        </div>
        <div>
          <Link to={Paths.Create}>
            <Button variant="primary" css={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="PlusSign" size="small" css={{ mr: '$space2' }} />
              Create keyword
            </Button>
          </Link>
        </div>
      </Box>
      <Box css={{ mt: '$space8' }}>
        <Select
          css={{ minWidth: PU_KEYWORD_FILTER_MIN_WIDTH, width: 'auto' }}
          size="small"
          value={state.filter}
          onChange={(newFilter: string) =>
            dispatch({ type: KeywordActions.FILTER_KEYWORDS, payload: newFilter, createToast })
          }
        >
          {Object.keys(KeywordFilterName).map((filterName) => (
            <Select.Item key={filterName} value={filterName}>
              {KeywordFilterName[filterName as KeywordFilter]}
            </Select.Item>
          ))}
        </Select>
      </Box>
      <Box css={{ mt: '$space8' }}>
        {state.filteredKeywords.length > 0 ? (
          state.filteredKeywords.map((keyword) => (
            <KeywordItem
              key={`keyword-${keyword.id}`}
              keyword={keyword}
              activate={() =>
                dispatch({
                  type: KeywordActions.ENABLE_KEYWORD_REQUEST,
                  payload: keyword,
                  createToast,
                })
              }
              deactivate={() =>
                dispatch({
                  type: KeywordActions.DISABLE_KEYWORD_REQUEST,
                  payload: keyword,
                  createToast,
                })
              }
            />
          ))
        ) : (
          <KeywordsEmptyList loading={state.loading} />
        )}
      </Box>
    </Box>
  );
};

export default PurchaseKeywordList;
