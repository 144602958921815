/**
 * @generated SignedSource<<867a140a0a0f68beba6214d1bbed0e06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanySearchFilter = {
  includeReadonly?: boolean | null;
  searchTerm?: string | null;
};
export type CompanyDropdown_Companies_refetchable$variables = {
  after?: string | null;
  filter?: CompanySearchFilter | null;
  pageSize?: number | null;
  selectedCompany: string;
};
export type CompanyDropdown_Companies_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CompanyDropdown_Companies_fragment">;
};
export type CompanyDropdown_Companies_refetchable = {
  response: CompanyDropdown_Companies_refetchable$data;
  variables: CompanyDropdown_Companies_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedCompany"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyDropdown_Companies_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompanyDropdown_Companies_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyDropdown_Companies_refetchable",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "CompanyDropdown_Companies_companies",
        "kind": "LinkedHandle",
        "name": "companies"
      },
      {
        "alias": "selectedCompany",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "selectedCompany"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c95f093a26af953c402280e72c24e85",
    "id": null,
    "metadata": {},
    "name": "CompanyDropdown_Companies_refetchable",
    "operationKind": "query",
    "text": "query CompanyDropdown_Companies_refetchable(\n  $after: String\n  $filter: CompanySearchFilter\n  $pageSize: Int\n  $selectedCompany: ID!\n) {\n  ...CompanyDropdown_Companies_fragment\n}\n\nfragment CompanyDropdown_Companies_fragment on Query {\n  companies(after: $after, first: $pageSize, filter: $filter) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  selectedCompany: node(id: $selectedCompany) {\n    __typename\n    ... on Company {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8131c8c517eb1548ae4235a2d8766a28";

export default node;
