import React from 'react';
import { Box, ButtonGroup } from '@attentive/picnic';
import { HistoryFilter } from '../../../../constants/EventDestinations/jobTypes';

interface FiltersProps {
  noOpFilterTab: number;
  toggleNoOpFilter: (updatedFilter: HistoryFilter, updatedTab: number) => void;
}

const NoOpFilter: React.FunctionComponent<FiltersProps> = ({ noOpFilterTab, toggleNoOpFilter }) => {
  return (
    <ButtonGroup activeItem={noOpFilterTab}>
      <ButtonGroup.Item
        value={HistoryFilter.INCLUDE_NOOP}
        onClick={() => toggleNoOpFilter(HistoryFilter.INCLUDE_NOOP, 0)}
      >
        {HistoryFilter.INCLUDE_NOOP}
      </ButtonGroup.Item>
      <ButtonGroup.Item
        value={HistoryFilter.EXCLUDE_NOOP}
        onClick={() => toggleNoOpFilter(HistoryFilter.EXCLUDE_NOOP, 1)}
      >
        {HistoryFilter.EXCLUDE_NOOP}
      </ButtonGroup.Item>
    </ButtonGroup>
  );
};

const Filters: React.FunctionComponent<FiltersProps> = ({ noOpFilterTab, toggleNoOpFilter }) => {
  return (
    <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
      <NoOpFilter noOpFilterTab={noOpFilterTab} toggleNoOpFilter={toggleNoOpFilter} />
    </Box>
  );
};

export default Filters;
