import { Form, Heading, Stack, styled } from '@attentive/picnic';
import React from 'react';
import { PollOptionType, PollType } from '../../../constants/PollSurveys/pollTypes';
import { SaveContainer } from '../PollDetailTabs/SaveBar';
import { SelectorType, SubscriberType } from './types';

const StyledFormField = styled(Form.FormField, { width: '100%' });
const FormRow = styled('div', {
  mb: '$space2',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: '$space3',
});

export const InnerForm: React.FC<{ poll: PollType }> = ({ poll }) => {
  return (
    <Stack spacing="$space4" css={{ width: '100%', mb: 250 }}>
      <Heading variant="lg">Particpant Filters</Heading>
      {/* numberOfWinners */}
      <StyledFormField name="numToSelect" css={{ width: '40%' }}>
        <Form.Label>Number of Winners</Form.Label>
        <Form.TextInput css={{ width: 125 }} type="number" placeholder="ex: 5" />
      </StyledFormField>

      <FormRow>
        {/* selectionType */}
        <StyledFormField name="selectorType">
          <Form.Label>Selection Option</Form.Label>
          <Form.HelperText>
            Determines how winners are selected from the pool of paricipants
          </Form.HelperText>
          <Form.Select>
            {Object.entries(SelectorType).map(([enumVal, enumName]) => (
              <Form.Select.Item key={enumVal} value={enumName}>
                {enumName}
              </Form.Select.Item>
            ))}
          </Form.Select>
        </StyledFormField>

        {/* subscriberType */}
        <StyledFormField name="subscriberType">
          <Form.Label>Subscriber Type</Form.Label>
          <Form.HelperText>
            Determines what type of subscribers are included in winners
          </Form.HelperText>
          <Form.Select>
            {Object.entries(SubscriberType).map(([enumVal, enumName]) => (
              <Form.Select.Item key={enumVal} value={enumName}>
                {enumName}
              </Form.Select.Item>
            ))}
          </Form.Select>
        </StyledFormField>
      </FormRow>

      {/* pollKeywordIds */}
      <StyledFormField name="pollKeywordIds">
        <Form.Label>Filter Poll Participants By Keyword</Form.Label>
        <Form.HelperText>Filter Parcipants based on submitted Poll Keywords</Form.HelperText>
        <Form.MultiSelect>
          {poll?.keywordOptions?.length ? (
            poll?.keywordOptions.map((option: PollOptionType) => (
              <Form.MultiSelect.Item key={option.keyword} value={option.id}>
                {option.keyword}
              </Form.MultiSelect.Item>
            ))
          ) : (
            <Form.MultiSelect.Item disabled={true} value="">
              No Keywords Created For Poll
            </Form.MultiSelect.Item>
          )}
        </Form.MultiSelect>
      </StyledFormField>

      <SaveContainer>
        <Form.SubmitButton>Submit</Form.SubmitButton>
      </SaveContainer>
    </Stack>
  );
};
