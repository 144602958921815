import React from 'react';

import { Box } from '@attentive/picnic';
import { EdsPerformanceStatusCodeReport, KeyValPair } from './types';
import RollupTotals from './RollupTotals';
import LastXFilters from './LastXFilter';
import StatusCodeBarChart from './StatusCodeBarChart';

import { getBarData } from './utils';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import PerformanceQuery, {
  PerformanceQuery as PerformanceQueryType,
  PerformanceQuery$data,
} from './__generated__/PerformanceQuery.graphql';

const useStatusCodeReports = (
  fetchedReports: PerformanceQuery$data
): EdsPerformanceStatusCodeReport[] => {
  const data: PerformanceQuery$data = fetchedReports;
  if (!data.edsLatestRunReports?.reports) {
    return [];
  }
  const reports: EdsPerformanceStatusCodeReport[] = data.edsLatestRunReports.reports.map((r) => {
    return {
      taskId: r.taskId,
      runUuid: r.runUuid,
      start: r.start,
      end: r.end,
      statusCodeCounts: r.statusCodeCounts.map((c): KeyValPair => ({ key: c.key, value: c.value })),
    };
  });

  return reports;
};

const JobPerformanceTabV3: React.FunctionComponent<{
  lastXRuns: number;
  setLastXRuns: (val: number) => void;
  queryRef: PreloadedQuery<PerformanceQueryType>;
}> = ({ lastXRuns, setLastXRuns, queryRef }) => {
  const fetchedReports = usePreloadedQuery(PerformanceQuery, queryRef);
  const realReports: EdsPerformanceStatusCodeReport[] = useStatusCodeReports(fetchedReports);
  const realData = getBarData(realReports);
  return (
    <Box>
      <LastXFilters lastXRuns={lastXRuns} setLastXRuns={setLastXRuns} />
      <RollupTotals reports={realReports} />
      <StatusCodeBarChart data={realData} />
    </Box>
  );
};

export default JobPerformanceTabV3;
