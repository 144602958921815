import { CreateToast } from '@attentive/acore-utils';
import { KeywordActions } from './keywordActions';

export interface Keyword {
  id?: number;
  keyword: string;
  state?: string;
  productId?: string;
  subProductId?: string;
  coupon: string;
  quantity: number;
  lastUpdated?: string;
  loading?: boolean;
  editing?: boolean;
}

export interface KeywordValidationState {
  keyword?: boolean;
  productId?: boolean;
  quantity?: boolean;
  initialValidation?: boolean;
}

export interface PurchaseKeywordState {
  keywords: Keyword[];
  filteredKeywords: Keyword[];
  updateKeyword?: Keyword;
  loading: boolean;
  filter: KeywordFilter;
}

export interface PurchaseKeywordAction {
  type: KeywordActions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  createToast: CreateToast;
}

export enum KeywordFilterNone {
  VALUE = 'ALL',
}

export enum KeywordStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type KeywordFilter = KeywordFilterNone | KeywordStatus;
