import {
  CustomActionAction,
  CustomActionDefinition,
  CustomActionsFilterNone,
  CustomActionsState,
  CustomActionStatus,
} from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsTypes';
import { CustomActionsActions } from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsActions';
import { logError } from '../../../utils/logger';
import { Toast, ToastType } from '@attentive/acore-utils';
import { fakeCustomActions } from './fakeCustomActions';

const filterCustomActions = (
  customActions: CustomActionDefinition[],
  filter: string
): CustomActionDefinition[] => {
  if (filter === CustomActionsFilterNone.VALUE) {
    return customActions;
  }
  return customActions.filter((keyword) => keyword.state === filter);
};

const sortCustomActionsByDate = (
  customActions: CustomActionDefinition[]
): CustomActionDefinition[] => {
  return customActions.sort((a, b) => {
    const aDate = a.lastUpdated ? a.lastUpdated : '';
    const bDate = b.lastUpdated ? b.lastUpdated : '';
    return Date.parse(bDate) - Date.parse(aDate);
  });
};

const fetchAllCustomActions = (action: CustomActionAction) => {
  if (action?.payload?.dispatch) {
    action.payload.dispatch({
      type: CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_SUCCESS,
      payload: fakeCustomActions,
    });
    // TacticalAPI.get<CustomActionDefinition[]>(baseURL)
    //     .then((response) =>
    //         action.payload.dispatch({
    //             type: CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_SUCCESS,
    //             payload: response.body,
    //         })
    //     )
    //     .catch((e) =>
    //         action.payload.dispatch({
    //             type: CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_FAILURE,
    //             payload: e
    //         })
    //     );
  }
};

const updateCustomAction = (
  payload: CustomActionDefinition,
  state: CustomActionsState
): CustomActionsState => {
  const updatedActions = state.customActions.map((customAction) =>
    customAction.id === payload.id ? payload : customAction
  );
  return {
    ...state,
    customActions: updatedActions,
    filteredCustomActions: filterCustomActions(updatedActions, state.filter),
  };
  // TacticalAPI.put<CustomActionDefinition>(`${baseURL}/${payload.id}`, payload)
  //   .then((response) =>
  //     action.payload.dispatch({
  //       type: CustomActionsActions.UPDATE_CUSTOM_ACTIONS_SUCCESS,
  //       payload: response.body,
  //     })
  //   )
  //   .catch((e) =>
  //     action.payload.dispatch({
  //       type: CustomActionsActions.UPDATE_CUSTOM_ACTIONS_FAILURE,
  //       payload: e,
  //     })
  //   );
};

const reducer = (state: CustomActionsState, action: CustomActionAction): CustomActionsState => {
  switch (action.type) {
    case CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_REQUEST:
      fetchAllCustomActions(action);
      return {
        ...state,
        loading: true,
      };

    case CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_SUCCESS: {
      const sortedActions = sortCustomActionsByDate(action.payload);
      return {
        ...state,
        customActions: sortedActions,
        filteredCustomActions: filterCustomActions(sortedActions, state.filter),
        loading: false,
      };
    }

    case CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_FAILURE:
      logError(action.payload instanceof Error ? action.payload : new Error(action.payload));
      Toast.create({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Unable to retrieve custom actions.',
      });
      return { ...state, loading: false };

    case CustomActionsActions.FILTER_CUSTOM_ACTIONS:
      return {
        ...state,
        filter: action.payload,
        filteredCustomActions: filterCustomActions(state.customActions, action.payload),
      };

    case CustomActionsActions.ENABLE_CUSTOM_ACTION_REQUEST:
      return updateCustomAction(
        { ...action.payload, enabled: true, state: CustomActionStatus.ACTIVE },
        state
      );

    case CustomActionsActions.DISABLE_CUSTOM_ACTION_REQUEST:
      return updateCustomAction(
        { ...action.payload, enabled: false, state: CustomActionStatus.INACTIVE },
        state
      );

    case CustomActionsActions.DELETE_CUSTOM_ACTION_REQUEST:
      // todo: kick off tactical api request (DELETE :id)
      Toast.create({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Oops no delete endpoint yet',
      });
      return state;

    case CustomActionsActions.UPDATE_CUSTOM_ACTIONS_SUCCESS:
      fetchAllCustomActions(action);
      return state;

    case CustomActionsActions.UPDATE_CUSTOM_ACTIONS_FAILURE:
      logError(action.payload instanceof Error ? action.payload : new Error(action.payload));
      Toast.create({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Unable to update custom action.',
      });
      return { ...state, loading: false };
  }
};

export default reducer;
