import React from 'react';
import { Box, Heading, Text, Switch } from '@attentive/picnic';
import { PollOptionSwitchType } from '../../../../../constants/PollSurveys/settingsConstants';
interface PollOptionSwitchProps {
  isChecked: boolean;
  pollOptionSwitchType: PollOptionSwitchType;
  onChange: (field: string, value: boolean) => void;
}

const PollOptionSwitch: React.FunctionComponent<PollOptionSwitchProps> = ({
  isChecked,
  pollOptionSwitchType,
  onChange,
}) => {
  const [isToggledOn, setIsToggledOn] = React.useState<boolean>(isChecked);
  const handleToggle = (checked: boolean) => {
    setIsToggledOn(checked);
    onChange(pollOptionSwitchType.option, checked);
  };
  return (
    <Switch checked={isToggledOn} onChange={handleToggle}>
      <Box>
        <Heading variant="sm">{pollOptionSwitchType.header}</Heading>
        <Text css={{ opacity: '0.6' }}>{pollOptionSwitchType.description}</Text>
      </Box>
    </Switch>
  );
};

export default PollOptionSwitch;
