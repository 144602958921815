/**
 * @generated SignedSource<<5e8245ed2c8364c725509340e028998b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
export type WithViewerRolesTacticalMfeQuery$variables = {
  companyId: string;
};
export type WithViewerRolesTacticalMfeQuery$data = {
  readonly viewer: {
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
  } | null;
};
export type WithViewerRolesTacticalMfeQuery = {
  response: WithViewerRolesTacticalMfeQuery$data;
  variables: WithViewerRolesTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          }
        ],
        "concreteType": "GetRolesResponse",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WithViewerRolesTacticalMfeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WithViewerRolesTacticalMfeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a4842e588443d9f64c2812c03ae574ae",
    "id": null,
    "metadata": {},
    "name": "WithViewerRolesTacticalMfeQuery",
    "operationKind": "query",
    "text": "query WithViewerRolesTacticalMfeQuery(\n  $companyId: ID!\n) {\n  viewer {\n    roles(companyId: $companyId) {\n      roles\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6eaa91d13b2f9a57eb4fcb273226b07f";

export default node;
