import React from 'react';

import logo from './assets/keyword.png';
import ProjectCard from './ProjectCard';
import { Paths } from '../../constants/routes';
import {
  ProjectCardConfig,
  TacticalHomeProjectCardProps,
} from '../../constants/Home/tacticalHomeTypes';

const config: ProjectCardConfig = {
  defaultEnabled: false,
  allowedRoles: ['ROLE_ENGINEERING', 'ROLE_CS_TAM'],
  heading: 'Custom Company Keywords',
  image: logo,
  path: Paths.CustomCompanyKeywords,
};

const CustomCompanyKeywordsCard: React.FunctionComponent<TacticalHomeProjectCardProps> = ({
  viewerRoles,
}) => {
  return <ProjectCard config={{ ...config, viewerRoles }} />;
};

export default CustomCompanyKeywordsCard;
