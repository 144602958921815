import { format, addMinutes } from 'date-fns';

export const dateToString = (date?: Date) => {
  if (date) {
    const formattedDate = format(date, 'MM/dd/yyyy HH:mm:ss');
    return `${formattedDate}`;
  }
  return '';
};

export const dateToStringLongForm = (date?: Date) => {
  if (date) {
    return format(date, 'E MMM d, y');
  }
};

export const dateToStringShortTime = (date?: Date) => {
  if (date) {
    const formattedDate = format(date, 'MM/dd/yy HH:mm');
    return `${formattedDate}`;
  }
  return '';
};

export const dateToStringDate = (date?: Date) => {
  if (date) {
    return format(date, 'MM/dd/yyyy');
  }
  return '';
};

export const dateToStringPlusGracePeriod = (date?: Date) => {
  if (date) {
    return `${format(addMinutes(date, 15), 'MM/dd/yyyy HH:mm:ss')} UTC`;
  }
  return '';
};

export const parseStringDateWithUTCTimeZone = (date: string) => {
  const newDate = new Date(date);
  return new Date(newDate.toLocaleString('en-US', { timeZone: 'UTC' }));
};
