import React, { useState, useEffect } from 'react';

import { IconButton, Popover } from '@attentive/picnic';
let clearPopoverTimer: ReturnType<typeof setTimeout>;

const CopyToClipboardButton: React.FunctionComponent<{
  textToCopy: string;
}> = ({ textToCopy }) => {
  const [showSuccessPopover, setShowSuccessPopover] = useState(false);

  clearPopoverTimer = setTimeout(() => {
    setShowSuccessPopover(false);
  }, 5000);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(textToCopy);
    setShowSuccessPopover(true);
  };

  useEffect(
    () => () => {
      if (clearPopoverTimer) {
        clearTimeout(clearPopoverTimer);
      }
    },
    []
  );

  return (
    <Popover open={showSuccessPopover} onOpenChange={setShowSuccessPopover}>
      <Popover.Anchor>
        <IconButton
          onClick={copyToClipboard}
          description="Copy to clipboard"
          iconName="Copy"
          variant="subdued"
          size="small"
        />
      </Popover.Anchor>
      <Popover.Content showCloseButton={false} css={{ width: 'auto' }}>
        Copied!
      </Popover.Content>
    </Popover>
  );
};

export default CopyToClipboardButton;
