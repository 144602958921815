import { Text, MultiSelect, SearchBar, Switch, styled, Stack } from '@attentive/picnic';
import React from 'react';
import { PollState, PollsFilterV2 } from './__generated__/PollsConnectedTableQuery.graphql';

const FormRow = styled('div', {
  gridTemplateColumns: '6fr 4fr auto',
  display: 'grid',
  width: '100%',
  gap: '$space2',
  mt: '$space8',
});

interface PollsSearchOptionsProps {
  filter: {
    get: PollsFilterV2;
    set: React.Dispatch<React.SetStateAction<PollsFilterV2>>;
  };
}

export const PollsSearchOptions: React.FC<PollsSearchOptionsProps> = ({ filter }) => {
  const { includedStates, searchTerm, showDeleted } = filter.get;
  return (
    <FormRow>
      <SearchBar
        size="normal"
        value={searchTerm || ''}
        onChange={(term) => {
          if (searchTerm !== term) {
            filter.set({ ...filter.get, searchTerm: term });
          }
        }}
      />
      <MultiSelect
        size="small"
        value={includedStates as string[]}
        placeholder={includedStates?.length === 0 ? 'Filter By State' : ''}
        onChange={(value: string[]) =>
          filter.set({
            ...filter.get,
            includedStates: value as PollState[],
          })
        }
      >
        <MultiSelect.Item value="OPEN">OPEN</MultiSelect.Item>
        <MultiSelect.Item value="CLOSED">CLOSED</MultiSelect.Item>
        <MultiSelect.Item value="OFF">OFF</MultiSelect.Item>
      </MultiSelect>
      <Stack css={{ alignItems: 'center' }} spacing="$space1">
        <Text variant="micro">Include Archived</Text>
        <Switch
          checked={showDeleted || false}
          onChange={(checked) => filter.set({ ...filter.get, showDeleted: checked })}
        />
      </Stack>
    </FormRow>
  );
};
