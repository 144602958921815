export interface PollOptionSwitchType {
  option: string;
  header: string;
  description: string;
}

export const MULTI_VOTE_OPTION: PollOptionSwitchType = {
  option: 'allowMultiVote',
  header: 'Enable Multi-vote',
  description:
    'Enable this option if you would like to allow participants to vote more than once. If disabled, the multivote disabled messages will be sent.',
};

export const BREAK_TIES_OPTION: PollOptionSwitchType = {
  option: 'allowBreakTies',
  header: 'Enable Break Ties',
  description:
    'Enable this option if you would like the winner to be picked at random when front-runners have equal votes. If disabled, it will not pick a winner.',
};

export const DECIMAL_PRECISION_OPTIONS = [0, 1, 2];

export enum WELCOME_JOURNEY_STATE {
  NONE = 'none',
  CUSTOM = 'custom',
}
