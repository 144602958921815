import React from 'react';
import { Box, Table, Button } from '@attentive/picnic';

import { HistoryFilter, JobHistory } from '../../../../constants/EventDestinations/jobTypes';
import { useJobHistory } from '../../../../pages/EventDestinations/graphql/WithEventDestinationJobHistory';
import { DATE_FORMAT_TYPE, getTomorrowFormatted } from '../utils/formattedDates';
import WithEventDestinationJobReport from '../../../../pages/EventDestinations/graphql/WithEventDestinationJobReport';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import Filters from './Filters';

const HEADERS = ['Date', 'Requests', 'Success Rate', 'Failure Count', 'Duration', 'Rate', 'Error'];

const ListBody: React.FunctionComponent<{ jobHistory: JobHistory[]; filter: HistoryFilter }> = ({
  jobHistory,
  filter,
}) => {
  return (
    <Table.Body>
      {jobHistory.map((run) => (
        <WithEventDestinationJobReport
          key={run.uuid}
          taskUuid={run.taskId}
          runUuid={run.uuid}
          start="2022-05-01T19:20:00.315Z"
          end={getTomorrowFormatted(DATE_FORMAT_TYPE.GRAPHQL)}
        >
          <TableRow run={run} filter={filter} />
        </WithEventDestinationJobReport>
      ))}
    </Table.Body>
  );
};

const JobHistoryList: React.FunctionComponent<{
  historyCount: number;
  setHistoryCount: (c: number) => void;
}> = ({ historyCount, setHistoryCount }) => {
  const jobHistory = useJobHistory();
  const [filterTab, setFilterTab] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<HistoryFilter>(HistoryFilter.INCLUDE_NOOP);

  const toggleHistoryFilter = (updatedFilter: HistoryFilter, tabIndex: number) => {
    setFilterTab(tabIndex);
    setFilter(updatedFilter);
  };

  const sortedFilteredJobHistory = jobHistory.sort(
    (a, b) => b.scheduledDate.getTime() - a.scheduledDate.getTime()
  );

  return (
    <Box>
      <Filters noOpFilterTab={filterTab} toggleNoOpFilter={toggleHistoryFilter} />
      <Table columnSizes={['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']}>
        <TableHeader headers={HEADERS} />
        <ListBody jobHistory={sortedFilteredJobHistory} filter={filter} />
      </Table>
      <Box css={{ textAlign: 'center', marginTop: '$space4' }}>
        <Button onClick={() => setHistoryCount(historyCount + 10)} variant="subdued">
          Load More
        </Button>
      </Box>
    </Box>
  );
};

export default JobHistoryList;
