import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { logError } from '../../utils/logger';
import {
  Box,
  Button,
  FormField,
  Heading,
  Text,
  TextInput,
  PicnicCss,
  FooterLayout,
} from '@attentive/picnic';
import { useToast, ToastType } from '@attentive/acore-utils';

import { TacticalAPI } from '../../utils/tacticalAPI';
import { Keyword, KeywordValidationState } from '../../constants/Keywords/keywordTypes';
import { ErrorResponse } from '../../utils/sharedTypes';

const emptyKeyword: Keyword = { keyword: '', productId: '', coupon: '', quantity: 0 };

const keywordInputStyle: PicnicCss = {
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  mb: '$space8',
};

const keywordFormStyle: PicnicCss = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  // This fixes TextInput box shadow issue
  pl: '$space1',
};

export const CreateEditKeywordPage: React.FunctionComponent = () => {
  const [createToast] = useToast();
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const isEdit = !!id;
  const [editKeyword, setEditKeyword] = React.useState<Keyword>(emptyKeyword);
  const [validationState, setValidationState] = React.useState<KeywordValidationState>({});

  const cancel = () => navigate('/tactical/keywords');

  const save = (modifiedKeyword: Keyword) => {
    let apiCall;
    if (isEdit) {
      // GMRU: PUT /keywords/{id}
      apiCall = TacticalAPI.put(`/keywords/${id}`, modifiedKeyword);
    } else {
      // GMRU: POST /keywords
      apiCall = TacticalAPI.post('/keywords', modifiedKeyword);
    }
    apiCall
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          // Error
          const body = response.body as ErrorResponse;
          if (response.status === 400) {
            createToast({
              type: ToastType.Error,
              title: response.statusText,
              text: body.message,
            });
            setValidationState({});
            return;
          }
          throw new Error(body.message);
        } else {
          navigate('/tactical/keywords');
          createToast({
            type: ToastType.Success,
            title: 'Success!',
            text: `Keyword successfully ${isEdit ? 'updated' : 'created'}.`,
          });
        }
      })
      .catch((err) => {
        logError(err);
        createToast({
          type: ToastType.Success,
          title: 'Something went wrong.',
          text: `Unable to ${isEdit ? 'update' : 'create'} keyword.`,
        });
      });
  };

  React.useEffect(() => {
    if (isEdit) {
      // GMRU: GET /user-profile/v1/subscriber-lists/manual-uploads/append
      TacticalAPI.get<Keyword>(`/keywords/${id}`)
        .then((response) => {
          if (response.status !== 200) {
            createToast({
              type: ToastType.Error,
              title: 'Something went wrong.',
              text: 'Unable to retreive the keyword',
            });
            cancel();
          } else if (response.body !== null) {
            setEditKeyword(response.body);
          }
          setValidationState({ initialValidation: false });
        })
        .catch((err) => {
          logError(err);
          createToast({
            type: ToastType.Error,
            title: 'Something went wrong.',
            text: 'Unable to retreive the keyword',
          });
          cancel();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateKeyword = (prop: string, value: string) => {
    const newEditKeyword = { ...editKeyword, [prop]: value };
    setEditKeyword(newEditKeyword);
  };

  const validateFormFields = (fieldName: keyof Keyword, targetValue?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = targetValue || editKeyword[fieldName];
    const { initialValidation: _initialValidation, ...newValidationState } = validationState;

    switch (fieldName) {
      case 'keyword':
        newValidationState[fieldName] = !value;
        break;
      case 'productId':
        newValidationState[fieldName] = !value;
        break;
      case 'quantity':
        newValidationState[fieldName] = !value || isNaN(value) || isNaN(Number.parseInt(value, 10));
        break;
      default:
        break;
    }
    setValidationState(newValidationState);
  };

  const isFormValid = () => {
    const values = Object.values(validationState);
    return values.length > 0 && !values.reduce((result, cur) => result || cur, false);
  };

  return (
    <FooterLayout css={{ height: '100%' }}>
      <FooterLayout.Content>
        <Box css={keywordFormStyle}>
          <Box css={{ display: 'flex', flexDirection: 'column', mb: '$space8' }}>
            <Heading css={{ mb: '$space2' }}>
              {isEdit ? 'Update' : 'Create'} Text to Buy keyword
            </Heading>
            <Text variant="caption">Fill out the fields below to update your keyword</Text>
          </Box>
          <FormField css={keywordInputStyle}>
            <FormField.Label requirement="required">Keyword</FormField.Label>
            <TextInput
              state={validationState.keyword ? 'error' : 'normal'}
              value={editKeyword.keyword}
              onChange={(e) => updateKeyword('keyword', e.target.value.toUpperCase())}
              onBlur={() => validateFormFields('keyword')}
            />
            {validationState.keyword && (
              <FormField.ErrorText>This field is required</FormField.ErrorText>
            )}
          </FormField>
          <FormField css={keywordInputStyle}>
            <FormField.Label requirement="required">Product Id</FormField.Label>
            <TextInput
              state={validationState.productId ? 'error' : 'normal'}
              value={editKeyword.productId}
              onChange={(e) => updateKeyword('productId', e.target.value)}
              onBlur={() => validateFormFields('productId')}
            />
            {validationState.productId && (
              <FormField.ErrorText>This field is required</FormField.ErrorText>
            )}
          </FormField>
          <FormField css={keywordInputStyle}>
            <FormField.Label requirement="optional">Discount Code</FormField.Label>
            <TextInput
              value={editKeyword.coupon || ''}
              onChange={(e) => updateKeyword('coupon', e.target.value)}
            />
          </FormField>
          <FormField css={keywordInputStyle}>
            <FormField.Label requirement="required">Quantity</FormField.Label>
            <TextInput
              state={validationState.quantity ? 'error' : 'normal'}
              value={`${editKeyword.quantity}`}
              onChange={(e) => updateKeyword('quantity', e.target.value)}
              onBlur={() => validateFormFields('quantity')}
            />
            {validationState.quantity && (
              <FormField.ErrorText>This field is required and must be a number</FormField.ErrorText>
            )}
          </FormField>
        </Box>
      </FooterLayout.Content>
      <FooterLayout.Footer>
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            py: '$space8',
          }}
        >
          <Box css={{ display: 'flex', width: '50%' }}>
            <Button css={{ flex: '1 0' }} variant="basic" onClick={cancel}>
              Cancel
            </Button>
            <Button
              css={{ flex: '1 0', ml: '$space4' }}
              onClick={() => save(editKeyword)}
              disabled={!isFormValid()}
            >
              {isEdit ? 'Update' : 'Create'} Keyword
            </Button>
          </Box>
        </Box>
      </FooterLayout.Footer>
    </FooterLayout>
  );
};

export default CreateEditKeywordPage;
