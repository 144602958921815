import React from 'react';
import { useQueryLoader } from 'react-relay';
import { useCurrentCompanyId } from '@attentive/acore-utils';
import { Box, LoadingIndicator } from '@attentive/picnic';

import { parseSortBy, parseSortDirection } from '../../../pages/CustomCompanyKeywords/helperFns';
import { KeywordOrderBy } from '../../../constants/CustomCompanyKeywords/keywordTypes';

import Query, {
  FetchCompanyKeywordsTacticalMfeQuery as QueryType,
} from './__generated__/FetchCompanyKeywordsTacticalMfeQuery.graphql';
import KeywordsTable from './KeywordsTable';

interface KeywordsTableContainerProps {
  search: string;
  filterByEnabled: boolean;
  sort: { get: KeywordOrderBy; set: React.Dispatch<React.SetStateAction<KeywordOrderBy>> };
  offset: { get: number; set: React.Dispatch<React.SetStateAction<number>> };
  pageSize: { get: number; set: React.Dispatch<React.SetStateAction<number>> };
}

const FallBack: React.FC = () => {
  return (
    <Box css={{ display: 'flex', justifyContent: 'center' }}>
      <LoadingIndicator />
    </Box>
  );
};

const KeywordsTableContainer: React.FunctionComponent<KeywordsTableContainerProps> = ({
  search,
  filterByEnabled,
  sort,
  offset,
  pageSize,
}) => {
  const companyId = useCurrentCompanyId();
  const [queryRef, loadQuery] = useQueryLoader<QueryType>(Query);

  React.useEffect(() => {
    const sortBy = parseSortBy(sort.get);
    const sortDirection = parseSortDirection(sort.get);
    loadQuery(
      {
        companyId,
        search,
        sortBy,
        enabled: filterByEnabled,
        sortDirection,
        offset: offset.get.toString(),
        pageSize: pageSize.get,
      },
      { fetchPolicy: 'store-and-network' }
    );
  }, [loadQuery, companyId, search, filterByEnabled, sort, pageSize.get, offset.get]);

  if (!queryRef) return null;

  return (
    <React.Suspense fallback={<FallBack />}>
      <KeywordsTable
        queryRef={queryRef}
        companyId={companyId}
        search={search}
        sort={sort}
        offset={offset}
        pageSize={pageSize}
      />
    </React.Suspense>
  );
};

export default KeywordsTableContainer;
