import React from 'react';
import { parse, format } from 'date-fns';

import { Box, Text, IconButton, DropdownMenu, ImagePreview } from '@attentive/picnic';

import {
  CustomActionDefinition,
  CustomActionStatus,
} from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsTypes';
import { CustomActionsMenuActions } from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsActions';
import CustomActionPillLabel from './CustomActionPillLabel';
import {
  customActionColumnStyle,
  customActionMaxColumnStyle,
  customActionRowDetailsInfoStyle,
  customActionRowDetailsStyle,
  customActionRowStyle,
  customActionValueStyle,
} from './customActionsStyles';

interface CustomActionItemProps {
  customAction: CustomActionDefinition;
  activate: () => void;
  deactivate: () => void;
}

interface ActionProps {
  customAction: CustomActionDefinition;
}

interface CustomActionValueProps {
  title: string;
  value: string;
}

interface EditActionProps {
  customAction: CustomActionDefinition;
  activate: () => void;
  deactivate: () => void;
  editCustomAction: (arg0: CustomActionDefinition) => void;
}

const CustomActionItemName: React.FunctionComponent<ActionProps> = ({ customAction }) => {
  const formatDate = (date: string | undefined) => {
    if (!date) return 'N/A';
    const dateObj = parse(date, 'MM/dd/yyyy', new Date());
    return format(dateObj, 'MMM dd, yyyy');
  };

  return (
    <div>
      <Text>{customAction.name}</Text>
      <Text color="subdued" variant="caption" css={customActionValueStyle}>
        Last edited {formatDate(customAction.lastUpdated)}
      </Text>
    </div>
  );
};

const CustomActionItemImage: React.FunctionComponent<CustomActionValueProps> = ({
  title,
  value,
}) => {
  return (
    <Box css={customActionColumnStyle}>
      <ImagePreview altText={title} src={value} />
    </Box>
  );
};

const CustomActionItemText: React.FunctionComponent<CustomActionValueProps> = ({
  title,
  value,
}) => {
  return (
    <Box css={customActionMaxColumnStyle}>
      <Text>{title}</Text>
      <Text color="subdued" variant="caption" css={customActionValueStyle}>
        {value}
      </Text>
    </Box>
  );
};

const CustomActionListMenu: React.FunctionComponent<EditActionProps> = ({
  customAction,
  activate,
  deactivate,
  editCustomAction,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <IconButton
          iconName="DotsVertical"
          size="small"
          variant="subdued"
          description="Open Menu"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end">
        <DropdownMenu.TextItem onSelect={() => editCustomAction(customAction)}>
          {CustomActionsMenuActions.EDIT}
        </DropdownMenu.TextItem>
        {customAction.state === CustomActionStatus.INACTIVE && (
          <DropdownMenu.TextItem onSelect={activate}>
            {CustomActionsMenuActions.ACTIVATE}
          </DropdownMenu.TextItem>
        )}
        {customAction.state === CustomActionStatus.ACTIVE && (
          <DropdownMenu.TextItem onSelect={deactivate}>
            {CustomActionsMenuActions.DEACTIVATE}
          </DropdownMenu.TextItem>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

export const CustomActionItem: React.FunctionComponent<CustomActionItemProps> = ({
  customAction,
  activate,
  deactivate,
}) => {
  const editCustomAction = () => {
    console.log('todo (sherr): make edit page for custom action');
  };

  return (
    <Box css={customActionRowStyle}>
      <CustomActionItemImage title="Icon image url" value={customAction.imageUrl} />
      <CustomActionItemName customAction={customAction} />
      <Box css={customActionRowDetailsStyle}>
        <Box css={customActionRowDetailsInfoStyle}>
          <CustomActionPillLabel state={customAction.state} />
          <CustomActionItemText title="Description" value={customAction.descriptionShort} />
        </Box>
        <CustomActionItemText title="Visibility" value={customAction.actionVisibilityType} />
        <CustomActionListMenu
          customAction={customAction}
          editCustomAction={editCustomAction}
          activate={activate}
          deactivate={deactivate}
        />
      </Box>
    </Box>
  );
};

export default CustomActionItem;
