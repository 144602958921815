import React from 'react';

import { Box, Heading, Card, styled, Text } from '@attentive/picnic';
import { Link } from 'react-router-dom';

interface TacticalCardProps {
  isEnabled: boolean;
  path: string;
  heading: string;
  imgUrl?: string;
  subheading?: string;
}

const Logo = styled('img', {
  width: '$size14',
  height: '$size14',
  border: '$borderWidths$borderWidth1 solid $grayscale200',
  borderRadius: '$radius1',
  'object-fit': 'contain',
});

const TacticalCard: React.FunctionComponent<TacticalCardProps> = ({
  isEnabled,
  path,
  heading,
  subheading,
  imgUrl,
}) => {
  return (
    <Link to={isEnabled && path ? path : '#'}>
      <Card
        css={{ px: '$space4', py: '$space5', minWidth: '400px', maxWidth: '600px', m: '$space2' }}
        interactive={isEnabled}
      >
        <Box css={{ opacity: isEnabled ? 1.0 : 0.4, display: 'flex', alignItems: 'center' }}>
          {imgUrl ? <Logo src={imgUrl} alt={heading} /> : null}
          <Box css={{ ml: '$space3', overflow: 'hidden' }}>
            <Heading
              variant="sm"
              css={{ textOverflow: 'ellipsis', whitespace: 'nowrap', overflow: 'hidden' }}
            >
              {heading}
            </Heading>
            {subheading ? <Text variant="caption">{subheading}</Text> : null}
          </Box>
        </Box>
      </Card>
    </Link>
  );
};

export default TacticalCard;
