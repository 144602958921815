import React from 'react';
import { NotFound } from '@attentive/acore-utils';
import { Route, Routes } from 'react-router-dom';
import { RoutePaths } from './constants/routes';

import CustomActionsAdminPage from './pages/CustomActions/CustomActionsAdminPage';
import DestinationPage from './pages/EventDestinations/Destinations/DestinationPage';
import DestinationsPage from './pages/EventDestinations/Destinations/DestinationsPage';
import EndpointPage from './pages/EventDestinations/Endpoints/EndpointPage';
import EndpointsPage from './pages/EventDestinations/Endpoints/EndpointsPage';
import CreatePage from './pages/EventDestinations/Jobs/CreateJob';
import EventDestinationsHome from './pages/EventDestinations/HomePage';
import JobPage from './pages/EventDestinations/Jobs/JobPage';
import JobsPage from './pages/EventDestinations/Jobs/JobsPage';
import EventReplayCreatePage from './pages/EventReplay/EventReplayCreatePage';
import EventReplayDetailsPage from './pages/EventReplay/EventReplayDetailsPage';
import EventReplayLandingPage from './pages/EventReplay/EventReplayLandingPage';
import KeywordPage from './pages/KeywordPage';
import CreateEditKeywordPage from './pages/KeywordPage/CreateEditKeywordPage';
import CreateNewPoll from './pages/PollSurveys/PollsCreatePage';
import PollDetailsPage from './pages/PollSurveys/PollDetailsPage';
import PollsHomePage from './pages/PollSurveys/PollsHomePage';
import PrivacyRequestPage from './pages/PrivacyRequests/PrivacyRequestPage';
import SalesforceMarketingCloudPage from './pages/Salesforce/SalesforceMarketingCloudPage';
import SalesforceServiceCloudPage from './pages/Salesforce/SalesforceServiceCloudPage';
import TacticalHomePage from './pages/TacticalHomePage';
import EdsClientPage from './pages/EventDestinations/Client/EdsClientPage';
import EdsClientJobPage from './pages/EventDestinations/Client/EdsClientJobPage';
import { CustomCompanyKeywordsPage } from './pages/CustomCompanyKeywords/CustomCompanyKeywordsPage';
import CustomCompanyKeywordPage from './pages/CustomCompanyKeywords/CustomCompanyKeywordPage';
import CustomCompanyKeywordBulkUpload from './pages/CustomCompanyKeywords/CustomCompanyKeywordBulkUpload';
import { MobileWalletHome } from './pages/MobileWallet/MobileWalletHome';

export const TacticalMfeRoutes: React.VFC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<TacticalHomePage />} />
      <Route path={RoutePaths.Keywords} element={<KeywordPage />} />
      <Route path={RoutePaths.Create} element={<CreateEditKeywordPage />} />
      <Route path={RoutePaths.Edit} element={<CreateEditKeywordPage />} />
      <Route path={RoutePaths.SalesforceServiceCloud} element={<SalesforceServiceCloudPage />} />
      <Route
        path={RoutePaths.SalesforceMarketingCloud}
        element={<SalesforceMarketingCloudPage />}
      />
      <Route path={RoutePaths.CustomActionsAdminPage} element={<CustomActionsAdminPage />} />
      <Route path={RoutePaths.CustomActionsAdminDefault} element={<CustomActionsAdminPage />} />
      <Route path={RoutePaths.EventReplayPage} element={<EventReplayLandingPage />} />
      <Route path={RoutePaths.EventReplayCreate} element={<EventReplayCreatePage />} />
      <Route path={RoutePaths.EventReplayDetails} element={<EventReplayDetailsPage />} />
      <Route path={RoutePaths.EventDestinations} element={<EventDestinationsHome />} />
      <Route path={RoutePaths.EventDestinationsCreate} element={<CreatePage />} />
      <Route path={RoutePaths.EventDestinationsJobs} element={<JobsPage />} />
      <Route path={RoutePaths.EventDestinationsJob} element={<JobPage />} />
      <Route path={RoutePaths.EventDestinationsDestinations} element={<DestinationsPage />} />
      <Route path={RoutePaths.EventDestinationsDestination} element={<DestinationPage />} />
      <Route path={RoutePaths.PrivacyRequests} element={<PrivacyRequestPage />} />
      <Route path={RoutePaths.EventDestinationsNodes} element={<EndpointsPage />} />
      <Route path={RoutePaths.EventDestinationsNode} element={<EndpointPage />} />
      <Route path={RoutePaths.PollSurveys} element={<PollsHomePage />} />
      <Route path={RoutePaths.PollCreateNew} element={<CreateNewPoll />} />
      <Route path={RoutePaths.PollSurveyDetails} element={<PollDetailsPage />} />
      <Route path={RoutePaths.ClientEdsPage} element={<EdsClientPage />} />
      <Route path={RoutePaths.ClientEdsJobPage} element={<EdsClientJobPage />} />
      <Route path={RoutePaths.CustomCompanyKeywordList} element={<CustomCompanyKeywordsPage />} />
      <Route path={RoutePaths.CustomCompanyKeywordId} element={<CustomCompanyKeywordPage />} />
      <Route
        path={RoutePaths.CustomCompanyKeywordBulkUpload}
        element={<CustomCompanyKeywordBulkUpload />}
      />
      <Route path={RoutePaths.MobileWallet} element={<MobileWalletHome />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
