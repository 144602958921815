export const containsSharedRole = (allowedRoles: string[], userRoles?: string[]) => {
  if (!userRoles) {
    return false;
  }

  for (let i = 0; i < userRoles.length; i++) {
    const userRole = userRoles[i];
    if (allowedRoles.includes(userRole)) {
      return true;
    }
  }
  return false;
};
