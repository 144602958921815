import { EventReplayStatus } from '@attentive/data/types';

import { EventReplayRequest } from '../../../constants/EventReplay/eventReplayTypes';
import { EventReplayByCompanyIdsTacticalMfeQuery$data as EventReplayByCompanyIdsTacticalMfeQueryResponse } from '../../../pages/EventReplay/__generated__/EventReplayByCompanyIdsTacticalMfeQuery.graphql';

import { parseStringDateWithUTCTimeZone } from './dateFormatter';

const parseTitle = (
  title: string | null | undefined,
  startDate: SerializedDateTime | undefined,
  endDate: SerializedDateTime | undefined
) => {
  return title ? title : `Event Replay requested for ${startDate} - ${endDate}`;
};

const parseStatus = (status?: string) => {
  const parsedStatus = status as keyof typeof EventReplayStatus;
  return parsedStatus;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const parseEventTypes = (eventTypes: any): string[] => {
  const res = [];
  for (const eventType in eventTypes.eventEstimates) {
    res.push(eventType);
  }
  return res;
};

const parseEstimatedCounts = (estCounts?: { [key: string]: { [key: string]: number } }) => {
  if (estCounts && estCounts.eventEstimates) {
    return estCounts.eventEstimates;
  }
  return {};
};

const parseIntegrationEstimatedCounts = (estCounts?: { [key: string]: string }) => {
  if (estCounts && estCounts.integrationEstimates) {
    return estCounts.integrationEstimates;
  }
  return {};
};

const totalEstimatedCounts = (estimatedCounts?: { [key: string]: [key: number] }) => {
  let total = 0;
  if (estimatedCounts && estimatedCounts.eventEstimates) {
    const eventEstimates = estimatedCounts.eventEstimates;
    for (const key in eventEstimates) {
      total += eventEstimates[key];
    }
  }
  return total;
};

export const parseQueryResponse = (
  response: EventReplayByCompanyIdsTacticalMfeQueryResponse
): EventReplayRequest[] => {
  const eventReplayRequests: EventReplayRequest[] = [];
  const companies = response.companiesByInternalId?.companies;
  if (companies) {
    for (let i = 0; i < companies.length; i++) {
      const company = companies[i];
      console.log('COMPANY ', company);
      const eventReplays = company?.eventReplays?.eventReplay;
      if (eventReplays) {
        for (let j = 0; j < eventReplays?.length; j++) {
          const eventReplay = eventReplays[j];
          console.log('EVENT REPLAY ', eventReplay);
          let parsedEvents = {};
          if (eventReplay && eventReplay?.estimatedEventCounts) {
            parsedEvents = JSON.parse(eventReplay?.estimatedEventCounts);
          }
          const replay: EventReplayRequest = {
            id: eventReplay?.id,
            title: parseTitle(
              eventReplay?.title,
              eventReplay?.eventRangeStart,
              eventReplay?.eventRangeEnd
            ),
            description: eventReplay?.description ? eventReplay?.description : '',
            startDate: parseStringDateWithUTCTimeZone(`${eventReplay?.eventRangeStart}`),
            endDate: parseStringDateWithUTCTimeZone(`${eventReplay?.eventRangeEnd}`),
            status: parseStatus(eventReplay?.status.toString()),
            eventTypes: parseEventTypes(parsedEvents),
            estimatedEventCounts: parseEstimatedCounts(parsedEvents),
            estimatedCountSum: totalEstimatedCounts(parsedEvents),
            estimatedIntegrationEventCounts: parseIntegrationEstimatedCounts(parsedEvents),
            companyId: company?.internalId,
            companyName: company?.displayName,
            companyGraphQLId: company?.id,
            created: parseStringDateWithUTCTimeZone(`${eventReplay?.created}`),
            updated: parseStringDateWithUTCTimeZone(`${eventReplay?.updated}`),
          };
          eventReplayRequests.push(replay);
        }
      }
    }
  }
  return eventReplayRequests.sort((a, b) => a.created.valueOf() - b.created.valueOf()).reverse();
};
