import React from 'react';
import { Box, SearchBar } from '@attentive/picnic';
import { searchContainerStyling } from './styling';
import { SetStateAction } from 'jotai';

interface SearchProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<SetStateAction<string>>;
}

const Search: React.FunctionComponent<SearchProps> = ({ searchTerm, setSearchTerm }) => {
  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };

  return (
    <Box css={searchContainerStyling}>
      <SearchBar
        onChange={handleSearch}
        size="small"
        css={{ color: 'Grey', border: 'Grey' }}
        value={searchTerm}
      />
    </Box>
  );
};

export default Search;
