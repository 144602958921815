import React from 'react';
import { Box } from '@attentive/picnic';
import DuplicateKeywordsBanner from './DuplicateKeywordsBanner';

const PollBanner: React.FunctionComponent = () => {
  return (
    <Box css={{ mb: '$space2' }}>
      <DuplicateKeywordsBanner />
    </Box>
  );
};

export default PollBanner;
