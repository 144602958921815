import React from 'react';
import { Banner } from '@attentive/picnic';

export const TacticalError: React.FC<{ header?: string; message?: string }> = ({
  header,
  message,
}) => {
  return (
    <Banner variant="error">
      <Banner.Heading>{header ? header : 'An error occurred'}</Banner.Heading>
      <Banner.Text>
        {message
          ? message
          : 'Try refreshing the page. If you still experience errors please reach out to #eng-tactical.'}
      </Banner.Text>
    </Banner>
  );
};
