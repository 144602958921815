import React from 'react';
import { Box, Button, Heading } from '@attentive/picnic';
import { Link } from 'react-router-dom';

import TacticalBreadcrumbs, {
  EVENT_DESTINATION_BREADCRUMB,
} from '../../../components/common/TacticalBreadcrumbs';
import DestinationList from '../../../components/EventDestinations/Destinations/DestinationList';
import JobsPage from '../Jobs/JobsPage/index';
import { Paths } from '../../../constants/routes';
import {
  destinationContainerStyle,
  headerButtonStyle,
  headerContainerStyle,
  jobContainerStyle,
  jobListStyle,
} from './styles';

const Header: React.FunctionComponent = () => {
  return (
    <Box>
      <TacticalBreadcrumbs breadcrumbs={[EVENT_DESTINATION_BREADCRUMB]} />
      <Box css={headerContainerStyle}>
        <Heading>Welcome to event destinations!</Heading>
        <Box>
          <Button css={headerButtonStyle}>
            <Link to={Paths.EventDestinationsCreate}>Create Job</Link>
          </Button>
          <Button css={headerButtonStyle} variant="secondary">
            <Link to={Paths.EventDestinationsJobs}>See All Jobs</Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const Destinations: React.FunctionComponent = () => {
  return (
    <Box css={destinationContainerStyle}>
      <Heading variant="subheading">Check out some destinations:</Heading>

      <DestinationList />
    </Box>
  );
};

const Jobs: React.FunctionComponent = () => {
  return (
    <Box css={jobContainerStyle}>
      <Heading variant="subheading">What jobs are active:</Heading>
      <Box css={jobListStyle}>
        <JobsPage />
      </Box>
    </Box>
  );
};

const EventDestinations: React.FunctionComponent = () => {
  return (
    <Box>
      <Header />
      <Destinations />
      <Jobs />
    </Box>
  );
};

export default EventDestinations;
