import React from 'react';

import { Box, Heading, Text } from '@attentive/picnic';
import TacticalBreadcrumbs, {
  EVENT_DESTINATION_BREADCRUMB,
  EVENT_DESTINATION_DESTINATION_BREADCRUMB,
} from '../../../components/common/TacticalBreadcrumbs';

import DestinationList from '../../../components/EventDestinations/Destinations/DestinationList';

const EventDestinationsDestinationsPage: React.FunctionComponent = () => {
  return (
    <Box>
      <TacticalBreadcrumbs
        breadcrumbs={[EVENT_DESTINATION_BREADCRUMB, EVENT_DESTINATION_DESTINATION_BREADCRUMB]}
      />
      <Box css={{ display: 'flex', flexDirection: 'column', flex: 0.5 }}>
        <div>
          <Heading css={{ mb: '$space2' }}>Destination List</Heading>
          <Text css={{ fontSize: '$fontSize3' }}>Add or manage your destinations.</Text>
        </div>
      </Box>
      <Box css={{ mt: '$space4' }}>
        <DestinationList />
      </Box>
    </Box>
  );
};

export default EventDestinationsDestinationsPage;
