import React from 'react';
import { Table } from '@attentive/picnic';

const TableHeader: React.FunctionComponent<{ headers: string[] }> = ({ headers }) => {
  return (
    <Table.Header>
      <Table.HeaderRow>
        {headers.map((header) => (
          <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
        ))}
      </Table.HeaderRow>
    </Table.Header>
  );
};

export default TableHeader;
