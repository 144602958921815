import { Box, Button, styled } from '@attentive/picnic';
import React from 'react';

export const SaveContainer = styled(Box, {
  borderTop: '0.5px solid $borderDefault',
  position: 'absolute',
  bottom: 0,
  backgroundColor: '#ffffff',
  width: 'stretch',
  pr: '$space4',
  pt: '$space4',
  pb: '$space4',
  mt: '$space14',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const SaveBar: React.FC<{ handleSave: () => void }> = ({ handleSave }) => {
  return (
    <SaveContainer>
      <Button onClick={handleSave}>Save Settings</Button>
    </SaveContainer>
  );
};
