import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { parseStringDateWithUTCTimeZone } from './utils/dateFormatter';

import {
  Box,
  Text,
  Heading,
  FormField,
  TextInput,
  DatePicker,
  Checkbox,
  Stack,
  Button,
} from '@attentive/picnic';

import { Paths } from '../../constants/routes';
import { CreateEventReplayInput, EventReplayEventType } from '@attentive/data/types';
import { useEventReplayCompanyBodyTacticalMfeQueryData } from '../../pages/EventReplay/EventReplayCompanyBodyTacticalMfeQuery';
import { castStringAsSerializedDateTime } from '@attentive/data';

interface EventReplayCreateFormProps {
  onSubmit: (input: CreateEventReplayInput) => void;
}

const EVENT_TYPES: EventReplayEventType[] = [
  EventReplayEventType.SubscriptionOptInStarted,
  EventReplayEventType.SubscriptionOptInCompleted,
  EventReplayEventType.SubscriptionOptedOut,
  EventReplayEventType.Join,
  EventReplayEventType.EmailSave,
  EventReplayEventType.UserPropertySet,
  EventReplayEventType.MessageLinkClick,
  EventReplayEventType.MessageReceipt,
  EventReplayEventType.SubscriptionAlreadySubscribed,
  EventReplayEventType.JourneyPostback,
];
const DEFAULT_TIME = '12:00:00';

const EventReplayCreateForm: React.FunctionComponent<EventReplayCreateFormProps> = ({
  onSubmit,
}) => {
  const [title, setTitle] = React.useState<string>('');
  const [isTitleError, setIsTitleError] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>('');
  const [startDate, setStartDate] = React.useState<string>('');
  const [startTime, setStartTime] = React.useState<string>(DEFAULT_TIME);
  const [startTimeError, setStartTimeError] = React.useState<string>('');
  const [isStartTimeError, setIsStartTimeError] = React.useState<boolean>(false);
  const [endDate, setEndDate] = React.useState<string>('');
  const [endTime, setEndTime] = React.useState<string>(DEFAULT_TIME);
  const [endTimeError, setEndTimeError] = React.useState<string>('');
  const [isEndTimeError, setIsEndTimeError] = React.useState<boolean>(false);
  const [isDateRangeError, setIsDateRangeError] = React.useState<boolean>(false);
  const [eventTypes, setEventTypes] = React.useState<EventReplayEventType[]>([]);
  const [isEventTypesError, setIsEventTypesError] = React.useState<boolean>(false);

  const queryData = useEventReplayCompanyBodyTacticalMfeQueryData();
  const companyId = queryData.viewer?.account?.recentCompanies?.edges?.find(
    (edge) => edge?.node?.id
  )?.node.id;

  const toggleEventType = (eventTypeVal: EventReplayEventType) => {
    const tempEventTypes = eventTypes.slice();
    const index = eventTypes.indexOf(eventTypeVal);
    if (index > -1) {
      tempEventTypes.splice(index, 1);
    } else {
      tempEventTypes.push(eventTypeVal);
    }
    setEventTypes(tempEventTypes);
  };

  const isChecked = (eventType: EventReplayEventType) => {
    return eventTypes.includes(eventType);
  };

  // Must be RFC3339 for graphql (https://validator.w3.org/feed/docs/error/InvalidRFC3339Date.html)
  // example: 2002-10-02T15:00:00Z
  const parseDateTime = (rangeDate: Date, time: string): SerializedDateTime => {
    const date = format(rangeDate, 'yyyy-MM-dd');
    return castStringAsSerializedDateTime(`${date}T${time}Z`);
  };

  const validate = (): boolean => {
    let isValid = true;
    if (!title) {
      isValid = false;
      setIsTitleError(true);
    } else {
      setIsTitleError(false);
    }

    if (!startDate || !endDate) {
      isValid = false;
      setIsDateRangeError(true);
    } else {
      setIsDateRangeError(false);
    }

    if (eventTypes.length < 1) {
      isValid = false;
      setIsEventTypesError(true);
    } else {
      setIsEventTypesError(false);
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      const eventRangeStart = parseDateTime(parseStringDateWithUTCTimeZone(startDate), startTime);
      const eventRangeEnd = parseDateTime(parseStringDateWithUTCTimeZone(endDate), endTime);
      const payload = {
        companyId: companyId ? companyId : '',
        title,
        description,
        eventRangeStart,
        eventRangeEnd,
        eventTypes,
      };
      onSubmit(payload);
    }
  };

  const MILITARY_TIME_REGEX = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9])|(24:?00))/i;

  const validateTime = (selectedTime: string): boolean => {
    return MILITARY_TIME_REGEX.test(selectedTime);
  };

  const handleStartTimeChange = (time: string) => {
    setStartTime(time);
    setIsStartTimeError(!validateTime(time));
  };

  const handleStartTime = () => {
    if (!validateTime(startTime)) {
      setStartTimeError('Must be military time HH:MM:SS');
    } else {
      setStartTimeError('');
    }
  };

  const handleEndTimeChange = (time: string) => {
    setEndTime(time);
    setIsEndTimeError(!validateTime(time));
  };

  const handleEndTime = () => {
    if (!validateTime(endTime)) {
      setEndTimeError('Must be military time HH:MM:SS');
    } else {
      setEndTimeError('');
    }
  };

  const validateDate = (date: string) => {
    const today = new Date();
    const lessThanToday = new Date(date) < today;
    // todo: validate startDate < endDate
    return lessThanToday;
  };

  const handleEndDateChange = (date: string) => {
    setEndDate(date);
    if (!validateDate(date)) {
      setEndTimeError('Must pick a date before today');
    } else {
      setEndTimeError('');
    }
  };

  const handleStartDateChange = (date: string) => {
    setStartDate(date);
    if (!validateDate(date)) {
      setStartTimeError('Must pick date before today');
    } else {
      setStartTimeError('');
    }
  };

  return (
    <Box css={{ width: '100%' }}>
      {/* Heading */}
      <Box>
        <Heading css={{ mb: '$space4' }}>Create Event Replay Request</Heading>
        <Text>Fill out the fields below to create an event replay request</Text>
      </Box>
      {/* Body */}
      <Box css={{ mt: '$space4', ml: '$space4' }}>
        {/* Title */}
        <FormField css={{ maxWidth: '400px', mt: '$space2', mb: '$space2' }}>
          <FormField.Label>Title</FormField.Label>
          {isTitleError ? (
            <Text variant="micro" color="critical">
              Title is required
            </Text>
          ) : null}
          <TextInput size="normal" onChange={(e) => setTitle(e.target.value)} />
        </FormField>
        {/* Description */}
        <FormField css={{ maxWidth: '400px', mt: '$space2' }}>
          <FormField.Label>Description</FormField.Label>
          <TextInput size="normal" onChange={(e) => setDescription(e.target.value)} />
        </FormField>
        {/* Date Ranges */}
        <FormField css={{ maxWidth: '400px', mt: '$space2' }}>
          <FormField.Label>Event Date Ranges</FormField.Label>
          {isDateRangeError ? (
            <Text variant="micro" color="critical">
              Date ranges are required
            </Text>
          ) : null}
          <Box css={{ display: 'flex' }}>
            <Stack css={{ mr: '$space3' }}>
              <DatePicker
                value={startDate}
                onChange={handleStartDateChange}
                placeholder="Start of Replay"
              />
              <TextInput
                value={startTime}
                onChange={(e) => handleStartTimeChange(e.target.value)}
                onBlur={handleStartTime}
                state={isStartTimeError ? 'error' : 'normal'}
              />
              {startTimeError.length ? (
                <Text variant="micro" color="critical">
                  {startTimeError}
                </Text>
              ) : null}
            </Stack>
            <Stack css={{ mr: '$space3' }}>
              <DatePicker
                value={endDate}
                onChange={handleEndDateChange}
                placeholder="End of Replay"
              />
              <TextInput
                value={endTime}
                onChange={(e) => handleEndTimeChange(e.target.value)}
                onBlur={handleEndTime}
                state={isEndTimeError ? 'error' : 'normal'}
              />
              {endTimeError.length ? (
                <Text variant="micro" color="critical">
                  {endTimeError}
                </Text>
              ) : null}
            </Stack>
          </Box>
        </FormField>
        {/* Event Types */}
        <FormField css={{ mt: '$space2' }}>
          <FormField.Label>Event Types</FormField.Label>
          {isEventTypesError ? (
            <Text variant="micro" color="critical">
              Minimum 1 event type selected
            </Text>
          ) : null}
          <Stack css={{ ml: '$space4' }}>
            {EVENT_TYPES.map((eventType) => (
              <Checkbox
                key={eventType}
                onChange={() => toggleEventType(eventType)}
                checked={isChecked(eventType)}
              >
                {eventType}
              </Checkbox>
            ))}
          </Stack>
        </FormField>
      </Box>
      {/* Submit Buttons */}
      <Box css={{ mt: '$space4', mr: '$space4', display: 'flex', justifyContent: 'flex-end' }}>
        <Link to={Paths.EventReplayPage}>
          <Button variant="secondary" css={{ m: '$space2' }}>
            Cancel
          </Button>
        </Link>
        <Button variant="primary" css={{ m: '$space2' }} onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default EventReplayCreateForm;
