/**
 * @generated SignedSource<<29986f1dabe2f6136799cf3e7837bd11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeDropdown_fragment$data = {
  readonly eventDestinations: {
    readonly nodesByTag: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly metadata: any;
          readonly name: string;
          readonly tags: ReadonlyArray<string>;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | null;
  readonly " $fragmentType": "NodeDropdown_fragment";
};
export type NodeDropdown_fragment$key = {
  readonly " $data"?: NodeDropdown_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeDropdown_fragment">;
};

import NodeDropdown_refetchable_graphql from './NodeDropdown_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "eventDestinations",
  "nodesByTag"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "tag"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": NodeDropdown_refetchable_graphql
    }
  },
  "name": "NodeDropdown_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventDestinations",
      "kind": "LinkedField",
      "name": "eventDestinations",
      "plural": false,
      "selections": [
        {
          "alias": "nodesByTag",
          "args": [
            {
              "kind": "Variable",
              "name": "tag",
              "variableName": "tag"
            }
          ],
          "concreteType": "EventDestinationNodeConnection",
          "kind": "LinkedField",
          "name": "__NodeDropdownQuery_nodesByTag_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EventDestinationNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EventDestinationNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "uuid",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tags",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "metadata",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2db7408e0f2f6d30531d4ffe018c966a";

export default node;
