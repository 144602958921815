import React from 'react';
import { Box, Button } from '@attentive/picnic';

const FooterButtons: React.FunctionComponent<{
  onAddKeyword?: () => void;
}> = ({ onAddKeyword }) => {
  return (
    <Box css={{ mb: '$space6', display: 'flex', justifyContent: 'flex-end' }}>
      <Button onClick={onAddKeyword} variant="secondary">
        Add Keyword
      </Button>
    </Box>
  );
};

export default FooterButtons;
