import { Box, Button, Heading } from '@attentive/picnic';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PollBanner from '../../components/PollSurveys/PollBanner';
import { PollsTable } from '../../components/PollSurveys/PollsList/PollsConnectedTable';
import { PollsSearchOptions } from '../../components/PollSurveys/PollsList/PollsListSearchOptions';
import {
  PollSorting,
  PollsFilterV2,
} from '../../components/PollSurveys/PollsList/__generated__/PollsConnectedTableQuery.graphql';
import TacticalBreadcrumbs, {
  POLL_SURVEY_BREADCRUMB,
} from '../../components/common/TacticalBreadcrumbs';
import { Paths } from '../../constants/routes';
import { PollSortingField, SortDirection } from '@attentive/data/types';

function useUrlParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useSortIfPresent = (urlParams: URLSearchParams) => {
  const sortByParam = urlParams.get('sortBy');
  const sortDirParam = urlParams.get('sortDirection');
  if (sortByParam && sortDirParam) {
    return {
      sortBy: sortByParam as PollSortingField,
      sortDirection: sortDirParam as SortDirection,
    };
  }
  return null;
};

const useFilterIfPresent = (urlParams: URLSearchParams) => {
  const statesParam = urlParams.get('includedStates');
  return {
    searchTerm: urlParams.get('searchTerm') || '',
    includedStates: statesParam ? JSON.parse(statesParam) : [],
    showDeleted: urlParams.get('includeArchived') === 'true',
  };
};

const PollsHomePage: React.FunctionComponent = () => {
  const urlParams = useUrlParams();

  const [filter, setFilter] = useState<PollsFilterV2>(useFilterIfPresent(urlParams));
  const [sorting, setSorting] = useState<PollSorting | null>(useSortIfPresent(urlParams));

  return (
    <Box>
      <TacticalBreadcrumbs breadcrumbs={[POLL_SURVEY_BREADCRUMB]} />
      <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading>Polls</Heading>
        <Button css={{ mr: '$space2' }}>
          <Link to={Paths.PollCreateNew}>Create New</Link>
        </Button>
      </Box>
      <Box css={{ mt: '$space10' }}>
        <PollBanner />
        <PollsSearchOptions filter={{ get: filter, set: setFilter }} />
        <Box css={{ mt: '$space6' }}>
          <PollsTable
            filter={{ get: filter, set: setFilter }}
            sorting={{ get: sorting, set: setSorting }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PollsHomePage;
