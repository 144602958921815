import _FetchCompanyKeywordRulesInfoTacticalMfeQuery from "./__generated__/FetchCompanyKeywordRulesInfoTacticalMfeQuery.graphql";
import { graphql } from '@attentive/data';
export const parseRulesInfoData = queryData => {
  if (queryData && queryData.keywordRulesInfoByKeywordId) {
    const {
      companyKeywordId,
      rulesLegend,
      rulesExpression,
      ruleCount
    } = queryData === null || queryData === void 0 ? void 0 : queryData.keywordRulesInfoByKeywordId;
    const rulesLegendMap = new Map(rulesLegend.map(_ref => {
      let {
        key,
        value
      } = _ref;
      return [key, value];
    }));
    return {
      companyKeywordId,
      rulesExpression,
      ruleCount,
      rulesLegend: rulesLegendMap
    };
  }

  return undefined;
};
_FetchCompanyKeywordRulesInfoTacticalMfeQuery;