import React from 'react';

import { PageLayout } from '@attentive/picnic';

import { TacticalMfeRoutes } from './TacticalMfeRoutes';

import { CustomCompanyKeywordsPage } from './pages/CustomCompanyKeywords/CustomCompanyKeywordsPage';

const TacticalMfe: React.FC = () => {
  return (
    <PageLayout>
      <TacticalMfeRoutes />
    </PageLayout>
  );
};
TacticalMfe.displayName = 'TacticalMfe';

export default TacticalMfe;

export const KeywordsPage = CustomCompanyKeywordsPage;
