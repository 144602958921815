/**
 * @generated SignedSource<<f57a172164ba1ba51315c847de944829>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type PerformanceConfigFormMutation$variables = {
  endpoint: string;
  id?: string | null;
  limitPerSec: number;
  retryTimes: number;
  taskId?: string | null;
};
export type PerformanceConfigFormMutation$data = {
  readonly upsertEdsTaskProcessingConfig: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type PerformanceConfigFormMutation$rawResponse = {
  readonly upsertEdsTaskProcessingConfig: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: string;
  } | null;
};
export type PerformanceConfigFormMutation = {
  rawResponse: PerformanceConfigFormMutation$rawResponse;
  response: PerformanceConfigFormMutation$data;
  variables: PerformanceConfigFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endpoint"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limitPerSec"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "retryTimes"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "endpoint",
            "variableName": "endpoint"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "limitPerSec",
            "variableName": "limitPerSec"
          },
          {
            "kind": "Variable",
            "name": "retryTimes",
            "variableName": "retryTimes"
          },
          {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "upsertEdsTaskProcessingConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "traceId",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceConfigFormMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PerformanceConfigFormMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "fabd18c1656a09736856c03cbb8f7843",
    "id": null,
    "metadata": {},
    "name": "PerformanceConfigFormMutation",
    "operationKind": "mutation",
    "text": "mutation PerformanceConfigFormMutation(\n  $id: String\n  $endpoint: String!\n  $taskId: String\n  $limitPerSec: Int!\n  $retryTimes: Int!\n) {\n  upsertEdsTaskProcessingConfig(input: {id: $id, endpoint: $endpoint, taskId: $taskId, limitPerSec: $limitPerSec, retryTimes: $retryTimes}) {\n    __typename\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61d59fcc4e2b933b313e054f76527eeb";

export default node;
