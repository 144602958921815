/**
 * @generated SignedSource<<53314496ff93eb6303b51264b5887b76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type SweepstakesSelectorType = "SWEEPSTAKES_SELECTOR_TYPE_FIRST" | "SWEEPSTAKES_SELECTOR_TYPE_LAST" | "SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES" | "SWEEPSTAKES_SELECTOR_TYPE_RANDOM" | "%future added value";
export type SweepstakesSubscriberType = "SWEEPSTAKES_SUBSCRIBER_TYPE_ANY" | "SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING" | "%future added value";
export type LoadPollTacticalMfeQuery$variables = {
  pollId: string;
};
export type LoadPollTacticalMfeQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoadPollFragment_query">;
};
export type LoadPollTacticalMfeQuery$rawResponse = {
  readonly poll: {
    readonly __typename: "Poll";
    readonly allowAltSignupUnit: boolean | null;
    readonly allowBreakTies: boolean | null;
    readonly allowMultiVote: boolean;
    readonly altSignupUnitId: number | null;
    readonly applicationId: string | null;
    readonly closedMessage: string | null;
    readonly decimalPrecision: number | null;
    readonly description: string | null;
    readonly duplicateVoteMessage: string | null;
    readonly hasWinnerFilter: boolean | null;
    readonly id: string;
    readonly isArchived: boolean | null;
    readonly name: string;
    readonly onClosedMessage: string | null;
    readonly participantAttributeName: string | null;
    readonly poll_keywords: {
      readonly pollKeywords: ReadonlyArray<{
        readonly altMatches: ReadonlyArray<string>;
        readonly deleted: boolean;
        readonly description: string;
        readonly id: string;
        readonly keyword: string;
      }>;
    } | null;
    readonly state: PollState;
    readonly submitMessage: string | null;
    readonly subscribedEventType: string | null;
    readonly sweepstakesFilter: {
      readonly id: number;
      readonly numToSelect: number;
      readonly pollKeywords: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly keyword: string;
        };
      }>;
      readonly selectorType: SweepstakesSelectorType;
      readonly subscriberType: SweepstakesSubscriberType;
    } | null;
    readonly totalResponses: number | null;
    readonly uuid: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type LoadPollTacticalMfeQuery = {
  rawResponse: LoadPollTacticalMfeQuery$rawResponse;
  response: LoadPollTacticalMfeQuery$data;
  variables: LoadPollTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pollId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyword",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadPollTacticalMfeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoadPollFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadPollTacticalMfeQuery",
    "selections": [
      {
        "alias": "poll",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "pollId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GetPollKeywordsResponse",
                "kind": "LinkedField",
                "name": "poll_keywords",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollKeyword",
                    "kind": "LinkedField",
                    "name": "pollKeywords",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "altMatches",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalResponses",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closedMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duplicateVoteMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submitMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowMultiVote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowBreakTies",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "decimalPrecision",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "participantAttributeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onClosedMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subscribedEventType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applicationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowAltSignupUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "altSignupUnitId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasWinnerFilter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GetSweepstakesFilterResponse",
                "kind": "LinkedField",
                "name": "sweepstakesFilter",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numToSelect",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollOptionEdge",
                    "kind": "LinkedField",
                    "name": "pollKeywords",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PollOption",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "selectorType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriberType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Poll",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5c03b732d50d9d2fbc66adb54b32f5d",
    "id": null,
    "metadata": {},
    "name": "LoadPollTacticalMfeQuery",
    "operationKind": "query",
    "text": "query LoadPollTacticalMfeQuery(\n  $pollId: ID!\n) {\n  ...LoadPollFragment_query\n}\n\nfragment LoadPollFragment_query on Query {\n  poll: node(id: $pollId) {\n    __typename\n    ... on Poll {\n      name\n      uuid\n      id\n      poll_keywords {\n        pollKeywords {\n          id\n          keyword\n          description\n          altMatches\n          deleted\n        }\n      }\n      state\n      description\n      totalResponses\n      closedMessage\n      duplicateVoteMessage\n      submitMessage\n      allowMultiVote\n      allowBreakTies\n      decimalPrecision\n      isArchived\n      participantAttributeName\n      onClosedMessage\n      subscribedEventType\n      applicationId\n      allowAltSignupUnit\n      altSignupUnitId\n      hasWinnerFilter\n      sweepstakesFilter {\n        id\n        numToSelect\n        pollKeywords {\n          node {\n            id\n            keyword\n          }\n        }\n        selectorType\n        subscriberType\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f72c396bad446513f843506a5b7c5126";

export default node;
