import React from 'react';
import { Box } from '@attentive/picnic';
import TacticalBreadcrumbs, {
  POLL_SURVEY_BREADCRUMB,
  POLL_NEW_BREADCRUMB,
} from '../../components/common/TacticalBreadcrumbs';
import CreateEditPollForm from '../../components/PollSurveys/CreateEditPoll/FormPage/CreateEditPollForm';

const CreateNewPoll: React.FunctionComponent = () => {
  return (
    <Box>
      <TacticalBreadcrumbs breadcrumbs={[POLL_SURVEY_BREADCRUMB, POLL_NEW_BREADCRUMB]} />

      <CreateEditPollForm />
    </Box>
  );
};

export default CreateNewPoll;
