/**
 * @generated SignedSource<<efb5359ebafdb668b00b2e49abdc3a4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type SweepstakesSelectorType = "SWEEPSTAKES_SELECTOR_TYPE_FIRST" | "SWEEPSTAKES_SELECTOR_TYPE_LAST" | "SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES" | "SWEEPSTAKES_SELECTOR_TYPE_RANDOM" | "%future added value";
export type SweepstakesSubscriberType = "SWEEPSTAKES_SUBSCRIBER_TYPE_ANY" | "SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LoadPollFragment_query$data = {
  readonly poll: {
    readonly allowAltSignupUnit?: boolean | null;
    readonly allowBreakTies?: boolean | null;
    readonly allowMultiVote?: boolean;
    readonly altSignupUnitId?: number | null;
    readonly applicationId?: string | null;
    readonly closedMessage?: string | null;
    readonly decimalPrecision?: number | null;
    readonly description?: string | null;
    readonly duplicateVoteMessage?: string | null;
    readonly hasWinnerFilter?: boolean | null;
    readonly id?: string;
    readonly isArchived?: boolean | null;
    readonly name?: string;
    readonly onClosedMessage?: string | null;
    readonly participantAttributeName?: string | null;
    readonly poll_keywords?: {
      readonly pollKeywords: ReadonlyArray<{
        readonly altMatches: ReadonlyArray<string>;
        readonly deleted: boolean;
        readonly description: string;
        readonly id: string;
        readonly keyword: string;
      }>;
    } | null;
    readonly state?: PollState;
    readonly submitMessage?: string | null;
    readonly subscribedEventType?: string | null;
    readonly sweepstakesFilter?: {
      readonly id: number;
      readonly numToSelect: number;
      readonly pollKeywords: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly keyword: string;
        };
      }>;
      readonly selectorType: SweepstakesSelectorType;
      readonly subscriberType: SweepstakesSubscriberType;
    } | null;
    readonly totalResponses?: number | null;
    readonly uuid?: string;
  } | null;
  readonly " $fragmentType": "LoadPollFragment_query";
};
export type LoadPollFragment_query$key = {
  readonly " $data"?: LoadPollFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoadPollFragment_query">;
};

import LoadPollFragment_query_refetchable_graphql from './LoadPollFragment_query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyword",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "pollId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": LoadPollFragment_query_refetchable_graphql
    }
  },
  "name": "LoadPollFragment_query",
  "selections": [
    {
      "alias": "poll",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "pollId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GetPollKeywordsResponse",
              "kind": "LinkedField",
              "name": "poll_keywords",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PollKeyword",
                  "kind": "LinkedField",
                  "name": "pollKeywords",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "altMatches",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deleted",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResponses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "closedMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duplicateVoteMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowMultiVote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowBreakTies",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "decimalPrecision",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isArchived",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "participantAttributeName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onClosedMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subscribedEventType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "applicationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowAltSignupUnit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "altSignupUnitId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasWinnerFilter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GetSweepstakesFilterResponse",
              "kind": "LinkedField",
              "name": "sweepstakesFilter",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "numToSelect",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PollOptionEdge",
                  "kind": "LinkedField",
                  "name": "pollKeywords",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PollOption",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selectorType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscriberType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Poll",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "62313285c94f22f598da4482669223e3";

export default node;
