/**
 * @generated SignedSource<<477b1a07fdbe1067be3d522d8bd62de2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JobPageQuery$variables = {
  jobUuid: string;
};
export type JobPageQuery$data = {
  readonly eventDestinations: {
    readonly job: {
      readonly company: {
        readonly displayName: string;
        readonly internalId: number;
      } | null;
      readonly description: string;
      readonly id: string;
      readonly internalId: string;
      readonly jobType: string;
      readonly lastExecutedRun: {
        readonly context: any | null;
        readonly parentTaskId: string | null;
        readonly runDate: SerializedDateTime | null;
        readonly scheduledDate: SerializedDateTime;
        readonly status: string;
        readonly taskId: string;
        readonly uuid: string;
      } | null;
      readonly name: string;
      readonly nextRun: SerializedDateTime | null;
      readonly previousRun: SerializedDateTime | null;
      readonly sourceInfo: string | null;
      readonly sourceNodeUuid: string | null;
      readonly sourceType: string | null;
      readonly status: string | null;
      readonly targetInfo: string | null;
      readonly targetNodeUuid: string | null;
      readonly targetType: string | null;
    } | null;
  } | null;
};
export type JobPageQuery$rawResponse = {
  readonly eventDestinations: {
    readonly job: {
      readonly company: {
        readonly displayName: string;
        readonly id: string;
        readonly internalId: number;
      } | null;
      readonly description: string;
      readonly id: string;
      readonly internalId: string;
      readonly jobType: string;
      readonly lastExecutedRun: {
        readonly context: any | null;
        readonly parentTaskId: string | null;
        readonly runDate: SerializedDateTime | null;
        readonly scheduledDate: SerializedDateTime;
        readonly status: string;
        readonly taskId: string;
        readonly uuid: string;
      } | null;
      readonly name: string;
      readonly nextRun: SerializedDateTime | null;
      readonly previousRun: SerializedDateTime | null;
      readonly sourceInfo: string | null;
      readonly sourceNodeUuid: string | null;
      readonly sourceType: string | null;
      readonly status: string | null;
      readonly targetInfo: string | null;
      readonly targetNodeUuid: string | null;
      readonly targetType: string | null;
    } | null;
  } | null;
};
export type JobPageQuery = {
  rawResponse: JobPageQuery$rawResponse;
  response: JobPageQuery$data;
  variables: JobPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "jobRecordUuid",
    "variableName": "jobUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previousRun",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextRun",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetInfo",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceInfo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceNodeUuid",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetNodeUuid",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "JobHistory",
  "kind": "LinkedField",
  "name": "lastExecutedRun",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTaskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "runDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "context",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JobRecord",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JobRecord",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e9d7a28a964ae647c6a0c0820371d24",
    "id": null,
    "metadata": {},
    "name": "JobPageQuery",
    "operationKind": "query",
    "text": "query JobPageQuery(\n  $jobUuid: String!\n) {\n  eventDestinations {\n    job(jobRecordUuid: $jobUuid) {\n      id\n      internalId\n      name\n      description\n      jobType\n      previousRun\n      nextRun\n      company {\n        internalId\n        displayName\n        id\n      }\n      targetInfo\n      sourceInfo\n      sourceType\n      targetType\n      status\n      sourceNodeUuid\n      targetNodeUuid\n      lastExecutedRun {\n        uuid\n        taskId\n        parentTaskId\n        runDate\n        scheduledDate\n        context\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41bcfce43261d34aa355fa7e95fd6f2f";

export default node;
