/**
 * @generated SignedSource<<8d363109821f67a7247e69854adcb6bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type SweepstakesSelectorType = "SWEEPSTAKES_SELECTOR_TYPE_FIRST" | "SWEEPSTAKES_SELECTOR_TYPE_LAST" | "SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES" | "SWEEPSTAKES_SELECTOR_TYPE_RANDOM" | "%future added value";
export type SweepstakesSubscriberType = "SWEEPSTAKES_SUBSCRIBER_TYPE_ANY" | "SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING" | "%future added value";
export type SetSweepstakesFilterInput = {
  numToSelect: number;
  pollId: string;
  pollKeywordIds?: ReadonlyArray<string> | null;
  selectorType: SweepstakesSelectorType;
  subscriberType: SweepstakesSubscriberType;
};
export type SetWinnerFilterMutation$variables = {
  input: SetSweepstakesFilterInput;
};
export type SetWinnerFilterMutation$data = {
  readonly setSweepstakesFilter: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
  } | {
    readonly __typename: "SetSweepstakesFilterSuccess";
    readonly poll: {
      readonly hasWinnerFilter: boolean | null;
      readonly id: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type SetWinnerFilterMutation = {
  response: SetWinnerFilterMutation$data;
  variables: SetWinnerFilterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "setSweepstakesFilter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasWinnerFilter",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SetSweepstakesFilterSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetWinnerFilterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetWinnerFilterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c105e8bb04e48a6a778b0868c81d0218",
    "id": null,
    "metadata": {},
    "name": "SetWinnerFilterMutation",
    "operationKind": "mutation",
    "text": "mutation SetWinnerFilterMutation(\n  $input: SetSweepstakesFilterInput!\n) {\n  setSweepstakesFilter(input: $input) {\n    __typename\n    ... on SetSweepstakesFilterSuccess {\n      poll {\n        id\n        hasWinnerFilter\n      }\n    }\n    ... on DefaultErrorFailure {\n      message\n      status\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff65994cb2eb1f5caf4b3fba4d424f14";

export default node;
