import React from 'react';

import { ProjectCardConfig } from '../../constants/Home/tacticalHomeTypes';
import { containsSharedRole } from './utils/helpers';
import TacticalCard from '../common/TacticalCard';

interface ProjectCardProps {
  config: ProjectCardConfig;
}

const ProjectCard: React.FunctionComponent<ProjectCardProps> = ({ config }) => {
  const allowedByRoles = containsSharedRole(config.allowedRoles, config.viewerRoles);
  const isEnabled = config.defaultEnabled || allowedByRoles;
  const path = config.path;
  const imgUrl = config.image;
  const heading = config.heading;
  return <TacticalCard isEnabled={isEnabled} path={path} imgUrl={imgUrl} heading={heading} />;
};

export default ProjectCard;
