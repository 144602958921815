import React from 'react';
import { Box, Heading, Text, Form } from '@attentive/picnic';
import { PollState } from '@attentive/data/types';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

const PollStateRadioInput: React.FunctionComponent<{
  pollState: PollState;
  handleStateChange: (v: PollState) => void;
}> = ({ pollState, handleStateChange }) => {
  const radioIdentifier = `poll_state_radio_${pollState}`;
  const handleClick = (value: PollState) => {
    handleStateChange(value);
  };

  const getDescription = (pollStateEnum: PollState) => {
    switch (pollStateEnum) {
      case PollState.Off:
        return 'When "Off", keywords are ignored and the default auto-response is sent to the participant.';
      case PollState.Closed:
        return 'When "Closed", keywords are captured but they are not counted in the poll results. Additionally the "Closed Message" is sent to the participant.';
      case PollState.Open:
        return 'When "Open", keywords are captured and counted against the poll results. Additionally the "Open Message" is sent to the participant.';
      default:
        return;
    }
  };

  return (
    <Form.RadioGroup.Radio
      id={radioIdentifier}
      value={pollState}
      css={{ mt: '$space5', alignItems: 'unset' }}
      onClick={() => {
        handleClick(pollState);
      }}
    >
      <Heading variant="sm" css={{ mb: '$space1' }}>
        {pollState}
      </Heading>
      <Text css={{ opacity: '0.6' }}>{getDescription(pollState)}</Text>
    </Form.RadioGroup.Radio>
  );
};
type FORM_FIELD_TYPES = {
  pollState: PollState;
};

const PollStatePicker: React.FunctionComponent<{
  state: string;
  handleStateChange: (v: PollState) => void;
}> = ({ state, handleStateChange }) => {
  const parseState = (s: string) => {
    switch (s) {
      case 'OFF':
        return PollState.Off;
      case 'OPEN':
        return PollState.Open;
      case 'CLOSED':
      default:
        return PollState.Closed;
    }
  };

  return (
    <Form<FORM_FIELD_TYPES> initialValues={{ pollState: parseState(state) }} onSubmit={() => {}}>
      <Form.FormField name="pollState">
        <Form.RadioGroup layout="vertical">
          <PollStateRadioInput pollState={PollState.Open} handleStateChange={handleStateChange} />
          <PollStateRadioInput pollState={PollState.Closed} handleStateChange={handleStateChange} />
          <PollStateRadioInput pollState={PollState.Off} handleStateChange={handleStateChange} />
        </Form.RadioGroup>
      </Form.FormField>
    </Form>
  );
};

const PollStateSection: React.FunctionComponent<{
  poll: PollType;
  updateRequestField: (field: string, value: string | PollState) => void;
}> = ({ poll, updateRequestField }) => {
  const [state, setState] = React.useState<string>(poll.state);

  const handleStateChange = (value: string | PollState) => {
    setState(value);
    updateRequestField('state', value);
  };

  return (
    <Box css={{ display: 'flex', gap: '$space4', flexDirection: 'column', mt: '$space2' }}>
      <Heading variant="lg">Poll State</Heading>
      <Text>
        Changing the poll state will determine which auto-responders are triggered when a
        participant texts in a keyword.
      </Text>
      <PollStatePicker state={state} handleStateChange={handleStateChange} />
    </Box>
  );
};

export default PollStateSection;
