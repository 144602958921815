import React from 'react';
import { StandardDialog } from '@attentive/picnic';

const DEFAULT_BODY_TEXT =
  'This feature will soon be available. To get an update reach out to your favorite Tactical Engineer or slack #eng-tactical';

interface ComingSoonDialogProps {
  isOpen: boolean;
  onOpenChange: () => void;
  bodyText?: string;
}

const ComingSoonDialog: React.FunctionComponent<ComingSoonDialogProps> = ({
  isOpen,
  onOpenChange,
  bodyText,
}) => {
  return (
    <StandardDialog open={isOpen} onOpenChange={onOpenChange}>
      <StandardDialog.Content>
        <StandardDialog.Header>
          <StandardDialog.Heading>Coming soon!</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>{bodyText ? bodyText : DEFAULT_BODY_TEXT}</StandardDialog.Body>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

export default ComingSoonDialog;
