import { Form, styled } from '@attentive/picnic';

export const StyledFormField = styled(Form.FormField, {
  mb: '$space5',
});

export const StyledFormLabel = styled(Form.Label, {
  mt: '$space1',
  mb: '$space1',
});
