import {
  CustomActionDefinition,
  CustomActionStatus,
} from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsTypes';

export const fakeCustomActions: CustomActionDefinition[] = [
  {
    id: 1,
    uuid: '81174fd6-b537-455c-a17d-f7f1728a142e',
    name: 'PopWallet Card',
    state: CustomActionStatus.ACTIVE,
    descriptionShort: 'Add popwallet card to subscribers wallet',
    descriptionLong: '',
    imageUrl:
      'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/v1505933641/htxmldhp9dubrgstmcvp.png',
    actionVisibilityType: 'All',
    enabled: true,
    lastUpdated: '06/02/2021',
  },
  {
    id: 2,
    uuid: '2415ef1b-fb93-4c18-8ceb-4d4d3ac2b593',
    name: 'MMS Barcode',
    state: CustomActionStatus.ACTIVE,
    descriptionShort: 'Send image with barcode to subscriber',
    descriptionLong: '',
    imageUrl:
      'https://d1nhio0ox7pgb.cloudfront.net/_img/i_collection_png/256x256/plain/barcode.png',
    actionVisibilityType: 'Super Users Only',
    enabled: true,
    lastUpdated: '04/01/2021',
  },
  {
    id: 3,
    uuid: 'fb8d7921-1727-4692-9529-6e1c5bb383f3',
    name: 'BigCommerce T2B',
    state: CustomActionStatus.INACTIVE,
    descriptionShort: 'Keyword purchase integration with BigCommerce',
    descriptionLong: '',
    imageUrl: 'https://multiorders.com/wp-content/uploads/2018/03/Big-Commerce.jpg',
    actionVisibilityType: 'Attentive Users Only',
    enabled: true,
    lastUpdated: '04/01/2021',
  },
];
