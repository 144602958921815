import { SortDirection } from '@attentive/data/types';
import React from 'react';
import { ConnectedTable } from './VendorCompanyCardConnectedTable';
import {
  MobileWalletSorting,
  MobileWalletSortingField,
} from './__generated__/VendorCompanyCardConnectedTableQuery.graphql';

interface SortableHeaderCellProps {
  sorting: { get: MobileWalletSorting; set: (val: MobileWalletSorting) => void };
  sortByField: MobileWalletSortingField;
  label: string;
}

export const SortableHeaderCell: React.FC<SortableHeaderCellProps> = ({
  sorting,
  sortByField,
  label,
}) => {
  return (
    <ConnectedTable.SortableHeaderCell
      ascending={
        sorting.get != null &&
        sorting.get.sortBy === sortByField &&
        sorting.get.direction === SortDirection.SortDirectionAsc
      }
      isSortActive={sorting.get?.sortBy && sorting.get.sortBy === sortByField}
      onChange={() => {
        if (sorting.get?.direction && sorting.get.direction === SortDirection.SortDirectionAsc) {
          sorting.set({
            sortBy: sortByField,
            direction: SortDirection.SortDirectionDesc,
          });
        } else {
          sorting.set({
            sortBy: sortByField,
            direction: SortDirection.SortDirectionAsc,
          });
        }
      }}
    >
      {label}
    </ConnectedTable.SortableHeaderCell>
  );
};
