import _EditPollMutation from "./__generated__/EditPollMutation.graphql";
import { graphql } from '@attentive/data';
_EditPollMutation;
export const buildInitialRequest = (companyId, pollId) => ({
  companyId,
  pollId,
  name: null,
  description: null,
  state: null,
  closedMessage: null,
  submitMessage: null,
  duplicateVoteMessage: null,
  allowMultiVote: null,
  allowBreakTies: null,
  decimalPrecision: null
});