/**
 * @generated SignedSource<<be10e2a26c468f2981087d492173be11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WithEventDestinationJobRunReportsV2TacticalMfeQuery$variables = {
  companyId: string;
  jobType: string;
  lastX: number;
  taskId: string;
};
export type WithEventDestinationJobRunReportsV2TacticalMfeQuery$data = {
  readonly eventDestinations: {
    readonly runReportsV2: {
      readonly reports: ReadonlyArray<{
        readonly end: string;
        readonly runUuid: string;
        readonly start: string;
        readonly statusCodeCounts: ReadonlyArray<{
          readonly key: string;
          readonly value: number;
        }>;
      }>;
    } | null;
  } | null;
};
export type WithEventDestinationJobRunReportsV2TacticalMfeQuery = {
  response: WithEventDestinationJobRunReportsV2TacticalMfeQuery$data;
  variables: WithEventDestinationJobRunReportsV2TacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastX"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventDestinations",
    "kind": "LinkedField",
    "name": "eventDestinations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Literal",
            "name": "lastXOffset",
            "value": 0
          },
          {
            "kind": "Variable",
            "name": "lastXRuns",
            "variableName": "lastX"
          },
          {
            "kind": "Variable",
            "name": "taskUuid",
            "variableName": "taskId"
          },
          {
            "kind": "Variable",
            "name": "topicType",
            "variableName": "jobType"
          }
        ],
        "concreteType": "GetRunReportsResponse",
        "kind": "LinkedField",
        "name": "runReportsV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RunReport",
            "kind": "LinkedField",
            "name": "reports",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "runUuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StatusCodeCountsEntry",
                "kind": "LinkedField",
                "name": "statusCodeCounts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WithEventDestinationJobRunReportsV2TacticalMfeQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "WithEventDestinationJobRunReportsV2TacticalMfeQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "9c046f4bbac5977db35288db92ab86e6",
    "id": null,
    "metadata": {},
    "name": "WithEventDestinationJobRunReportsV2TacticalMfeQuery",
    "operationKind": "query",
    "text": "query WithEventDestinationJobRunReportsV2TacticalMfeQuery(\n  $taskId: String!\n  $companyId: ID!\n  $jobType: String!\n  $lastX: Int!\n) {\n  eventDestinations {\n    runReportsV2(companyId: $companyId, taskUuid: $taskId, topicType: $jobType, lastXRuns: $lastX, lastXOffset: 0) {\n      reports {\n        runUuid\n        start\n        end\n        statusCodeCounts {\n          key\n          value\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "71aadebd9d761e64045119ed40513e02";

export default node;
