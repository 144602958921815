import { useCurrentCompanyId } from '@attentive/acore-utils';
import { SortDirection } from '@attentive/data/types';
import { SearchBar, Select } from '@attentive/picnic';
import React, { Suspense, useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay';
import { CardRow } from './CardRow';
import { SortableHeaderCell } from './SortableHeaderCell';
import { ConnectedTable } from './VendorCompanyCardConnectedTable';
import Query, {
  MobileWalletSearchFilter,
  MobileWalletSorting,
  VendorCompanyCardConnectedTableQuery as QueryType,
} from './__generated__/VendorCompanyCardConnectedTableQuery.graphql';

export const VendorCompanyCardTable: React.FC = () => {
  const companyId = useCurrentCompanyId();
  const [filter, setFilter] = useState<MobileWalletSearchFilter>({ search: '' });
  const [sorting, setSorting] = useState<MobileWalletSorting>({
    sortBy: 'MOBILE_WALLET_SORTING_FIELD_ID',
    direction: SortDirection.SortDirectionDesc,
  });
  const [pageSize, setPageSize] = useState<number>(10);

  const [queryRef, loadQuery] = useQueryLoader<QueryType>(Query);

  useEffect(() => {
    loadQuery(
      {
        companyId,
        first: pageSize,
        filter,
        sorting,
      },
      { fetchPolicy: 'store-and-network' }
    );
  }, [loadQuery, companyId, pageSize, filter, sorting]);

  if (!queryRef) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <SearchBar
        value={filter.search || ''}
        placeholder="Search by Campaign or Offer ID"
        onChange={(value) => setFilter({ search: value })}
        css={{ mb: '$space4' }}
      />
      <ConnectedTable queryRef={queryRef} columns={[5, 3, 3, 2]}>
        <ConnectedTable.Header>
          <ConnectedTable.HeaderRow>
            <ConnectedTable.HeaderCell>UUID</ConnectedTable.HeaderCell>
            <ConnectedTable.HeaderCell>Campaign ID</ConnectedTable.HeaderCell>
            <ConnectedTable.HeaderCell>Offer ID</ConnectedTable.HeaderCell>
            <SortableHeaderCell
              label="Updated"
              sortByField="MOBILE_WALLET_SORTING_FIELD_UPDATED"
              sorting={{ get: sorting, set: setSorting }}
            />
          </ConnectedTable.HeaderRow>
        </ConnectedTable.Header>
        <ConnectedTable.Body>{(data) => <CardRow data={data} />}</ConnectedTable.Body>
      </ConnectedTable>
      <Select
        size="small"
        css={{ width: '125px', mt: -55 }}
        value={pageSize}
        onChange={(val: number) => setPageSize(val)}
      >
        <Select.Item value={10}>10 rows</Select.Item>
        <Select.Item value={50}>50 rows</Select.Item>
        <Select.Item value={100}>100 rows</Select.Item>
      </Select>
    </Suspense>
  );
};
