import React from 'react';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import { Box, Heading, Text, TextInput, Form } from '@attentive/picnic';
import { WELCOME_JOURNEY_STATE } from '../../../../constants/PollSurveys/settingsConstants';

const OpenJourneySection: React.FunctionComponent<{
  poll: PollType;
  updateRequestField: (field: string, value: number | boolean) => void;
}> = ({ poll, updateRequestField }) => {
  const [enableCustomTextBox, setEnableCustomTextBox] = React.useState<boolean>(
    poll.allowAltSignupUnit || false
  );
  const [altSignUpUnit, setAltSignUpUnit] = React.useState<string>(
    poll.altSignupUnitId ? poll.altSignupUnitId.toString() : ''
  );

  const parseState = (allowAltSignUpUnit: boolean) => {
    if (allowAltSignUpUnit) {
      return WELCOME_JOURNEY_STATE.CUSTOM;
    }
    return WELCOME_JOURNEY_STATE.NONE;
  };

  const handleRadioClick = (welcomeJourneyState: WELCOME_JOURNEY_STATE) => {
    if (welcomeJourneyState === WELCOME_JOURNEY_STATE.CUSTOM) {
      setEnableCustomTextBox(true);
      updateRequestField('allowAltSignupUnit', true);
    } else {
      setEnableCustomTextBox(false);
      updateRequestField('allowAltSignupUnit', false);
    }
  };

  const handleTextInputClick: React.MouseEventHandler = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the parent (radio button)
    event.stopPropagation();
  };

  const handleTextInputChange = (val: string) => {
    setAltSignUpUnit(val);
    updateRequestField('altSignupUnitId', parseInt(val));
  };

  type FORM_FIELD_TYPES = {
    welcomeJourneyState: WELCOME_JOURNEY_STATE;
  };
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$space4',
      }}
    >
      <Heading variant="lg">Welcome Journey</Heading>
      <Text>Trigger a Welcome Journey for non-subscribed participants when entering a poll.</Text>
      <Form<FORM_FIELD_TYPES>
        initialValues={{ welcomeJourneyState: parseState(enableCustomTextBox) }}
        onSubmit={() => {}}
      >
        <Form.FormField name="welcomeJourneyState">
          <Form.RadioGroup layout="vertical">
            <Form.RadioGroup.Radio
              id={`welcome_jouney_state_${WELCOME_JOURNEY_STATE.NONE}`}
              value={WELCOME_JOURNEY_STATE.NONE}
              onClick={() => {
                handleRadioClick(WELCOME_JOURNEY_STATE.NONE);
              }}
            >
              <Text>Default Welcome Journey</Text>
            </Form.RadioGroup.Radio>
            <Form.RadioGroup.Radio
              id={`welcome_jouney_state_${WELCOME_JOURNEY_STATE.CUSTOM}`}
              value={WELCOME_JOURNEY_STATE.CUSTOM}
              css={{ alignItems: 'unset' }}
              onClick={() => {
                handleRadioClick(WELCOME_JOURNEY_STATE.CUSTOM);
              }}
            >
              <Box css={{ display: 'flex', flexDirection: 'column', gap: '$space1' }}>
                <Text>Custom Welcome Journey</Text>
                <Text css={{ color: 'gray' }}>
                  Enter an API Sign-Up Source ID that is assigned to a custom Welcome Journey
                </Text>
                <TextInput
                  id="welcome_jouney_custom_input"
                  placeholder="Sign-Up Source ID"
                  value={enableCustomTextBox ? altSignUpUnit : ''}
                  disabled={!enableCustomTextBox}
                  onClick={handleTextInputClick}
                  onChange={(e) => handleTextInputChange(e.target.value)}
                ></TextInput>
              </Box>
            </Form.RadioGroup.Radio>
          </Form.RadioGroup>
        </Form.FormField>
      </Form>
    </Box>
  );
};

export default OpenJourneySection;
