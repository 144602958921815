/**
 * @generated SignedSource<<f337483a2da8e443e23487eca81303b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollResultsFragment_query_refetchable$variables = {
  pollId: string;
};
export type PollResultsFragment_query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PollResultsFragment_query">;
};
export type PollResultsFragment_query_refetchable = {
  response: PollResultsFragment_query_refetchable$data;
  variables: PollResultsFragment_query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pollId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyword",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PollResultsFragment_query_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PollResultsFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PollResultsFragment_query_refetchable",
    "selections": [
      {
        "alias": "poll",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "pollId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GetPollKeywordsResponse",
                "kind": "LinkedField",
                "name": "poll_keywords",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollKeyword",
                    "kind": "LinkedField",
                    "name": "pollKeywords",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "altMatches",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GetPollStatsResponse",
                "kind": "LinkedField",
                "name": "poll_stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollStats",
                    "kind": "LinkedField",
                    "name": "stats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeywordStats",
                        "kind": "LinkedField",
                        "name": "keywordStats",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Poll",
            "abstractKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c44e7fc400c989a1fa2a7634294a04e",
    "id": null,
    "metadata": {},
    "name": "PollResultsFragment_query_refetchable",
    "operationKind": "query",
    "text": "query PollResultsFragment_query_refetchable(\n  $pollId: ID!\n) {\n  ...PollResultsFragment_query\n}\n\nfragment PollResultsFragment_query on Query {\n  poll: node(id: $pollId) {\n    __typename\n    ... on Poll {\n      uuid\n      name\n      poll_keywords {\n        pollKeywords {\n          id\n          keyword\n          description\n          altMatches\n          deleted\n        }\n      }\n      poll_stats {\n        stats {\n          keywordStats {\n            keyword\n            count\n            percentage\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "052995f22a5fc27fcb51fc8330fc7a7c";

export default node;
