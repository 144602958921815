/**
 * @generated SignedSource<<a17ee151fa5ed922a260cb25313790c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EdsJobIngestionFormat = "EDS_JOB_INGESTION_FORMAT_CSV" | "EDS_JOB_INGESTION_FORMAT_NDJSON" | "EDS_JOB_INGESTION_FORMAT_PSV" | "EDS_JOB_INGESTION_FORMAT_TSV" | "EDS_JOB_INGESTION_FORMAT_UNKNOWN" | "EDS_JOB_INGESTION_FORMAT_XML" | "%future added value";
export type EdsJobSync = "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES" | "EDS_JOB_SYNC_CUSTOM_EVENTS" | "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS" | "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS" | "EDS_JOB_SYNC_ECOMMERCE_PURCHASES" | "EDS_JOB_SYNC_MOBILE_WALLET" | "EDS_JOB_SYNC_PRODUCT_CATALOG" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS" | "EDS_JOB_SYNC_TEXT_SEND" | "EDS_JOB_SYNC_TEXT_SEND_BULK" | "EDS_JOB_SYNC_UNKNOWN" | "EDS_JOB_SYNC_USER_IDENTIFIERS" | "%future added value";
export type EdsJobSyncMethod = "EDS_JOB_SYNC_METHOD_AMAZON_S3" | "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP" | "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP" | "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD" | "EDS_JOB_SYNC_METHOD_S3" | "EDS_JOB_SYNC_METHOD_SFTP" | "EDS_JOB_SYNC_METHOD_UNKNOWN" | "%future added value";
export type CreateEdsJobInput = {
  companyId: string;
  description?: string | null;
  ingestionFormat?: EdsJobIngestionFormat | null;
  name: string;
  sync?: EdsJobSync | null;
  syncMethod?: EdsJobSyncMethod | null;
};
export type InitializeWithNameFormTacticalMfeMutation$variables = {
  input: CreateEdsJobInput;
};
export type InitializeWithNameFormTacticalMfeMutation$data = {
  readonly createEdsJob: {
    readonly taskUuid: string | null;
  } | null;
};
export type InitializeWithNameFormTacticalMfeMutation = {
  response: InitializeWithNameFormTacticalMfeMutation$data;
  variables: InitializeWithNameFormTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateEdsJobPayload",
    "kind": "LinkedField",
    "name": "createEdsJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskUuid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InitializeWithNameFormTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InitializeWithNameFormTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ed674d171568fe1e7a0137152788d1b",
    "id": null,
    "metadata": {},
    "name": "InitializeWithNameFormTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation InitializeWithNameFormTacticalMfeMutation(\n  $input: CreateEdsJobInput!\n) {\n  createEdsJob(input: $input) {\n    taskUuid\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f6985eddf57e03e673c895513fd681e";

export default node;
