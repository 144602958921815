import React from 'react';
import { Box, Heading } from '@attentive/picnic';

const EdsClientPage: React.FunctionComponent = () => {
  return (
    <Box>
      <Heading>Event Destinations</Heading>
    </Box>
  );
};

export default EdsClientPage;
