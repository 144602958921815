import React from 'react';
import { Box, TabGroup } from '@attentive/picnic';

import { JobPageTabType, JobRecord } from '../../../../constants/EventDestinations/jobTypes';
import JobConfigTab from '../../../../components/EventDestinations/Jobs/TabPanels/Config/JobConfigTab';
import WithEventDestinationJobHistory from '../../graphql/WithEventDestinationJobHistory';
import JobHistoryList from '../../../../components/EventDestinations/Jobs/JobHistoryList';
import JobPerformanceTab from '../../../../components/EventDestinations/Jobs/TabPanels/Performance';
import Advanced from '../../../../components/EventDestinations/Jobs/TabPanels/Advance';

const JobHistoryTabPanel: React.FunctionComponent<{ job?: JobRecord }> = ({ job }) => {
  const [historyCount, setHistoryCount] = React.useState(10);

  return (
    <TabGroup.Panel value={JobPageTabType.HISTORY}>
      {job?.internalId ? (
        <WithEventDestinationJobHistory count={historyCount} taskUuid={job?.internalId}>
          <JobHistoryList historyCount={historyCount} setHistoryCount={setHistoryCount} />
        </WithEventDestinationJobHistory>
      ) : null}
    </TabGroup.Panel>
  );
};

const JobPerformanceTabPanel: React.FunctionComponent<{ job?: JobRecord }> = ({ job }) => {
  return (
    <TabGroup.Panel value={JobPageTabType.METRICS}>
      {job?.internalId ? (
        <WithEventDestinationJobHistory taskUuid={job?.internalId}>
          <JobPerformanceTab taskId={job.internalId} jobType="HTTP" />
        </WithEventDestinationJobHistory>
      ) : null}
    </TabGroup.Panel>
  );
};

const AdvancedTabPanel: React.FunctionComponent<{ job?: JobRecord }> = ({ job }) => {
  return (
    <TabGroup.Panel value={JobPageTabType.ADVANCED}>
      <Advanced job={job} />
    </TabGroup.Panel>
  );
};

const TabPanels: React.FunctionComponent<{ job?: JobRecord }> = ({ job }) => {
  return (
    <Box>
      <JobConfigTab job={job} />
      <JobHistoryTabPanel job={job} />
      <JobPerformanceTabPanel job={job} />
      <AdvancedTabPanel job={job} />
    </Box>
  );
};

export default TabPanels;
