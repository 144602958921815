import React from 'react';
import { Box, Heading, PicnicCss, Text } from '@attentive/picnic';

const eventReplayEmptyListOverlay: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '70vw',
  mt: '$space2',
};

const eventReplayEmptyListOverlayText: PicnicCss = {
  backgroundColor: '$bgDefault',
  textAlign: 'center',
  padding: '$space2',
};

const EventReplayEmptyList = () => (
  <Box css={eventReplayEmptyListOverlay}>
    <Box css={eventReplayEmptyListOverlayText}>
      <Heading variant="sm">No Event Replay Requests</Heading>
      <Text>
        You haven’t requested any event replays — <br />
        why not create one now?
      </Text>
    </Box>
  </Box>
);

export default EventReplayEmptyList;
