/**
 * @generated SignedSource<<1fd3e155d781e373efff8f6fc1cc796b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendMessageDialogToAllParticipantsTacticalMfeMutation$variables = {
  messageText: string;
  pollId: string;
};
export type SendMessageDialogToAllParticipantsTacticalMfeMutation$data = {
  readonly sendMessageToAudience: {
    readonly success: boolean;
  } | null;
};
export type SendMessageDialogToAllParticipantsTacticalMfeMutation = {
  response: SendMessageDialogToAllParticipantsTacticalMfeMutation$data;
  variables: SendMessageDialogToAllParticipantsTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messageText"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pollId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "messageText",
            "variableName": "messageText"
          },
          {
            "kind": "Variable",
            "name": "pollId",
            "variableName": "pollId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "SendMessageToAudiencePayload",
    "kind": "LinkedField",
    "name": "sendMessageToAudience",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendMessageDialogToAllParticipantsTacticalMfeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SendMessageDialogToAllParticipantsTacticalMfeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1680c2f956e43d713bc579542c68894c",
    "id": null,
    "metadata": {},
    "name": "SendMessageDialogToAllParticipantsTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation SendMessageDialogToAllParticipantsTacticalMfeMutation(\n  $pollId: ID!\n  $messageText: String!\n) {\n  sendMessageToAudience(input: {pollId: $pollId, messageText: $messageText}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd779b4e9fe1c827b9889d91fda27c59";

export default node;
