/**
 * @generated SignedSource<<8f7d31711b472ee211df0ef4e64c4de8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type FetchCompanyKeywordsFragment_query_refetchable$variables = {
  companyId: string;
  enabled?: boolean | null;
  offset?: string | null;
  pageSize?: number | null;
  search?: string | null;
  sortBy?: string | null;
  sortDirection?: SortDirection | null;
};
export type FetchCompanyKeywordsFragment_query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FetchCompanyKeywordsFragment_query">;
};
export type FetchCompanyKeywordsFragment_query_refetchable = {
  response: FetchCompanyKeywordsFragment_query_refetchable$data;
  variables: FetchCompanyKeywordsFragment_query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "enabled"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offset"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchCompanyKeywordsFragment_query_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FetchCompanyKeywordsFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchCompanyKeywordsFragment_query_refetchable",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "offset"
          },
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "enabled",
                "variableName": "enabled"
              },
              {
                "kind": "Variable",
                "name": "search",
                "variableName": "search"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "pageSize"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "direction",
                "variableName": "sortDirection"
              },
              {
                "kind": "Variable",
                "name": "sortBy",
                "variableName": "sortBy"
              }
            ],
            "kind": "ObjectValue",
            "name": "sort"
          }
        ],
        "concreteType": "CustomCompanyKeywordsConnection",
        "kind": "LinkedField",
        "name": "customCompanyKeywords",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomCompanyKeywordEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomCompanyKeyword",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KeywordInfo",
                    "kind": "LinkedField",
                    "name": "keywordInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "keyword",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributeName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "keywordOrdinal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasSiblings",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ruleCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageInfo",
                    "kind": "LinkedField",
                    "name": "messageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "messageText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forceSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mediaUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortenLink",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f45c8b7bf847ca37d55392efa2df677b",
    "id": null,
    "metadata": {},
    "name": "FetchCompanyKeywordsFragment_query_refetchable",
    "operationKind": "query",
    "text": "query FetchCompanyKeywordsFragment_query_refetchable(\n  $companyId: ID!\n  $enabled: Boolean\n  $offset: String\n  $pageSize: Int\n  $search: String\n  $sortBy: String\n  $sortDirection: SortDirection\n) {\n  ...FetchCompanyKeywordsFragment_query\n}\n\nfragment FetchCompanyKeywordsFragment_query on Query {\n  customCompanyKeywords(filter: {enabled: $enabled, search: $search}, sort: {sortBy: $sortBy, direction: $sortDirection}, companyId: $companyId, first: $pageSize, after: $offset) {\n    edges {\n      node {\n        id\n        keywordInfo {\n          keyword\n          enabled\n          attributeName\n          keywordOrdinal\n          hasSiblings\n          ruleCount\n        }\n        messageInfo {\n          messageText\n          forceSend\n          mediaUrl\n          shortenLink\n        }\n        updated\n        created\n      }\n    }\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "99f6d283353cddee4d8e22709c233783";

export default node;
