/**
 * @generated SignedSource<<4669e814bb6a20fff6eed68e6661a39a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivacyRequestSortColumn = "PRIVACY_REQUEST_SORT_COLUMN_PROCESSING_DATETIME" | "PRIVACY_REQUEST_SORT_COLUMN_REQUEST_DATETIME" | "PRIVACY_REQUEST_SORT_COLUMN_UNKNOWN" | "%future added value";
export type PrivacyRequestStatus = "PRIVACY_REQUEST_STATUS_ALL" | "PRIVACY_REQUEST_STATUS_PROCESSED" | "PRIVACY_REQUEST_STATUS_UNKNOWN" | "PRIVACY_REQUEST_STATUS_UNPROCESSED" | "%future added value";
export type PrivacyRequestType = "ACCESS" | "DELETE" | "%future added value";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type PrivacyRequestFilterInput = {
  requestType: PrivacyRequestType;
  status: PrivacyRequestStatus;
};
export type PrivacyRequestSortInput = {
  sortColumn: PrivacyRequestSortColumn;
  sortDirection: SortDirection;
};
export type PrivacyRequestPresenterTacticalMfeQuery$variables = {
  companyId: string;
  filter?: PrivacyRequestFilterInput | null;
  first: number;
  sort?: PrivacyRequestSortInput | null;
};
export type PrivacyRequestPresenterTacticalMfeQuery$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"usePaginatedPrivacyRequests_CompanyFragment">;
  } | null;
};
export type PrivacyRequestPresenterTacticalMfeQuery = {
  response: PrivacyRequestPresenterTacticalMfeQuery$data;
  variables: PrivacyRequestPresenterTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivacyRequestPresenterTacticalMfeQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "usePaginatedPrivacyRequests_CompanyFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PrivacyRequestPresenterTacticalMfeQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "PrivacyRequestConnection",
                "kind": "LinkedField",
                "name": "privacyRequests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrivacyRequestEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrivacyRequest",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subjectPhone",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subjectEmail",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requestMsg",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "processed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requestDateTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "processingEndDateTime",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9cbb48f892f4f76e2d23c38759777b0",
    "id": null,
    "metadata": {},
    "name": "PrivacyRequestPresenterTacticalMfeQuery",
    "operationKind": "query",
    "text": "query PrivacyRequestPresenterTacticalMfeQuery(\n  $companyId: ID!\n  $first: Int!\n  $filter: PrivacyRequestFilterInput\n  $sort: PrivacyRequestSortInput\n) {\n  company: node(id: $companyId) {\n    __typename\n    ...usePaginatedPrivacyRequests_CompanyFragment_1bvy9D\n    id\n  }\n}\n\nfragment PrivacyRequestRow_TacticalMfe_CompanyFragment on PrivacyRequest {\n  id\n  subjectPhone\n  subjectEmail\n  requestMsg\n  processed\n  requestDateTime\n  processingEndDateTime\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n\nfragment usePaginatedPrivacyRequests_CompanyFragment_1bvy9D on Company {\n  id\n  displayName\n  privacyRequests(first: $first, sort: $sort, filter: $filter) {\n    pageInfo {\n      ...usePaginatedDataFragment_pageInfo\n    }\n    totalCount\n    edges {\n      node {\n        ...PrivacyRequestRow_TacticalMfe_CompanyFragment\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "158bdbe32c16e16bcb07dc49c395e38b";

export default node;
