import { Box, Heading, Text } from '@attentive/picnic';
import React from 'react';
import TacticalBreadcrumbs, {
  PRIVACY_REQUESTS_BREADCRUMB,
} from '../../components/common/TacticalBreadcrumbs';
import PrivacyRequestContainer from '../../components/PrivacyRequests/RequestContainer';
import PrivacyRequestPresenter from './PrivacyRequestPresenter';

export const PrivacyRequestPage: React.FunctionComponent = () => {
  // TODO Use a button group to toggle the filters
  // const [filter] = useState<PrivacyRequestStatus>(PrivacyRequestStatus.PrivacyRequestStatusAll);

  return (
    <>
      <TacticalBreadcrumbs breadcrumbs={[PRIVACY_REQUESTS_BREADCRUMB]} />
      <Box as="section" css={{ display: 'flex', mb: '$space10' }}>
        <Box css={{ display: 'flex', flexDirection: 'column', flex: 0.5 }}>
          <div>
            <Heading css={{ mb: '$space2' }}>Privacy Requests</Heading>
            <Text css={{ fontSize: '$fontSize3' }}>Add or manage requests.</Text>
          </div>
        </Box>
      </Box>

      <PrivacyRequestPresenter>
        <PrivacyRequestContainer />
      </PrivacyRequestPresenter>
    </>
  );
};

export default PrivacyRequestPage;
