import React from 'react';
import { Box } from '@attentive/picnic';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import SubmitMessageEdit from './SubmitMessageEdit';
import ClosedMessageEdit from './ClosedMessageEdit';
import DuplicateMessageEdit from './DuplicateMessageEdit';

const ResponsesTab: React.FunctionComponent<{ poll: PollType }> = ({ poll }) => {
  return (
    <Box>
      <SubmitMessageEdit poll={poll} />
      <ClosedMessageEdit poll={poll} />
      <DuplicateMessageEdit poll={poll} />
    </Box>
  );
};

export default ResponsesTab;
