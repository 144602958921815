import React from 'react';

import logo from './assets/replay.png';
import ProjectCard from './ProjectCard';
import { Paths } from '../../constants/routes';
import {
  TacticalHomeProjectCardProps,
  ProjectCardConfig,
} from '../../constants/Home/tacticalHomeTypes';

const config: ProjectCardConfig = {
  defaultEnabled: false,
  allowedRoles: ['ROLE_ENGINEERING', 'ROLE_CS_TAM'],
  heading: 'Event Replay',
  image: logo,
  path: Paths.EventReplayPage,
};

const EventReplayCard: React.FunctionComponent<TacticalHomeProjectCardProps> = ({
  viewerRoles,
}) => {
  return <ProjectCard config={{ ...config, viewerRoles }} />;
};

export default EventReplayCard;
