import { JobRecord } from '../../../../constants/EventDestinations/jobTypes';

export const prettyJson = (value: string, autoFormat: boolean) => {
  if (!autoFormat) {
    return value;
  }

  try {
    const json = JSON.parse(value);
    return JSON.stringify(json, null, 4);
  } catch {
    return value;
  }
};

export const cleanJson = (value: string) => {
  return value.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, '');
};

export const minifyJson = (json: string) => {
  return cleanJson(JSON.stringify(json, null, 0));
};

export const isValidJson = (value?: string) => {
  if (!value) {
    return false;
  }

  try {
    JSON.parse(value);
    return true;
  } catch {
    return false;
  }
};

export const getEndpointFromJob = (job: JobRecord) => {
  const targetInfo = job.targetInfo;
  if (targetInfo) {
    try {
      return JSON.parse(targetInfo).url;
    } catch (e) {
      return null;
    }
  }
  return null;
};
