/**
 * @generated SignedSource<<a86f3d7ef4d5c5624856bec7fe399d97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type UpdatePollV2Input = {
  allowAltSignupUnit?: boolean | null;
  allowBreakTies?: boolean | null;
  allowMarketingSubmitMessage?: boolean | null;
  allowMultiVote?: boolean | null;
  altSignupUnitId?: number | null;
  applicationId?: string | null;
  closedMessage?: string | null;
  companyId: string;
  decimalPrecision?: number | null;
  description?: string | null;
  duplicateVoteMessage?: string | null;
  enableParticipantAttributeName?: boolean | null;
  marketingSubmitMessage?: string | null;
  name?: string | null;
  onClosedMessage?: string | null;
  participantAttributeName?: string | null;
  pollId: string;
  state?: PollState | null;
  submitMessage?: string | null;
  subscribedEventType?: string | null;
};
export type EditPollMutation$variables = {
  input: UpdatePollV2Input;
};
export type EditPollMutation$data = {
  readonly updatePollV2: {
    readonly error: string | null;
    readonly poll: {
      readonly allowAltSignupUnit: boolean | null;
      readonly allowBreakTies: boolean | null;
      readonly allowMultiVote: boolean;
      readonly altSignupUnitId: number | null;
      readonly applicationId: string | null;
      readonly closedMessage: string | null;
      readonly decimalPrecision: number | null;
      readonly description: string | null;
      readonly duplicateVoteMessage: string | null;
      readonly name: string;
      readonly onClosedMessage: string | null;
      readonly participantAttributeName: string | null;
      readonly state: PollState;
      readonly submitMessage: string | null;
      readonly subscribedEventType: string | null;
      readonly uuid: string;
    } | null;
  } | null;
};
export type EditPollMutation = {
  response: EditPollMutation$data;
  variables: EditPollMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submitMessage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closedMessage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duplicateVoteMessage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowMultiVote",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowBreakTies",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimalPrecision",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "participantAttributeName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onClosedMessage",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscribedEventType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applicationId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowAltSignupUnit",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "altSignupUnitId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPollMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePollV2Payload",
        "kind": "LinkedField",
        "name": "updatePollV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPollMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePollV2Payload",
        "kind": "LinkedField",
        "name": "updatePollV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b4bb0384b493491d6eb23d884d46fd4",
    "id": null,
    "metadata": {},
    "name": "EditPollMutation",
    "operationKind": "mutation",
    "text": "mutation EditPollMutation(\n  $input: UpdatePollV2Input!\n) {\n  updatePollV2(input: $input) {\n    poll {\n      name\n      description\n      uuid\n      state\n      submitMessage\n      closedMessage\n      duplicateVoteMessage\n      allowMultiVote\n      allowBreakTies\n      decimalPrecision\n      participantAttributeName\n      onClosedMessage\n      subscribedEventType\n      applicationId\n      allowAltSignupUnit\n      altSignupUnitId\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3bdd1a7b3b63a761fb58d96a8fbd1bc";

export default node;
