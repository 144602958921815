import React from 'react';
import {
  RunReportV2,
  useRunReportsV2,
} from '../../../pages/EventDestinations/graphql/WithEventDestinationJobRunReportsV2';
import { PerformanceBarDatum, RollupCounts } from '../Jobs/TabPanels/Performance/types';
import { Box, Text, Heading, IconPopover } from '@attentive/picnic';
import { calculateRollups, parseRunsToDatum } from './helper';
import { rollupContainerStyles, rollupCountStyles } from '../Jobs/TabPanels/Performance/styles';
import StatusCodeBarChart from '../Jobs/TabPanels/Performance/StatusCodeBarChart';

const InfoPopover: React.FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <IconPopover iconName="CircleInformation" iconColor="subdued" size="extraSmall">
      <Text>{text}</Text>
    </IconPopover>
  );
};

const RollupValueWithInfo: React.FunctionComponent<{
  value: string;
  label: string;
  tooltip: string;
}> = ({ value, label, tooltip }) => {
  return (
    <Box css={rollupCountStyles}>
      <Text variant="lede">{value}</Text>
      <Box css={{ display: 'flex' }}>
        <Heading variant="sm">{label}</Heading>
        <InfoPopover text={tooltip} />
      </Box>
    </Box>
  );
};

const RollupValue: React.FunctionComponent<{ value: string; label: string }> = ({
  value,
  label,
}) => {
  return (
    <Box css={rollupCountStyles}>
      <Text variant="lede">{value}</Text>
      <Heading variant="sm">{label}</Heading>
    </Box>
  );
};

const RollupGrid: React.FunctionComponent<{ rollupData: RollupCounts }> = ({ rollupData }) => {
  return (
    <Box css={rollupContainerStyles}>
      <RollupValue value={rollupData.topic_status_code_200.toLocaleString()} label="Successful" />
      <RollupValue value={rollupData.topic_status_code_400.toLocaleString()} label="Invalid" />
      <RollupValueWithInfo
        value={rollupData.topic_status_code_404.toLocaleString()}
        label="Not Found"
        tooltip="Subscriber not found in our db"
      />
      <RollupValueWithInfo
        value={rollupData.topic_status_code_403.toLocaleString()}
        label="Inactive"
        tooltip="Subscriber has not fully opted in to sms"
      />
      <RollupValueWithInfo
        value={rollupData.topic_status_code_405.toLocaleString()}
        label="Opted Out"
        tooltip="Subscriber has opted out of sms"
      />
    </Box>
  );
};

const ClientEdsBarChart: React.FunctionComponent = () => {
  const runReports: RunReportV2[] = useRunReportsV2();
  const performanceData: PerformanceBarDatum[] = parseRunsToDatum(runReports);
  const rollupData = calculateRollups(performanceData);

  return (
    <Box css={{ mt: '$space12' }}>
      <RollupGrid rollupData={rollupData} />
      <StatusCodeBarChart data={performanceData} />
    </Box>
  );
};

export default ClientEdsBarChart;
