/**
 * @generated SignedSource<<7facd53abd3f4354d70591a3b1f9b3ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WithEventDestinationJobHistoryTacticalMfeQuery$variables = {
  first: number;
  taskId: string;
};
export type WithEventDestinationJobHistoryTacticalMfeQuery$data = {
  readonly eventDestinations: {
    readonly jobHistory: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly context: any | null;
          readonly parentTaskId: string | null;
          readonly runDate: SerializedDateTime | null;
          readonly scheduledDate: SerializedDateTime;
          readonly status: string;
          readonly taskId: string;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | null;
};
export type WithEventDestinationJobHistoryTacticalMfeQuery = {
  response: WithEventDestinationJobHistoryTacticalMfeQuery$data;
  variables: WithEventDestinationJobHistoryTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventDestinations",
    "kind": "LinkedField",
    "name": "eventDestinations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
          }
        ],
        "concreteType": "JobHistoryConnection",
        "kind": "LinkedField",
        "name": "jobHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobHistoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobHistory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taskId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentTaskId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "runDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "context",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WithEventDestinationJobHistoryTacticalMfeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WithEventDestinationJobHistoryTacticalMfeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0dd7bffff0b81567d91325fb775d35ee",
    "id": null,
    "metadata": {},
    "name": "WithEventDestinationJobHistoryTacticalMfeQuery",
    "operationKind": "query",
    "text": "query WithEventDestinationJobHistoryTacticalMfeQuery(\n  $taskId: String!\n  $first: Int!\n) {\n  eventDestinations {\n    jobHistory(taskId: $taskId, first: $first) {\n      edges {\n        node {\n          uuid\n          taskId\n          parentTaskId\n          runDate\n          scheduledDate\n          context\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "77c1c9e70d5e15bb6dc75473f8bb75c4";

export default node;
