export interface CustomCompanyKeyword {
  id: string;
  keyword: string;
  messageText: string;
  attributeName: string | null;
  keywordOrdinal: number | null;
  hasSiblings: boolean | null;
  ruleCount: number | null;
  enabled: boolean;
  forceSend: boolean;
  // created: string;
  updated: string;
  mediaUrl: string | null;
  shortenLink: boolean;
}

export interface KeywordRulesInfo {
  companyKeywordId: number;
  ruleCount: number;
  rulesExpression: string;
  rulesLegend: Map<string, string>;
}

export enum KeywordOrderBy {
  KEYWORD_ASC,
  KEYWORD_DESC,
  UPDATE_ASC,
  UPDATE_DESC,
}

export interface UploadedCompanyKeyword {
  keyword: string;
  messageText: string;
  isTransactional: boolean;
  isEnabled: boolean;
  customAttribute?: string;
}
