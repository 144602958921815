import React from 'react';
import { Box, Table, PicnicCss } from '@attentive/picnic';
import { EventReplayStatus } from '@attentive/data/types';

interface EventReplayTypesTableProps {
  estimatedCounts: { [key: string]: number };
  status?: string;
}

const EventReplayTypesTable: React.FunctionComponent<EventReplayTypesTableProps> = ({
  estimatedCounts,
  status,
}) => {
  const eventTypes = Object.keys(estimatedCounts);

  const shouldBeDisabled = (): boolean => {
    return status !== EventReplayStatus.Completed;
  };

  const headerCss = (): PicnicCss | undefined => {
    if (shouldBeDisabled()) {
      return { color: '$textDisabled' };
    }
    return;
  };

  return (
    <Box>
      <Table columns={[3, 1, 1, 1]}>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell>Event Type</Table.HeaderCell>
            <Table.HeaderCell>Estimated Count</Table.HeaderCell>
            <Table.HeaderCell css={headerCss()}>Actual Count</Table.HeaderCell>
            <Table.HeaderCell css={headerCss()}>Completed</Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {eventTypes.map((evtType) => (
            <Table.BodyRow key={evtType}>
              <Table.BodyCell>{evtType}</Table.BodyCell>
              <Table.BodyCell>{estimatedCounts[evtType]}</Table.BodyCell>
              <Table.BodyCell>{shouldBeDisabled() ? '' : estimatedCounts[evtType]}</Table.BodyCell>
              <Table.BodyCell>{shouldBeDisabled() ? '' : 'Fri Feb 18, 2022'}</Table.BodyCell>
            </Table.BodyRow>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default EventReplayTypesTable;
