import { CreateEdsJobMappingInput } from '../CreateJob/CreateMappings/__generated__/CreateMappingsFormTacticalMfeMutation.graphql';
import { TestConfig, object, string } from 'yup';
import { ScheduleJobRequest } from '../../../../constants/EventDestinations/jobTypes';
import { cleanJson, isValidJson } from './jsonUtils';
import { TIMEZONES } from '../TabPanels/Advance/ScheduleJob/timezones';
import { isValidCronExpression } from 'cron-expression-validator';

export const POST_MAP_INITIAL_VALUES_FRAGMENT = {
  sourceUuid: '',
  targetUuid: '',
  sourceDetails: '{}',
  targetDetails: '{}',
  authDetails: '{}',
  responseDetails: '{"response_predicates":{"successStatusCode":"[200]"}}',
};

const buildTest = (type: string): TestConfig => {
  return {
    name: `${type}DetailsTest`,
    message: `Is ${type}Details valid JSON`,
    test: (value) => {
      return isValidJson(value as string);
    },
  };
};

export const POST_MAP_VALIDATION_SCHEMA = object().shape({
  companyId: string().required(),
  taskId: string().required(),
  sourceUuid: string().required(),
  targetUuid: string().required(),
  sourceDetails: string().required().test(buildTest('source')),
  targetDetails: string().required().test(buildTest('target')),
  authDetails: string().required().test(buildTest('auth')),
  responseDetails: string().required().test(buildTest('response')),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildGrahpqlInput = (values: any): CreateEdsJobMappingInput => {
  const {
    companyId,
    taskId,
    sourceUuid,
    targetUuid,
    sourceDetails,
    targetDetails,
    responseDetails,
    authDetails,
  } = values;
  return {
    companyId,
    taskId,
    sourceUuid,
    targetUuid,
    sourceDetails: cleanJson(sourceDetails),
    targetDetails: cleanJson(targetDetails),
    responseDetails: cleanJson(responseDetails),
    authDetails: cleanJson(authDetails),
  };
};

export const SETUP_SCHEDULE_INITIAL_VALUES_FRAGMENT: Partial<ScheduleJobRequest> = {
  cron: '',
  description: '',
  zoneId: '',
};

export const SETUP_SCHEDULE_VALIDATION_SCHEMA = object().shape({
  taskId: string().required(),
  cron: string().test({
    name: 'is valid cron',
    message: 'cron expression is invalid',
    test: (value) => {
      if (!value) {
        return false;
      }
      return isValidCronExpression(value);
    },
  }),
  description: string().max(140),
  zoneId: string().oneOf(TIMEZONES).required(''),
});
