/**
 * @generated SignedSource<<a41ce44c993f01631fc38fcd4f9b260c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventDestinationTasksFilter = {
  companyId?: string | null;
  search?: string | null;
};
export type Table2TacticalMfeQuery$variables = {
  after?: string | null;
  before?: string | null;
  filter?: EventDestinationTasksFilter | null;
  first?: number | null;
  last?: number | null;
};
export type Table2TacticalMfeQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Table2_fragment">;
};
export type Table2TacticalMfeQuery$rawResponse = {
  readonly eventDestinationTasks: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly __typename: "EventDestinationTask";
        readonly company: {
          readonly id: string;
          readonly internalId: number;
          readonly name: string;
        } | null;
        readonly created: SerializedDateTime | null;
        readonly description: string | null;
        readonly id: string;
        readonly name: string | null;
        readonly userId: string;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string;
    };
  } | null;
};
export type Table2TacticalMfeQuery = {
  rawResponse: Table2TacticalMfeQuery$rawResponse;
  response: Table2TacticalMfeQuery$data;
  variables: Table2TacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "withTaskHistory",
    "value": false
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Table2TacticalMfeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Table2_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "Table2TacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EventDestinationTasksPayload",
        "kind": "LinkedField",
        "name": "eventDestinationTasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventDestinationTaskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventDestinationTask",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "internalId",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "filter",
          "withTaskHistory"
        ],
        "handle": "connection",
        "key": "JobsTable2_eventDestinationTasks",
        "kind": "LinkedHandle",
        "name": "eventDestinationTasks"
      }
    ]
  },
  "params": {
    "cacheID": "4c7bcc274b0a303dc3fb1fb933586c9f",
    "id": null,
    "metadata": {},
    "name": "Table2TacticalMfeQuery",
    "operationKind": "query",
    "text": "query Table2TacticalMfeQuery(\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $filter: EventDestinationTasksFilter\n) {\n  ...Table2_fragment\n}\n\nfragment Table2_fragment on Query {\n  eventDestinationTasks(after: $after, first: $first, last: $last, before: $before, filter: $filter, withTaskHistory: false) {\n    edges {\n      cursor\n      node {\n        id\n        name\n        description\n        created\n        userId\n        company {\n          internalId\n          name\n          id\n        }\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bc64ef8bd376de96db9ae53e10b2a75";

export default node;
