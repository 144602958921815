import { ToastType, handleMutationResponse, useToast } from '@attentive/acore-utils';
import { Box, Form, Heading } from '@attentive/picnic';
import React from 'react';
import { useMutation } from 'react-relay';
import { array, mixed, object, string } from 'yup';
import { PollType } from '../../../constants/PollSurveys/pollTypes';
import { InnerForm } from './InnerForm';
import { buildSetSweepstakesFilterInput } from './graphql/SetWinnerFilter';
import SetWinnerFilterMutation, {
  SetWinnerFilterMutation$data,
  SetWinnerFilterMutation as SetWinnerFilterMutationType,
} from './graphql/__generated__/SetWinnerFilterMutation.graphql';
import { SelectorType, SubscriberType, SweepstakesFormValues } from './types';

const buildInitialValues = (pollId: string): SweepstakesFormValues => {
  return {
    pollId,
    numToSelect: '',
    selectorType: SelectorType.FIRST,
    subscriberType: SubscriberType.ANY,
    pollKeywordIds: [] as string[],
  };
};

const VALIDATION_SCHEMA = object().shape({
  pollId: string().required(),
  numToSelect: string()
    .matches(/^[1-9]+\d*$/g, {
      message: 'Please provide a valid number of participants to select.',
    })
    .required("You must provide number of participants if you're adding rules."),
  selectorType: mixed<SelectorType>().required(),
  subscriberType: mixed<SubscriberType>().required(),
  pollKeywordIds: array().of(string()).min(1).required(),
});

export const SweepstakesBuilderForm: React.FC<{ poll: PollType }> = ({ poll }) => {
  const [setWinnerFilter] = useMutation<SetWinnerFilterMutationType>(SetWinnerFilterMutation);
  const [createToast] = useToast();

  const handleSubmit = (
    values: SweepstakesFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const variables = buildSetSweepstakesFilterInput(values);

    const onCompleted = (response: SetWinnerFilterMutation$data) => {
      handleMutationResponse({
        response: response.setSweepstakesFilter,
        onSuccess: (_) => {
          createToast({
            type: ToastType.Success,
            title: 'Winner Filter Set!',
            text: '',
          });
          resetForm();
        },
        onError: (err) => {
          console.log('Mutation error', err);
          createToast({
            type: ToastType.Error,
            title: 'Failed to set Winner Filter',
            text: err.message,
          });
        },
      });
    };

    const onError = (error: Error) => {
      console.error('Connection error', error);
      createToast({
        type: ToastType.Error,
        title: 'Connection Error',
        text: "Couldn't complete request, please try again later or contact Tactical if behavior persists.",
      });
    };

    try {
      setWinnerFilter({
        variables,
        onCompleted,
        onError,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Heading css={{ mb: '$space6' }}>Sweepstakes Segment Builder</Heading>
      <Form
        initialValues={buildInitialValues(poll.id)}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values: SweepstakesFormValues, { resetForm, setSubmitting }) => {
          handleSubmit(values, resetForm, setSubmitting);
        }}
      >
        <InnerForm poll={poll} />
      </Form>
    </Box>
  );
};
