import React from 'react';
import { Box, Heading, Separator } from '@attentive/picnic';
import ApiSection from './ApiSection';
import ResetPoll from './ResetPoll';
import ToggleArchivePoll from './ToggleArchivePoll';
import ClonePoll from './ClonePoll';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

const AdvancedTab: React.FunctionComponent<{
  poll: PollType;
}> = ({ poll }) => {
  const [openResetDialog, setOpenResetDialog] = React.useState<boolean>(false);
  const [openToggleArchiveDialog, setOpenToggleArchiveDialog] = React.useState<boolean>(false);
  const [openCloneDialog, setCloneDialog] = React.useState<boolean>(false);
  return (
    <Box>
      <Heading css={{ mb: '$space2' }}>Advanced</Heading>
      <Box css={{ maxWidth: '600px' }}>
        <Box css={{ mb: '$space8' }}>
          <ResetPoll pollId={poll.id} isOpen={openResetDialog} setIsOpen={setOpenResetDialog} />
          <Separator />
          <ApiSection pollId={poll.id} />
          <Separator />
          <ToggleArchivePoll
            pollId={poll.id}
            isArchived={poll.isArchived || false}
            isOpen={openToggleArchiveDialog}
            setIsOpen={setOpenToggleArchiveDialog}
          />
          <ClonePoll poll={poll} isOpen={openCloneDialog} setIsOpen={setCloneDialog}></ClonePoll>
        </Box>
      </Box>
    </Box>
  );
};

export default AdvancedTab;
