import React from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { Box } from '@attentive/picnic';

import { SubscriberCount } from './SubscriberCount';
import Query, {
  AudienceTabPollCountTacticalMfeQuery as QueryType,
} from './__generated__/AudienceTabPollCountTacticalMfeQuery.graphql';
import { ParticipantsList } from './Participants';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

interface AudienceTabProps {
  poll: PollType;
  queryRef: PreloadedQuery<QueryType>;
}

export const AudienceTab: React.FunctionComponent<AudienceTabProps> = ({ poll, queryRef }) => {
  const data = usePreloadedQuery<QueryType>(Query, queryRef);
  const audienceCount = data.poll?.audience?.count?.count || 0;

  return (
    <Box css={{ maxWidth: '600px' }}>
      <SubscriberCount audienceCount={audienceCount} />
      <ParticipantsList poll={poll} />
    </Box>
  );
};
