/**
 * @generated SignedSource<<29998d4aaa4bc3f748156d9bcd29ca1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DestinationListQuery$variables = {};
export type DestinationListQuery$data = {
  readonly eventDestinations: {
    readonly destinations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly author: {
            readonly email: string;
            readonly firstName: string;
            readonly lastName: string;
          } | null;
          readonly created: SerializedDateTime;
          readonly description: string;
          readonly image: string;
          readonly name: string;
          readonly updated: SerializedDateTime;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | null;
};
export type DestinationListQuery = {
  response: DestinationListQuery$data;
  variables: DestinationListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DestinationListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NodeCollectionConnection",
            "kind": "LinkedField",
            "name": "destinations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NodeCollectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NodeCollection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DestinationListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NodeCollectionConnection",
            "kind": "LinkedField",
            "name": "destinations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NodeCollectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NodeCollection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9b2152c8afd8c51db5509c660ea1bfb",
    "id": null,
    "metadata": {},
    "name": "DestinationListQuery",
    "operationKind": "query",
    "text": "query DestinationListQuery {\n  eventDestinations {\n    destinations {\n      edges {\n        node {\n          uuid\n          name\n          description\n          image\n          updated\n          created\n          author {\n            firstName\n            lastName\n            email\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bdd09352de1ba83edf58eb9af91749c";

export default node;
