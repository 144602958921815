import React from 'react';
import { RefetchFnDynamic } from 'react-relay';
import { Box, Button, ContainedLabel, Heading, Separator, Text } from '@attentive/picnic';
import { PollState } from '@attentive/data/types';
import TacticalBreadcrumbs, {
  POLL_SURVEY_BREADCRUMB,
  TacticalBreadcrumbType,
} from '../../common/TacticalBreadcrumbs';
import { PollType } from '../../../constants/PollSurveys/pollTypes';
import { PollResults } from '../../PollSurveys/PollResults/PollResults';
import { LoadPollFragment_query_refetchable as RefetchQuery } from '../../../pages/PollSurveys/graphql/__generated__/LoadPollFragment_query_refetchable.graphql';
import { LoadPollFragment_query$key as FragmentType } from '../../../pages/PollSurveys/graphql/__generated__/LoadPollFragment_query.graphql';
import ManualResponders from '../PollDetailTabs/MessagingTab/ManualResponders';
import UpdateStateDialog from './UpdateStateDialog';

const PollDetailsMobile: React.FunctionComponent<{
  poll: PollType;
  refetch: RefetchFnDynamic<RefetchQuery, FragmentType>;
  setMobile: (mobile: boolean) => void;
}> = ({ poll, setMobile }) => {
  const [state, setState] = React.useState<string>(poll.state);
  const [isStateDialogOpen, setIsStateDialogOpen] = React.useState<boolean>(false);

  const breadcrumb: TacticalBreadcrumbType = {
    id: 99,
    path: '',
    text: poll.name,
  };

  const handleStateClick = () => {
    setIsStateDialogOpen(true);
  };

  return (
    <Box>
      <TacticalBreadcrumbs breadcrumbs={[POLL_SURVEY_BREADCRUMB, breadcrumb]} />
      <Box css={{ display: 'flex' }}>
        <Heading>{poll.name}</Heading>
        <Box css={{ ml: '$space4', mt: '$space2' }}>
          <ContainedLabel
            onClick={handleStateClick}
            variant={state === PollState.Open ? 'success' : 'neutral'}
          >
            {state}
          </ContainedLabel>
        </Box>
      </Box>
      <Text>{poll.description}</Text>
      <Box css={{ mt: '$space6' }}>
        <Separator />
        <PollResults poll={poll} />
        <Separator />
        <Box css={{ mt: '$space4' }}>
          <ManualResponders poll={poll} updateRequestField={(f, v) => console.log(f, v)} />
        </Box>
        <Box css={{ mb: '$space4', display: 'flex', justifyContent: 'center' }}>
          <Button variant="subdued" onClick={() => setMobile(false)}>
            View desktop version
          </Button>
        </Box>
      </Box>
      <UpdateStateDialog
        poll={poll}
        isOpen={isStateDialogOpen}
        setIsOpen={setIsStateDialogOpen}
        state={state}
        setState={setState}
      />
    </Box>
  );
};

export default PollDetailsMobile;
