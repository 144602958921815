import { Box, ContainedLabel, Heading, TabGroup, Text } from '@attentive/picnic';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import KeywordsTab from '../../components/PollSurveys/PollDetailTabs/KeywordsTab';
import SettingsTab from '../../components/PollSurveys/PollDetailTabs/SettingsTab';
import TacticalBreadcrumbs, {
  POLL_SURVEY_BREADCRUMB,
  TacticalBreadcrumbType,
} from '../../components/common/TacticalBreadcrumbs';
import { POLL_PAGE_TAB, PollType } from '../../constants/PollSurveys/pollTypes';

import { ToastType, getRoles, useCurrentCompanyId, useToast } from '@attentive/acore-utils';
import { PollState, Role } from '@attentive/data/types';
import { RefetchFnDynamic, useMutation } from 'react-relay';
import AdvancedTab from '../../components/PollSurveys/PollDetailTabs/AdvancedTab';
import AudienceTab from '../../components/PollSurveys/PollDetailTabs/AudienceTab';
import EditPollDialog from '../../components/PollSurveys/PollDetailTabs/EditPollDialog';
import { MessagingTab } from '../../components/PollSurveys/PollDetailTabs/MessagingTab';
import ResponsesTab from '../../components/PollSurveys/PollDetailTabs/ResponsesTab';
import { TriggersTab } from '../../components/PollSurveys/PollDetailTabs/TriggersTab';
import { PollResults } from '../../components/PollSurveys/PollResults/PollResults';
import { SweepstakesBuilderForm } from '../../components/PollSurveys/Sweepstakes/SweepstakesBuilderForm';
import { WinnerFilterDisplay } from '../../components/PollSurveys/Sweepstakes/WinnerFilterDisplay';
import { buildInitialRequest } from './graphql/EditPollMutation';
import EditPollMutation, {
  EditPollMutation as EditPollMutationType,
  UpdatePollV2Input,
} from './graphql/__generated__/EditPollMutation.graphql';
import { LoadPollFragment_query$key as FragmentType } from './graphql/__generated__/LoadPollFragment_query.graphql';
import { LoadPollFragment_query_refetchable as RefetchQuery } from './graphql/__generated__/LoadPollFragment_query_refetchable.graphql';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const updateUrlSearchParam = (key: string, value: string) => {
  const url = new URL(window.location.toString());
  url.searchParams.set(key, value);
  window.history.pushState(null, '', url.toString());
};

const PollDetailsPageDesktop: React.FunctionComponent<{
  poll: PollType;
  refetch: RefetchFnDynamic<RefetchQuery, FragmentType>;
}> = ({ poll, refetch }) => {
  // Query and TabGroup related vars/methods
  const roles = getRoles();
  const query = useQuery();
  const [tabVal, setTabVal] = React.useState<string>(query.get('tab') || POLL_PAGE_TAB.RESULTS);
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);

  const breadcrumb: TacticalBreadcrumbType = {
    id: 99,
    path: '',
    text: poll.name,
  };

  const enabledLabel = poll.state;
  const enabledVariant = enabledLabel === PollState.Open ? 'success' : 'neutral';

  // Mutation related vars/methods
  const companyId = useCurrentCompanyId();
  const [createToast] = useToast();
  const [editPollRequest, setEditPollRequest] = useState<UpdatePollV2Input>(
    buildInitialRequest(companyId, poll.id)
  );

  const handleTabChange = (tab: string) => {
    setTabVal(tab);
    updateUrlSearchParam('tab', tab);
    setEditPollRequest(buildInitialRequest(companyId, poll.id));
  };

  const [commitEditPollMutation] = useMutation<EditPollMutationType>(EditPollMutation);

  const handleSave = () => {
    if (editPollRequest.state === 'OPEN' && poll.keywordOptions.length < 1) {
      createToast({
        title: 'Cannot Update State',
        type: ToastType.Error,
        text: 'Must have at least one keyword to open a poll.',
      });
    } else if (
      editPollRequest.enableParticipantAttributeName &&
      !editPollRequest.participantAttributeName
    ) {
      createToast({
        title: 'Cannot Update Participant Attribute',
        type: ToastType.Error,
        text: 'Must have custom attribute value if enabled.',
      });
    } else {
      commitEditPollMutation({
        variables: { input: editPollRequest },
        onCompleted: (response) => {
          console.log(response);
          createToast({
            type: ToastType.Success,
            text: 'Successfully updated poll',
          });
          setEditPollRequest(buildInitialRequest(companyId, poll.id));
        },
        onError: () => {
          createToast({
            type: ToastType.Error,
            text: 'Uh-oh something went wrong',
          });
        },
      });
    }
  };

  const isEng = roles.has(Role.RoleEngineering);

  return (
    <Box>
      <TacticalBreadcrumbs breadcrumbs={[POLL_SURVEY_BREADCRUMB, breadcrumb]} />
      <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box css={{ display: 'flex' }}>
          <Heading>{poll.name}</Heading>
          <Box css={{ ml: '$space4', mt: '$space2' }}>
            <ContainedLabel variant={enabledVariant}>{enabledLabel}</ContainedLabel>
          </Box>
        </Box>
      </Box>
      <Text>{poll.description}</Text>
      <Box css={{ mt: '$space6' }}>
        <TabGroup value={tabVal} onValueChange={handleTabChange}>
          <TabGroup.List>
            <TabGroup.Tab value={POLL_PAGE_TAB.RESULTS}>{POLL_PAGE_TAB.RESULTS}</TabGroup.Tab>
            <TabGroup.Tab value={POLL_PAGE_TAB.KEYWORDS}>{POLL_PAGE_TAB.KEYWORDS}</TabGroup.Tab>
            <TabGroup.Tab value={POLL_PAGE_TAB.SETTINGS}>{POLL_PAGE_TAB.SETTINGS}</TabGroup.Tab>
            <TabGroup.Tab value={POLL_PAGE_TAB.MESSAGING}>{POLL_PAGE_TAB.MESSAGING}</TabGroup.Tab>
            <TabGroup.Tab value={POLL_PAGE_TAB.TRIGGERS}>{POLL_PAGE_TAB.TRIGGERS}</TabGroup.Tab>
            <TabGroup.Tab value={POLL_PAGE_TAB.AUDIENCE}>{POLL_PAGE_TAB.AUDIENCE}</TabGroup.Tab>
            <TabGroup.Tab value={POLL_PAGE_TAB.ADVANCED}>{POLL_PAGE_TAB.ADVANCED}</TabGroup.Tab>
            {isEng && (
              <TabGroup.Tab value={POLL_PAGE_TAB.SWEEPSTAKES}>
                {POLL_PAGE_TAB.SWEEPSTAKES}
              </TabGroup.Tab>
            )}
          </TabGroup.List>
          <TabGroup.Panel value={POLL_PAGE_TAB.RESULTS}>
            <PollResults poll={poll} />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.KEYWORDS}>
            <KeywordsTab poll={poll} refetch={refetch} />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.RESPONSES}>
            <ResponsesTab poll={poll} />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.AUDIENCE}>
            <AudienceTab poll={poll} />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.SETTINGS}>
            <SettingsTab
              poll={poll}
              request={editPollRequest}
              setRequest={setEditPollRequest}
              handleSave={handleSave}
            />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.TRIGGERS}>
            <TriggersTab
              poll={poll}
              request={editPollRequest}
              setRequest={setEditPollRequest}
              handleSave={handleSave}
            />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.MESSAGING}>
            <MessagingTab
              poll={poll}
              request={editPollRequest}
              setRequest={setEditPollRequest}
              handleSave={handleSave}
            />
          </TabGroup.Panel>
          <TabGroup.Panel value={POLL_PAGE_TAB.ADVANCED}>
            <AdvancedTab poll={poll} />
          </TabGroup.Panel>
          {isEng && (
            <TabGroup.Panel value={POLL_PAGE_TAB.SWEEPSTAKES}>
              {poll.hasWinnerFilter && poll.sweepstakesFilter ? (
                <WinnerFilterDisplay poll={poll} />
              ) : (
                <SweepstakesBuilderForm poll={poll} />
              )}
            </TabGroup.Panel>
          )}
        </TabGroup>
      </Box>
      <EditPollDialog
        poll={poll}
        isOpen={isEditOpen}
        onOpenChange={() => setIsEditOpen(!isEditOpen)}
      />
    </Box>
  );
};

export default PollDetailsPageDesktop;
