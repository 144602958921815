import { useBreakpoints } from '@attentive/picnic';
import { Author } from '../constants/EventDestinations/sharedTypes';

export const useIsMobile = (): boolean => {
  const { atBp3 } = useBreakpoints();
  const isMobile = !atBp3;
  return isMobile;
};

export const shouldMakeRequest = (jobType?: string) => {
  return jobType === 'recurring';
};

export const parseAuthor = (
  author: { readonly firstName: string; readonly lastName: string; readonly email: string } | null
): Author => {
  if (author) {
    return {
      firstName: author.firstName,
      lastName: author.lastName,
      email: author.email,
    };
  }
  return {
    firstName: 'Rosey',
    lastName: 'Posey',
    email: 'rposey@fake.com',
  };
};
