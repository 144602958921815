export interface ToggleArchivePollType {
  header: string;
  description: string;
  buttonText: string;
  dialogText: string;
  successMessage: string;
  errorMessage: string;
}

export const ARCHIVE_POLL_OPTION: ToggleArchivePollType = {
  header: 'Archive Poll',
  description:
    'Archiving the poll will stop it from showing up in your polls list. You can restore this poll later.',
  buttonText: 'Archive',
  dialogText: 'Are you sure you want to archive this poll?',
  successMessage: 'Poll successfully archived',
  errorMessage: 'Something went wrong archiving this poll',
};

export const RESTORE_POLL_OPTION: ToggleArchivePollType = {
  header: 'Restore Poll',
  description:
    'Restoring the poll will allow it to start appearing in your polls list again. You can archive this poll later.',
  buttonText: 'Restore',
  dialogText: 'Are you sure you want to restore this poll?',
  successMessage: 'Poll successfully restored',
  errorMessage: 'Something went wrong restoring this poll',
};
