import { format, formatDistance } from 'date-fns';
import { JobStatus } from '../../../../constants/EventDestinations/jobTypes';

export enum DATE_FORMAT_TYPE {
  GRAPHQL,
}

const graphqlFormatted = (date: Date): string => {
  return `${format(date, 'yyyy-MM-dd')}T00:00:00.000Z`;
};

export const getTomorrowFormatted = (formatType: DATE_FORMAT_TYPE): string => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  switch (formatType) {
    case DATE_FORMAT_TYPE.GRAPHQL:
      return graphqlFormatted(tomorrow);
  }
};

// For jobs table

const formatDateToString = (date: SerializedDateTime, addSuffix: boolean) => {
  if (date) {
    return formatDistance(new Date(date.toString()), new Date(), { addSuffix });
  }
};

export const formatLastRun = (
  lastHistory: SerializedDateTime | null | undefined,
  lastRun: SerializedDateTime | null | undefined
) => {
  if (lastHistory) {
    return formatDateToString(lastHistory, true);
  }
  if (lastRun) {
    return formatDateToString(lastRun, true);
  }
};

export const formatNextRun = (status: JobStatus, nextRun: SerializedDateTime | null): string => {
  if (status === JobStatus.COMPLETED && !nextRun) {
    return 'N/A';
  } else if (!nextRun) {
    return 'N/A';
  }
  const formattedDate = formatDateToString(nextRun, false);
  return formattedDate ? `in ${formattedDate}` : '';
};
