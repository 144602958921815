/**
 * @generated SignedSource<<90253a0a026b52e15c1e03a5729d6fab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScheduleEdsCronJobInput = {
  cron: string;
  description?: string | null;
  taskId: string;
  zoneId: string;
};
export type SetupScheduleTacticalMfeMutation$variables = {
  input: ScheduleEdsCronJobInput;
};
export type SetupScheduleTacticalMfeMutation$data = {
  readonly scheduleEdsCronJob: {
    readonly msg: string;
  } | null;
};
export type SetupScheduleTacticalMfeMutation$rawResponse = {
  readonly scheduleEdsCronJob: {
    readonly msg: string;
  } | null;
};
export type SetupScheduleTacticalMfeMutation = {
  rawResponse: SetupScheduleTacticalMfeMutation$rawResponse;
  response: SetupScheduleTacticalMfeMutation$data;
  variables: SetupScheduleTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScheduleEdsCronJobPayload",
    "kind": "LinkedField",
    "name": "scheduleEdsCronJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "msg",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetupScheduleTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetupScheduleTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2f524b443b7b11cf42e45471c07a616d",
    "id": null,
    "metadata": {},
    "name": "SetupScheduleTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation SetupScheduleTacticalMfeMutation(\n  $input: ScheduleEdsCronJobInput!\n) {\n  scheduleEdsCronJob(input: $input) {\n    msg\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c18793056397c636fcd1b85274f575f";

export default node;
