import React from 'react';
import { LoadingPlaceholder, Box, PicnicCss, Heading, Text } from '@attentive/picnic';

const keywordOverlayStyle: PicnicCss = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const keywordOverlayTextStyle: PicnicCss = {
  backgroundColor: '$bgDefault',
  textAlign: 'center',
  padding: '$space2',
};

const keywordRowStyle: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '$space4 0',
  borderTop: '1px solid $borderDefault',
  '&:last-of-type': {
    borderBottom: '1px solid $borderDefault',
  },
};

export interface KeywordsEmptyListProps {
  loading: boolean;
}

const KeywordsEmptyOverlay = () => (
  <Box css={keywordOverlayStyle}>
    <Box css={keywordOverlayTextStyle}>
      <Heading variant="sm">No Keywords</Heading>
      <Text>
        You haven’t added any keyword listeners — <br />
        why not create one now?
      </Text>
    </Box>
  </Box>
);

const KeywordsEmptyListComponent: React.FunctionComponent<KeywordsEmptyListProps> = ({
  loading,
}) => {
  const rows = Array.from({ length: loading ? 5 : 3 }, (_, k) => k + 1);
  return (
    <Box css={{ position: 'relative' }}>
      {!loading && <KeywordsEmptyOverlay />}
      {rows.map((n) => (
        <Box key={`row-${n}`} css={keywordRowStyle}>
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LoadingPlaceholder
              css={{
                height: '$size4',
                width: '120px',
                mb: '$space2',
              }}
            />
            <LoadingPlaceholder
              css={{
                height: '$size3',
                width: '160px',
              }}
            />
          </Box>
          <LoadingPlaceholder
            css={{
              height: '$size5',
              width: '80px',
              borderRadius: '$radiusMax',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default KeywordsEmptyListComponent;
