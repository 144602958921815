/**
 * @generated SignedSource<<85119af8ab16d6eb0c00d49b632dd987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EndpointsPageQuery$variables = {};
export type EndpointsPageQuery$data = {
  readonly eventDestinations: {
    readonly nodes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly authorId: number;
          readonly collectionId: number;
          readonly created: SerializedDateTime;
          readonly description: string;
          readonly enabled: boolean;
          readonly jobCount: number | null;
          readonly metadata: any;
          readonly name: string;
          readonly nodeType: string | null;
          readonly nodeTypeId: number;
          readonly tags: ReadonlyArray<string>;
          readonly updated: SerializedDateTime;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | null;
};
export type EndpointsPageQuery = {
  response: EndpointsPageQuery$data;
  variables: EndpointsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventDestinations",
    "kind": "LinkedField",
    "name": "eventDestinations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinationNodeConnection",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventDestinationNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventDestinationNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeTypeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadata",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tags",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authorId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collectionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jobCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointsPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EndpointsPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "562e4f39775eeb503ea7e0eb7e0a2f68",
    "id": null,
    "metadata": {},
    "name": "EndpointsPageQuery",
    "operationKind": "query",
    "text": "query EndpointsPageQuery {\n  eventDestinations {\n    nodes {\n      edges {\n        node {\n          uuid\n          name\n          description\n          nodeTypeId\n          metadata\n          enabled\n          tags\n          authorId\n          collectionId\n          updated\n          created\n          jobCount\n          nodeType\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d43a1b1a7900202f3c2c6a549542957";

export default node;
