/**
 * @generated SignedSource<<439ffe73d395b197eef5882c89e44919>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventReplayCompanyBodyTacticalMfeQuery$variables = {};
export type EventReplayCompanyBodyTacticalMfeQuery$data = {
  readonly viewer: {
    readonly account: {
      readonly recentCompanies: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          };
        }>;
      } | null;
    } | null;
  } | null;
};
export type EventReplayCompanyBodyTacticalMfeQuery = {
  response: EventReplayCompanyBodyTacticalMfeQuery$data;
  variables: EventReplayCompanyBodyTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "CompanyConnection",
  "kind": "LinkedField",
  "name": "recentCompanies",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "recentCompanies(first:1)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventReplayCompanyBodyTacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventReplayCompanyBodyTacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5757ae924bdfaef806e465eecbe37d1",
    "id": null,
    "metadata": {},
    "name": "EventReplayCompanyBodyTacticalMfeQuery",
    "operationKind": "query",
    "text": "query EventReplayCompanyBodyTacticalMfeQuery {\n  viewer {\n    account {\n      recentCompanies(first: 1) {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c115e3296bd626fc942547eea789cbda";

export default node;
