import React from 'react';
import { ContainedLabel } from '@attentive/picnic';

interface OwnProps {
  state: string | undefined;
}

const getDisplayName = (value: string | undefined): string => {
  switch (value) {
    case 'ACTIVE':
      return 'Active';
    case 'INACTIVE':
      return 'Inactive';
    default:
      return value || 'UNKNOWN';
  }
};

const KeywordPillLabel: React.FunctionComponent<OwnProps> = ({ state }) => (
  <ContainedLabel variant={state === 'ACTIVE' ? 'decorative1' : 'neutral'} css={{ mr: '$space4' }}>
    {getDisplayName(state)}
  </ContainedLabel>
);

export default KeywordPillLabel;
