import React from 'react';
import { Box, Grid, Text } from '@attentive/picnic';
import { infoCellContainerStyling } from './styles';

const InfoCell: React.FunctionComponent<{ label: string; value?: string }> = ({ label, value }) => {
  return (
    <Grid.Cell>
      <Box css={infoCellContainerStyling}>
        <Text variant="lede">{value}</Text>
        <Text variant="micro">{label}</Text>
      </Box>
    </Grid.Cell>
  );
};

export default InfoCell;
