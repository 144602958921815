/**
 * @generated SignedSource<<93f8fc5cf8a42bd57a84bd549ba0573c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollSortingField = "POLL_SORTING_FIELD_NAME" | "POLL_SORTING_FIELD_UPDATED" | "%future added value";
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type PollsFilterV2 = {
  includedStates?: ReadonlyArray<PollState> | null;
  searchTerm?: string | null;
  showDeleted?: boolean | null;
};
export type PollSorting = {
  sortBy: PollSortingField;
  sortDirection: SortDirection;
};
export type PollsConnectedTableQuery$variables = {
  after?: string | null;
  before?: string | null;
  companyId: string;
  filter?: PollsFilterV2 | null;
  first?: number | null;
  last?: number | null;
  sorting?: PollSorting | null;
};
export type PollsConnectedTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PollsConnectedTableQuery_query">;
};
export type PollsConnectedTableQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly internalId: number;
    readonly name: string;
    readonly pollsV2: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: "Poll";
          readonly closedMessage: string | null;
          readonly description: string | null;
          readonly duplicateVoteMessage: string | null;
          readonly id: string;
          readonly isArchived: boolean | null;
          readonly name: string;
          readonly poll_keywords: {
            readonly pollKeywords: ReadonlyArray<{
              readonly altMatches: ReadonlyArray<string>;
              readonly deleted: boolean;
              readonly description: string;
              readonly id: string;
              readonly keyword: string;
            }>;
          } | null;
          readonly state: PollState;
          readonly submitMessage: string | null;
          readonly totalResponses: number | null;
          readonly updatedAt: SerializedDateTime;
          readonly uuid: string;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string;
      };
      readonly totalCount: number | null;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type PollsConnectedTableQuery = {
  rawResponse: PollsConnectedTableQuery$rawResponse;
  response: PollsConnectedTableQuery$data;
  variables: PollsConnectedTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sorting"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "sorting",
    "variableName": "sorting"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PollsConnectedTableQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PollsConnectedTableQuery_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "PollsConnectedTableQuery",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "PollsConnection",
                "kind": "LinkedField",
                "name": "pollsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Poll",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uuid",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GetPollKeywordsResponse",
                            "kind": "LinkedField",
                            "name": "poll_keywords",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PollKeyword",
                                "kind": "LinkedField",
                                "name": "pollKeywords",
                                "plural": true,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "keyword",
                                    "storageKey": null
                                  },
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "altMatches",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "deleted",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalResponses",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "closedMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duplicateVoteMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submitMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "filter",
                  "sorting"
                ],
                "handle": "connection",
                "key": "PollsConnectedTableQuery_query_pollsV2",
                "kind": "LinkedHandle",
                "name": "pollsV2"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "360ca463d08fc6c3708efc4a02cb7569",
    "id": null,
    "metadata": {},
    "name": "PollsConnectedTableQuery",
    "operationKind": "query",
    "text": "query PollsConnectedTableQuery(\n  $companyId: ID!\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $filter: PollsFilterV2\n  $sorting: PollSorting\n) {\n  ...PollsConnectedTableQuery_query\n}\n\nfragment PollsConnectedTableQuery_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      name\n      internalId\n      id\n      pollsV2(first: $first, after: $after, last: $last, before: $before, filter: $filter, sorting: $sorting) {\n        edges {\n          node {\n            name\n            uuid\n            id\n            poll_keywords {\n              pollKeywords {\n                id\n                keyword\n                description\n                altMatches\n                deleted\n              }\n            }\n            state\n            description\n            totalResponses\n            closedMessage\n            duplicateVoteMessage\n            submitMessage\n            isArchived\n            updatedAt\n            __typename\n          }\n          cursor\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cddab95d334c9382cf994b54228cb6e";

export default node;
