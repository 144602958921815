export enum KeywordActions {
  FETCH_ALL_KEYWORDS_REQUEST = 'FETCH_ALL_KEYWORDS_REQUEST',
  FETCH_ALL_KEYWORDS_SUCCESS = 'FETCH_ALL_KEYWORDS_SUCCESS',
  FETCH_ALL_KEYWORDS_FAILURE = 'FETCH_ALL_KEYWORDS_FAILURE',
  CREATE_KEYWORD_REQUEST = 'CREATE_KEYWORD_REQUEST',
  CREATE_KEYWORD_SUCCESS = 'CREATE_KEYWORD_SUCCESS',
  CREATE_KEYWORD_FAILURE = 'CREATE_KEYWORD_FAILURE',
  UPDATE_KEYWORD_REQUEST = 'UPDATE_KEYWORD_REQUEST',
  UPDATE_KEYWORD_SUCCESS = 'UPDATE_KEYWORD_SUCCESS',
  UPDATE_KEYWORD_FAILURE = 'UPDATE_KEYWORD_FAILURE',
  ENABLE_KEYWORD_REQUEST = 'ENABLE_KEYWORD_REQUEST',
  ENABLE_KEYWORD_SUCCESS = 'ENABLE_KEYWORD_SUCCESS',
  ENABLE_KEYWORD_FAILURE = 'ENABLE_KEYWORD_FAILURE',
  DISABLE_KEYWORD_REQUEST = 'DISABLE_KEYWORD_REQUEST',
  DISABLE_KEYWORD_SUCCESS = 'DISABLE_KEYWORD_SUCCESS',
  DISABLE_KEYWORD_FAILURE = 'DISABLE_KEYWORD_FAILURE',
  FILTER_KEYWORDS = 'FILTER_KEYWORDS',
  UPDATE_KEYWORD = 'UPDATE_KEYWORD',
  CREATE_KEYWORD = 'CREATE_KEYWORD',
  VIEW_KEYWORDS = 'VIEW_KEYWORDS',
}

export enum KeywordMenuActions {
  EDIT = 'Edit',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  DELETE = 'Delete',
}
