import React from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Box, Text, IconButton, DropdownMenu, PicnicCss } from '@attentive/picnic';

import { KeywordMenuActions } from '../../constants/Keywords/keywordActions';
import { Keyword, KeywordStatus } from '../../constants/Keywords/keywordTypes';
import KeywordPillLabel from './KeywordPillLabel';

const keywordRowStyle: PicnicCss = {
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: '1px solid $borderDefault',
  padding: '$space4 0',
  '&:last-of-type': {
    borderBottom: `1px solid $borderDefault`,
  },
};

const keywordRowDetailsStyle: PicnicCss = {
  display: 'flex',
  flexDirection: 'row',
  flex: '1 1 auto',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '75%',
};

const keywordColumnStyle: PicnicCss = {
  mx: '$space4',
  minWidth: ' 150px',
};

const keywordValueStyle: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '$space1',
};

interface OwnProps {
  keyword: Keyword;
  activate: () => void;
  deactivate: () => void;
}

export const KeywordItem: React.FunctionComponent<OwnProps> = ({
  keyword,
  activate,
  deactivate,
}) => {
  const navigate = useNavigate();

  const editKeyword = (editWord: Keyword) => {
    navigate(`/tactical/keywords/${editWord.id}`);
  };

  function formatDate(date: string | undefined) {
    if (!date) return 'N/A';
    const dateObj = new Date(date);
    return format(dateObj, 'MMM dd, yyyy');
  }

  return (
    <Box css={keywordRowStyle}>
      <div>
        <Text>{keyword.keyword}</Text>
        <Text color="subdued" variant="caption" css={keywordValueStyle}>
          Last edited {formatDate(keyword.lastUpdated)}
        </Text>
      </div>
      <Box css={keywordRowDetailsStyle}>
        <KeywordPillLabel state={keyword.state} />
        <Box css={keywordColumnStyle}>
          <Text>Product Id</Text>
          <Text color="subdued" variant="caption" css={keywordValueStyle}>
            {keyword.productId}
          </Text>
        </Box>
        <Box css={keywordColumnStyle}>
          <Text>Offer</Text>
          <Text color="subdued" variant="caption" css={keywordValueStyle}>
            {keyword.coupon || 'None'}
          </Text>
        </Box>
        <Box css={keywordColumnStyle}>
          <Text>Quantity</Text>
          <Text color="subdued" variant="caption" css={keywordValueStyle}>
            {keyword.quantity}
          </Text>
        </Box>
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <IconButton
              iconName="DotsVertical"
              size="small"
              variant="subdued"
              description="Open Menu"
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            <DropdownMenu.TextItem onSelect={() => editKeyword(keyword)}>
              {KeywordMenuActions.EDIT}
            </DropdownMenu.TextItem>
            {keyword.state === KeywordStatus.INACTIVE && (
              <DropdownMenu.TextItem onSelect={activate}>
                {KeywordMenuActions.ACTIVATE}
              </DropdownMenu.TextItem>
            )}
            {keyword.state === KeywordStatus.ACTIVE && (
              <DropdownMenu.TextItem onSelect={deactivate}>
                {KeywordMenuActions.DEACTIVATE}
              </DropdownMenu.TextItem>
            )}
          </DropdownMenu.Content>
        </DropdownMenu>
      </Box>
    </Box>
  );
};

export default KeywordItem;
