/**
 * @generated SignedSource<<3bef76436d7320b33cc335b4665f7e79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeEventReplayInput = {
  eventReplayId: string;
  ignoreGracePeriod?: boolean | null;
};
export type EventReplayRunDialogTacticalMfeMutation$variables = {
  input: ChangeEventReplayInput;
};
export type EventReplayRunDialogTacticalMfeMutation$data = {
  readonly updateEventReplayToQueued: {
    readonly eventReplay: {
      readonly id: string;
      readonly title: string | null;
    };
  } | null;
};
export type EventReplayRunDialogTacticalMfeMutation = {
  response: EventReplayRunDialogTacticalMfeMutation$data;
  variables: EventReplayRunDialogTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeEventReplayPayload",
    "kind": "LinkedField",
    "name": "updateEventReplayToQueued",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventReplay",
        "kind": "LinkedField",
        "name": "eventReplay",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventReplayRunDialogTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventReplayRunDialogTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c7d52dea9ec110f2119e09dfac546385",
    "id": null,
    "metadata": {},
    "name": "EventReplayRunDialogTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation EventReplayRunDialogTacticalMfeMutation(\n  $input: ChangeEventReplayInput!\n) {\n  updateEventReplayToQueued(input: $input) {\n    eventReplay {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55ade590e112127ba362ce4a21900ada";

export default node;
