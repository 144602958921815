/**
 * @generated SignedSource<<bbea8831f2e11baa15d12049b5267fa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PerformanceConfigQuery$variables = {
  endpoint: string;
  taskId?: string | null;
};
export type PerformanceConfigQuery$data = {
  readonly edsTaskProcessingConfig: {
    readonly endpoint: string;
    readonly id: string | null;
    readonly limitPerSec: number;
    readonly metadata: string;
    readonly retryTimes: number;
    readonly taskId: string | null;
  } | null;
};
export type PerformanceConfigQuery$rawResponse = {
  readonly edsTaskProcessingConfig: {
    readonly endpoint: string;
    readonly id: string | null;
    readonly limitPerSec: number;
    readonly metadata: string;
    readonly retryTimes: number;
    readonly taskId: string | null;
  } | null;
};
export type PerformanceConfigQuery = {
  rawResponse: PerformanceConfigQuery$rawResponse;
  response: PerformanceConfigQuery$data;
  variables: PerformanceConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endpoint"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endpoint",
        "variableName": "endpoint"
      },
      {
        "kind": "Variable",
        "name": "taskId",
        "variableName": "taskId"
      }
    ],
    "concreteType": "EdsTaskProcessingConfig",
    "kind": "LinkedField",
    "name": "edsTaskProcessingConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endpoint",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "limitPerSec",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "retryTimes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceConfigQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformanceConfigQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "143f5d7d0aecf444623894ad8e205aef",
    "id": null,
    "metadata": {},
    "name": "PerformanceConfigQuery",
    "operationKind": "query",
    "text": "query PerformanceConfigQuery(\n  $endpoint: String!\n  $taskId: String\n) {\n  edsTaskProcessingConfig(endpoint: $endpoint, taskId: $taskId) {\n    id\n    taskId\n    endpoint\n    limitPerSec\n    metadata\n    retryTimes\n  }\n}\n"
  }
};
})();

(node as any).hash = "d63889f1659323463fefbe6af93d6f44";

export default node;
