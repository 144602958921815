import _usePaginatedPrivacyRequests_CompanyFragment from "./__generated__/usePaginatedPrivacyRequests_CompanyFragment.graphql";
import { createPaginationContext, graphql } from '@attentive/data';
import usePaginatedPrivacyRequestsQuery from './__generated__/usePaginatedPrivacyRequests_CompanyFragment.graphql';
_usePaginatedPrivacyRequests_CompanyFragment;

const getPageInfoFragment = data => {
  var _data$privacyRequests;

  return (data === null || data === void 0 ? void 0 : (_data$privacyRequests = data.privacyRequests) === null || _data$privacyRequests === void 0 ? void 0 : _data$privacyRequests.pageInfo) || null;
};

export const [usePrivacyRequestPaginationContext, PrivacyRequestsPaginationContext] = createPaginationContext({
  query: usePaginatedPrivacyRequestsQuery,
  getPageInfoFragment
});