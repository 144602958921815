import React from 'react';
import { Box, Heading, Text } from '@attentive/picnic';
import PollOptionSwitch from './PollOptions/PollOptionSwitch';
import DecimalPrecision from './PollOptions/DecimalPrecision';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import {
  MULTI_VOTE_OPTION,
  BREAK_TIES_OPTION,
} from '../../../../constants/PollSurveys/settingsConstants';

const PollOptions: React.FunctionComponent<{
  poll: PollType;
  updateSwitchRequestField: (field: string, value: boolean) => void;
  updateDecimalRequestField: (field: string, value: number) => void;
}> = ({ poll, updateSwitchRequestField, updateDecimalRequestField }) => {
  console.log('in poll option ', poll);
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$space4',
        mt: '$space2',
        mb: '$space14',
      }}
    >
      <Heading variant="lg">Options</Heading>
      <Text>You can further customize the behavior of your poll with the following options.</Text>
      <PollOptionSwitch
        isChecked={poll.allowMultiVote ? poll.allowMultiVote : false}
        pollOptionSwitchType={MULTI_VOTE_OPTION}
        onChange={updateSwitchRequestField}
      />
      <PollOptionSwitch
        isChecked={poll.allowBreakTies ? poll.allowBreakTies : false}
        pollOptionSwitchType={BREAK_TIES_OPTION}
        onChange={updateSwitchRequestField}
      />
      <DecimalPrecision
        initialDecimalPrecision={poll.decimalPrecision ? poll.decimalPrecision : 0}
        updateRequestField={updateDecimalRequestField}
      />
    </Box>
  );
};

export default PollOptions;
