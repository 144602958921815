/**
 * @generated SignedSource<<67c87a104eecb7faa056e65236486559>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollResultsFragment_query$data = {
  readonly poll: {
    readonly name?: string;
    readonly poll_keywords?: {
      readonly pollKeywords: ReadonlyArray<{
        readonly altMatches: ReadonlyArray<string>;
        readonly deleted: boolean;
        readonly description: string;
        readonly id: string;
        readonly keyword: string;
      }>;
    } | null;
    readonly poll_stats?: {
      readonly stats: {
        readonly keywordStats: ReadonlyArray<{
          readonly count: number;
          readonly keyword: string;
          readonly percentage: number;
        }>;
      };
    } | null;
    readonly uuid?: string;
  } | null;
  readonly " $fragmentType": "PollResultsFragment_query";
};
export type PollResultsFragment_query$key = {
  readonly " $data"?: PollResultsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"PollResultsFragment_query">;
};

import PollResultsFragment_query_refetchable_graphql from './PollResultsFragment_query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyword",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "pollId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": PollResultsFragment_query_refetchable_graphql
    }
  },
  "name": "PollResultsFragment_query",
  "selections": [
    {
      "alias": "poll",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "pollId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GetPollKeywordsResponse",
              "kind": "LinkedField",
              "name": "poll_keywords",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PollKeyword",
                  "kind": "LinkedField",
                  "name": "pollKeywords",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "altMatches",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deleted",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GetPollStatsResponse",
              "kind": "LinkedField",
              "name": "poll_stats",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PollStats",
                  "kind": "LinkedField",
                  "name": "stats",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "KeywordStats",
                      "kind": "LinkedField",
                      "name": "keywordStats",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "percentage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Poll",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "052995f22a5fc27fcb51fc8330fc7a7c";

export default node;
