/**
 * @generated SignedSource<<ee4b48d72dcf4e73c5848702afc941a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadV1CustomKeywordsTacticalMfeQuery$variables = {
  companyId: string;
};
export type LoadV1CustomKeywordsTacticalMfeQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"LoadV1CustomKeywordsFragment">;
  } | null;
};
export type LoadV1CustomKeywordsTacticalMfeQuery = {
  response: LoadV1CustomKeywordsTacticalMfeQuery$data;
  variables: LoadV1CustomKeywordsTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadV1CustomKeywordsTacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LoadV1CustomKeywordsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadV1CustomKeywordsTacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomKeyword",
                "kind": "LinkedField",
                "name": "customKeywords",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "keyword",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "response",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriberTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTransactional",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b14cc9f13080c22fbc83ce66b6d96405",
    "id": null,
    "metadata": {},
    "name": "LoadV1CustomKeywordsTacticalMfeQuery",
    "operationKind": "query",
    "text": "query LoadV1CustomKeywordsTacticalMfeQuery(\n  $companyId: ID!\n) {\n  node(id: $companyId) {\n    __typename\n    ...LoadV1CustomKeywordsFragment\n    id\n  }\n}\n\nfragment LoadV1CustomKeywordsFragment on Company {\n  internalId\n  name\n  customKeywords {\n    id\n    keyword\n    response\n    subscriberTag\n    isTransactional\n    isActive\n  }\n}\n"
  }
};
})();

(node as any).hash = "41bb1d87cd8cf6bc0fa472cee9557139";

export default node;
