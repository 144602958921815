import React from 'react';
import { Grid } from '@attentive/picnic';
import { EdsNode } from '../../../../constants/EventDestinations/endpointTypes';
import GridKeyValue from '../../../common/GridKeyValue';

const parseNodeType = (node: EdsNode): string => {
  return node.nodeType ? node.nodeType.toUpperCase() : 'n/a';
};

const parseJobCount = (node: EdsNode): string => {
  return node.jobCount ? `${node.jobCount}` : '0';
};

const EndpointCardContent: React.FunctionComponent<{ node: EdsNode }> = ({ node }) => {
  return (
    <Grid columns={3}>
      <GridKeyValue label="JOBS" value={parseJobCount(node)} />
      <GridKeyValue label="COMPANIES" value={parseJobCount(node)} />
      <GridKeyValue label="TYPE" value={parseNodeType(node)} />
    </Grid>
  );
};

export default EndpointCardContent;
