import { Box, Heading, Table } from '@attentive/picnic';
import React from 'react';
import { useFragment } from 'react-relay';
import PrivacyRequestRow from './PrivacyRequestRow';
import {
  PrivacyRequestsPaginationContext,
  usePrivacyRequestPaginationContext,
} from './usePaginatedPrivacyRequests';
import PaginatedCompanyRequests, {
  usePaginatedPrivacyRequests_CompanyFragment$key as usePaginatedPrivacyRequestsFragKey,
} from './__generated__/usePaginatedPrivacyRequests_CompanyFragment.graphql';

export const PAGE_LENGTH = 5;

const TableDataEmptyState: React.FC = ({ children }) => {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '500px',
        gridColumn: 'span 2',
      }}
    >
      <Heading variant="lg">{children}</Heading>
    </Box>
  );
};

const TableData: React.VFC<{ fragmentRef: usePaginatedPrivacyRequestsFragKey }> = ({
  fragmentRef,
}) => {
  const { usePaginatedData } = usePrivacyRequestPaginationContext();
  const { data } = usePaginatedData(fragmentRef);

  if (!data?.privacyRequests) {
    return <TableDataEmptyState>Unable to load privacy requests</TableDataEmptyState>;
  }

  if (data.privacyRequests.edges.length === 0) {
    return <TableDataEmptyState>No privacy requests for company</TableDataEmptyState>;
  }

  return (
    <Table.Body>
      {data.privacyRequests.edges.map(({ node }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <PrivacyRequestRow key={idx} privacyRequestRef={node} />
      ))}
    </Table.Body>
  );
};

export const PrivacyRequestTable: React.VFC<{
  fragmentRef: usePaginatedPrivacyRequestsFragKey;
}> = ({ fragmentRef }) => {
  const fragData = useFragment<usePaginatedPrivacyRequestsFragKey>(
    PaginatedCompanyRequests,
    fragmentRef
  );

  return (
    <Box>
      <Heading color="subdued" variant="page">
        {fragData.displayName}
      </Heading>
      <Box css={{ display: 'grid', gridGap: '$space4' }}>
        <article>
          <Table columnSizes={['auto', 'auto', 'auto', 'auto']} css={{ fontSize: '$fontSize3' }}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.HeaderCell>ID (GQL Node ID)</Table.HeaderCell>
                <Table.HeaderCell>Processed (y/n)</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
              </Table.HeaderRow>
            </Table.Header>
            <TableData fragmentRef={fragmentRef}></TableData>
          </Table>
        </article>
      </Box>
      <Box css={{ display: 'flex', justifyContent: 'flex-end', mt: '$space6' }}>
        <PrivacyRequestsPaginationContext.PageSizeSelect options={[5, 10, 20]} />
        <PrivacyRequestsPaginationContext.Paginator fragmentRef={fragmentRef} />
      </Box>
    </Box>
  );
};
