import { BarDatum } from '@attentive/charts';

export interface EdsPerformanceStatusCodeReport {
  taskId: string;
  runUuid: string;
  start: string;
  end: string;
  statusCodeCounts: KeyValPair[];
}

export interface KeyValPair {
  key: string;
  value: number;
}

export interface PerformanceBarDatum extends BarDatum {
  date: string;
  success: number;
  subscriber_not_found: number;
  rate_limited: number;
  invalid_request: number;
  subscriber_inactive: number;
  subscriber_optedout: number;
}

export const INITIAL_ROLLUP_COUNTS: RollupCounts = {
  topic_status_code_200: 0,
  topic_status_code_400: 0,
  topic_status_code_403: 0,
  topic_status_code_404: 0,
  topic_status_code_405: 0,
  topic_status_code_429: 0,
};

export interface RollupCounts {
  topic_status_code_200: number;
  topic_status_code_400: number;
  topic_status_code_403: number;
  topic_status_code_404: number;
  topic_status_code_405: number;
  topic_status_code_429: number;
}

export interface CodeCountKeyVal {
  readonly key: string;
  readonly value: number;
}
