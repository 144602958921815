export type SetNullableFn = (v: null) => void;
export type SetStringFn = (v: string) => void;
export type SetBoolFn = (v: boolean) => void;

export const clearErrors = (setErrorFns: SetNullableFn[]) => {
  setErrorFns.forEach((setErrorFn) => setErrorFn(null));
};
export const clearValues = (setStringFns: SetStringFn[], value: string) => {
  setStringFns.forEach((setStrFn) => setStrFn(value));
};
export const clearBools = (setBoolFns: SetBoolFn[], value: boolean) => {
  setBoolFns.forEach((setBoolFn) => setBoolFn(value));
};

export const validateKeywordForm = (
  keyword: string,
  setKeywordError: (v: string | null) => void,
  mediaUrl: string | null,
  setMediaUrlError: (v: string | null) => void,
  attributeName: string | null,
  setAttributeNameError: (v: string | null) => void
) => {
  let isValid = true;

  if (keyword.length < 2) {
    setKeywordError('Keyword must be at least 2 characters');
    isValid = false;
  } else {
    setKeywordError(null);
  }

  if (
    mediaUrl != null &&
    mediaUrl.length > 0 &&
    (!mediaUrl.startsWith('http:\\') || !mediaUrl.startsWith('https:\\'))
  ) {
    setMediaUrlError('Media url must be a valid url');
    isValid = false;
  } else {
    setMediaUrlError(null);
  }

  if (attributeName != null && attributeName.length > 0 && attributeName.length < 2) {
    setAttributeNameError('Attribute name must be at least 2 characters');
    isValid = false;
  } else {
    setAttributeNameError(null);
  }

  return isValid;
};
