import { PicnicCss } from '@attentive/picnic';

export const customActionRowStyle: PicnicCss = {
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: '1px solid $borderDefault',
  padding: '$space4 0',
  '&:last-of-type': {
    borderBottom: `1px solid $borderDefault`,
  },
};

export const customActionRowDetailsStyle: PicnicCss = {
  display: 'flex',
  flexDirection: 'row',
  flex: '1 1 auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '75%',
};

export const customActionRowDetailsInfoStyle: PicnicCss = {
  display: 'flex',
  flexDirection: 'row',
  flex: '1 1 auto',
  justifyContent: 'flex-start',
  marginLeft: '20px',
  alignItems: 'center',
  // width: '75%',
};

export const customActionColumnStyle: PicnicCss = {
  mx: '$space4',
};

export const customActionMaxColumnStyle: PicnicCss = {
  mx: '$space4',
  minWidth: '200px',
};

export const customActionValueStyle: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '$space1',
};

export const customActionsOverlayStyle: PicnicCss = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const customActionsOverlayTextStyle: PicnicCss = {
  backgroundColor: '$bgDefault',
  textAlign: 'center',
  padding: '$space2',
};

export const customActionsRowStyle: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '$space4 0',
  borderTop: '1px solid $borderDefault',
  '&:last-of-type': {
    borderBottom: '1px solid $borderDefault',
  },
};
