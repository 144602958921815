/**
 * @generated SignedSource<<783ebdd6b5d58fafdbc76a403ede1649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventReplayEventType = "EMAIL_SAVE" | "JOIN" | "JOURNEY_POSTBACK" | "MESSAGE_LINK_CLICK" | "MESSAGE_RECEIPT" | "SUBSCRIPTION_ALREADY_SUBSCRIBED" | "SUBSCRIPTION_OPTED_OUT" | "SUBSCRIPTION_OPT_IN_COMPLETED" | "SUBSCRIPTION_OPT_IN_STARTED" | "USER_PROPERTY_SET" | "%future added value";
export type EventReplayStatus = "ANALYZING" | "COMPLETED" | "FAILED" | "IN_PROGRESS" | "PENDING" | "QUEUED" | "READY" | "%future added value";
export type EventReplayByCompanyIdsTacticalMfeQuery$variables = {
  companyIds: ReadonlyArray<number>;
};
export type EventReplayByCompanyIdsTacticalMfeQuery$data = {
  readonly companiesByInternalId: {
    readonly companies: ReadonlyArray<{
      readonly displayName: string;
      readonly eventReplays: {
        readonly eventReplay: ReadonlyArray<{
          readonly created: SerializedDateTime;
          readonly description: string | null;
          readonly estimatedEventCounts: string;
          readonly eventRangeEnd: SerializedDateTime;
          readonly eventRangeStart: SerializedDateTime;
          readonly eventTypes: ReadonlyArray<EventReplayEventType>;
          readonly id: string;
          readonly status: EventReplayStatus;
          readonly title: string | null;
          readonly updated: SerializedDateTime;
        }>;
      } | null;
      readonly id: string;
      readonly internalId: number;
    } | null>;
  } | null;
};
export type EventReplayByCompanyIdsTacticalMfeQuery = {
  response: EventReplayByCompanyIdsTacticalMfeQuery$data;
  variables: EventReplayByCompanyIdsTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "internalIds",
        "variableName": "companyIds"
      }
    ],
    "concreteType": "GetCompaniesByInternalIdResponse",
    "kind": "LinkedField",
    "name": "companiesByInternalId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companies",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventReplaysResponse",
            "kind": "LinkedField",
            "name": "eventReplays",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventReplay",
                "kind": "LinkedField",
                "name": "eventReplay",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "estimatedEventCounts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventRangeStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventRangeEnd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventReplayByCompanyIdsTacticalMfeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventReplayByCompanyIdsTacticalMfeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4f913e7b3c4a9872d99a85c4fddb2767",
    "id": null,
    "metadata": {},
    "name": "EventReplayByCompanyIdsTacticalMfeQuery",
    "operationKind": "query",
    "text": "query EventReplayByCompanyIdsTacticalMfeQuery(\n  $companyIds: [Long!]!\n) {\n  companiesByInternalId(internalIds: $companyIds) {\n    companies {\n      id\n      displayName\n      internalId\n      eventReplays {\n        eventReplay {\n          id\n          title\n          description\n          status\n          estimatedEventCounts\n          eventRangeStart\n          eventRangeEnd\n          eventTypes\n          created\n          updated\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be43a562920d0438ecdd84ccfc5ef9be";

export default node;
