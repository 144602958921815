/**
 * @generated SignedSource<<622fa3ec0045eb43972725a1d81634d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditPollDialogTacticalMfeMutation$variables = {
  companyId: string;
  description?: string | null;
  name?: string | null;
  pollId: string;
};
export type EditPollDialogTacticalMfeMutation$data = {
  readonly updatePollV2: {
    readonly error: string | null;
    readonly poll: {
      readonly description: string | null;
      readonly name: string;
    } | null;
  } | null;
};
export type EditPollDialogTacticalMfeMutation = {
  response: EditPollDialogTacticalMfeMutation$data;
  variables: EditPollDialogTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pollId"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "pollId",
        "variableName": "pollId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPollDialogTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdatePollV2Payload",
        "kind": "LinkedField",
        "name": "updatePollV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPollDialogTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdatePollV2Payload",
        "kind": "LinkedField",
        "name": "updatePollV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd84d0ca4596cb570c04967a55f458b7",
    "id": null,
    "metadata": {},
    "name": "EditPollDialogTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation EditPollDialogTacticalMfeMutation(\n  $companyId: ID!\n  $pollId: ID!\n  $name: String\n  $description: String\n) {\n  updatePollV2(input: {companyId: $companyId, pollId: $pollId, name: $name, description: $description}) {\n    poll {\n      name\n      description\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "43085811ed749488d301be741f591208";

export default node;
