export interface JobRecord {
  id: string;
  internalId: string;
  idx: number;
  name: string;
  description: string;
  status: string;
  company?: string;
  companyId?: number;
  type: string;
  health: string;
  lastEditedBy: string | null;
  lastRunDate: Date | null;
  nextRunDate: Date | null;
  targetInfo?: string;
  sourceInfo?: string;
  sourceNodeUuid?: string;
  targetNodeUuid?: string;
  lastExecutedRun?: JobHistory;
}

export const ALL_JOBS = 'ALL';
export enum JobStatus {
  SCHEDULED = 'Scheduled',
  COMPLETED = 'Completed',
  PAUSED = 'Paused',
  ACTIVE = 'Active',
  DRAFT = 'Draft',
  ERROR = 'Error',
}

type Variant = 'decorative1' | 'success' | 'informational' | 'neutral';
export const JobStatusColorVariantMap: Record<JobStatus, Variant> = {
  [JobStatus.SCHEDULED]: 'decorative1',
  [JobStatus.COMPLETED]: 'success',
  [JobStatus.PAUSED]: 'informational',
  [JobStatus.DRAFT]: 'neutral',
  [JobStatus.ACTIVE]: 'decorative1',
  [JobStatus.ERROR]: 'informational',
};

export enum JobPageTabType {
  CONFIG = 'CONFIG',
  METRICS = 'METRICS',
  HISTORY = 'HISTORY',
  ADVANCED = 'ADVANCED',
}

export interface JobHistory {
  uuid: string;
  taskId: string;
  parentTaskId?: string;
  runDate?: Date;
  scheduledDate: Date;
  context: JobHistoryContext;
  status?: string;
}

export interface JobHistoryContext {
  fileRowIndex?: number;
  fileHashes?: string[];
  error?: string;
}

export interface JobReport {
  taskId: string;
  startTime: string;
  endTime: string;
  errorMsg: string;
  requests: number;
  success: number;
  failure: number;
}

export const EMPTY_REPORT: JobReport = {
  taskId: '',
  startTime: '',
  endTime: '',
  errorMsg: '',
  requests: 0,
  success: 0,
  failure: 0,
};

export enum HistoryFilter {
  INCLUDE_NOOP = 'Include NoOp',
  EXCLUDE_NOOP = 'Exclude NoOp',
}

export const JOB_TABLE_PAGE_LENGTH = 5;

export interface ScheduleJobRequest {
  taskId: string;
  description?: string;
  cron: string;
  zoneId: string;
}
