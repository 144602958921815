import React from 'react';

import logo from './assets/destination.png';
import ProjectCard from './ProjectCard';
import { Paths } from '../../constants/routes';
import {
  TacticalHomeProjectCardProps,
  ProjectCardConfig,
} from '../../constants/Home/tacticalHomeTypes';

const config: ProjectCardConfig = {
  defaultEnabled: false,
  allowedRoles: ['ROLE_ENGINEERING', 'ROLE_CS_TAM'],
  heading: 'Event Destinations',
  image: logo,
  path: Paths.EventDestinations,
};

const EventDestinationsCard: React.FunctionComponent<TacticalHomeProjectCardProps> = ({
  viewerRoles,
}) => {
  return <ProjectCard config={{ ...config, viewerRoles }} />;
};

export default EventDestinationsCard;
