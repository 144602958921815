import React from 'react';
import { Box, Checkbox, Heading, Text, TextInput } from '@attentive/picnic';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

const TriggerJourney: React.FunctionComponent<{
  poll: PollType;
  updateRequestField: (field: string, value: string) => void;
}> = ({ poll, updateRequestField }) => {
  const [customEventType, setCustomEventType] = React.useState<string>(
    poll.subscribedEventType || ''
  );
  const [applicationId, setApplicationId] = React.useState<string>(poll.applicationId || '');
  const [isChecked, setIsChecked] = React.useState<boolean>(poll.subscribedEventType !== '');

  const handleCustomEventUpdate = (val: string) => {
    setCustomEventType(val);
    updateRequestField('subscribedEventType', val);
  };

  const handleApplicationIdUpdate = (val: string) => {
    setApplicationId(val);
    updateRequestField('applicationId', val);
  };

  const handleIsChecked = (checked: boolean | 'indeterminate') => {
    const isCurrentlyChecked: boolean = checked === true;
    setIsChecked(isCurrentlyChecked);

    if (!isCurrentlyChecked) {
      setApplicationId('');
      updateRequestField('applicationId', '');

      setCustomEventType('');
      updateRequestField('subscribedEventType', '');
    }
  };

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$space4', mb: '$space14' }}>
      <Heading variant="lg">Custom Event Journey</Heading>
      <Text>
        Trigger an optional custom event journey for a open poll after setting the event type and
        app ID.
      </Text>
      <Checkbox css={{ justifyContent: 'left' }} onChange={handleIsChecked} checked={isChecked}>
        Trigger a custom event journey
      </Checkbox>
      <Box css={{ display: 'flex', flexDirection: 'row', gap: '$space4' }}>
        <Text css={{ width: '30%' }}>App ID</Text>
        <TextInput
          placeholder="Enter Marketplace App ID (e.g. 1234)"
          disabled={!isChecked}
          value={applicationId}
          onChange={(e) => handleApplicationIdUpdate(e.target.value)}
        />
      </Box>
      <Box css={{ display: 'flex', flexDirection: 'row', gap: '$space4' }}>
        <Text css={{ width: '30%' }}> Custom Event Type</Text>

        <TextInput
          placeholder="Enter Custom Event Type (e.g. Poll)"
          disabled={!isChecked}
          value={customEventType}
          onChange={(e) => handleCustomEventUpdate(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default TriggerJourney;
