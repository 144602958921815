import { Box, Button, FormField, Heading, Text, TextArea } from '@attentive/picnic';
import React from 'react';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import SendMessageDialog from './SendMessageDialog';

interface ManualRespondersProps {
  poll: PollType;
  updateRequestField: (field: string, value: string) => void;
}

const ManualRespondersHeading: React.FunctionComponent = () => {
  return (
    <Box>
      <Heading css={{ mb: '$space4' }} variant="lg">
        Manual Send
      </Heading>
      <Text css={{ mb: '$space8' }}>Manually message poll participants.</Text>
    </Box>
  );
};

interface OnClosedMessageProps {
  poll: PollType;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  onClosedMessage: string;
  handleOnClosedMessage: (v: string) => void;
}
const OnClosedMessage: React.FunctionComponent<OnClosedMessageProps> = ({
  isOpen,
  setIsOpen,
  poll,
  onClosedMessage,
  handleOnClosedMessage,
}) => {
  return (
    <Box>
      <FormField.Label>Message To All</FormField.Label>
      <FormField.HelperText>
        Compose and save a message. When ready, send it to all poll participants. (Optional)
      </FormField.HelperText>
      <TextArea
        css={{ mt: '$space2', mb: '$space2', height: '200px' }}
        value={onClosedMessage}
        onChange={(e) => handleOnClosedMessage(e.target.value)}
      />
      <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => setIsOpen(true)}>Review & Send</Button>
      </Box>
      <SendMessageDialog
        isOpen={isOpen}
        onOpenChange={() => setIsOpen(!isOpen)}
        pollId={poll.id}
        messageText={onClosedMessage}
      />
    </Box>
  );
};

const ManualResponders: React.FunctionComponent<ManualRespondersProps> = ({
  poll,
  updateRequestField,
}) => {
  const [onClosedMessage, setOnClosedMessage] = React.useState<string>(poll.onClosedMessage || '');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOnClosedMessage = (value: string) => {
    setOnClosedMessage(value);
    updateRequestField('onClosedMessage', value);
  };

  return (
    <Box css={{ mt: '$space2', mb: 100 }}>
      <ManualRespondersHeading />
      <OnClosedMessage
        poll={poll}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClosedMessage={onClosedMessage}
        handleOnClosedMessage={handleOnClosedMessage}
      />
    </Box>
  );
};
export default ManualResponders;
