/**
 * @generated SignedSource<<61fc1c76f993782d78119822a9a3dc24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivacyRequestRow_TacticalMfe_CompanyFragment$data = {
  readonly id: string;
  readonly processed: boolean;
  readonly processingEndDateTime: SerializedDateTime | null;
  readonly requestDateTime: SerializedDateTime;
  readonly requestMsg: string | null;
  readonly subjectEmail: string | null;
  readonly subjectPhone: string | null;
  readonly " $fragmentType": "PrivacyRequestRow_TacticalMfe_CompanyFragment";
};
export type PrivacyRequestRow_TacticalMfe_CompanyFragment$key = {
  readonly " $data"?: PrivacyRequestRow_TacticalMfe_CompanyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivacyRequestRow_TacticalMfe_CompanyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivacyRequestRow_TacticalMfe_CompanyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subjectPhone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subjectEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestMsg",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestDateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processingEndDateTime",
      "storageKey": null
    }
  ],
  "type": "PrivacyRequest",
  "abstractKey": null
};

(node as any).hash = "c226c32b415386422a1f2f3065102def";

export default node;
