/**
 * @generated SignedSource<<036faf643154099524549c5f2f4821df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type DeleteJobDialogMutation$variables = {
  taskId: string;
};
export type DeleteJobDialogMutation$data = {
  readonly deleteEdsJob: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type DeleteJobDialogMutation$rawResponse = {
  readonly deleteEdsJob: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: string;
  } | null;
};
export type DeleteJobDialogMutation = {
  rawResponse: DeleteJobDialogMutation$rawResponse;
  response: DeleteJobDialogMutation$data;
  variables: DeleteJobDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "deleteEdsJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "traceId",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteJobDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteJobDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d5629c81d092f76b37be63c9cabd33b1",
    "id": null,
    "metadata": {},
    "name": "DeleteJobDialogMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteJobDialogMutation(\n  $taskId: String!\n) {\n  deleteEdsJob(input: {taskId: $taskId}) {\n    __typename\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e333d8f6b6df689f8603c1442c1f507";

export default node;
