import React from 'react';
import { VendorCompanyCardTable } from '../../components/MobileWallet/Coupons/VendorCompanyCardTable';
import { Heading } from '@attentive/picnic';
import TacticalBreadcrumbs, {
  MOBILE_WALLET_BREADCRUMB,
} from '../../components/common/TacticalBreadcrumbs';

export const MobileWalletHome: React.FC = () => {
  return (
    <>
      <TacticalBreadcrumbs breadcrumbs={[MOBILE_WALLET_BREADCRUMB]} />
      <Heading as="h2" css={{ mb: '$space6' }}>
        Vendor Company Cards
      </Heading>
      <VendorCompanyCardTable />
    </>
  );
};
