export enum CustomActionsActions {
  FETCH_ALL_CUSTOM_ACTIONS_REQUEST = 'FETCH_ALL_CUSTOM_ACTIONS_REQUEST',
  FETCH_ALL_CUSTOM_ACTIONS_SUCCESS = 'FETCH_ALL_CUSTOM_ACTIONS_SUCCESS',
  FETCH_ALL_CUSTOM_ACTIONS_FAILURE = 'FETCH_ALL_CUSTOM_ACTIONS_FAILURE',
  FILTER_CUSTOM_ACTIONS = 'FILTER_CUSTOM_ACTIONS',
  ENABLE_CUSTOM_ACTION_REQUEST = 'ENABLE_CUSTOM_ACTION_REQUEST',
  DISABLE_CUSTOM_ACTION_REQUEST = 'DISABLE_CUSTOM_ACTION_REQUEST',
  DELETE_CUSTOM_ACTION_REQUEST = 'DELETE_CUSTOM_ACTION_REQUEST',
  UPDATE_CUSTOM_ACTIONS_SUCCESS = 'UPDATE_CUSTOM_ACTIONS_SUCCESS',
  UPDATE_CUSTOM_ACTIONS_FAILURE = 'UPDATE_CUSTOM_ACTIONS_FAILURE',
}

export enum CustomActionsMenuActions {
  EDIT = 'Edit',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  DELETE = 'Delete',
}
