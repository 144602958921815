import React from 'react';
import { Table, FooterLayout, Paginator, Box, ButtonGroup } from '@attentive/picnic';
import { EventReplayStatus } from '@attentive/data/types';

import EventReplayRow from './EventReplayRow';
import EventReplayEmptyList from './EventReplayEmptyList';
import { parseQueryResponse } from './utils/eventReplayMapper';
import { EventReplayRequest } from '../../constants/EventReplay/eventReplayTypes';
import { useEventReplayByCompanyIdsTacticalMfeQueryData } from '../../pages/EventReplay/EventReplayByCompanyIdsTacticalMfeQuery';

const COLUMN_NAMES: string[] = [
  'Event Replay',
  'Status',
  'Company',
  'Replay Range (UTC)',
  'Event Count',
  '',
];

const ITEMS_PER_PAGE_LIST_VIEW = 5;

const EventReplayList: React.FunctionComponent = () => {
  const queryData = useEventReplayByCompanyIdsTacticalMfeQueryData();
  const eventReplayRequests = parseQueryResponse(queryData);

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [requestsToShow, setRequestsToShow] =
    React.useState<EventReplayRequest[]>(eventReplayRequests);

  const handleFilterBy = (filterTabIndex: number, filter: string[]) => {
    setTabIndex(filterTabIndex);
    if (filter.length) {
      const toShow = eventReplayRequests.slice().filter((replay) => filter.includes(replay.status));
      setRequestsToShow(toShow);
    } else {
      setRequestsToShow(eventReplayRequests);
    }
  };

  const totalItems = requestsToShow.length;
  const pageItems = requestsToShow.slice(
    currentPage * ITEMS_PER_PAGE_LIST_VIEW,
    (currentPage + 1) * ITEMS_PER_PAGE_LIST_VIEW
  );

  return (
    <Box>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <ButtonGroup
          activeItem={tabIndex}
          itemSizing="equal"
          css={{ flex: 1, maxWidth: '550px', mb: '$space4' }}
        >
          <ButtonGroup.Item onClick={() => handleFilterBy(0, [])}>All</ButtonGroup.Item>
          <ButtonGroup.Item onClick={() => handleFilterBy(1, [EventReplayStatus.Pending])}>
            Ready
          </ButtonGroup.Item>
          <ButtonGroup.Item onClick={() => handleFilterBy(2, [EventReplayStatus.InProgress])}>
            Running
          </ButtonGroup.Item>
          <ButtonGroup.Item onClick={() => handleFilterBy(3, [EventReplayStatus.Completed])}>
            Completed
          </ButtonGroup.Item>
          <ButtonGroup.Item onClick={() => handleFilterBy(4, [EventReplayStatus.Failed])}>
            Failed
          </ButtonGroup.Item>
        </ButtonGroup>
      </Box>
      <FooterLayout css={{ height: '100%' }} includeSeparator={true}>
        <FooterLayout.Content
          css={{
            // -1px margin bottom prevents the bottom item border from doubling up with the separator
            marginBottom: '-1px',
          }}
        >
          <Table columns={[4, 2, 2, 4, 2, 1]}>
            <Table.Header>
              <Table.HeaderRow>
                {COLUMN_NAMES.map((column) => (
                  <Table.HeaderCell key={column}>{column}</Table.HeaderCell>
                ))}
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {pageItems.length ? (
                pageItems.map((req) => <EventReplayRow key={req.id} request={req} />)
              ) : (
                <EventReplayEmptyList />
              )}
            </Table.Body>
          </Table>
        </FooterLayout.Content>
        <FooterLayout.Footer
          css={{ display: 'flex', flexDirection: 'row-reverse', padding: '$space6 $space12' }}
        >
          <Paginator
            offset={currentPage}
            onOffsetChange={setCurrentPage}
            totalItems={totalItems}
            maxItemsPerPage={ITEMS_PER_PAGE_LIST_VIEW}
          />
        </FooterLayout.Footer>
      </FooterLayout>
    </Box>
  );
};

export default EventReplayList;
