/**
 * @generated SignedSource<<1f790a6b30eac1b2f26485650379ef5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AudienceTabPollCountTacticalMfeQuery$variables = {
  pollId: string;
};
export type AudienceTabPollCountTacticalMfeQuery$data = {
  readonly poll: {
    readonly audience?: {
      readonly count: {
        readonly count: number;
      } | null;
    } | null;
  } | null;
};
export type AudienceTabPollCountTacticalMfeQuery = {
  response: AudienceTabPollCountTacticalMfeQuery$data;
  variables: AudienceTabPollCountTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pollId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "pollId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AudienceResponse",
      "kind": "LinkedField",
      "name": "audience",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GetAudienceCountResponse",
          "kind": "LinkedField",
          "name": "count",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Poll",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AudienceTabPollCountTacticalMfeQuery",
    "selections": [
      {
        "alias": "poll",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AudienceTabPollCountTacticalMfeQuery",
    "selections": [
      {
        "alias": "poll",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b1d9802a3904623c65a44d0474d9765a",
    "id": null,
    "metadata": {},
    "name": "AudienceTabPollCountTacticalMfeQuery",
    "operationKind": "query",
    "text": "query AudienceTabPollCountTacticalMfeQuery(\n  $pollId: ID!\n) {\n  poll: node(id: $pollId) {\n    __typename\n    ... on Poll {\n      audience {\n        count {\n          count\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c3472d364e9a3e3c29e892714e0fd91";

export default node;
