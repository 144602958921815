/**
 * @generated SignedSource<<a0cccdce55b12a7ab64e2e98391fe64f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WithEventDestinationJobReportTacticalMfeQuery$variables = {
  end: SerializedDateTime;
  start: SerializedDateTime;
  taskId: string;
};
export type WithEventDestinationJobReportTacticalMfeQuery$data = {
  readonly eventDestinations: {
    readonly jobReport: {
      readonly report: {
        readonly endTime: string;
        readonly errorMsg: string;
        readonly failure: number;
        readonly requests: number;
        readonly startTime: string;
        readonly success: number;
        readonly taskId: string;
      } | null;
    } | null;
  } | null;
};
export type WithEventDestinationJobReportTacticalMfeQuery = {
  response: WithEventDestinationJobReportTacticalMfeQuery$data;
  variables: WithEventDestinationJobReportTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventDestinations",
    "kind": "LinkedField",
    "name": "eventDestinations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "end",
            "variableName": "end"
          },
          {
            "kind": "Variable",
            "name": "start",
            "variableName": "start"
          },
          {
            "kind": "Variable",
            "name": "taskId",
            "variableName": "taskId"
          }
        ],
        "concreteType": "GetJobReportResponse",
        "kind": "LinkedField",
        "name": "jobReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobReport",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taskId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requests",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failure",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "success",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorMsg",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WithEventDestinationJobReportTacticalMfeQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WithEventDestinationJobReportTacticalMfeQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "cb6975011e4dbbe4b05f6c03ab209c86",
    "id": null,
    "metadata": {},
    "name": "WithEventDestinationJobReportTacticalMfeQuery",
    "operationKind": "query",
    "text": "query WithEventDestinationJobReportTacticalMfeQuery(\n  $taskId: String!\n  $start: DateTime!\n  $end: DateTime!\n) {\n  eventDestinations {\n    jobReport(taskId: $taskId, start: $start, end: $end) {\n      report {\n        taskId\n        requests\n        failure\n        success\n        startTime\n        endTime\n        errorMsg\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1c0dadc266ad8ad2bc909de728904b2";

export default node;
