/**
 * @generated SignedSource<<7fd1dbef06d116094e5b2a748dd9449a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertKeywordRuleRequestInput = {
  areaCodeRule?: UpsertAreaCodeRuleRequest | null;
  companyId: string;
  customAttributeRule?: UpsertCustomAttributeRuleRequest | null;
  id?: number | null;
  keywordId: string;
  marketingSubscriberRule?: boolean | null;
  ruleExpression?: string | null;
  timeWindowRule?: UpsertTimeWindowRuleRequest | null;
};
export type UpsertAreaCodeRuleRequest = {
  areaCodes?: ReadonlyArray<string> | null;
};
export type UpsertCustomAttributeRuleRequest = {
  expression: string;
};
export type UpsertTimeWindowRuleRequest = {
  endTime: UpsertLocalTimeMessage;
  startTime: UpsertLocalTimeMessage;
  timeZoneId: string;
};
export type UpsertLocalTimeMessage = {
  hour: number;
  minute: number;
  second: number;
};
export type CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation$variables = {
  input: UpsertKeywordRuleRequestInput;
};
export type CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation$data = {
  readonly upsertKeywordRule: {
    readonly __typename: string;
  } | null;
};
export type CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation = {
  response: CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation$data;
  variables: CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "upsertKeywordRule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "400e943d9c77b95348bdc68d95b7bd72",
    "id": null,
    "metadata": {},
    "name": "CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation CreateKeywordModalUpsertSubscriberRuleTacticalMfeMutation(\n  $input: UpsertKeywordRuleRequestInput!\n) {\n  upsertKeywordRule(input: $input) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "24e73f285647c21485dcdbf0320e60cf";

export default node;
