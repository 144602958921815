import React from 'react';
import { useFragment } from 'react-relay';
import { usePrivacyRequests } from '../../pages/PrivacyRequests/PrivacyRequestPresenter';
import { PAGE_LENGTH, PrivacyRequestTable } from './PrivacyRequestTable';
import { PrivacyRequestsPaginationContext } from './usePaginatedPrivacyRequests';
import PaginatedCompanyRequests, {
  usePaginatedPrivacyRequests_CompanyFragment$key as usePaginatedPrivacyRequestsFragKey,
} from './__generated__/usePaginatedPrivacyRequests_CompanyFragment.graphql';

// TODO pass in the Filter / Sort info as props here from the top level page
const PrivacyRequestContainer: React.FunctionComponent = () => {
  const fragmentRef = usePrivacyRequests();
  const fragData = useFragment<usePaginatedPrivacyRequestsFragKey>(
    PaginatedCompanyRequests,
    fragmentRef.company
  );

  if (fragmentRef.company && fragData) {
    const defaultVariables = {
      first: PAGE_LENGTH,
      id: fragData.id,
    };
    return (
      <PrivacyRequestsPaginationContext.Provider defaultVariables={defaultVariables}>
        <PrivacyRequestTable fragmentRef={fragmentRef.company} />
      </PrivacyRequestsPaginationContext.Provider>
    );
  }
  return null;
};

export default PrivacyRequestContainer;
