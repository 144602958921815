import React from 'react';
import { Box, Banner } from '@attentive/picnic';
import Search from '../../../../components/EventDestinations/Jobs/JobsTable/Search';

import Header from './Header';
import JobsTable2 from '../../../../components/EventDestinations/Jobs/JobsTable/Table2';

const JobsPage: React.FunctionComponent = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [errorMsg, setErrorMsg] = React.useState('');

  return (
    <Box>
      <Header />
      {errorMsg && (
        <Banner variant="error" css={{ mb: '$space4' }}>
          {errorMsg}
        </Banner>
      )}
      <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <JobsTable2 search={searchTerm} setErrMsg={setErrorMsg} />
    </Box>
  );
};

export default JobsPage;
