import { Banner } from '@attentive/picnic';
import React from 'react';
import { ConnectedTable } from './VendorCompanyCardConnectedTable';
import { VendorCompanyCardConnectedTableQuery_query$data } from './__generated__/VendorCompanyCardConnectedTableQuery_query.graphql';

export const CardRow: React.FC<{ data: VendorCompanyCardConnectedTableQuery_query$data }> = ({
  data,
}) => {
  const cards = data.company?.vendorCompanyCards?.edges;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {cards && cards.length > 0 ? (
        cards.map(({ node }) => (
          <ConnectedTable.BodyRow key={node.uuid}>
            <ConnectedTable.BodyCell>{node.uuid}</ConnectedTable.BodyCell>
            <ConnectedTable.BodyCell>{node.vendorCampaignId}</ConnectedTable.BodyCell>
            <ConnectedTable.BodyCell>{node.vendorOfferId}</ConnectedTable.BodyCell>
            <ConnectedTable.BodyCell>
              {new Date(String(node.updated)).toLocaleString()}
            </ConnectedTable.BodyCell>
          </ConnectedTable.BodyRow>
        ))
      ) : (
        <ConnectedTable.BodyRow>
          <Banner variant="guidance" css={{ gridColumn: '1/-1', mt: '$space2' }}>
            <Banner.Heading>No Vendor Company Cards Found</Banner.Heading>
            <Banner.Text>
              If you expect to see entries here and don't, refresh the page. If the problem persists
              reach out to the tactical team.
            </Banner.Text>
          </Banner>
        </ConnectedTable.BodyRow>
      )}
    </>
  );
};
