import React from 'react';
import { Box, Text, Heading, useForm } from '@attentive/picnic';

const CreateMappingHeader: React.FC = () => {
  const { values } = useForm();
  const { taskId } = values;

  return (
    <Box css={{ mb: '$space6' }}>
      <Heading>Add Mappings</Heading>
      <Text variant="micro">For job {taskId}</Text>
    </Box>
  );
};

export default CreateMappingHeader;
