import React from 'react';
import { Box, Grid } from '@attentive/picnic';
import { usePreloadedQuery, PreloadedQuery } from 'react-relay';
import EndpointCard from './EndpointCard';
import EndpointsPageQuery, {
  EndpointsPageQuery as EndpointsPageQueryType,
} from '../../../pages/EventDestinations/Endpoints/__generated__/EndpointsPageQuery.graphql';

import { deserializeGQLDateTime } from '@attentive/data';

const EndpointsList: React.FunctionComponent<{
  queryRef: PreloadedQuery<EndpointsPageQueryType>;
}> = ({ queryRef }) => {
  const data = usePreloadedQuery(EndpointsPageQuery, queryRef);
  if (
    !data ||
    !data.eventDestinations ||
    !data.eventDestinations.nodes ||
    !data.eventDestinations.nodes.edges
  ) {
    return null;
  }
  const enabledEndpoints = data?.eventDestinations?.nodes?.edges?.filter((d) => !!d.node.enabled);
  const disabledEndpoints = data?.eventDestinations?.nodes?.edges?.filter((d) => !d.node.enabled);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapQueryResultsToNodeType = (node: any) => {
    const subNode = node.node;
    return {
      uuid: subNode.uuid,
      name: subNode.name,
      description: subNode.description,
      authorId: subNode.authorId,
      created: deserializeGQLDateTime(subNode.created),
      enabled: subNode.enabled,
    };
  };

  return (
    <Box>
      <Grid columns={3}>
        {enabledEndpoints.map((node) => (
          <Grid.Cell key={node.node.uuid} css={{ m: '$space2' }}>
            <EndpointCard node={mapQueryResultsToNodeType(node)} />
          </Grid.Cell>
        ))}
        {disabledEndpoints.map((node) => (
          <Grid.Cell key={node.node.uuid} css={{ m: '$space2' }}>
            <EndpointCard node={mapQueryResultsToNodeType(node)} />
          </Grid.Cell>
        ))}
      </Grid>
    </Box>
  );
};

export default EndpointsList;
