/**
 * @generated SignedSource<<aa28b5794ef96db3aba93eeaeb354033>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClonePollInput = {
  companyId: string;
  description?: string | null;
  name?: string | null;
  pollId: string;
  shouldCloneKeywords?: boolean | null;
};
export type ClonePollDialogTacticalMfeMutation$variables = {
  input: ClonePollInput;
};
export type ClonePollDialogTacticalMfeMutation$data = {
  readonly clonePoll: {
    readonly newPoll?: {
      readonly id: string;
      readonly name: string;
      readonly uuid: string;
    } | null;
  } | null;
};
export type ClonePollDialogTacticalMfeMutation = {
  response: ClonePollDialogTacticalMfeMutation$data;
  variables: ClonePollDialogTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Poll",
      "kind": "LinkedField",
      "name": "newPoll",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClonePollSuccess",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClonePollDialogTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "clonePoll",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClonePollDialogTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "clonePoll",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f398121803155519252c0bee0d740690",
    "id": null,
    "metadata": {},
    "name": "ClonePollDialogTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation ClonePollDialogTacticalMfeMutation(\n  $input: ClonePollInput!\n) {\n  clonePoll(input: $input) {\n    __typename\n    ... on ClonePollSuccess {\n      newPoll {\n        id\n        uuid\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dae18c636fce888ac6025ed09fae2797";

export default node;
