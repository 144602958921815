/**
 * @generated SignedSource<<ea1effab00c0892c89029dd75230f194>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DestinationPageQuery$variables = {
  collectionId: string;
};
export type DestinationPageQuery$data = {
  readonly eventDestinations: {
    readonly destinations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly author: {
            readonly email: string;
            readonly firstName: string;
            readonly lastName: string;
          } | null;
          readonly created: SerializedDateTime;
          readonly description: string;
          readonly image: string;
          readonly name: string;
          readonly updated: SerializedDateTime;
          readonly uuid: string;
          readonly visibilityTypeId: number;
        };
      }>;
    } | null;
    readonly nodes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly authorId: number;
          readonly collectionId: number;
          readonly created: SerializedDateTime;
          readonly description: string;
          readonly enabled: boolean;
          readonly jobCount: number | null;
          readonly metadata: any;
          readonly name: string;
          readonly nodeType: string | null;
          readonly nodeTypeId: number;
          readonly tags: ReadonlyArray<string>;
          readonly updated: SerializedDateTime;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | null;
};
export type DestinationPageQuery = {
  response: DestinationPageQuery$data;
  variables: DestinationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collectionId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibilityTypeId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "collectionId",
      "variableName": "collectionId"
    }
  ],
  "concreteType": "EventDestinationNodeConnection",
  "kind": "LinkedField",
  "name": "nodes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventDestinationNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventDestinationNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nodeTypeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "metadata",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "authorId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "collectionId",
              "storageKey": null
            },
            (v6/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nodeType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DestinationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NodeCollectionConnection",
            "kind": "LinkedField",
            "name": "destinations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NodeCollectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NodeCollection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DestinationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NodeCollectionConnection",
            "kind": "LinkedField",
            "name": "destinations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NodeCollectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NodeCollection",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd8e9ad1a647948ceb2c517bffb0f564",
    "id": null,
    "metadata": {},
    "name": "DestinationPageQuery",
    "operationKind": "query",
    "text": "query DestinationPageQuery(\n  $collectionId: String!\n) {\n  eventDestinations {\n    destinations {\n      edges {\n        node {\n          uuid\n          name\n          description\n          image\n          created\n          updated\n          visibilityTypeId\n          author {\n            firstName\n            lastName\n            email\n            id\n          }\n          id\n        }\n      }\n    }\n    nodes(collectionId: $collectionId) {\n      edges {\n        node {\n          uuid\n          name\n          description\n          nodeTypeId\n          metadata\n          enabled\n          tags\n          authorId\n          collectionId\n          updated\n          created\n          jobCount\n          nodeType\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fea90846e06bf9377e5c91600868e8b";

export default node;
