import React from 'react';

import { Box, Card, Heading, Text } from '@attentive/picnic';
import { BarDatum, BarTooltipProps } from '@attentive/charts';

const StatusCodeTooltip = (bar: BarTooltipProps<BarDatum>) => {
  return (
    <Card>
      <Box>
        <Heading variant="sm">{bar.data.date}</Heading>
        <Text color="success">{bar.data.success && `Success: ${bar.data.success}`}</Text>
        <Text color="info">
          {bar.data.rate_limited && `Rate Limited: ${bar.data.rate_limited}`}
        </Text>
        <Text css={{ color: '$yellow700' }}>
          {bar.data.subscriber_not_found && `Not Found: ${bar.data.subscriber_not_found}`}
        </Text>
        <Text css={{ color: '$lavenderPurple700' }}>
          {bar.data.invalid_request && `Invalid Requests: ${bar.data.invalid_request}`}
        </Text>
        <Text css={{ color: '#fb8072' }}>
          {bar.data.subscriber_inactive && `Inactive Subscribers: ${bar.data.subscriber_inactive}`}
        </Text>
        <Text css={{ color: '#80b1d3' }}>
          {bar.data.subscriber_optedout && `Opted Out Subscribers: ${bar.data.subscriber_optedout}`}
        </Text>
      </Box>
    </Card>
  );
};

export default StatusCodeTooltip;
