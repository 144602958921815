import React from 'react';
import { TabGroup } from '@attentive/picnic';
import { JobPageTabType } from '../../../../constants/EventDestinations/jobTypes';
import { tabGroupStyling } from './styles';

const TabOptions: React.FunctionComponent = () => {
  return (
    <TabGroup.List css={tabGroupStyling}>
      <TabGroup.Tab value={JobPageTabType.CONFIG}>Config</TabGroup.Tab>
      <TabGroup.Tab value={JobPageTabType.HISTORY}>History</TabGroup.Tab>
      <TabGroup.Tab value={JobPageTabType.METRICS}>Performance</TabGroup.Tab>
      <TabGroup.Tab value={JobPageTabType.ADVANCED}>Advanced</TabGroup.Tab>
    </TabGroup.List>
  );
};

export default TabOptions;
