import { Box, Button, ButtonGroup, Heading, SearchBar, Text, styled } from '@attentive/picnic';
import React, { useState } from 'react';
import CreateKeywordModal from '../../components/CustomCompanyKeywords/KeywordModalForms/CreateKeywordModal';
import { KeywordOrderBy } from '../../constants/CustomCompanyKeywords/keywordTypes';

const FormRow = styled('div', {
  gridTemplateColumns: '8fr auto',
  display: 'grid',
  width: '100%',
  gap: '$space3',
  mt: '$space8',
});

interface CustomCompanyKeywordsPageHeaderContainerProps {
  search: { get: string; set: React.Dispatch<React.SetStateAction<string>> };
  orderBy: { get: KeywordOrderBy; set: React.Dispatch<React.SetStateAction<KeywordOrderBy>> };
  filterByEnabled: { get: boolean; set: React.Dispatch<React.SetStateAction<boolean>> };
}

const CustomCompanyKeywordsPageHeader: React.FunctionComponent<
  CustomCompanyKeywordsPageHeaderContainerProps
> = ({ search, orderBy, filterByEnabled: filterEnabled }) => {
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);

  return (
    <Box>
      <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Heading>Keywords</Heading>
          <Text>View, search, and manage message triggers</Text>
        </Box>
        <Box>
          <Button onClick={() => setIsCreateOpen(true)} size="small">
            + Add Keyword
          </Button>
        </Box>
      </Box>
      <FormRow>
        <SearchBar size="small" onChange={search.set} value={search.get} css={{ width: '100%' }} />
        <ButtonGroup activeItem={filterEnabled.get ? 0 : 1}>
          <ButtonGroup.Item onClick={() => filterEnabled.set(true)}>Enabled</ButtonGroup.Item>
          <ButtonGroup.Item onClick={() => filterEnabled.set(false)}>Disabled</ButtonGroup.Item>
        </ButtonGroup>
      </FormRow>

      <CreateKeywordModal
        isOpen={isCreateOpen}
        onOpenChange={() => setIsCreateOpen(!isCreateOpen)}
        afterCreate={() => {
          search.set('');
          orderBy.set(KeywordOrderBy.UPDATE_DESC);
        }}
      />
    </Box>
  );
};

const CustomCompanyKeywordsPageHeaderContainer: React.FunctionComponent<
  CustomCompanyKeywordsPageHeaderContainerProps
> = ({ search, orderBy, filterByEnabled: filterEnabled }) => {
  return (
    <CustomCompanyKeywordsPageHeader
      search={search}
      orderBy={orderBy}
      filterByEnabled={filterEnabled}
    />
  );
};

export default CustomCompanyKeywordsPageHeaderContainer;
