import React, { useState } from 'react';
import { Box } from '@attentive/picnic';
import CustomCompanyKeywordsPageHeader from './CustomCompanyKeywordsPageHeader';
import { KeywordOrderBy } from '../../constants/CustomCompanyKeywords/keywordTypes';
import KeywordsTable from '../../components/CustomCompanyKeywords/KeywordsTable';

export const CustomCompanyKeywordsPage: React.FunctionComponent = () => {
  const [search, setSearch] = React.useState<string>('');
  const [orderBy, setOrderBy] = React.useState<KeywordOrderBy>(KeywordOrderBy.KEYWORD_ASC);
  const [offset, setOffset] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [filterByEnabled, setFilterEnabled] = useState<boolean>(false);

  return (
    <Box>
      <CustomCompanyKeywordsPageHeader
        search={{ get: search, set: setSearch }}
        orderBy={{ get: orderBy, set: setOrderBy }}
        filterByEnabled={{ get: filterByEnabled, set: setFilterEnabled }}
      />
      <Box css={{ mt: '$space4', mb: '$space4', ml: -15, mr: -15 }}>
        <KeywordsTable
          search={search}
          filterByEnabled={filterByEnabled}
          sort={{ get: orderBy, set: setOrderBy }}
          offset={{ get: offset, set: setOffset }}
          pageSize={{ get: pageSize, set: setPageSize }}
        />
      </Box>
    </Box>
  );
};
