import React from 'react';
import { ResponsivePie } from '@nivo/pie';

export interface TacticalPieChartData {
  id: string;
  label: string;
  value: number;
  color: string;
  keyword?: string;
}

const TacticalPieChart: React.FunctionComponent<{
  data: TacticalPieChartData[];
  isMobile: boolean;
}> = ({ data, isMobile }) => {
  const desktopCss = { top: 40, right: 80, bottom: 80, left: 80 };

  return (
    <ResponsivePie
      data={data}
      colors={data.map((d) => d.color)}
      margin={isMobile ? { top: 5, right: 10, bottom: 10, left: 10 } : desktopCss}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLabel={isMobile ? 'id' : 'value'}
      enableArcLinkLabels={!isMobile}
      arcLinkLabelsSkipAngle={isMobile ? 0 : 10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={isMobile ? 0 : 10}
      legends={[
        {
          anchor: isMobile ? 'bottom-left' : 'top-left',
          direction: 'column',
          justify: false,
          itemsSpacing: 1,
          itemWidth: 100,
          itemHeight: 25,
          itemTextColor: '#000',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [],
        },
      ]}
      theme={{
        textColor: '#656567',
        fontFamily: 'Ginto Normal',
        fontSize: 16,
      }}
    />
  );
};

export default TacticalPieChart;
