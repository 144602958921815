export enum SelectorType {
  FIRST = 'First',
  LAST = 'Last',
  RANDOM = 'Random',
  MOST_VOTES = 'Most Votes',
}

export enum SubscriberType {
  MARKETING = 'Marketing',
  ANY = 'Any',
}

export interface SweepstakesFormValues {
  pollId: string;
  numToSelect: string;
  selectorType: SelectorType;
  subscriberType: SubscriberType;
  pollKeywordIds: string[];
}
