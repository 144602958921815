import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Heading, Text, Icon } from '@attentive/picnic';

import EventReplayList from '../../components/EventReplay/EventReplayList';
import { Paths } from '../../constants/routes';
import {
  useCurrentCompanyData,
  parseCompanyIds,
} from '../../components/common/CurrentCompanyTacticalMfeQuery';
import { EventReplaysPresenter } from './EventReplayByCompanyIdsTacticalMfeQuery';

const EventReplayListPage: React.FunctionComponent = () => {
  const companyIds = parseCompanyIds(useCurrentCompanyData());
  return (
    <Box css={{ width: '100%' }}>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: '$space4',
        }}
      >
        <div>
          <Heading css={{ mb: '$space4' }}>Event Replay</Heading>
          <Text>Replay events that failed or had issues</Text>
        </div>
        <div>
          <Link to={Paths.EventReplayCreate}>
            <Button variant="primary" css={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="PlusSign" size="small" css={{ mr: '$space2' }} />
              Create Request
            </Button>
          </Link>
        </div>
      </Box>
      <EventReplaysPresenter vars={{ companyIds }} queryOptions={{ fetchPolicy: 'network-only' }}>
        <EventReplayList />
      </EventReplaysPresenter>
    </Box>
  );
};

export default EventReplayListPage;
