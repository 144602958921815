import { differenceInMinutes, differenceInSeconds, format } from 'date-fns';

export const parseDate = (d: Date): string => {
  return format(d, 'MM/d/yy hh:mm a');
};

export const calculateDuration = (start: Date, end: Date): string => {
  try {
    const minutes = differenceInMinutes(end, start);
    const seconds = differenceInSeconds(end, start) - minutes * 60;
    if (!minutes && !seconds) {
      return 'n/a';
    }
    if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  } catch (ex) {
    return 'n/a';
  }
};

export const calculateRequestRate = (start: Date, end: Date, requests: number): string => {
  try {
    const diff = (end.getTime() - start.getTime()) / 1000;
    const rate = Math.round(requests / diff);
    if (!rate) {
      return 'n/a';
    }
    return `${rate} / second`;
  } catch (ex) {
    return 'n/a';
  }
};

export const calculateSuccessRate = (success: number, total: number): string => {
  const percent = Math.floor((success / total) * 100);
  if (!percent) {
    return 'n/a';
  }
  return `${percent}%`;
};
