import _CurrentCompanyTacticalMfeQuery from "./__generated__/CurrentCompanyTacticalMfeQuery.graphql";
import { graphql, createPresenter } from '@attentive/data';
import CurrentCompanyTacticalMfeQuery from './__generated__/CurrentCompanyTacticalMfeQuery.graphql';
_CurrentCompanyTacticalMfeQuery;
export const [useCurrentCompanyData, CurrentCompanyPresenter] = createPresenter(CurrentCompanyTacticalMfeQuery);
export const parseCompanyIds = queryData => {
  var _queryData$viewer, _queryData$viewer$acc, _queryData$viewer$acc2;

  let companyIds = (_queryData$viewer = queryData.viewer) === null || _queryData$viewer === void 0 ? void 0 : (_queryData$viewer$acc = _queryData$viewer.account) === null || _queryData$viewer$acc === void 0 ? void 0 : (_queryData$viewer$acc2 = _queryData$viewer$acc.recentCompanies) === null || _queryData$viewer$acc2 === void 0 ? void 0 : _queryData$viewer$acc2.edges.map(e => e.node.internalId);

  if (!companyIds) {
    companyIds = [];
  }

  return companyIds;
};