import React from 'react';
import { ContainedLabel, Icon } from '@attentive/picnic';
import { EventReplayStatus } from '@attentive/data/types';

interface PillLabelProps {
  status: string;
}

const getVariant = (status: string) => {
  switch (status) {
    case EventReplayStatus.Analyzing:
      return 'neutral';
    case EventReplayStatus.Pending:
      return 'neutral';
    case EventReplayStatus.Queued:
      return 'informational';
    case EventReplayStatus.InProgress:
      return 'warning';
    case EventReplayStatus.Completed:
      return 'success';
    case EventReplayStatus.Failed:
      return 'critical';
    default:
      return 'neutral';
  }
};

const getColor = (status: string) => {
  switch (status) {
    case EventReplayStatus.Analyzing:
      return 'subdued';
    case EventReplayStatus.Pending:
      return 'subdued';
    case EventReplayStatus.Queued:
      return 'info';
    case EventReplayStatus.InProgress:
      return 'warning';
    case EventReplayStatus.Completed:
      return 'success';
    case EventReplayStatus.Failed:
      return 'error';
    default:
      return 'neutral';
  }
};

const getName = (status: string) => {
  switch (status) {
    case EventReplayStatus.Analyzing:
      return 'Cog';
    case EventReplayStatus.Pending:
      return 'Bell';
    case EventReplayStatus.Queued:
      return 'Calendar';
    case EventReplayStatus.InProgress:
      return 'Sync';
    case EventReplayStatus.Completed:
      return 'Checkmark';
    case EventReplayStatus.Failed:
      return 'X';
    default:
      return 'Cog';
  }
};

const getTextValue = (status: string) => {
  switch (status) {
    case EventReplayStatus.Analyzing:
      return 'Analyzing';
    case EventReplayStatus.Pending:
      return 'Ready';
    case EventReplayStatus.Queued:
      return 'Queued';
    case EventReplayStatus.InProgress:
      return 'Running';
    case EventReplayStatus.Completed:
      return 'Completed';
    case EventReplayStatus.Failed:
      return 'Failed';
    default:
      return 'Unknown';
  }
};

const BackfillPillLabel: React.FunctionComponent<PillLabelProps> = ({ status }) => (
  <ContainedLabel variant={getVariant(status)} css={{ mr: '$space4' }}>
    {getTextValue(status)}
  </ContainedLabel>
);

export const StatusIconLabel: React.FunctionComponent<PillLabelProps> = ({ status }) => (
  <Icon name={getName(status)} color={getColor(status)} />
);

export default BackfillPillLabel;
