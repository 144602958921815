import { JobHistory, JobRecord } from '../../../constants/EventDestinations/jobTypes';
import { getStatus, parseHistoryContext } from '../../../utils/responseParser';
import { deserializeGQLDateTime } from '@attentive/data';
import { JobPageQuery$data } from './JobPage/__generated__/JobPageQuery.graphql';

export const createJobRecordFromQuery = (queryData: JobPageQuery$data) => {
  const job = queryData?.eventDestinations?.job;
  if (job) {
    const lastRun: JobHistory | undefined = job?.lastExecutedRun
      ? {
          uuid: job?.lastExecutedRun.uuid,
          taskId: job?.lastExecutedRun.taskId,
          parentTaskId: job?.lastExecutedRun.parentTaskId
            ? job?.lastExecutedRun.parentTaskId
            : undefined,
          runDate: job?.lastExecutedRun.runDate
            ? deserializeGQLDateTime(job?.lastExecutedRun.runDate)
            : undefined,
          scheduledDate: deserializeGQLDateTime(job?.lastExecutedRun.scheduledDate),
          context: parseHistoryContext(job?.lastExecutedRun.context),
          status: job?.lastExecutedRun.status,
        }
      : undefined;

    return {
      id: job.id,
      internalId: job.internalId,
      idx: 1,
      name: job.name,
      description: job.description,
      company: job.company?.displayName,
      companyId: job.company?.internalId,
      status: getStatus(job.jobType, job?.previousRun, job.nextRun, lastRun),
      type: job?.jobType,
      health: 'Good',
      lastEditedBy: null,
      lastRunDate: job.previousRun ? deserializeGQLDateTime(job.previousRun) : null,
      nextRunDate: job.nextRun ? deserializeGQLDateTime(job.nextRun) : null,
      targetInfo: job.targetInfo ? job?.targetInfo : '{}',
      sourceInfo: job.sourceInfo ? job?.sourceInfo : '{}',
      sourceNodeUuid: job.sourceNodeUuid ?? '',
      targetNodeUuid: job.targetNodeUuid ?? '',
      lastExecutedRun: lastRun,
    } as JobRecord;
  }
};
