import React from 'react';

import EventReplayListPage from './EventReplayListPage';
import { CurrentCompanyPresenter } from '../../components/common/CurrentCompanyTacticalMfeQuery';

const EventReplayLandingPage: React.FunctionComponent = () => {
  return (
    <CurrentCompanyPresenter>
      <EventReplayListPage />
    </CurrentCompanyPresenter>
  );
};

export default EventReplayLandingPage;
