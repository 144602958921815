import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, StandardDialog, Text, Box, Table, Icon } from '@attentive/picnic';

import { dateToStringLongForm } from './utils/dateFormatter';
import { EventReplayRequest } from '../../constants/EventReplay/eventReplayTypes';

interface EventReplayDetailsProps {
  request: EventReplayRequest;
  showDialog: boolean;
  handleClose: () => void;
}

const EventReplayDetailsDialog: React.FunctionComponent<EventReplayDetailsProps> = ({
  request,
  showDialog,
  handleClose,
}) => {
  const parseCount = (evtType: string) => {
    const counts = request?.estimatedEventCounts ? request.estimatedEventCounts : {};
    if (counts[evtType]) {
      return counts[evtType];
    }
    return 0;
  };

  return (
    <StandardDialog open={showDialog} onOpenChange={handleClose}>
      <StandardDialog.Content>
        <StandardDialog.Header>
          <Box css={{ display: 'flex' }}>
            <StandardDialog.Heading>{request.title}</StandardDialog.Heading>
            <Link
              to={`/tactical/event-replay/${request.companyId}/${request.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="NewWindow" color="subdued" />
            </Link>
          </Box>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <Box>
            <Text>{request.description}</Text>
            <Text>
              Replay from {dateToStringLongForm(request.startDate)} to{' '}
              {dateToStringLongForm(request.endDate)}
            </Text>
            <Text>Total events to replay: {request.estimatedCountSum}</Text>
          </Box>
          <Box css={{ mt: '$space4' }}>
            <Heading variant="subheading">Event Types</Heading>
            <Table
              columns={[3, 1]}
              css={{ border: '1px solid $borderDefault', borderRadius: '5px' }}
            >
              <Table.Body>
                {request.estimatedEventCounts
                  ? Object.keys(request.estimatedEventCounts).map((evtType) => (
                      <Table.BodyRow key={evtType}>
                        <Table.BodyCell>{evtType}</Table.BodyCell>
                        <Table.BodyCell>{parseCount(evtType)}</Table.BodyCell>
                      </Table.BodyRow>
                    ))
                  : null}
              </Table.Body>
            </Table>
          </Box>
        </StandardDialog.Body>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

export default EventReplayDetailsDialog;
