export interface MarketingCloudAppInfo {
  clientId?: string;
  clientSecret?: string;
  accountId?: string;
  subDomain?: string;
}

export interface MarketingCloudPageState {
  appInfo: MarketingCloudAppInfo;
  isLoading: boolean;
  error: string;
}

export enum MarketingCloudActions {
  SET_CLIENT_ID = 'SET_CLIENT_ID',
  SET_CLIENT_SECRET = 'SET_CLIENT_SECRET',
  SET_ACCOUNT_ID = 'SET_ACCOUNT_ID',
  SET_SUBDOMAIN = 'SET_SUBDOMAIN',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_APP_INFO = 'SET_APP_INFO',
}

export interface MarketingCloudAction {
  type: MarketingCloudActions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export interface ClientCredentialsRedirectDto {
  link: string;
}
