import React from 'react';
import { Card } from '@attentive/picnic';
import { EdsNode } from '../../../../constants/EventDestinations/endpointTypes';
import EndpointCardContent from './EndpointCardContent';
import EndpointCardHeader from './EndpointCardHeader';
import { Link } from 'react-router-dom';

interface EndpointCardProps {
  node: EdsNode;
}

const EndpointCard: React.FunctionComponent<EndpointCardProps> = ({ node }) => {
  return (
    <Card
      css={{
        px: '$space4',
        py: '$space5',
        maxWidth: '600px',
        m: '$space2',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      // todo: based on visibility and enabled
      interactive={true}
    >
      <Link to={`/tactical/event-destinations/nodes/${node.uuid}`}>
        <EndpointCardHeader node={node} />
        <EndpointCardContent node={node} />
      </Link>
    </Card>
  );
};

export default EndpointCard;
