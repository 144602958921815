import { CustomActionsActions } from './customActionDefinitionsActions';

export interface CustomActionDefinition {
  id?: number;
  uuid: string;
  name: string;
  state?: CustomActionStatus;
  descriptionShort: string;
  descriptionLong?: string;
  imageUrl: string;
  actionVisibilityType: string;
  enabled: boolean;
  lastUpdated?: string;
  loading?: boolean;
  editing?: boolean;
}

export interface CustomActionsState {
  customActions: CustomActionDefinition[];
  filteredCustomActions: CustomActionDefinition[];
  updatedCustomAction?: CustomActionDefinition;
  loading: boolean;
  filter: CustomActionsFilter;
}

export interface CustomActionAction {
  type: CustomActionsActions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export enum CustomActionsFilterNone {
  VALUE = 'ALL',
}

export enum CustomActionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CustomActionsFilter = CustomActionsFilterNone | CustomActionStatus;
