import React from 'react';
import { useParams } from 'react-router-dom';
import TacticalBreadcrumbs, {
  CUSTOM_COMPANY_KEYWORDS_BREADCRUMB,
  TacticalBreadcrumbType,
} from '../../components/common/TacticalBreadcrumbs';
import { Box } from '@attentive/picnic';
import { Paths } from '../../constants/routes';

const CustomCompanyKeywordPage: React.FunctionComponent = () => {
  const { id } = useParams<{ id?: string }>();

  const CUSTOM_COMPANY_KEYWORD_BREADCRUMB: TacticalBreadcrumbType = {
    id: 10,
    path: Paths.CustomCompanyKeywords,
    text: id || '',
  };

  return (
    <Box>
      <TacticalBreadcrumbs
        breadcrumbs={[CUSTOM_COMPANY_KEYWORDS_BREADCRUMB, CUSTOM_COMPANY_KEYWORD_BREADCRUMB]}
      />
    </Box>
  );
};
export default CustomCompanyKeywordPage;
