import React from 'react';
import { Table } from '@attentive/picnic';
import { HistoryFilter, JobHistory } from '../../../../constants/EventDestinations/jobTypes';
import { useJobReport } from '../../../../pages/EventDestinations/graphql/WithEventDestinationJobReport';
import DetailsDialog from './DetailsDialog';
import {
  calculateDuration,
  calculateRequestRate,
  calculateSuccessRate,
  parseDate,
} from './helpers';

const TableRow: React.FunctionComponent<{ run: JobHistory; filter: HistoryFilter }> = ({
  run,
  filter,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { startTime, endTime, requests, success, failure, errorMsg } = useJobReport();
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  if (filter === HistoryFilter.EXCLUDE_NOOP && requests === 0) {
    return null;
  }

  const getRunDate = (jobRun: JobHistory) =>
    jobRun.runDate ? parseDate(jobRun.runDate) : parseDate(jobRun.scheduledDate);
  const getErrorMsg = (jobRun: JobHistory) =>
    jobRun.context.error ? jobRun.context.error : errorMsg;

  return (
    <Table.BodyFocusableRow key={run.uuid} onClick={() => setIsOpen(!isOpen)}>
      <Table.BodyCell>{getRunDate(run)}</Table.BodyCell>
      <Table.BodyCell>{requests}</Table.BodyCell>
      <Table.BodyCell>{calculateSuccessRate(success, requests)}</Table.BodyCell>
      <Table.BodyCell>{failure}</Table.BodyCell>
      <Table.BodyCell>{calculateDuration(startDate, endDate)}</Table.BodyCell>
      <Table.BodyCell>{calculateRequestRate(startDate, endDate, requests)}</Table.BodyCell>
      <Table.BodyCell>{getErrorMsg(run)}</Table.BodyCell>

      <DetailsDialog
        open={isOpen}
        handleOpenChange={() => setIsOpen(!isOpen)}
        run={run}
        requests={requests}
        success={success}
        failure={failure}
        start={startDate}
        end={endDate}
      />
    </Table.BodyFocusableRow>
  );
};

export default TableRow;
