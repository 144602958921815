import { PollSortingField, SortDirection } from '@attentive/data/types';
import React from 'react';
import { PollSorting } from './__generated__/PollsConnectedTableQuery.graphql';
import { ConnectedTable } from './PollsConnectedTable';

interface SortByCellProps {
  sorting: {
    get: PollSorting | null;
    set: (val: PollSorting | null) => void;
  };
  sortByField: PollSortingField;
  cellLabel: string;
}

export const SortByCell: React.FC<SortByCellProps> = ({ sorting, sortByField, cellLabel }) => {
  return (
    <ConnectedTable.SortableHeaderCell
      ascending={
        sorting.get != null &&
        sorting.get.sortBy === sortByField &&
        sorting.get.sortDirection === SortDirection.SortDirectionAsc
      }
      isSortActive={sorting.get?.sortBy && sorting.get.sortBy === sortByField}
      onChange={() => {
        if (
          sorting.get?.sortDirection &&
          sorting.get.sortDirection === SortDirection.SortDirectionAsc
        ) {
          sorting.set({
            sortBy: sortByField,
            sortDirection: SortDirection.SortDirectionDesc,
          });
        } else {
          sorting.set({
            sortBy: sortByField,
            sortDirection: SortDirection.SortDirectionAsc,
          });
        }
      }}
    >
      {cellLabel}
    </ConnectedTable.SortableHeaderCell>
  );
};
