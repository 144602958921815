/**
 * @generated SignedSource<<0100035477047ec501ec19ae8f5d1669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EndpointPageNodeByUuidQuery$variables = {
  uuid: string;
};
export type EndpointPageNodeByUuidQuery$data = {
  readonly eventDestinations: {
    readonly node: {
      readonly authorId: number;
      readonly collectionId: number;
      readonly created: SerializedDateTime;
      readonly description: string;
      readonly enabled: boolean;
      readonly jobCount: number | null;
      readonly metadata: any;
      readonly name: string;
      readonly nodeType: string | null;
      readonly nodeTypeId: number;
      readonly tags: ReadonlyArray<string>;
      readonly updated: SerializedDateTime;
      readonly uuid: string;
    } | null;
  } | null;
};
export type EndpointPageNodeByUuidQuery = {
  response: EndpointPageNodeByUuidQuery$data;
  variables: EndpointPageNodeByUuidQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventDestinations",
    "kind": "LinkedField",
    "name": "eventDestinations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "nodeUuid",
            "variableName": "uuid"
          }
        ],
        "concreteType": "EventDestinationNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeTypeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadata",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authorId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointPageNodeByUuidQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EndpointPageNodeByUuidQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "29a218c548885987d666b1da558c789a",
    "id": null,
    "metadata": {},
    "name": "EndpointPageNodeByUuidQuery",
    "operationKind": "query",
    "text": "query EndpointPageNodeByUuidQuery(\n  $uuid: String!\n) {\n  eventDestinations {\n    node(nodeUuid: $uuid) {\n      uuid\n      name\n      description\n      nodeTypeId\n      metadata\n      enabled\n      tags\n      authorId\n      collectionId\n      updated\n      created\n      jobCount\n      nodeType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eef649e5e5cd3bf63d5057f10476d4f0";

export default node;
