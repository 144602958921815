import { PicnicCss } from '@attentive/picnic';

export const columnSizes = '6fr 1fr 1fr 1fr 1fr 1fr';

export const tableContainerStyling = {
  display: 'grid',
  gridGap: '$space4',
};

export const tableStyling = {
  fontSize: '$fontSize3',
};

export const searchContainerStyling = {
  m: '$space4',
  mt: '$space2',
  mb: '$space6',
};
export const pagingContainerStyling = {
  display: 'flex',
  justifyContent: 'flex-end',
  mt: '$space6',
};

export const statusLabelStyling = {
  fontSize: '$fontSize2',
};

export const statusCellCss: PicnicCss = {
  display: 'inline-flex',
  justifyContent: 'left',
  px: '$space0',
  pl: '$space4',
};

export const nameCellCss: PicnicCss = {
  maxWidth: '100%',
};

export const bodyCellCss: PicnicCss = {
  fontSize: '$fontSize2',
  mb: '$space2',
};

export const subtitleCellCss: PicnicCss = {
  fontSize: '$fontSize2',
};
