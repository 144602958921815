/**
 * @generated SignedSource<<cbadc431a9fc34188b1e4d84d9d30b8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type SweepstakesSelectorType = "SWEEPSTAKES_SELECTOR_TYPE_FIRST" | "SWEEPSTAKES_SELECTOR_TYPE_LAST" | "SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES" | "SWEEPSTAKES_SELECTOR_TYPE_RANDOM" | "%future added value";
export type SweepstakesSubscriberType = "SWEEPSTAKES_SUBSCRIBER_TYPE_ANY" | "SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LoadCompanyPollsFragment_query$data = {
  readonly company: {
    readonly id?: string;
    readonly internalId?: number;
    readonly name?: string;
    readonly polls?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly closedMessage: string | null;
          readonly description: string | null;
          readonly duplicateVoteMessage: string | null;
          readonly hasWinnerFilter: boolean | null;
          readonly id: string;
          readonly isArchived: boolean | null;
          readonly name: string;
          readonly poll_keywords: {
            readonly pollKeywords: ReadonlyArray<{
              readonly altMatches: ReadonlyArray<string>;
              readonly deleted: boolean;
              readonly description: string;
              readonly id: string;
              readonly keyword: string;
            }>;
          } | null;
          readonly state: PollState;
          readonly submitMessage: string | null;
          readonly sweepstakesFilter: {
            readonly id: number;
            readonly numToSelect: number;
            readonly pollKeywords: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly keyword: string;
              };
            }>;
            readonly selectorType: SweepstakesSelectorType;
            readonly subscriberType: SweepstakesSubscriberType;
          } | null;
          readonly totalResponses: number | null;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | null;
  readonly " $fragmentType": "LoadCompanyPollsFragment_query";
};
export type LoadCompanyPollsFragment_query$key = {
  readonly " $data"?: LoadCompanyPollsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoadCompanyPollsFragment_query">;
};

import LoadCompanyPollsFragment_query_refetchable_graphql from './LoadCompanyPollsFragment_query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyword",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": LoadCompanyPollsFragment_query_refetchable_graphql
    }
  },
  "name": "LoadCompanyPollsFragment_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "fields": [
                    {
                      "kind": "Literal",
                      "name": "hideDisabled",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "page",
                      "value": 1
                    },
                    {
                      "kind": "Literal",
                      "name": "pageSize",
                      "value": 100
                    },
                    {
                      "kind": "Variable",
                      "name": "searchFilter",
                      "variableName": "search"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "paging"
                }
              ],
              "concreteType": "PollsConnection",
              "kind": "LinkedField",
              "name": "polls",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PollEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Poll",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "uuid",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GetPollKeywordsResponse",
                          "kind": "LinkedField",
                          "name": "poll_keywords",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PollKeyword",
                              "kind": "LinkedField",
                              "name": "pollKeywords",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "altMatches",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "deleted",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "state",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalResponses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "closedMessage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "duplicateVoteMessage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "submitMessage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isArchived",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasWinnerFilter",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GetSweepstakesFilterResponse",
                          "kind": "LinkedField",
                          "name": "sweepstakesFilter",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "numToSelect",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PollOptionEdge",
                              "kind": "LinkedField",
                              "name": "pollKeywords",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PollOption",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "selectorType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "subscriberType",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "991531fce87babc2c1b72efda93b1ab9";

export default node;
