import React from 'react';

import { Box, MenuItemValueType, Select, Text } from '@attentive/picnic';
import {
  lastXFilterContainerStyles,
  lastXFilterLabelStyles,
  lastXFilterSelectStyles,
} from './styles';

const LastXFilters: React.FunctionComponent<{
  lastXRuns: number;
  setLastXRuns: (val: number) => void;
}> = ({ lastXRuns, setLastXRuns }) => {
  return (
    <Box css={lastXFilterContainerStyles}>
      <Text css={lastXFilterLabelStyles}>View last </Text>
      <Select
        css={lastXFilterSelectStyles}
        value={lastXRuns}
        onChange={(v: MenuItemValueType) => setLastXRuns(parseFloat(v.toString()))}
      >
        <Select.Item value={5}>5 runs</Select.Item>
        <Select.Item value={10}>10 runs</Select.Item>
        <Select.Item value={15}>15 runs</Select.Item>
      </Select>
    </Box>
  );
};

export default LastXFilters;
