import React, { useState } from 'react';
import { Box, DropdownMenu, IconButton } from '@attentive/picnic';
import { RunJobNowChild } from '../TabPanels/Advance/RunJobNow/RunJobNowChild';
import CloneJobDialog from '../CloneJobDialog';
import EditJobDialog from '../EditJobDialog';
import DeleteDialog from '../DeleteJobDialog';

export const EventDestinationKebabMenu: React.FC<{
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
  taskId: string;
  taskName: string;
  taskDescription: string;
  refetchPage: () => void;
}> = ({ setErrMsg, taskId, taskName, taskDescription, refetchPage }) => {
  const [runNowIsOpen, setRunNowIsOpen] = useState(false);
  const [cloneIsOpen, setCloneIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);

  return (
    <Box>
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <IconButton
            iconName="DotsVertical"
            iconColor="default"
            description="EDS menu"
            size="small"
            data-disable-focus
            data-segmentation-id="action-menu-toggle"
            data-testid="action-menu-toggle"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end">
          <DropdownMenu.Item
            onClick={() => {
              setRunNowIsOpen(true);
            }}
          >
            Run Now
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={() => {
              setEditIsOpen(true);
            }}
          >
            Edit
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={() => {
              setDeleteIsOpen(true);
            }}
          >
            Delete
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={() => {
              setCloneIsOpen(true);
            }}
          >
            Clone
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
      {runNowIsOpen && (
        <RunJobNowChild
          isOpen={runNowIsOpen}
          taskId={taskId}
          setIsOpen={setRunNowIsOpen}
          setErrMsg={setErrMsg}
        />
      )}
      {cloneIsOpen && (
        <CloneJobDialog
          isOpen={cloneIsOpen}
          taskId={taskId}
          setIsOpen={setCloneIsOpen}
          setErrMsg={setErrMsg}
          taskName={taskName}
        />
      )}
      {editIsOpen && (
        <EditJobDialog
          isOpen={editIsOpen}
          taskId={taskId}
          setIsOpen={setEditIsOpen}
          setErrMsg={setErrMsg}
          taskName={taskName}
          taskDescription={taskDescription}
        />
      )}
      {deleteIsOpen && (
        <DeleteDialog
          isOpen={deleteIsOpen}
          taskId={taskId}
          setIsOpen={setDeleteIsOpen}
          setErrMsg={setErrMsg}
          taskName={taskName}
          refetchPage={refetchPage}
        />
      )}
    </Box>
  );
};
