export interface ServiceCloudSetupConfig {
  standardObject?: StandardObject;
  customExternalIdTemplate?: string;
  sandboxDomain?: string;
  fieldMappings: ServiceCloudFieldMapping[];
  customAttributeMappings: CustomAttributeMapping[];
}

export interface CustomAttributeMapping {
  customAttributeId?: string;
  objectField: SalesforceObjectAttributeField;
}

export enum SalesforceObjectAttributeField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
}

export interface ServiceCloudSetupConfigValidation {
  standardObject: boolean;
  customExternalIdTemplate: boolean;
  sandboxDomain: boolean;
}

export interface AuthLinkRedirectDto {
  authRedirectUrl: string;
}

export interface AuthLinkRedirectError {
  message: string;
}

export interface ServiceCloudFieldMapping {
  fieldApiName: string;
  fieldValue: string;
}

export enum StandardObject {
  CONTACT = 'Contact',
  PERSON_ACCOUNT = 'Person Account',
  LEAD = 'Lead',
}

export type ValueOf<T> = T[keyof T];

export interface CustomPropertyMapping {
  customAttributeId?: string;
  displayName: string;
  attributeField: SalesforceObjectAttributeField;
}
