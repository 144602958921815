/**
 * @generated SignedSource<<9598f847b8e67630e780a3f63ef61546>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertPollKeywordInput = {
  companyId: string;
  pollId: string;
  pollKeywords?: ReadonlyArray<PollKeywordInput> | null;
};
export type PollKeywordInput = {
  altMatches?: ReadonlyArray<string> | null;
  deleted: boolean;
  description: string;
  keyword: string;
  keywordId?: string | null;
};
export type EditKeywordDialogUpsertKeywordTacticalMfeMutation$variables = {
  input: UpsertPollKeywordInput;
};
export type EditKeywordDialogUpsertKeywordTacticalMfeMutation$data = {
  readonly upsertPollKeyword: {
    readonly pollKeywords: ReadonlyArray<{
      readonly altMatches: ReadonlyArray<string>;
      readonly description: string;
      readonly id: string;
      readonly keyword: string;
    }>;
    readonly success: boolean;
  } | null;
};
export type EditKeywordDialogUpsertKeywordTacticalMfeMutation = {
  response: EditKeywordDialogUpsertKeywordTacticalMfeMutation$data;
  variables: EditKeywordDialogUpsertKeywordTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertPollKeywordPayload",
    "kind": "LinkedField",
    "name": "upsertPollKeyword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PollKeyword",
        "kind": "LinkedField",
        "name": "pollKeywords",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keyword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "altMatches",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditKeywordDialogUpsertKeywordTacticalMfeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditKeywordDialogUpsertKeywordTacticalMfeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3d4f76537b3698a123ff6e4c1eaa688a",
    "id": null,
    "metadata": {},
    "name": "EditKeywordDialogUpsertKeywordTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation EditKeywordDialogUpsertKeywordTacticalMfeMutation(\n  $input: UpsertPollKeywordInput!\n) {\n  upsertPollKeyword(input: $input) {\n    success\n    pollKeywords {\n      id\n      keyword\n      description\n      altMatches\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "538215b22f57e1748e6cbb8ce7f0e2d8";

export default node;
