/**
 * @generated SignedSource<<1fb8adcb6db832325a5635c25a953952>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type SweepstakesSelectorType = "SWEEPSTAKES_SELECTOR_TYPE_FIRST" | "SWEEPSTAKES_SELECTOR_TYPE_LAST" | "SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES" | "SWEEPSTAKES_SELECTOR_TYPE_RANDOM" | "%future added value";
export type SweepstakesSubscriberType = "SWEEPSTAKES_SUBSCRIBER_TYPE_ANY" | "SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING" | "%future added value";
export type LoadCompanyPollsTacticalMfeQuery$variables = {
  companyId: string;
  search?: string | null;
};
export type LoadCompanyPollsTacticalMfeQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoadCompanyPollsFragment_query">;
};
export type LoadCompanyPollsTacticalMfeQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly internalId: number;
    readonly name: string;
    readonly polls: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly closedMessage: string | null;
          readonly description: string | null;
          readonly duplicateVoteMessage: string | null;
          readonly hasWinnerFilter: boolean | null;
          readonly id: string;
          readonly isArchived: boolean | null;
          readonly name: string;
          readonly poll_keywords: {
            readonly pollKeywords: ReadonlyArray<{
              readonly altMatches: ReadonlyArray<string>;
              readonly deleted: boolean;
              readonly description: string;
              readonly id: string;
              readonly keyword: string;
            }>;
          } | null;
          readonly state: PollState;
          readonly submitMessage: string | null;
          readonly sweepstakesFilter: {
            readonly id: number;
            readonly numToSelect: number;
            readonly pollKeywords: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly keyword: string;
              };
            }>;
            readonly selectorType: SweepstakesSelectorType;
            readonly subscriberType: SweepstakesSubscriberType;
          } | null;
          readonly totalResponses: number | null;
          readonly uuid: string;
        };
      }>;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type LoadCompanyPollsTacticalMfeQuery = {
  rawResponse: LoadCompanyPollsTacticalMfeQuery$rawResponse;
  response: LoadCompanyPollsTacticalMfeQuery$data;
  variables: LoadCompanyPollsTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyword",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadCompanyPollsTacticalMfeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoadCompanyPollsFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadCompanyPollsTacticalMfeQuery",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "hideDisabled",
                        "value": true
                      },
                      {
                        "kind": "Literal",
                        "name": "page",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "pageSize",
                        "value": 100
                      },
                      {
                        "kind": "Variable",
                        "name": "searchFilter",
                        "variableName": "search"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "paging"
                  }
                ],
                "concreteType": "PollsConnection",
                "kind": "LinkedField",
                "name": "polls",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PollEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Poll",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uuid",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GetPollKeywordsResponse",
                            "kind": "LinkedField",
                            "name": "poll_keywords",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PollKeyword",
                                "kind": "LinkedField",
                                "name": "pollKeywords",
                                "plural": true,
                                "selections": [
                                  (v1/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "altMatches",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "deleted",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalResponses",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "closedMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duplicateVoteMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submitMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasWinnerFilter",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GetSweepstakesFilterResponse",
                            "kind": "LinkedField",
                            "name": "sweepstakesFilter",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numToSelect",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PollOptionEdge",
                                "kind": "LinkedField",
                                "name": "pollKeywords",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PollOption",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "selectorType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "subscriberType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa9dc1cfffe893f49661028728b01369",
    "id": null,
    "metadata": {},
    "name": "LoadCompanyPollsTacticalMfeQuery",
    "operationKind": "query",
    "text": "query LoadCompanyPollsTacticalMfeQuery(\n  $companyId: ID!\n  $search: String\n) {\n  ...LoadCompanyPollsFragment_query\n}\n\nfragment LoadCompanyPollsFragment_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      name\n      internalId\n      id\n      polls(paging: {page: 1, pageSize: 100, hideDisabled: true, searchFilter: $search}) {\n        edges {\n          node {\n            name\n            uuid\n            id\n            poll_keywords {\n              pollKeywords {\n                id\n                keyword\n                description\n                altMatches\n                deleted\n              }\n            }\n            state\n            description\n            totalResponses\n            closedMessage\n            duplicateVoteMessage\n            submitMessage\n            isArchived\n            hasWinnerFilter\n            sweepstakesFilter {\n              id\n              numToSelect\n              pollKeywords {\n                node {\n                  id\n                  keyword\n                }\n              }\n              selectorType\n              subscriberType\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9dee09b9c6f6abeddc5149211ae0f842";

export default node;
