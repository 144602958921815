/**
 * @generated SignedSource<<7f3368216b682845fcaa0581ab765c39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ParticipantsPollFragment_query_refetchable$variables = {
  pollId: string;
};
export type ParticipantsPollFragment_query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantsPollFragment_query">;
};
export type ParticipantsPollFragment_query_refetchable = {
  response: ParticipantsPollFragment_query_refetchable$data;
  variables: ParticipantsPollFragment_query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pollId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ParticipantsPollFragment_query_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ParticipantsPollFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ParticipantsPollFragment_query_refetchable",
    "selections": [
      {
        "alias": "poll",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "pollId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AudienceResponse",
                "kind": "LinkedField",
                "name": "audience",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GetAudienceParticipantsResponse",
                    "kind": "LinkedField",
                    "name": "participants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AudienceParticipant",
                        "kind": "LinkedField",
                        "name": "participants",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phone",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Poll",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03928b78543edf3dd20919a8ccdeca8c",
    "id": null,
    "metadata": {},
    "name": "ParticipantsPollFragment_query_refetchable",
    "operationKind": "query",
    "text": "query ParticipantsPollFragment_query_refetchable(\n  $pollId: ID!\n) {\n  ...ParticipantsPollFragment_query\n}\n\nfragment ParticipantsPollFragment_query on Query {\n  poll: node(id: $pollId) {\n    __typename\n    ... on Poll {\n      audience {\n        participants {\n          participants {\n            phone\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a95eb829257f448a8bafd7ce8432b6b9";

export default node;
