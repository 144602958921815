export enum RoutePaths {
  Home = '',
  Keywords = 'keywords',
  Create = 'keywords/create',
  Edit = 'keywords/:id/*',
  // Salesforce
  SalesforceServiceCloud = 'salesforce-service-cloud',
  SalesforceMarketingCloud = 'salesforce-marketing-cloud',
  // Custom actions
  CustomActionsAdminPage = 'custom-actions/admin',
  CustomActionsAdminDefault = 'custom-actions/*',
  // Event replay
  EventReplayPage = 'event-replay',
  EventReplayCreate = 'event-replay/create',
  EventReplayDetails = 'event-replay/:companyId/:id',
  // Privacy Request
  PrivacyRequests = 'privacy-requests',
  // Event Destinations
  EventDestinations = 'event-destinations',
  EventDestinationsCreate = 'event-destinations/create',
  EventDestinationsJobs = 'event-destinations/jobs',
  EventDestinationsJob = 'event-destinations/jobs/:id',
  EventDestinationsDestinations = 'event-destinations/destinations',
  EventDestinationsDestination = 'event-destinations/destinations/:uuid',
  EventDestinationsNodes = 'event-destinations/nodes',
  EventDestinationsNode = 'event-destinations/nodes/:uuid',
  // Poll Surveys
  PollCreateNew = 'polls/new/submission',
  PollSurveys = 'polls',
  PollSurveyNew = 'polls/new',
  PollSurveyDetails = 'polls/:id',
  PollSurveyKeywordEdit = 'polls/:id/keywords/edit',
  // Client Views
  ClientEdsPage = 'client/event-destinations',
  ClientEdsJobPage = 'client/event-destinations/:jobId',
  // Custom Company Keywords
  CustomCompanyKeywordList = 'custom-company-keywords',
  CustomCompanyKeywordId = 'custom-company-keywords/:id',
  CustomCompanyKeywordBulkUpload = 'custom-company-keywords/bulk-upload',
  // Mobile Wallet
  MobileWallet = 'mobile-wallet',
}

export enum Paths {
  Home = '/tactical',
  Keywords = '/tactical/keywords',
  Create = '/tactical/keywords/create',
  Edit = '/tactical/keywords/:id',
  // Salesforce
  SalesforceServiceCloud = '/tactical/salesforce-service-cloud',
  SalesforceMarketingCloud = '/tactical/salesforce-marketing-cloud',
  // Custom actions
  CustomActionsAdminPage = '/tactical/custom-actions/admin',
  CustomActionsAdminDefault = '/tactical/custom-actions',
  // Event replay
  EventReplayPage = '/tactical/event-replay',
  EventReplayCreate = '/tactical/event-replay/create',
  EventReplayDetails = '/tactical/event-replay/:companyId/:id',
  // Privacy Request
  PrivacyRequests = '/tactical/privacy-requests',
  // Event Destinations
  EventDestinations = '/tactical/event-destinations',
  EventDestinationsCreate = '/tactical/event-destinations/create',
  EventDestinationsJobs = '/tactical/event-destinations/jobs',
  EventDestinationsJob = '/tactical/event-destinations/jobs/:id',
  EventDestinationsDestinations = '/tactical/event-destinations/destinations',
  EventDestinationsDestination = '/tactical/event-destinations/destinations/:uuid',
  EventDestinationsNodes = '/tactical/event-destinations/nodes',
  EventDestinationsNode = '/tactical/event-destinations/nodes/:uuid',
  // Poll Surveys
  PollCreateNew = '/tactical/polls/new/submission',
  PollSurveys = '/tactical/polls',
  PollSurveyNew = '/tactical/polls/new',
  PollSurveyDetails = '/tactical/polls/:id',
  PollSurveyKeywordEdit = '/tactical/polls/:id/keywords/edit',
  // Client Views
  ClientEdsPage = '/tactical/client/event-destinations',
  ClientEdsJobPage = '/tactical/client/event-destinations/:jobId',
  // Custom Company Keywords
  CustomCompanyKeywords = '/tactical/custom-company-keywords',
  CustomCompanyKeywordBulkUpload = '/tactical/custom-company-keywords/bulk-upload',
  // Mobile Wallet
  MobileWallet = '/tactical/mobile-wallet',
}
