import React from 'react';
import { Table } from '@attentive/picnic';
import { PollOptionType } from '../../../../constants/PollSurveys/pollTypes';
import KeywordsListRow from './KeywordsListRow';

interface KeywordsListProps {
  pollOptions: PollOptionType[];
  pollId: string;
}

const KeywordsList: React.FunctionComponent<KeywordsListProps> = ({ pollOptions, pollId }) => {
  return (
    <Table columnSizes={['175px', 'auto', 'auto', '25px']} css={{ mb: '$space6' }}>
      <Table.HeaderRow>
        <Table.HeaderCell>Keyword</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Alternative Matches</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.HeaderRow>
      {pollOptions.map((option) =>
        !option.deleted ? (
          <KeywordsListRow pollId={pollId} key={option.keyword} option={option} />
        ) : null
      )}
    </Table>
  );
};

export default KeywordsList;
