import React from 'react';
import { Box, Heading, Text } from '@attentive/picnic';
import TacticalBreadcrumbs, {
  EVENT_DESTINATION_BREADCRUMB,
  EVENT_DESTINATION_JOBS_BREADCRUMB,
} from '../../../../components/common/TacticalBreadcrumbs';

const Title: React.FunctionComponent = () => {
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', flex: 0.5 }}>
      <Heading css={{ mb: '$space2' }}>Jobs</Heading>
      <Text css={{ fontSize: '$fontSize3' }}>Add or manage jobs for your Destination.</Text>
    </Box>
  );
};

const Header: React.FunctionComponent = () => {
  return (
    <Box>
      <TacticalBreadcrumbs
        breadcrumbs={[EVENT_DESTINATION_BREADCRUMB, EVENT_DESTINATION_JOBS_BREADCRUMB]}
      />
      <Box as="section" css={{ display: 'flex', mb: '$space10' }}>
        <Title />
      </Box>
    </Box>
  );
};

export default Header;
