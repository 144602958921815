import React from 'react';
import { Grid, Box, Text } from '@attentive/picnic';

const GridKeyValue: React.FunctionComponent<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <Grid.Cell>
      <Box css={{ textAlign: 'center' }}>
        <Text variant="lede">{value}</Text>
        <Text variant="micro">{label}</Text>
      </Box>
    </Grid.Cell>
  );
};

export default GridKeyValue;
