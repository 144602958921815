import React from 'react';
import { Box, Heading } from '@attentive/picnic';

import EventReplayCard from '../../components/HomePage/EventReplayCard';
import T2BCard from '../../components/HomePage/T2BCard';
import SFMCCard from '../../components/HomePage/SFMCCard';
import SFSCCard from '../../components/HomePage/SFSCCard';
import EventDestinationsCard from '../../components/HomePage/EventDestinationsCard';
import PollSurveyCard from '../../components/HomePage/PollSurveyCard';
import WithViewerRoles, { useViewerRoles } from '../../components/common/WithViewerRoles';
import CustomCompanyKeywordsCard from '../../components/HomePage/CustomCompanyKeywordsCard';
import { MobileWalletCard } from '../../components/HomePage/MobileWalletCard';

const TacticalHomePage = () => {
  const roles = useViewerRoles();
  return (
    <Box css={{ display: 'flex', flexDirections: 'row', flexWrap: 'wrap', mt: '$space4' }}>
      <SFMCCard viewerRoles={roles} />
      <SFSCCard viewerRoles={roles} />
      <T2BCard viewerRoles={roles} />
      <EventReplayCard viewerRoles={roles} />
      <EventDestinationsCard viewerRoles={roles} />
      <PollSurveyCard viewerRoles={roles} />
      <CustomCompanyKeywordsCard viewerRoles={roles} />
      <MobileWalletCard viewerRoles={roles} />
    </Box>
  );
};

const TacticalHomePageContainer: React.FunctionComponent = () => {
  return (
    <Box>
      <Heading>Tactical</Heading>
      <WithViewerRoles>
        <TacticalHomePage />
      </WithViewerRoles>
    </Box>
  );
};

export default TacticalHomePageContainer;
