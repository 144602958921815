/**
 * @generated SignedSource<<00484dc529d08fbebee5c96e046cd91f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PollsConnectedTableQuery_query$data = {
  readonly company: {
    readonly id?: string;
    readonly internalId?: number;
    readonly name?: string;
    readonly pollsV2?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly closedMessage: string | null;
          readonly description: string | null;
          readonly duplicateVoteMessage: string | null;
          readonly id: string;
          readonly isArchived: boolean | null;
          readonly name: string;
          readonly poll_keywords: {
            readonly pollKeywords: ReadonlyArray<{
              readonly altMatches: ReadonlyArray<string>;
              readonly deleted: boolean;
              readonly description: string;
              readonly id: string;
              readonly keyword: string;
            }>;
          } | null;
          readonly state: PollState;
          readonly submitMessage: string | null;
          readonly totalResponses: number | null;
          readonly updatedAt: SerializedDateTime;
          readonly uuid: string;
        };
      }>;
      readonly totalCount: number | null;
    } | null;
  } | null;
  readonly " $fragmentType": "PollsConnectedTableQuery_query";
};
export type PollsConnectedTableQuery_query$key = {
  readonly " $data"?: PollsConnectedTableQuery_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"PollsConnectedTableQuery_query">;
};

import PollsConnectedTableQuery_query_refetchable_graphql from './PollsConnectedTableQuery_query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "company",
  "pollsV2"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "sorting"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": PollsConnectedTableQuery_query_refetchable_graphql
    }
  },
  "name": "PollsConnectedTableQuery_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": "pollsV2",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filter",
                  "variableName": "filter"
                },
                {
                  "kind": "Variable",
                  "name": "sorting",
                  "variableName": "sorting"
                }
              ],
              "concreteType": "PollsConnection",
              "kind": "LinkedField",
              "name": "__PollsConnectedTableQuery_query_pollsV2_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PollEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Poll",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "uuid",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GetPollKeywordsResponse",
                          "kind": "LinkedField",
                          "name": "poll_keywords",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PollKeyword",
                              "kind": "LinkedField",
                              "name": "pollKeywords",
                              "plural": true,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "keyword",
                                  "storageKey": null
                                },
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "altMatches",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "deleted",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "state",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalResponses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "closedMessage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "duplicateVoteMessage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "submitMessage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isArchived",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "updatedAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a5ea0c3f5e4fce88e506eb29ae9e48df";

export default node;
