// Rollup Counts
export const rollupContainerStyles = { display: 'flex', justifyContent: 'space-around' };
export const rollupCountStyles = { textAlign: 'center' };

// Status Code Bar Chart
const CHART_HEIGHT = 400;
const MIN_CHART_WIDTH = 1150;
export const statusCodeBarChartContainerStyles = {
  padding: '$space4',
  mt: '$space6',
  display: 'flex',
  justifyContent: 'center',
};
export const statusCodeBarChartStyles = {
  height: CHART_HEIGHT,
  width: '100%',
  minWidth: MIN_CHART_WIDTH,
};

// Last X Filter
export const lastXFilterContainerStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  verticalAlign: 'center',
  mb: '$space4',
};
export const lastXFilterLabelStyles = { padding: '$space3' };
export const lastXFilterSelectStyles = { width: '140px' };
