import React from 'react';
import { Box, FormField, Heading, Text, TextArea } from '@attentive/picnic';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

interface AutoRespondersProps {
  poll: PollType;
  updateRequestField: (field: string, value: string) => void;
}

const AutoResponders: React.FunctionComponent<AutoRespondersProps> = ({
  poll,
  updateRequestField,
}) => {
  const [submitMessage, setSubmitMessage] = React.useState<string>(poll.submitMessage || '');
  const [closedMessage, setClosedMessage] = React.useState<string>(poll.closedMessage || '');
  const [dupliateMessage, setDuplicateMessage] = React.useState<string>(
    poll.duplicateVoteMessage || ''
  );

  const handleSubmitMessageChange = (value: string) => {
    setSubmitMessage(value);
    updateRequestField('submitMessage', value);
  };

  const handleClosedMessageChange = (value: string) => {
    setClosedMessage(value);
    updateRequestField('closedMessage', value);
  };

  const handleDuplicateMessageChange = (value: string) => {
    setDuplicateMessage(value);
    updateRequestField('duplicateVoteMessage', value);
  };

  return (
    <Box css={{ mt: '$space2' }}>
      <Heading css={{ mb: '$space4' }} variant="lg">
        Auto Responders
      </Heading>
      <Text css={{ mb: '$space8' }}>
        Reply to you participants in different ways based on poll state, opt status, and
        participation
      </Text>
      <FormField.Label>On Sumbit when Open</FormField.Label>
      <FormField.HelperText>
        Reply sent in response to particpants while poll is open
      </FormField.HelperText>
      <TextArea
        css={{ mt: '$space2', mb: '$space6' }}
        value={submitMessage}
        onChange={(e) => handleSubmitMessageChange(e.target.value)}
      />
      <FormField.Label>On Submit when Closed</FormField.Label>
      <FormField.HelperText>
        Reply sent in response to particpants while poll is closed
      </FormField.HelperText>
      <TextArea
        css={{ mt: '$space2', mb: '$space6' }}
        value={closedMessage}
        onChange={(e) => handleClosedMessageChange(e.target.value)}
      />
      <FormField.Label>Mutli-vote disabled</FormField.Label>
      <FormField.HelperText>
        Reply sent in response to particpants who sent in more than one vote while multi-vote is
        disabled
      </FormField.HelperText>
      <TextArea
        css={{ mt: '$space2', mb: '$space6' }}
        value={dupliateMessage}
        onChange={(e) => handleDuplicateMessageChange(e.target.value)}
      />
    </Box>
  );
};

export default AutoResponders;
