import React from 'react';
import {
  PollState,
  UpdatePollV2Input,
} from '../../../../pages/PollSurveys/graphql/__generated__/EditPollMutation.graphql';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import AutoResponders from './AutoResponders';
import ManualResponders from './ManualResponders';
import { Box, Heading, Separator } from '@attentive/picnic';
import { SaveBar } from '../SaveBar';

export const MessagingTab: React.FC<{
  poll: PollType;
  request: UpdatePollV2Input;
  setRequest: React.Dispatch<React.SetStateAction<UpdatePollV2Input>>;
  handleSave: () => void;
}> = ({ poll, request, setRequest, handleSave }) => {
  const updateRequestField = (field: string, value: string | PollState | number | boolean) => {
    const updatedRequest = Object.assign(request);
    updatedRequest[field as keyof UpdatePollV2Input] = value;
    setRequest(updatedRequest);
  };

  return (
    <>
      <Box>
        <Heading css={{ mb: '$space8' }}>Messaging</Heading>
        <AutoResponders poll={poll} updateRequestField={updateRequestField} />
        <Separator />
        <ManualResponders poll={poll} updateRequestField={updateRequestField} />
      </Box>
      <SaveBar handleSave={handleSave} />
    </>
  );
};
