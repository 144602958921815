/**
 * @generated SignedSource<<a0c2f4846c94f1b0c420bda3e5861bce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PollState = "CLOSED" | "OFF" | "OPEN" | "%future added value";
export type UpdatePollV2Input = {
  allowAltSignupUnit?: boolean | null;
  allowBreakTies?: boolean | null;
  allowMarketingSubmitMessage?: boolean | null;
  allowMultiVote?: boolean | null;
  altSignupUnitId?: number | null;
  applicationId?: string | null;
  closedMessage?: string | null;
  companyId: string;
  decimalPrecision?: number | null;
  description?: string | null;
  duplicateVoteMessage?: string | null;
  enableParticipantAttributeName?: boolean | null;
  marketingSubmitMessage?: string | null;
  name?: string | null;
  onClosedMessage?: string | null;
  participantAttributeName?: string | null;
  pollId: string;
  state?: PollState | null;
  submitMessage?: string | null;
  subscribedEventType?: string | null;
};
export type SubmitMessageEditPollTacticalMfeMutation$variables = {
  input: UpdatePollV2Input;
};
export type SubmitMessageEditPollTacticalMfeMutation$data = {
  readonly updatePollV2: {
    readonly error: string | null;
    readonly poll: {
      readonly name: string;
      readonly state: PollState;
      readonly uuid: string;
    } | null;
  } | null;
};
export type SubmitMessageEditPollTacticalMfeMutation = {
  response: SubmitMessageEditPollTacticalMfeMutation$data;
  variables: SubmitMessageEditPollTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitMessageEditPollTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePollV2Payload",
        "kind": "LinkedField",
        "name": "updatePollV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitMessageEditPollTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePollV2Payload",
        "kind": "LinkedField",
        "name": "updatePollV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Poll",
            "kind": "LinkedField",
            "name": "poll",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d45b96a6531a89180f2b63f01e599454",
    "id": null,
    "metadata": {},
    "name": "SubmitMessageEditPollTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitMessageEditPollTacticalMfeMutation(\n  $input: UpdatePollV2Input!\n) {\n  updatePollV2(input: $input) {\n    poll {\n      name\n      uuid\n      state\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "79bd1215d55fb69bcec0871fd0f2c456";

export default node;
