import React from 'react';
import { PreloadedQuery, usePreloadedQuery, useRefetchableFragment } from 'react-relay';
import { Button } from '@attentive/picnic';

import Query, {
  ParticipantsT2VTacticalMfeQuery as QueryType,
} from './__generated__/ParticipantsT2VTacticalMfeQuery.graphql';
import Fragment, {
  ParticipantsPollFragment_query$key as FragmentType,
} from './__generated__/ParticipantsPollFragment_query.graphql';
import { ParticipantsPollFragment_query_refetchable as RefetchQuery } from './__generated__/ParticipantsPollFragment_query_refetchable.graphql';
import { PollType } from '../../../../../constants/PollSurveys/pollTypes';

export const DownloadCsv: React.FunctionComponent<{
  poll: PollType;
  queryRef: PreloadedQuery<QueryType>;
}> = ({ poll, queryRef }) => {
  const queryData = usePreloadedQuery<QueryType>(Query, queryRef);
  const [data, refetch] = useRefetchableFragment<RefetchQuery, FragmentType>(Fragment, queryData);

  const onClick = () => {
    refetch(
      { pollId: poll.id },
      {
        onComplete: () => {
          const csvRows = data.poll?.audience?.participants?.participants
            .map((p) => p.phone)
            .join('\n');
          const csvFile = new File([`phone\n${csvRows}`], 'file.csv', { type: 'text/csv' });

          const element = document.createElement('a');
          element.href = URL.createObjectURL(csvFile);
          element.download = `${poll.name}_participants.csv`;

          // simulate link click
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        },
      }
    );
  };

  return (
    <Button onClick={onClick} variant="primary">
      Download
    </Button>
  );
};
