/**
 * @generated SignedSource<<5b454ae4c3d597380ee4b50a9a0746b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FetchCompanyKeywordsFragment_query$data = {
  readonly customCompanyKeywords: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created: SerializedDateTime;
        readonly id: string;
        readonly keywordInfo: {
          readonly attributeName: string | null;
          readonly enabled: boolean;
          readonly hasSiblings: boolean | null;
          readonly keyword: string;
          readonly keywordOrdinal: number | null;
          readonly ruleCount: number | null;
        };
        readonly messageInfo: {
          readonly forceSend: boolean;
          readonly mediaUrl: string | null;
          readonly messageText: string;
          readonly shortenLink: boolean;
        };
        readonly updated: SerializedDateTime;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "FetchCompanyKeywordsFragment_query";
};
export type FetchCompanyKeywordsFragment_query$key = {
  readonly " $data"?: FetchCompanyKeywordsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"FetchCompanyKeywordsFragment_query">;
};

import FetchCompanyKeywordsFragment_query_refetchable_graphql from './FetchCompanyKeywordsFragment_query_refetchable.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "enabled"
    },
    {
      "kind": "RootArgument",
      "name": "offset"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sortBy"
    },
    {
      "kind": "RootArgument",
      "name": "sortDirection"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": FetchCompanyKeywordsFragment_query_refetchable_graphql
    }
  },
  "name": "FetchCompanyKeywordsFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "offset"
        },
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "enabled",
              "variableName": "enabled"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "pageSize"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "direction",
              "variableName": "sortDirection"
            },
            {
              "kind": "Variable",
              "name": "sortBy",
              "variableName": "sortBy"
            }
          ],
          "kind": "ObjectValue",
          "name": "sort"
        }
      ],
      "concreteType": "CustomCompanyKeywordsConnection",
      "kind": "LinkedField",
      "name": "customCompanyKeywords",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomCompanyKeywordEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomCompanyKeyword",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "KeywordInfo",
                  "kind": "LinkedField",
                  "name": "keywordInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "keyword",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "enabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "attributeName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "keywordOrdinal",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasSiblings",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ruleCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MessageInfo",
                  "kind": "LinkedField",
                  "name": "messageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "messageText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forceSend",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mediaUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "shortenLink",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "99f6d283353cddee4d8e22709c233783";

export default node;
