import React from 'react';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import { Box } from '@attentive/picnic';
import KeywordsList from './KeywordsList';
import FooterButtons from './FooterButtons';
import AddKeywordDialog from './AddKeywordDialog';
import { RefetchFnDynamic } from 'react-relay';
import { LoadPollFragment_query_refetchable as RefetchQuery } from '../../../../pages/PollSurveys/graphql/__generated__/LoadPollFragment_query_refetchable.graphql';
import { LoadPollFragment_query$key as FragmentType } from '../../../../pages/PollSurveys/graphql/__generated__/LoadPollFragment_query.graphql';

const KeywordsTab: React.FunctionComponent<{
  poll: PollType;
  refetch: RefetchFnDynamic<RefetchQuery, FragmentType>;
}> = ({ poll, refetch }) => {
  const [isAddOpen, setIsAddOpen] = React.useState<boolean>(false);

  const handleAddKeyword = () => {
    setIsAddOpen(true);
  };

  const handleAddKeywordComplete = () => {
    refetch({ pollId: poll.id }, { fetchPolicy: 'store-and-network' });
  };

  return (
    <Box>
      <KeywordsList pollId={poll.id} pollOptions={poll.keywordOptions} />
      <FooterButtons onAddKeyword={handleAddKeyword} />
      <AddKeywordDialog
        pollId={poll.id}
        isOpen={isAddOpen}
        onOpenChange={() => setIsAddOpen(!isAddOpen)}
        onComplete={handleAddKeywordComplete}
      />
    </Box>
  );
};

export default KeywordsTab;
