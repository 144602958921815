import React, { useState } from 'react';
import { Box, Button, Heading, Text } from '@attentive/picnic';
import CloneDialog from '../../../CloneJobDialog';

const Clone: React.FunctionComponent<{
  taskId: string;
  taskName: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
}> = ({ taskId, taskName, setErrMsg }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Heading as="h2" variant="lg" css={{ mb: '$space6' }}>
        Clone Job
      </Heading>
      <Text css={{ mb: '$space4' }}>Clone this job to a different, or the same, company.</Text>
      <Box>
        <Button onClick={() => setIsOpen(true)}>Clone</Button>
      </Box>
      {isOpen && (
        <CloneDialog
          taskId={taskId}
          setErrMsg={setErrMsg}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          taskName={taskName}
        />
      )}
    </>
  );
};

export default Clone;
