/**
 * @generated SignedSource<<c945156d451e7b0d7fd95a203ae7a276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnableCustomCompanyKeywordInput = {
  companyId: string;
  id: string;
  shouldEnable: boolean;
};
export type MoreOptionsDropdownEnabledUpdateTacticalMfeMutation$variables = {
  input: EnableCustomCompanyKeywordInput;
};
export type MoreOptionsDropdownEnabledUpdateTacticalMfeMutation$data = {
  readonly enableCustomCompanyKeyword: {
    readonly keyword?: {
      readonly id: string;
      readonly keywordInfo: {
        readonly enabled: boolean;
      };
    };
  } | null;
};
export type MoreOptionsDropdownEnabledUpdateTacticalMfeMutation = {
  response: MoreOptionsDropdownEnabledUpdateTacticalMfeMutation$data;
  variables: MoreOptionsDropdownEnabledUpdateTacticalMfeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomCompanyKeyword",
      "kind": "LinkedField",
      "name": "keyword",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "KeywordInfo",
          "kind": "LinkedField",
          "name": "keywordInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EnableCustomCompanyKeywordSuccess",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MoreOptionsDropdownEnabledUpdateTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "enableCustomCompanyKeyword",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MoreOptionsDropdownEnabledUpdateTacticalMfeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "enableCustomCompanyKeyword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ad66b532778972a20b31069b8d41c12",
    "id": null,
    "metadata": {},
    "name": "MoreOptionsDropdownEnabledUpdateTacticalMfeMutation",
    "operationKind": "mutation",
    "text": "mutation MoreOptionsDropdownEnabledUpdateTacticalMfeMutation(\n  $input: EnableCustomCompanyKeywordInput!\n) {\n  enableCustomCompanyKeyword(input: $input) {\n    __typename\n    ... on EnableCustomCompanyKeywordSuccess {\n      keyword {\n        id\n        keywordInfo {\n          enabled\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "730c37b3b54f57de069964adeddff3a4";

export default node;
