import React from 'react';
import { Box, Heading, Text, Button } from '@attentive/picnic';
import ClonePollDialog from './ClonePollDialog';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

const ClonePoll: React.FunctionComponent<{
  poll: PollType;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}> = ({ poll, isOpen, setIsOpen }) => {
  return (
    <Box css={{ mt: '$space10', mb: '$space10' }}>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: '$space4' }}>
        <Heading css={{ mb: '$space2' }} variant="lg">
          Clone Poll
        </Heading>
        <Text>
          Cloning the poll will allow users to create a poll with the same settings but under a new
          name.
        </Text>
        <Box>
          <Button onClick={() => setIsOpen(true)}>Clone</Button>
        </Box>
      </Box>
      <ClonePollDialog poll={poll} isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
};

export default ClonePoll;
