import _VendorCompanyCardConnectedTableQuery_query from "./__generated__/VendorCompanyCardConnectedTableQuery_query.graphql";
import _VendorCompanyCardConnectedTableQuery from "./__generated__/VendorCompanyCardConnectedTableQuery.graphql";
import { graphql } from '@attentive/data';
import { createConnectedTable } from '@attentive/yogi';
import Query from './__generated__/VendorCompanyCardConnectedTableQuery.graphql';
import Fragment from './__generated__/VendorCompanyCardConnectedTableQuery_query.graphql';
_VendorCompanyCardConnectedTableQuery;
_VendorCompanyCardConnectedTableQuery_query;
export const [ConnectedTable, usePaginationData] = createConnectedTable({
  rootQuery: Query,
  refetchableFragment: Fragment
});