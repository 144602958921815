import React from 'react';
import { Box, Button, Heading, Icon, Select, Text } from '@attentive/picnic';
import { CustomActionsActions } from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsActions';

import {
  CustomActionsFilter,
  CustomActionsFilterNone,
  CustomActionsState,
  CustomActionStatus,
} from '../../../constants/CustomActions/CustomActionDefinitions/customActionDefinitionsTypes';
import CustomActionItem from '../../../components/CustomActions/CustomActionDefinitionsList/CustomActionDefinitionItem';
import CustomActionsEmptyListComponent from '../../../components/CustomActions/CustomActionDefinitionsList/CustomActionDefinitionsEmptyList';
import reducer from './reducer';

const PU_KEYWORD_FILTER_MIN_WIDTH = '200px';

const initialState: CustomActionsState = {
  customActions: [],
  filteredCustomActions: [],
  loading: false,
  filter: CustomActionsFilterNone.VALUE,
};

const CustomActionFilterName = {
  [CustomActionsFilterNone.VALUE]: 'All Actions',
  [CustomActionStatus.ACTIVE]: 'Active',
  [CustomActionStatus.INACTIVE]: 'Inactive',
};

const CustomActionsAdminPage: React.FunctionComponent = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const sendWithDispatch = (action: CustomActionsActions, payload: object): void => {
    dispatch({
      type: action,
      payload: { ...payload, dispatch },
    });
  };

  React.useEffect(() => {
    sendWithDispatch(CustomActionsActions.FETCH_ALL_CUSTOM_ACTIONS_REQUEST, {});
  }, []);

  const CustomActionsAdminPageTitle = () => (
    <Box css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div>
        <Heading css={{ mb: '$space4' }}>Custom Actions Control Center</Heading>
        <Text>Add or edit Custom Actions</Text>
      </div>
      <div>
        {/*<Link to={Paths.CustomActionsAdminPageCreate}>*/}
        <Button variant="primary" css={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="PlusSign" size="small" css={{ mr: '$space2' }} />
          Add Action
        </Button>
        {/*</Link>*/}
      </div>
    </Box>
  );

  const CustomActionsAdminPageFilter = () => (
    <Box css={{ mt: '$space8' }}>
      <Select
        css={{ minWidth: PU_KEYWORD_FILTER_MIN_WIDTH, width: 'auto' }}
        size="small"
        value={state.filter}
        onChange={(newFilter: string) =>
          dispatch({ type: CustomActionsActions.FILTER_CUSTOM_ACTIONS, payload: newFilter })
        }
      >
        {Object.keys(CustomActionFilterName).map((filterName) => (
          <Select.Item key={filterName} value={filterName}>
            {CustomActionFilterName[filterName as CustomActionsFilter]}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );

  const CustomActionsAdminPageList = () => (
    <Box css={{ mt: '$space8' }}>
      {state.filteredCustomActions.length > 0 ? (
        state.filteredCustomActions.map((customAction) => (
          <CustomActionItem
            key={`customAction-${customAction.id}`}
            customAction={customAction}
            activate={() =>
              dispatch({
                type: CustomActionsActions.ENABLE_CUSTOM_ACTION_REQUEST,
                payload: customAction,
              })
            }
            deactivate={() =>
              dispatch({
                type: CustomActionsActions.DISABLE_CUSTOM_ACTION_REQUEST,
                payload: customAction,
              })
            }
          />
        ))
      ) : (
        <CustomActionsEmptyListComponent loading={state.loading} />
      )}
    </Box>
  );

  return (
    <Box css={{ width: '100%' }}>
      <CustomActionsAdminPageTitle />
      <CustomActionsAdminPageFilter />
      <CustomActionsAdminPageList />
    </Box>
  );
};

export default CustomActionsAdminPage;
