import React from 'react';

import { Box } from '@attentive/picnic';
import { BarChart, GroupMode } from '@attentive/charts';
import StatusCodeTooltip from './StatusCodeTooltip';
import { PerformanceBarDatum } from './types';
import { statusCodeBarChartContainerStyles, statusCodeBarChartStyles } from './styles';

const INDEX_NAME = 'date';
const CHART_KEYS = [
  'success',
  'subscriber_not_found',
  'invalid_request',
  'subscriber_inactive',
  'subscriber_optedout',
];
const ENABLE_LABEL = true;
const ENABLE_LEGEND = true;
const Y_AXIS_LABEL = 'requests';

const StatusCodeBarChart: React.FunctionComponent<{ data: PerformanceBarDatum[] }> = ({ data }) => {
  const groupMode = data.length < 2 ? 'grouped' : 'stacked';
  return (
    <Box css={statusCodeBarChartContainerStyles}>
      <BarChart
        data={data}
        keys={CHART_KEYS}
        indexBy={INDEX_NAME}
        css={statusCodeBarChartStyles}
        groupMode={groupMode as GroupMode}
        enableLabel={ENABLE_LABEL}
        yAxisLabel={Y_AXIS_LABEL}
        enableLegend={ENABLE_LEGEND}
        tooltip={StatusCodeTooltip}
      />
    </Box>
  );
};

export default StatusCodeBarChart;
