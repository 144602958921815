import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Text, Heading, IconButton, IconName } from '@attentive/picnic';

import { Paths } from '../../../../constants/routes';

interface ReviewNewJobProps {
  taskUuid: string;
}

const ReviewNewJob: React.FunctionComponent<ReviewNewJobProps> = ({ taskUuid }) => {
  const [icon, setIcon] = React.useState<IconName>('Copy');

  const handleCopyTaskUuid = () => {
    navigator.clipboard.writeText(taskUuid);
    setIcon('Checkmark');
  };

  return (
    <Box>
      <Heading>New job created!</Heading>
      <Box css={{ display: 'flex', justifyContent: 'flex-start', mt: '$space6' }}>
        <Text variant="lede" css={{ mt: '$space2' }}>
          UUID: {taskUuid}
        </Text>
        <IconButton
          iconColor="subdued"
          description="Click to copy taskUuid to your clipboard."
          onClick={handleCopyTaskUuid}
          iconName={icon}
          css={{ pb: '$space6' }}
        />
      </Box>
      <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button css={{ m: '$space2' }} variant="basic">
          <Link to={Paths.EventDestinations}>EDS Home</Link>
        </Button>
        <Button css={{ m: '$space2' }} variant="basic">
          <Link to={Paths.EventDestinationsJobs}>All Jobs</Link>
        </Button>
        <Button css={{ m: '$space2' }} variant="primary">
          <Link to={`/tactical/event-destinations/jobs/${taskUuid}`}>Go to job</Link>
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewNewJob;
