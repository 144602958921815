import { Box, Heading, Separator } from '@attentive/picnic';
import React from 'react';
import OpenJourneySection from './OpenJourneySection';
import ParticipantAttributeName from './ParticipantAttributeName';
import TriggerJourney from './TriggerJourney';

import { PollType } from '../../../../constants/PollSurveys/pollTypes';
import {
  PollState,
  UpdatePollV2Input,
} from '../../../../pages/PollSurveys/graphql/__generated__/EditPollMutation.graphql';
import { SaveBar } from '../SaveBar';

export const TriggersTab: React.FC<{
  poll: PollType;
  request: UpdatePollV2Input;
  setRequest: React.Dispatch<React.SetStateAction<UpdatePollV2Input>>;
  handleSave: () => void;
}> = ({ poll, request, setRequest, handleSave }) => {
  const updateRequestField = (field: string, value: string | PollState | number | boolean) => {
    const updatedRequest = Object.assign(request);
    updatedRequest[field as keyof UpdatePollV2Input] = value;
    setRequest(updatedRequest);
  };

  return (
    <Box>
      <Box
        css={{
          maxWidth: '600px',
          display: 'flex',
          flexDirection: 'column',
          gap: '$space8',
          position: 'relative',
          mb: '$space12',
        }}
      >
        <Heading>Triggers</Heading>
        <ParticipantAttributeName poll={poll} updateRequestField={updateRequestField} />
        <Separator />
        <OpenJourneySection poll={poll} updateRequestField={updateRequestField} />
        <Separator />
        <TriggerJourney poll={poll} updateRequestField={updateRequestField} />
      </Box>
      <SaveBar handleSave={handleSave} />
    </Box>
  );
};
