import React from 'react';

import { PollState } from '@attentive/data/__generated__/graphql-schema-types';
import { Banner, ContainedLabel, theme2021 } from '@attentive/picnic';
import { PollOptionType, PollType } from '../../../constants/PollSurveys/pollTypes';
import { parseState } from '../../../pages/PollSurveys/graphql/LoadCompanyPolls';
import PollMenuList from './PollMenuList';
import { ConnectedTable } from './PollsConnectedTable';
import { PollsConnectedTableQuery_query$data } from './__generated__/PollsConnectedTableQuery_query.graphql';

const parseVariant = (state: PollState) => {
  switch (state) {
    case PollState.Open:
      return 'success';
    case PollState.Closed:
      return 'warning';
    case PollState.Off:
    default:
      return 'neutral';
  }
};

const parseData = (data: PollsConnectedTableQuery_query$data): PollType[] | undefined => {
  return data.company?.pollsV2?.edges.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any): PollType => ({
      id: e.node.id,
      name: e.node.name,
      companyId: data.company?.id?.toString() || '',
      uuid: e.node.uuid,
      description: e.node.description || '',
      state: parseState(e.node.state),
      submitMessage: e.node.submitMessage || undefined,
      closedMessage: e.node.closedMessage || undefined,
      duplicateVoteMessage: e.node.duplicateVoteMessage || undefined,
      isArchived: e.node.isArchived || false,
      updatedAt: new Date(e.node.updatedAt.toString()),
      keywordOptions:
        e.node.poll_keywords?.pollKeywords.map((k: PollOptionType) => {
          return {
            id: k.id,
            keyword: k.keyword,
            altMatches: k.altMatches?.slice() || [],
            description: k.description,
            deleted: k.deleted,
          };
        }) || [],
    })
  );
};

interface PollRowProps {
  data: PollsConnectedTableQuery_query$data;
}

export const PollRow: React.FC<PollRowProps> = ({ data }) => {
  const polls: PollType[] | undefined = parseData(data);

  const openPollDetails = (id: string) => {
    window.open(`/tactical/polls/${id}`, '_blank');
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {polls?.length ? (
        polls.map((poll) => {
          const style = {
            color: poll.isArchived ? theme2021.colors.grayscale300 : theme2021.colors.textDefault,
          };
          return (
            <ConnectedTable.BodyFocusableRow key={poll.id} onClick={() => openPollDetails(poll.id)}>
              <ConnectedTable.BodyCell css={style}>{poll.name}</ConnectedTable.BodyCell>
              <ConnectedTable.BodyCell css={style}>{poll.description}</ConnectedTable.BodyCell>
              <ConnectedTable.BodyCell css={style}>
                <ContainedLabel variant={parseVariant(poll.state)}>{poll.state}</ContainedLabel>
              </ConnectedTable.BodyCell>
              <ConnectedTable.BodyCell css={style}>
                {poll.updatedAt?.toLocaleString()}
              </ConnectedTable.BodyCell>
              <ConnectedTable.BodyCell css={style}>
                <PollMenuList poll={poll} />
              </ConnectedTable.BodyCell>
            </ConnectedTable.BodyFocusableRow>
          );
        })
      ) : (
        <ConnectedTable.BodyRow>
          <Banner variant="guidance" css={{ gridColumn: '1/-1', mt: '$space2' }}>
            No Polls Found
          </Banner>
        </ConnectedTable.BodyRow>
      )}
    </>
  );
};
