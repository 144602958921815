import { JobRecord } from '../../../../../constants/EventDestinations/jobTypes';

export const parse = (json: string | undefined): string => {
  const src = json ? JSON.parse(json) : {};

  if (src.password) {
    src.password = '******************';
  }
  if (src.header_mapping) {
    const header = src.header_mapping;
    if (header.Authorization) {
      header.Authorization = 'Bearer ******************';
    }
  }

  return JSON.stringify(src, null, 4);
};

export const getSource = (job?: JobRecord): string => {
  if (!job) {
    return '';
  }
  if (job.sourceInfo?.includes('bucket_name')) {
    return 'S3';
  }
  if (job.sourceInfo?.includes('host')) {
    return 'SFTP';
  }
  return '';
};

export const getTarget = (job?: JobRecord): string => {
  if (!job) {
    return '';
  }
  if (job.targetInfo?.includes('http')) {
    return 'HTTP';
  }
  if (job.targetInfo?.includes('bucket_name')) {
    return 'S3';
  }
  return '';
};
