/**
 * @generated SignedSource<<0518e7e04543ab782ecf1ee659d087b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivacyRequestSortColumn = "PRIVACY_REQUEST_SORT_COLUMN_PROCESSING_DATETIME" | "PRIVACY_REQUEST_SORT_COLUMN_REQUEST_DATETIME" | "PRIVACY_REQUEST_SORT_COLUMN_UNKNOWN" | "%future added value";
export type PrivacyRequestStatus = "PRIVACY_REQUEST_STATUS_ALL" | "PRIVACY_REQUEST_STATUS_PROCESSED" | "PRIVACY_REQUEST_STATUS_UNKNOWN" | "PRIVACY_REQUEST_STATUS_UNPROCESSED" | "%future added value";
export type PrivacyRequestType = "ACCESS" | "DELETE" | "%future added value";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type PrivacyRequestFilterInput = {
  requestType: PrivacyRequestType;
  status: PrivacyRequestStatus;
};
export type PrivacyRequestSortInput = {
  sortColumn: PrivacyRequestSortColumn;
  sortDirection: SortDirection;
};
export type usePaginatedPrivacyRequests_CompanyFragment_refetchable$variables = {
  after?: string | null;
  filter?: PrivacyRequestFilterInput | null;
  first: number;
  id: string;
  sort?: PrivacyRequestSortInput | null;
};
export type usePaginatedPrivacyRequests_CompanyFragment_refetchable$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"usePaginatedPrivacyRequests_CompanyFragment">;
  } | null;
};
export type usePaginatedPrivacyRequests_CompanyFragment_refetchable = {
  response: usePaginatedPrivacyRequests_CompanyFragment_refetchable$data;
  variables: usePaginatedPrivacyRequests_CompanyFragment_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePaginatedPrivacyRequests_CompanyFragment_refetchable",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "FragmentSpread",
            "name": "usePaginatedPrivacyRequests_CompanyFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "usePaginatedPrivacyRequests_CompanyFragment_refetchable",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "PrivacyRequestConnection",
                "kind": "LinkedField",
                "name": "privacyRequests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrivacyRequestEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrivacyRequest",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subjectPhone",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subjectEmail",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requestMsg",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "processed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requestDateTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "processingEndDateTime",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "678e9af8baf5c61750f7b2192a78b220",
    "id": null,
    "metadata": {},
    "name": "usePaginatedPrivacyRequests_CompanyFragment_refetchable",
    "operationKind": "query",
    "text": "query usePaginatedPrivacyRequests_CompanyFragment_refetchable(\n  $after: String = null\n  $filter: PrivacyRequestFilterInput = null\n  $first: Int!\n  $sort: PrivacyRequestSortInput = null\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...usePaginatedPrivacyRequests_CompanyFragment_3JsJJ3\n    id\n  }\n}\n\nfragment PrivacyRequestRow_TacticalMfe_CompanyFragment on PrivacyRequest {\n  id\n  subjectPhone\n  subjectEmail\n  requestMsg\n  processed\n  requestDateTime\n  processingEndDateTime\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n\nfragment usePaginatedPrivacyRequests_CompanyFragment_3JsJJ3 on Company {\n  id\n  displayName\n  privacyRequests(first: $first, after: $after, sort: $sort, filter: $filter) {\n    pageInfo {\n      ...usePaginatedDataFragment_pageInfo\n    }\n    totalCount\n    edges {\n      node {\n        ...PrivacyRequestRow_TacticalMfe_CompanyFragment\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "380e25635189124b2823fe78106f0f42";

export default node;
