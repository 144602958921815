/**
 * @generated SignedSource<<7e1ef541aed9285763b46ce3e39a4091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileWalletSortingField = "MOBILE_WALLET_SORTING_FIELD_ID" | "MOBILE_WALLET_SORTING_FIELD_UPDATED" | "%future added value";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type MobileWalletSearchFilter = {
  search?: string | null;
};
export type MobileWalletSorting = {
  direction?: SortDirection | null;
  sortBy: MobileWalletSortingField;
};
export type VendorCompanyCardConnectedTableQuery_query_refetchable$variables = {
  after?: string | null;
  before?: string | null;
  companyId: string;
  filter?: MobileWalletSearchFilter | null;
  first?: number | null;
  last?: number | null;
  sorting?: MobileWalletSorting | null;
};
export type VendorCompanyCardConnectedTableQuery_query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"VendorCompanyCardConnectedTableQuery_query">;
};
export type VendorCompanyCardConnectedTableQuery_query_refetchable = {
  response: VendorCompanyCardConnectedTableQuery_query_refetchable$data;
  variables: VendorCompanyCardConnectedTableQuery_query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sorting"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "sorting",
    "variableName": "sorting"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VendorCompanyCardConnectedTableQuery_query_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "VendorCompanyCardConnectedTableQuery_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VendorCompanyCardConnectedTableQuery_query_refetchable",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "VendorCompanyCardConnection",
                "kind": "LinkedField",
                "name": "vendorCompanyCards",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VendorCompanyCardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VendorCompanyCard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uuid",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "vendorCampaignId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "vendorOfferId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updated",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "filter",
                  "sorting"
                ],
                "handle": "connection",
                "key": "VendorCompanyCardConnectedTableQuery_query_vendorCompanyCards",
                "kind": "LinkedHandle",
                "name": "vendorCompanyCards"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10ef88d85af0e00ca0f86fd019f089eb",
    "id": null,
    "metadata": {},
    "name": "VendorCompanyCardConnectedTableQuery_query_refetchable",
    "operationKind": "query",
    "text": "query VendorCompanyCardConnectedTableQuery_query_refetchable(\n  $after: String\n  $before: String\n  $companyId: ID!\n  $filter: MobileWalletSearchFilter\n  $first: Int\n  $last: Int\n  $sorting: MobileWalletSorting\n) {\n  ...VendorCompanyCardConnectedTableQuery_query\n}\n\nfragment VendorCompanyCardConnectedTableQuery_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      name\n      internalId\n      id\n      vendorCompanyCards(first: $first, after: $after, last: $last, before: $before, filter: $filter, sorting: $sorting) {\n        edges {\n          node {\n            id\n            uuid\n            vendorCampaignId\n            vendorOfferId\n            updated\n            __typename\n          }\n          cursor\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5b67f1b0a9ee2e8d1384e8a50f0b654";

export default node;
