import { format } from 'date-fns';
import {
  CodeCountKeyVal,
  EdsPerformanceStatusCodeReport,
  INITIAL_ROLLUP_COUNTS,
  PerformanceBarDatum,
  RollupCounts,
} from './types';

export const formatGroupDate = (date: string): string => {
  const d = new Date(date);
  return format(d, 'MM/dd hh:mm a');
};

export const parseCodeCounts = (codeCounts: readonly CodeCountKeyVal[]): RollupCounts => {
  const success = codeCounts.filter((c) => c.key === 'topic_status_code_200')[0]?.value;
  const rateLimited = codeCounts.filter((c) => c.key === 'topic_status_code_429')[0]?.value;
  const failure = codeCounts.filter((c) => c.key === 'topic_status_code_400')[0]?.value;
  const subNotFound = codeCounts.filter((c) => c.key === 'topic_status_code_404')[0]?.value;
  const subInactive = codeCounts.filter((c) => c.key === 'topic_status_code_403')[0]?.value;
  const subOptedOut = codeCounts.filter((c) => c.key === 'topic_status_code_405')[0]?.value;
  return {
    topic_status_code_200: success || 0,
    topic_status_code_400: failure || 0,
    topic_status_code_429: rateLimited || 0,
    topic_status_code_404: subNotFound || 0,
    topic_status_code_403: subInactive || 0,
    topic_status_code_405: subOptedOut || 0,
  };
};

export const getBarData = (reports?: EdsPerformanceStatusCodeReport[]): PerformanceBarDatum[] => {
  if (!reports) {
    return [];
  }

  return reports.map((r): PerformanceBarDatum => {
    const codes = parseCodeCounts(r.statusCodeCounts);
    return {
      date: formatGroupDate(r.start),
      success: codes.topic_status_code_200,
      subscriber_not_found: codes.topic_status_code_404,
      rate_limited: codes.topic_status_code_429,
      invalid_request: codes.topic_status_code_400,
      subscriber_inactive: codes.topic_status_code_403,
      subscriber_optedout: codes.topic_status_code_405,
    };
  });
};

export const parseRollupCounts = (reports: EdsPerformanceStatusCodeReport[]) => {
  const rollupCounts: RollupCounts = { ...INITIAL_ROLLUP_COUNTS };
  const allStatusCodeCounts = reports.flatMap((r) => r.statusCodeCounts);

  for (let i = 0; i < allStatusCodeCounts.length; i++) {
    const codeCounts = allStatusCodeCounts[i];
    rollupCounts[codeCounts.key as keyof RollupCounts] += codeCounts.value;
  }
  return rollupCounts;
};
