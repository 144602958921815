import React from 'react';
import { Link } from 'react-router-dom';
import { Text, Table, Stack, Heading } from '@attentive/picnic';

import EventReplayDetailsDialog from './EventReplayDetailsDialog';
import EventReplayRunDialog from './EventReplayRunDialog';
import EventReplayListItemMenu from './EventReplayListItemMenu';
import StatusPillLabel from './StatusPillLabel';
import {
  dateToStringShortTime,
  dateToStringDate,
  dateToStringPlusGracePeriod,
} from './utils/dateFormatter';
import { EventReplayRequest } from '../../constants/EventReplay/eventReplayTypes';

interface EventReplaySummaryProps {
  request: EventReplayRequest;
}

const EventReplayRow: React.FunctionComponent<EventReplaySummaryProps> = ({ request }) => {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showRunDialog, setShowRunDialog] = React.useState<boolean>(false);

  const title = request.title;
  const requestedDate = dateToStringDate(request?.created);
  const company = `${request.companyName} (${request.companyId})`;
  const eventRange = `${dateToStringShortTime(request.startDate)} - ${dateToStringShortTime(
    request.endDate
  )}`;
  const countDown = dateToStringPlusGracePeriod(request?.updated);
  return (
    <Table.BodyRow>
      <Table.BodyCell>
        <Stack>
          <Link to={`/tactical/event-replay/${request.companyId}/${request.id}`}>
            <Heading variant="md">{title}</Heading>
          </Link>
          <Text variant="micro" css={{ mt: '$space1' }}>
            Requested {requestedDate}
          </Text>
          {request.status === 'QUEUED' && (
            <Text color="critical" variant="micro" css={{ mt: '$space1' }}>
              Running at {countDown}
            </Text>
          )}
        </Stack>
      </Table.BodyCell>
      <Table.BodyCell>
        <StatusPillLabel status={request.status} />
      </Table.BodyCell>
      <Table.BodyCell>
        <Text>{company}</Text>
      </Table.BodyCell>
      <Table.BodyCell>
        <Text>{eventRange}</Text>
      </Table.BodyCell>
      <Table.BodyCell>
        <Text>{request.estimatedCountSum}</Text>
      </Table.BodyCell>
      <Table.BodyCell align="right" css={{ maxWidth: '$space2' }}>
        <EventReplayListItemMenu
          request={request}
          showRunDialog={() => setShowRunDialog(true)}
          showEventCountDialog={() => setShowDialog(true)}
        />
      </Table.BodyCell>
      <EventReplayDetailsDialog
        request={request}
        showDialog={showDialog}
        handleClose={() => setShowDialog(false)}
      />
      <EventReplayRunDialog
        showDialog={showRunDialog}
        replayId={request?.id}
        toggleShowDialog={() => setShowRunDialog(!showRunDialog)}
        eventCount={request.estimatedCountSum}
      />
    </Table.BodyRow>
  );
};

export default EventReplayRow;
