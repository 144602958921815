import React from 'react';

import {
  ProjectCardConfig,
  TacticalHomeProjectCardProps,
} from '../../constants/Home/tacticalHomeTypes';
import { Paths } from '../../constants/routes';
import ProjectCard from './ProjectCard';
import logo from './assets/digital-wallet.png';

const config: ProjectCardConfig = {
  defaultEnabled: false,
  allowedRoles: ['ROLE_ENGINEERING', 'ROLE_CS_TAM'],
  heading: 'Mobile Wallet',
  image: logo,
  path: Paths.MobileWallet,
};

export const MobileWalletCard: React.FunctionComponent<TacticalHomeProjectCardProps> = ({
  viewerRoles,
}) => {
  return <ProjectCard config={{ ...config, viewerRoles }} />;
};
