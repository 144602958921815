import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { TextToVotePoll } from '../../types';

export const initialPollData: TextToVotePoll = {
  pollName: '',
  pollDescription: '',
};

const pollFormDataAtom = atom<TextToVotePoll>(initialPollData);
const isPollFormValidAtom = atom<boolean>(false);

export const useInitPollFormData = (data: TextToVotePoll) => {
  useHydrateAtoms([[pollFormDataAtom, data]]);
};

export const usePollFormData = () => {
  return useAtomValue(pollFormDataAtom);
};

export const useUpdatePollFormData = () => {
  return useSetAtom(pollFormDataAtom);
};

export const useIsFormValid = () => {
  return useAtomValue(isPollFormValidAtom);
};

export const useSetIsFormValid = () => {
  return useSetAtom(isPollFormValidAtom);
};
