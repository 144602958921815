/**
 * @generated SignedSource<<6be4805c81918c468915ada08b740907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WithEventDestinationNodeJobsTacticalMfeQuery$variables = {
  nodeId?: string | null;
};
export type WithEventDestinationNodeJobsTacticalMfeQuery$data = {
  readonly eventDestinations: {
    readonly jobs: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly company: {
            readonly displayName: string;
            readonly internalId: number;
          } | null;
          readonly description: string;
          readonly id: string;
          readonly internalId: string;
          readonly jobType: string;
          readonly lastExecutedRun: {
            readonly context: any | null;
            readonly parentTaskId: string | null;
            readonly runDate: SerializedDateTime | null;
            readonly scheduledDate: SerializedDateTime;
            readonly status: string;
            readonly taskId: string;
            readonly uuid: string;
          } | null;
          readonly name: string;
          readonly nextRun: SerializedDateTime | null;
          readonly previousRun: SerializedDateTime | null;
          readonly sourceInfo: string | null;
          readonly targetInfo: string | null;
        };
      }>;
    } | null;
  } | null;
};
export type WithEventDestinationNodeJobsTacticalMfeQuery = {
  response: WithEventDestinationNodeJobsTacticalMfeQuery$data;
  variables: WithEventDestinationNodeJobsTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "nodeUuid",
    "variableName": "nodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previousRun",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextRun",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetInfo",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceInfo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "JobHistory",
  "kind": "LinkedField",
  "name": "lastExecutedRun",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTaskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "runDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "context",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WithEventDestinationNodeJobsTacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JobRecordConnection",
            "kind": "LinkedField",
            "name": "jobs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobRecordEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobRecord",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "company",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WithEventDestinationNodeJobsTacticalMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventDestinations",
        "kind": "LinkedField",
        "name": "eventDestinations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JobRecordConnection",
            "kind": "LinkedField",
            "name": "jobs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobRecordEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobRecord",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "company",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v9/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d5cf0431d1b470ae800860a805248c5",
    "id": null,
    "metadata": {},
    "name": "WithEventDestinationNodeJobsTacticalMfeQuery",
    "operationKind": "query",
    "text": "query WithEventDestinationNodeJobsTacticalMfeQuery(\n  $nodeId: String\n) {\n  eventDestinations {\n    jobs(nodeUuid: $nodeId) {\n      edges {\n        node {\n          id\n          internalId\n          name\n          description\n          jobType\n          previousRun\n          nextRun\n          company {\n            internalId\n            displayName\n            id\n          }\n          targetInfo\n          sourceInfo\n          lastExecutedRun {\n            uuid\n            taskId\n            parentTaskId\n            runDate\n            scheduledDate\n            context\n            status\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "349520546a072399350a696d76024cdc";

export default node;
