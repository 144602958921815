import React, { useState } from 'react';
import { Box, Separator, Banner, Heading } from '@attentive/picnic';
import PerformanceConfig from './PerformanceConfig';
import ScheduleJob from './ScheduleJob';
import { JobRecord } from '../../../../../constants/EventDestinations/jobTypes';
import RunJobNow from './RunJobNow/RunJobNow';
import Clone from './Clone/index';

const Advanced: React.FunctionComponent<{ job?: JobRecord }> = ({ job }) => {
  const [errorMsg, setErrorMsg] = useState('');

  return (
    <Box
      css={{
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {job?.internalId && (
        <>
          {errorMsg && (
            <Banner variant="error" css={{ mb: '$space4' }}>
              {errorMsg}
            </Banner>
          )}
          <Heading css={{ mb: '$space4' }}>Advanced</Heading>
          <ScheduleJob job={job} setErrorMsg={setErrorMsg} />
          <Separator decorative css={{ my: '$space6' }} />
          <RunJobNow taskId={job.internalId} setErrMsg={setErrorMsg} />
          <Separator decorative css={{ my: '$space6' }} />
          <Clone taskId={job.internalId} taskName={job.name} setErrMsg={setErrorMsg} />
          <Separator decorative css={{ my: '$space6' }} />
          <PerformanceConfig job={job} setErrorMsg={setErrorMsg} />
        </>
      )}
    </Box>
  );
};

export default Advanced;
