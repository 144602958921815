import React from 'react';
import { useParams } from 'react-router-dom';
import { PollType } from '../../constants/PollSurveys/pollTypes';
import LoadPollContainer from './graphql/LoadPoll';
import { RefetchFnDynamic } from 'react-relay';
import { LoadPollFragment_query_refetchable as RefetchQuery } from './graphql/__generated__/LoadPollFragment_query_refetchable.graphql';
import { LoadPollFragment_query$key as FragmentType } from './graphql/__generated__/LoadPollFragment_query.graphql';
import { useIsMobile } from '../../utils/commonFns';
import PollDetailsPageDesktop from './PollDetailsPageDesktop';
import PollDetailsMobile from '../../components/PollSurveys/Mobile/PollDetailsMobile';

const PollDetailsPage: React.FunctionComponent<{
  poll: PollType;
  refetch: RefetchFnDynamic<RefetchQuery, FragmentType>;
}> = ({ poll, refetch }) => {
  const [mobile, setMobile] = React.useState<boolean>(useIsMobile());
  return mobile ? (
    <PollDetailsMobile poll={poll} refetch={refetch} setMobile={setMobile} />
  ) : (
    <PollDetailsPageDesktop poll={poll} refetch={refetch} />
  );
};

const PollDetailsPageContainer: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error('missing id');
  return <LoadPollContainer pollId={id} DisplayComponent={PollDetailsPage} />;
};

export default PollDetailsPageContainer;
