import React from 'react';
import { Link } from 'react-router-dom';
import { Box, DropdownMenu, IconButton } from '@attentive/picnic';
import ComingSoonDialog from '../../../common/ComingSoonDialog';
import { Paths } from '../../../../constants/routes';
import AreYouSureDialog from '../../../common/AreYouSureDialog';
import { EdsNode } from '../../../../constants/EventDestinations/endpointTypes';

const EndpointCardMenu: React.FunctionComponent<{ node: EdsNode }> = ({ node }) => {
  const { enabled, uuid } = node;
  const [comingSoonDialog, setComingSoonDialog] = React.useState<boolean>(false);
  const [areYouSureDialog, setAreYouSureDialog] = React.useState<boolean>(false);
  const jobsPath = `${Paths.EventDestinationsJobs}?nodeId=${uuid}`;
  const enabledAction = enabled ? 'Disable' : 'Enable';

  const handleConfirm = () => {
    setAreYouSureDialog(false);
    setTimeout(() => setComingSoonDialog(true), 800);
  };

  return (
    <Box>
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <IconButton
            iconName="DotsVertical"
            size="small"
            variant="subdued"
            description="Open Menu"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.TextItem onSelect={() => setComingSoonDialog(true)} disabled>
            View Performance
          </DropdownMenu.TextItem>
          <DropdownMenu.TextItem>
            <Link to={jobsPath} target="_blank" rel="noopener noreferrer">
              View Jobs
            </Link>
          </DropdownMenu.TextItem>
          <DropdownMenu.TextItem onSelect={() => setAreYouSureDialog(true)}>
            {enabledAction}
          </DropdownMenu.TextItem>
        </DropdownMenu.Content>
      </DropdownMenu>
      <ComingSoonDialog
        isOpen={comingSoonDialog}
        onOpenChange={() => setComingSoonDialog(!comingSoonDialog)}
      />
      <AreYouSureDialog
        isOpen={areYouSureDialog}
        onOpenChange={() => setAreYouSureDialog(!areYouSureDialog)}
        bodyText={`Are you sure you want to ${enabledAction.toLowerCase()} endpoint ${
          node.name
        }? It will automatically disable all ${node.jobCount} jobs that use it.`}
        onConfirm={handleConfirm}
        onCancel={() => setAreYouSureDialog(false)}
      />
    </Box>
  );
};

export default EndpointCardMenu;
