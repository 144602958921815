import React, { useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useRefetchableFragment } from 'react-relay';
import { Box, Heading, Text, theme2021 } from '@attentive/picnic';

import TacticalPieChart, { TacticalPieChartData } from '../../common/TacticalPieChart';
import PollResultsQuery, {
  PollResultsQuery as PollResultsQueryType,
} from './__generated__/PollResultsQuery.graphql';
import PollResultsFragment, {
  PollResultsFragment_query$key as PollResultsFragmentType,
  PollResultsFragment_query$data,
} from './__generated__/PollResultsFragment_query.graphql';
import { PollResultsFragment_query_refetchable as PollResultsFragmentRefetchQuery } from './__generated__/PollResultsFragment_query_refetchable.graphql';
import { PollType } from '../../../constants/PollSurveys/pollTypes';
import { useIsMobile } from '../../../utils/commonFns';

interface PollResultsPieChartProps {
  poll: PollType;
  pollResultsQueryReference: PreloadedQuery<PollResultsQueryType, Record<string, unknown>>;
}

const sortByKeyword = (a: TacticalPieChartData, b: TacticalPieChartData): number => {
  const keywordA = a.keyword?.toUpperCase() || '';
  const keywordB = b.keyword?.toUpperCase() || '';
  if (keywordA < keywordB) {
    return -1;
  }
  return 1;
};

const parseData = (fragData: PollResultsFragment_query$data): TacticalPieChartData[] => {
  const allColors = [
    theme2021.colors.aperolOrange200,
    theme2021.colors.celeryGreen200,
    theme2021.colors.cloudBlue200,
    theme2021.colors.creamsicleOrange100,
    theme2021.colors.green200,
    theme2021.colors.lavenderPurple200,
    theme2021.colors.red200,
    theme2021.colors.steelBlue200,
    theme2021.colors.yellow200,
  ];
  const keywords = fragData.poll?.poll_keywords?.pollKeywords;
  const stats = fragData.poll?.poll_stats?.stats.keywordStats;
  return (
    stats
      ?.map((stat) => {
        const keyword = keywords && keywords.find((k) => k.keyword === stat.keyword);
        return {
          keyword: keyword?.keyword,
          id: `${keyword?.keyword} - ${Math.round(stat.percentage)}%`,
          label: `[${keyword?.keyword}] ${keyword?.description} - ${Math.round(stat.percentage)}%`,
          value: stat.count,
          color: '',
        };
      })
      .sort(sortByKeyword)
      .map((d, i) => {
        d.color = allColors[i];
        return d;
      }) || []
  );
};

const MobilePieChart: React.FunctionComponent<{
  totalValues: number;
  isMobile: boolean;
  pieValues: TacticalPieChartData[];
}> = ({ totalValues, isMobile, pieValues }) => {
  return (
    <Box css={{ contain: 'content', height: '60vh' }}>
      <Heading variant="sm" css={{ mb: '-50px' }}>
        Total Votes: {totalValues}
      </Heading>
      <TacticalPieChart data={pieValues} isMobile={isMobile} />
    </Box>
  );
};

const DesktopPieChart: React.FunctionComponent<{
  isMobile: boolean;
  pieValues: TacticalPieChartData[];
}> = ({ isMobile, pieValues }) => {
  return (
    <Box css={{ height: '500px' }}>
      <TacticalPieChart data={pieValues} isMobile={isMobile} />
    </Box>
  );
};

const NoDataPlaceholder: React.FunctionComponent = () => {
  return (
    <Box
      css={{
        mt: '$space4',
        mb: '$space4',
        padding: '$space2',
        borderRadius: theme2021.radii.radius2,
        backgroundColor: theme2021.colors.bgAccent,
        textAlign: 'center',
      }}
    >
      <Text>No results yet, check back later</Text>
    </Box>
  );
};

export const PollResultsPieChart: React.FunctionComponent<PollResultsPieChartProps> = ({
  poll,
  pollResultsQueryReference,
}) => {
  const queryData = usePreloadedQuery<PollResultsQueryType>(
    PollResultsQuery,
    pollResultsQueryReference
  );
  const [fragmentData, refetch] = useRefetchableFragment<
    PollResultsFragmentRefetchQuery,
    PollResultsFragmentType
  >(PollResultsFragment, queryData);
  const isMobile = useIsMobile();
  const pieValues = parseData(fragmentData);
  const totalValues = pieValues.reduce((prev, curr) => prev + curr.value, 0);
  const pollId = poll?.id || '';

  useEffect(() => {
    if (poll?.state === 'OPEN' && totalValues !== 0) {
      setInterval(() => {
        refetch({ pollId }, { fetchPolicy: 'store-and-network' });
      }, 3000);
    }
  }, [poll.state, pollId, refetch, totalValues]);

  if (totalValues === 0) {
    return <NoDataPlaceholder />;
  }

  return isMobile ? (
    <MobilePieChart isMobile={isMobile} pieValues={pieValues} totalValues={totalValues} />
  ) : (
    <DesktopPieChart isMobile={isMobile} pieValues={pieValues} />
  );
};
