/**
 * @generated SignedSource<<bbf3f2297eedeb0a4a68681796bd9747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchCompanyKeywordRulesInfoTacticalMfeQuery$variables = {
  companyId: string;
  keywordId: string;
};
export type FetchCompanyKeywordRulesInfoTacticalMfeQuery$data = {
  readonly keywordRulesInfoByKeywordId: {
    readonly companyKeywordId: number;
    readonly ruleCount: number;
    readonly rulesExpression: string;
    readonly rulesLegend: ReadonlyArray<{
      readonly key: string;
      readonly value: string;
    }>;
  } | null;
};
export type FetchCompanyKeywordRulesInfoTacticalMfeQuery = {
  response: FetchCompanyKeywordRulesInfoTacticalMfeQuery$data;
  variables: FetchCompanyKeywordRulesInfoTacticalMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keywordId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
      },
      {
        "kind": "Variable",
        "name": "keywordId",
        "variableName": "keywordId"
      }
    ],
    "concreteType": "KeywordRulesInfoPayload",
    "kind": "LinkedField",
    "name": "keywordRulesInfoByKeywordId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyKeywordId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ruleCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rulesExpression",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RulesLegendEntry",
        "kind": "LinkedField",
        "name": "rulesLegend",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchCompanyKeywordRulesInfoTacticalMfeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchCompanyKeywordRulesInfoTacticalMfeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c4790f5076e0732207cffd640061eb15",
    "id": null,
    "metadata": {},
    "name": "FetchCompanyKeywordRulesInfoTacticalMfeQuery",
    "operationKind": "query",
    "text": "query FetchCompanyKeywordRulesInfoTacticalMfeQuery(\n  $companyId: ID!\n  $keywordId: ID!\n) {\n  keywordRulesInfoByKeywordId(companyId: $companyId, keywordId: $keywordId) {\n    companyKeywordId\n    ruleCount\n    rulesExpression\n    rulesLegend {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "49a1a75b75fff2428086d2f7e62a4c2c";

export default node;
