import { Button, Heading, Stack, Text } from '@attentive/picnic';
import React from 'react';
import { PollType } from '../../../constants/PollSurveys/pollTypes';

export const WinnerFilterDisplay: React.FC<{ poll: PollType }> = ({ poll }) => {
  const filter = poll.sweepstakesFilter;
  <Stack>
    <Heading variant="lg" as="h2">
      Sweeptstakes Filter Settings
    </Heading>
    <Text>
      Select {filter?.numToSelect} participants that are {filter?.subscriberType} subscriber type
      using selection type: {filter?.selectorType}.
    </Text>
    <br />
    {filter?.pollKeywords && (
      <Text>
        Filtering participants who selected one of the following keywords:{' '}
        {JSON.stringify(filter.pollKeywords)}
      </Text>
    )}

    <Button onClick={() => console.log('HELLO')}>Generate Winner Set</Button>
  </Stack>;

  return <Text>{JSON.stringify(poll.sweepstakesFilter)}</Text>;
};
