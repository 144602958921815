import { PollState } from '@attentive/data/types';
import { SelectorType, SubscriberType } from '../../components/PollSurveys/Sweepstakes/types';

export interface PollType {
  id: string;
  uuid: string;
  companyId: string;
  name: string;
  description: string;
  state: PollState;
  keywordOptions: PollOptionType[];
  submitMessage?: string;
  closedMessage?: string;
  duplicateVoteMessage?: string;
  allowMultiVote?: boolean;
  allowBreakTies?: boolean;
  decimalPrecision?: number;
  isArchived?: boolean;
  participantAttributeName?: string;
  onClosedMessage?: string;
  subscribedEventType?: string;
  applicationId?: string;
  allowAltSignupUnit?: boolean;
  altSignupUnitId?: number;
  updatedAt?: Date;
  hasWinnerFilter?: boolean;
  sweepstakesFilter?: {
    id: number;
    numToSelect: number;
    pollKeywords: string[];
    selectorType: SelectorType;
    subscriberType: SubscriberType;
  };
}

export interface PollOptionType {
  id: string;
  keyword: string;
  altMatches: string[];
  description: string;
  deleted: boolean;
}

export const EMPTY_POLL: PollType = {
  id: '',
  uuid: '',
  companyId: '',
  name: '',
  description: '',
  state: PollState.Off,
  keywordOptions: [],
};

export const EMPTY_OPTION: PollOptionType = {
  id: '',
  keyword: '',
  altMatches: [],
  description: '',
  deleted: false,
};

export enum POLL_PAGE_TAB {
  PERFORMANCE = 'Performance',
  CONFIG = 'Config',
  ADVANCED = 'Advanced',
  KEYWORDS = 'Keywords',
  SETTINGS = 'Settings',
  TRIGGERS = 'Triggers',
  MESSAGING = 'Messaging',
  RESULTS = 'Results',
  RESPONSES = 'Responses',
  AUDIENCE = 'Audience',
  SWEEPSTAKES = 'Sweepstakes',
}

export interface PollStat {
  keyword: string;
  count: number;
  percentage: string;
  description: string | undefined;
}
