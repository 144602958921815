import React from 'react';

import logo from './assets/salesforce.png';
import ProjectCard from './ProjectCard';
import { Paths } from '../../constants/routes';
import {
  ProjectCardConfig,
  TacticalHomeProjectCardProps,
} from '../../constants/Home/tacticalHomeTypes';

const config: ProjectCardConfig = {
  defaultEnabled: false,
  allowedRoles: ['ROLE_ENGINEERING', 'ROLE_CS_TAM'],
  heading: 'Salesforce Service Cloud',
  image: logo,
  path: Paths.SalesforceServiceCloud,
};

const SFSCCard: React.FunctionComponent<TacticalHomeProjectCardProps> = ({ viewerRoles }) => {
  return <ProjectCard config={{ ...config, viewerRoles }} />;
};
export default SFSCCard;
