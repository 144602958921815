import React from 'react';
import {
  Banner,
  Box,
  Button,
  FooterLayout,
  Link,
  Text,
  Heading,
  FormField,
  PicnicCss,
  Select,
  TextInput,
  Table as GridTable,
  Stack,
  IconButton,
} from '@attentive/picnic';
import { CustomAttributeSelect } from '@attentive/targeting-common';
import { SalesforceAPI } from '../../components/Salesforce/SalesforceAPI';
import { logError } from '../../utils/logger';
import {
  AuthLinkRedirectDto,
  AuthLinkRedirectError,
  ServiceCloudFieldMapping,
  ServiceCloudSetupConfig,
  ServiceCloudSetupConfigValidation,
  CustomPropertyMapping,
  ValueOf,
  StandardObject,
  SalesforceObjectAttributeField,
  CustomAttributeMapping,
} from '../../constants/Salesforce/salesforceServiceCloudTypes';
import { useToast, ToastType, useCompanyFeatureFlag } from '@attentive/acore-utils';

const serviceCloudInputStyle: PicnicCss = {
  disply: 'flex',
  flexDirection: 'column',
  width: '60%',
  mb: '$space5',
};

const SalesforceServiceCloudPage: React.FunctionComponent = () => {
  const [createToast] = useToast();
  const setupEndpoint = '/service-cloud/attentive/setup';

  const [setupConfig, setSetupConfig] = React.useState<ServiceCloudSetupConfig>({
    fieldMappings: [],
    customAttributeMappings: [],
  });
  const [validation, setValidation] = React.useState<ServiceCloudSetupConfigValidation>({
    standardObject: false,
    customExternalIdTemplate: true,
    sandboxDomain: true,
  });

  const enableServiceCloudCustomExternalId = useCompanyFeatureFlag(
    'ENABLE_SERVICE_CLOUD_CUSTOM_EXTERNAL_ID'
  );

  const enableSandboxDomain = useCompanyFeatureFlag('ENABLE_SALESFORCE_SERVICE_CLOUD_SANDBOX');

  const enableServiceCloudSiteId = useCompanyFeatureFlag(
    'ENABLE_SERVICE_CLOUD_CUSTOM_FIELD_MAPPING'
  );

  const enableCustomAttributeFieldMapping = useCompanyFeatureFlag(
    'ENABLE_SERVICE_CLOUD_CUSTOM_ATTRIBUTE_FIELD_MAPPING'
  );

  const [error, setError] = React.useState<String | undefined>();
  const [pageIsLoading, setPageIsLoading] = React.useState<boolean>(true);

  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  const [customPropertyMapping, setCustomPropertyMapping] = React.useState<CustomPropertyMapping[]>(
    [
      { displayName: 'First Name', attributeField: SalesforceObjectAttributeField.FIRST_NAME },
      { displayName: 'Last Name', attributeField: SalesforceObjectAttributeField.LAST_NAME },
    ]
  );

  const [fieldApiNameInput, setFieldApiNameInput] = React.useState<string>('');
  const [fieldValueInput, setFieldValueInput] = React.useState<string>('');

  const updateConfig = (prop: string, value: ValueOf<ServiceCloudSetupConfig> | null) => {
    const newAuthConfig = { ...setupConfig, [prop]: value };
    setSetupConfig(newAuthConfig);
  };

  if (setupConfig.customAttributeMappings.length > 0 && !dataLoaded) {
    // hydrate the ids from server onto customPropertyMapping
    for (const element of setupConfig.customAttributeMappings) {
      const result = customPropertyMapping.map((e) => {
        const a = element.objectField.toString();
        const b = e.attributeField.toString();
        if (a === b) {
          e.customAttributeId = element.customAttributeId;
        }
        return e;
      });
      setCustomPropertyMapping(result);
      setDataLoaded(true);
    }
  }

  React.useState(() => {
    SalesforceAPI.get(setupEndpoint)
      .then((response) => {
        if (response.status === 200) {
          const existingAuthConfig = response.body as ServiceCloudSetupConfig;
          validation.standardObject = !!existingAuthConfig.standardObject;

          setValidation(validation);
          if (!existingAuthConfig.fieldMappings) {
            existingAuthConfig.fieldMappings = [];
          }

          if (!existingAuthConfig.customAttributeMappings) {
            existingAuthConfig.customAttributeMappings = [];
          }

          setSetupConfig(existingAuthConfig);
          setPageIsLoading(false);
        }
      })
      .catch((e) => {
        setPageIsLoading(false);
        logError(e);
      });
  });

  const isFormValid = () => {
    return Object.values(validation).every((item) => item === true);
  };

  const isValidCustomExternalIdTemplate = (value: ValueOf<ServiceCloudSetupConfig>) => {
    return !value || value.toString().includes('{email}');
  };

  const isValidSandboxDomain = (value: ValueOf<ServiceCloudSetupConfig>) => {
    return (
      !value ||
      (value.toString().includes('.sandbox.my.salesforce.com') &&
        value.toString().includes('https://'))
    );
  };

  const validateForm = (
    fieldName: keyof ServiceCloudSetupConfig,
    targetValue?: ValueOf<ServiceCloudSetupConfig>
  ) => {
    const value: ValueOf<ServiceCloudSetupConfig> = targetValue || setupConfig[fieldName];
    const { ...newValidationState } = validation;

    switch (fieldName) {
      case 'standardObject':
        newValidationState[fieldName] = !!value;
        break;
      case 'customExternalIdTemplate':
        newValidationState[fieldName] = isValidCustomExternalIdTemplate(value);
        break;
      case 'sandboxDomain':
        newValidationState[fieldName] = isValidSandboxDomain(value);
        break;
    }

    setValidation(newValidationState);
  };

  const updateStandardObject = (sObjectVal: string) => {
    const sObject = Object.keys(StandardObject).find((so) => so === sObjectVal);
    const sObjectEnum: StandardObject = sObject as StandardObject;
    updateConfig('standardObject', sObjectEnum);
    validateForm('standardObject', sObjectEnum);
  };

  const getStandardObjectName = () => {
    if (!setupConfig.standardObject) {
      return '';
    }

    const stdObjIndex = Object.keys(StandardObject).indexOf(setupConfig.standardObject);
    return Object.values(StandardObject)[stdObjIndex];
  };

  const updateCustomExternalIdTemplate = (customExternalIdTemplate: string) => {
    updateConfig('customExternalIdTemplate', customExternalIdTemplate);
    validateForm('customExternalIdTemplate', customExternalIdTemplate);
  };

  const updateSandboxDomain = (SandboxDomain: string) => {
    updateConfig('sandboxDomain', SandboxDomain);
    validateForm('sandboxDomain', SandboxDomain);
  };

  const postSetupConfig = async () => {
    const attributeMappings = customPropertyMapping
      .filter((elm) => elm.customAttributeId)
      .map((elm) => {
        const mapping: CustomAttributeMapping = {
          customAttributeId: elm.customAttributeId,
          objectField: elm.attributeField,
        };
        return mapping;
      });

    setupConfig.customAttributeMappings = attributeMappings;
    setSetupConfig(setupConfig);
    return SalesforceAPI.post(setupEndpoint, setupConfig);
  };

  const createErrorToast = async () => {
    await createToast({
      type: ToastType.Error,
      title: 'Something went wrong.',
      text: 'Unable to set up authorization. Please contact your CSM',
    });
  };

  const submit = async () => {
    setPageIsLoading(true);

    try {
      const response = await postSetupConfig();
      if (response.status === 400) {
        const responseBody = response.body as AuthLinkRedirectError;
        setError(responseBody.message);
        setPageIsLoading(false);
      } else if (response.status === 200) {
        const responseBody = response.body as AuthLinkRedirectDto;
        window.location.assign(responseBody.authRedirectUrl);
      } else {
        setPageIsLoading(false);
        await createErrorToast();
      }
    } catch (e) {
      logError(e as Error);
      setPageIsLoading(false);
      await createToast({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Unable to set up authorization. Please contact your CSM',
      });
    }
  };

  const setNewPropertyMapping = (
    prop: CustomPropertyMapping,
    customAttributeId: string | undefined
  ) => {
    const newMapping = customPropertyMapping.map((existingProp) => {
      if (existingProp.displayName === prop.displayName) {
        existingProp.customAttributeId = customAttributeId;
      }
      return existingProp;
    });
    setCustomPropertyMapping(newMapping);
  };

  const removeFieldMapping = (customMapping: ServiceCloudFieldMapping) => {
    const newCustomMappings = setupConfig.fieldMappings.filter(
      (mapping) => mapping.fieldApiName !== customMapping.fieldApiName
    );
    updateConfig('fieldMappings', newCustomMappings);
  };

  const updateTable = () => {
    if (fieldApiNameInput && fieldValueInput) {
      const elm = setupConfig.fieldMappings.find(
        (entry) => entry.fieldApiName === fieldApiNameInput
      );

      if (!elm) {
        setupConfig.fieldMappings.push({
          fieldApiName: fieldApiNameInput,
          fieldValue: fieldValueInput,
        });
      } else {
        setupConfig.fieldMappings = setupConfig.fieldMappings.map((e) =>
          e.fieldApiName === fieldApiNameInput
            ? { fieldApiName: fieldApiNameInput, fieldValue: fieldValueInput }
            : e
        );
      }
      updateConfig('fieldMappings', setupConfig.fieldMappings);
      setFieldApiNameInput('');
      setFieldValueInput('');
    }
  };

  const SandboxDomain = () => {
    return (
      <FormField css={serviceCloudInputStyle}>
        <FormField.Label requirement="optional">Sandbox Login URL</FormField.Label>
        <FormField.HelperText>
          If this field is populated, this integration will connect to a specified Salesforce
          Sandbox environment. You can find an org's My Domain login URL on the My Domain Setup
          page. See more details at{' '}
          <Link
            target="_blank"
            href="https://help.salesforce.com/s/articleView?id=sf.deploy_sandboxes_parent.htm"
          >
            https://help.salesforce.com
          </Link>
          .
        </FormField.HelperText>
        <TextInput
          state={!validation.sandboxDomain ? 'error' : 'normal'}
          value={setupConfig.sandboxDomain}
          placeholder="e.g. https://mydomainname--sandboxname.sandbox.my.salesforce.com"
          onChange={(e) => updateSandboxDomain(e.target.value)}
          onBlur={() => validateForm('sandboxDomain', setupConfig.sandboxDomain)}
        />
        {!validation.sandboxDomain && (
          <FormField.ErrorText>
            The domain must have the form
            https://MyDomainName--SandboxName.sandbox.my.salesforce.com.
          </FormField.ErrorText>
        )}
      </FormField>
    );
  };

  const CustomExternalIdField = () => {
    return (
      <FormField css={serviceCloudInputStyle}>
        <FormField.Label requirement="optional">Custom External Id Template</FormField.Label>
        <FormField.HelperText>
          If this field is populated, a new "Custom External Id" field will be created in Salesforce
          and populated with the value from the template. This must include {'{'}
          email{'}'}.
        </FormField.HelperText>
        <TextInput
          state={!validation.customExternalIdTemplate ? 'error' : 'normal'}
          value={setupConfig.customExternalIdTemplate}
          placeholder="{email}-ExampleBrand-US"
          onChange={(e) => updateCustomExternalIdTemplate(e.target.value)}
          onBlur={() =>
            validateForm('customExternalIdTemplate', setupConfig.customExternalIdTemplate)
          }
        />
        {!validation.customExternalIdTemplate && (
          <FormField.ErrorText>
            Template must include {'{'}email{'}'}. For example, "{'{'}email{'}'}
            -ExampleBrand-US"
          </FormField.ErrorText>
        )}
      </FormField>
    );
  };

  const CustomAttributeMappingFields = () => {
    return (
      <FormField css={serviceCloudInputStyle}>
        <FormField.Label requirement="optional">Custom Attribute Mapping</FormField.Label>
        <FormField.HelperText>
          Use the fields below to map standard object fields to custom subscriber attributes.
        </FormField.HelperText>

        {customPropertyMapping.map((prop: CustomPropertyMapping) => {
          return (
            <Box key={prop.displayName} css={serviceCloudInputStyle}>
              <FormField.Label>
                {getStandardObjectName()
                  ? `${getStandardObjectName()} ${prop.displayName}`
                  : prop.displayName}
              </FormField.Label>
              <CustomAttributeSelect
                value={prop.customAttributeId || ''}
                onChange={(value) => {
                  setNewPropertyMapping(prop, value);
                }}
                useInternalId
                showEmptyOption
              />
            </Box>
          );
        })}
      </FormField>
    );
  };

  const renderAdditionalPostbackFields = () => {
    return (
      enableServiceCloudSiteId && (
        <Stack>
          <FormField css={serviceCloudInputStyle}>
            <FormField.Label requirement="optional">Additional Postback Fields</FormField.Label>
            <FormField.HelperText>
              Additional values can be updated on your Salesforce {getStandardObjectName()} object
              by defining a mapping to existing custom fields.
            </FormField.HelperText>
            <Box css={{ display: 'grid', gridTemplateColumns: 'repeat(3, 3fr)', gap: '$space6' }}>
              <TextInput
                value={fieldApiNameInput}
                placeholder="Salesforce Field Api Name"
                onChange={(e) => setFieldApiNameInput(e.target.value)}
              ></TextInput>
              <TextInput
                value={fieldValueInput}
                placeholder="Field Value"
                onChange={(e) => setFieldValueInput(e.target.value)}
              ></TextInput>
              <Box>
                <Button css={{ width: '150px' }} loading={pageIsLoading} onClick={updateTable}>
                  Add
                </Button>
              </Box>
            </Box>
          </FormField>
          {setupConfig.fieldMappings.length > 0 && (
            <FormField>
              <GridTable columns={[3, 2, 1]} aria-label="Additional field mapping">
                <GridTable.Header>
                  <GridTable.HeaderRow>
                    <GridTable.HeaderCell align="center">
                      Salesforce Field Api Name
                    </GridTable.HeaderCell>
                    <GridTable.HeaderCell align="center">Value</GridTable.HeaderCell>
                    <GridTable.HeaderCell align="left"></GridTable.HeaderCell>
                  </GridTable.HeaderRow>
                </GridTable.Header>
                <GridTable.Body>
                  {setupConfig.fieldMappings.map((fieldMapping) => {
                    return (
                      <GridTable.BodyFocusableRow key={fieldMapping.fieldApiName}>
                        <GridTable.BodyCell align="center">
                          {fieldMapping.fieldApiName}
                        </GridTable.BodyCell>
                        <GridTable.BodyCell align="center">
                          {fieldMapping.fieldValue}
                        </GridTable.BodyCell>
                        <GridTable.BodyCell align="left">
                          <IconButton
                            iconName="X"
                            description="Remove"
                            data-disable-focus
                            onClick={() => removeFieldMapping(fieldMapping)}
                          />
                        </GridTable.BodyCell>
                      </GridTable.BodyFocusableRow>
                    );
                  })}
                </GridTable.Body>
              </GridTable>
            </FormField>
          )}
        </Stack>
      )
    );
  };

  return (
    <FooterLayout css={{ height: '100%' }}>
      <FooterLayout.Content>
        {error && (
          <Banner dismissible variant="error" css={{ mb: '$space4' }}>
            <Banner.Text>{error}</Banner.Text>
          </Banner>
        )}
        <Box css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <Heading>Salesforce Service Cloud</Heading>
            <Text variant="caption">
              Configure your Salesforce Service Cloud AppExchange package
            </Text>
          </div>
        </Box>
        {!pageIsLoading && (
          <Box css={{ mt: '$space5', pl: '$space1' }}>
            <FormField css={serviceCloudInputStyle}>
              <FormField.Label requirement="required">Standard Object</FormField.Label>
              <FormField.HelperText>Standard Object used for Postback</FormField.HelperText>
              <Select value={setupConfig.standardObject} onChange={updateStandardObject}>
                {Object.entries(StandardObject).map((sObject) => (
                  <Select.Item key={sObject[0]} value={sObject[0]}>
                    {sObject[1]}
                  </Select.Item>
                ))}
              </Select>
            </FormField>
            {enableSandboxDomain && <SandboxDomain />}
            {enableServiceCloudCustomExternalId && <CustomExternalIdField />}
            {enableCustomAttributeFieldMapping && <CustomAttributeMappingFields />}
            {renderAdditionalPostbackFields()}
          </Box>
        )}
      </FooterLayout.Content>
      <FooterLayout.Footer>
        <Box
          css={{
            py: '$space8',
            pl: '$space2',
          }}
        >
          <Button
            css={{ width: '250px' }}
            loading={pageIsLoading}
            onClick={submit}
            disabled={!isFormValid()}
          >
            Complete Setup
          </Button>
        </Box>
      </FooterLayout.Footer>
    </FooterLayout>
  );
};

export default SalesforceServiceCloudPage;
