import React, { useEffect } from 'react';
import { useForm, Box, Checkbox, Form } from '@attentive/picnic';
import { prettyJson } from '../../utils/jsonUtils';
import { StyledFormField, StyledFormLabel } from '../../../../common/StyledFormComponents';

interface CreateMappingInputBoxProps {
  heading: string;
  fieldName: string;
}

const CreateMappingInputBox: React.FunctionComponent<CreateMappingInputBoxProps> = ({
  heading,
  fieldName,
}) => {
  const [autoFormat, setAutoFormat] = React.useState<boolean>(true);
  const { values, setFieldValue } = useForm();

  useEffect(() => {
    const pretty = prettyJson(values[fieldName], autoFormat);
    if (values[fieldName] !== pretty) {
      setFieldValue(fieldName, pretty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[fieldName]]);

  return (
    <StyledFormField name={fieldName}>
      <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledFormLabel>{heading}</StyledFormLabel>
        <Checkbox onChange={(checked) => setAutoFormat(!!checked)} checked={autoFormat}>
          auto-format
        </Checkbox>
      </Box>
      <Form.TextArea css={{ height: 300 }} />
    </StyledFormField>
  );
};

export default CreateMappingInputBox;
