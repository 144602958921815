import { JobHistory, JobHistoryContext, JobStatus } from '../constants/EventDestinations/jobTypes';
import { deserializeGQLDateTime } from '@attentive/data';
import { JobsTablePaginated_fragment$data } from '../components/EventDestinations/Jobs/JobsTable/__generated__/JobsTablePaginated_fragment.graphql';

export const parseHistoryContext = (contextVal: string): JobHistoryContext => {
  console.log(contextVal);
  const context = JSON.parse(contextVal);
  return {
    fileRowIndex: context.fileRowIndex ? context.fileRowIndex : 0,
    fileHashes: context.fileHashes,
    error: context.error ? context.error : 'None',
  };
};

export const getStatus = (
  jobType: string,
  prevRun: SerializedDateTime | null,
  nextRun: SerializedDateTime | null,
  lastRun: JobHistory | undefined
): string => {
  if (jobType === 'recurring' && nextRun) {
    return JobStatus.ACTIVE;
  }

  if (lastRun) {
    if (lastRun.status === 'TASK_COMPLETE' || lastRun.runDate) {
      return JobStatus.COMPLETED;
    }
    if (lastRun.status === 'TASK_QUEUED' || lastRun.status === 'NO_SOURCE_TASK_DATA') {
      return JobStatus.ACTIVE;
    }
  }

  if (jobType === 'one time' && prevRun) {
    return JobStatus.COMPLETED;
  }
  return JobStatus.DRAFT;
};

export const getHistory = (node: JobsTablePaginated_fragment$data) => {
  return node.lastExecutedRun
    ? {
        uuid: node.lastExecutedRun.uuid,
        taskId: node.lastExecutedRun.taskId,
        parentTaskId: node.lastExecutedRun.parentTaskId
          ? node.lastExecutedRun.parentTaskId
          : undefined,
        runDate: node.lastExecutedRun.runDate
          ? deserializeGQLDateTime(node.lastExecutedRun.runDate)
          : undefined,
        scheduledDate: deserializeGQLDateTime(node.lastExecutedRun.scheduledDate),
        context: parseHistoryContext(node.lastExecutedRun.context.toString()),
        status: node.lastExecutedRun.status,
      }
    : undefined;
};
