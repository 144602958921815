import React from 'react';
import {
  Box,
  Button,
  FooterLayout,
  FormField,
  Heading,
  PicnicCss,
  Text,
  TextInput,
} from '@attentive/picnic';
import { ToastType, useToast } from '@attentive/acore-utils';
import {
  MarketingCloudAppInfo,
  MarketingCloudPageState,
  MarketingCloudAction,
  MarketingCloudActions,
  ClientCredentialsRedirectDto,
} from '../../constants/Salesforce/salesforceMarketingCloudTypes';
import { SalesforceAPI } from '../../components/Salesforce/SalesforceAPI';
import { logError } from '../../utils/logger';

const marketingCloudInputStyle: PicnicCss = {
  disply: 'flex',
  flexDirection: 'column',
  width: '60%',
  mb: '$space5',
};

const initialState = {
  appInfo: {} as MarketingCloudAppInfo,
  isLoading: true,
  error: '',
};

const reducer = (
  state: MarketingCloudPageState,
  action: MarketingCloudAction
): MarketingCloudPageState => {
  switch (action.type) {
    case MarketingCloudActions.SET_ACCOUNT_ID: {
      const newAppInfo = state.appInfo;
      newAppInfo.accountId = action.payload;
      return {
        ...state,
        appInfo: newAppInfo,
      };
    }
    case MarketingCloudActions.SET_CLIENT_ID: {
      const newAppInfo = state.appInfo;
      newAppInfo.clientId = action.payload;
      return {
        ...state,
        appInfo: newAppInfo,
      };
    }
    case MarketingCloudActions.SET_CLIENT_SECRET: {
      const newAppInfo = state.appInfo;
      newAppInfo.clientSecret = action.payload;
      return {
        ...state,
        appInfo: newAppInfo,
      };
    }
    case MarketingCloudActions.SET_SUBDOMAIN: {
      const newAppInfo = state.appInfo;
      newAppInfo.subDomain = action.payload;
      return {
        ...state,
        appInfo: newAppInfo,
      };
    }
    case MarketingCloudActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case MarketingCloudActions.SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case MarketingCloudActions.SET_APP_INFO: {
      return {
        ...state,
        appInfo: action.payload,
      };
    }
  }
  return state;
};

const SalesforceMarketingCloudPage: React.FunctionComponent = () => {
  const [createToast] = useToast();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const clientCredentialsEndpoint = '/client-credentials';

  React.useState(() => {
    SalesforceAPI.get(clientCredentialsEndpoint)
      .then((response) => {
        if (response.status === 200) {
          const existingMarketingCloudSetup = response.body as MarketingCloudAppInfo;
          dispatch({
            type: MarketingCloudActions.SET_APP_INFO,
            payload: existingMarketingCloudSetup,
          });
        }
        dispatch({ type: MarketingCloudActions.SET_IS_LOADING, payload: false });
      })
      .catch((e) => {
        dispatch({ type: MarketingCloudActions.SET_IS_LOADING, payload: false });
        logError(e);
      });
  });

  const isFormValid = () => {
    return (
      state.appInfo.clientId &&
      state.appInfo.clientSecret &&
      state.appInfo.accountId &&
      state.appInfo.subDomain
    );
  };

  const buildClientCredentialsRequestBody = () => {
    const body = {
      clientId: state.appInfo.clientId,
      clientSecret: state.appInfo.clientSecret,
      accountId: state.appInfo.accountId,
      subDomain: state.appInfo.subDomain,
    };
    return body;
  };

  const sendClientCredentials = async () => {
    const requestBody = buildClientCredentialsRequestBody();
    return SalesforceAPI.post(clientCredentialsEndpoint, requestBody);
  };

  const createErrorToast = async () => {
    await createToast({
      type: ToastType.Error,
      title: 'Something went wrong.',
      text: 'Unable to set up authorization. Please contact your CSM',
    });
  };

  const submit = async () => {
    dispatch({ type: MarketingCloudActions.SET_IS_LOADING, payload: true });
    try {
      const response = await sendClientCredentials();
      if (response.status === 400) {
        dispatch({ type: MarketingCloudActions.SET_ERROR, payload: response.bodyText });
        dispatch({ type: MarketingCloudActions.SET_IS_LOADING, payload: false });
        await createToast({
          type: ToastType.Error,
          title: 'Invalid Credentials',
          text: response.bodyText,
        });
      } else if (response.status === 200) {
        const responseBody = response.body as ClientCredentialsRedirectDto;
        window.location.assign(responseBody.link);
      } else {
        dispatch({ type: MarketingCloudActions.SET_IS_LOADING, payload: false });
        logError(
          new Error(
            `Unhandled response from server while uploading client credentials: ${response.body}`
          )
        );
        await createErrorToast();
      }
    } catch (e) {
      logError(e);
      dispatch({ type: MarketingCloudActions.SET_IS_LOADING, payload: false });
      await createToast({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Unable to set up authorization. Please contact your CSM',
      });
    }
  };

  return (
    <FooterLayout css={{ height: '100%' }}>
      <FooterLayout.Content>
        <Box css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <Heading>Salesforce Marketing Cloud</Heading>
            <Text variant="caption">Setup your Salesforce Marketing Cloud Integration</Text>
          </div>
        </Box>
        {!state.isLoading && (
          <Box css={{ mt: '$space5', pl: '$space1' }}>
            <FormField css={marketingCloudInputStyle}>
              <FormField.Label requirement="required">Client ID</FormField.Label>
              <FormField.HelperText>Client ID of the Connected App</FormField.HelperText>
              <TextInput
                state={!state.appInfo.clientId ? 'error' : 'normal'}
                value={state.appInfo.clientId || ''}
                onChange={(e) =>
                  dispatch({
                    type: MarketingCloudActions.SET_CLIENT_ID,
                    payload: e.target.value,
                  })
                }
              />
              {!state.appInfo.clientId && (
                <FormField.ErrorText>This field is required</FormField.ErrorText>
              )}
            </FormField>
            <FormField css={marketingCloudInputStyle}>
              <FormField.Label requirement="required">Client Secret</FormField.Label>
              <FormField.HelperText>Client Secret of the Connected App</FormField.HelperText>
              <TextInput
                state={!state.appInfo.clientSecret ? 'error' : 'normal'}
                value={state.appInfo.clientSecret || ''}
                onChange={(e) =>
                  dispatch({
                    type: MarketingCloudActions.SET_CLIENT_SECRET,
                    payload: e.target.value,
                  })
                }
              />
              {!state.appInfo.clientSecret && (
                <FormField.ErrorText>This field is required</FormField.ErrorText>
              )}
            </FormField>
            <FormField css={marketingCloudInputStyle}>
              <FormField.Label requirement="required">Account Id</FormField.Label>
              <FormField.HelperText>Account ID of the Connected App</FormField.HelperText>
              <TextInput
                state={!state.appInfo.accountId ? 'error' : 'normal'}
                value={state.appInfo.accountId || ''}
                onChange={(e) =>
                  dispatch({
                    type: MarketingCloudActions.SET_ACCOUNT_ID,
                    payload: e.target.value,
                  })
                }
              />
              {!state.appInfo.accountId && (
                <FormField.ErrorText>This field is required</FormField.ErrorText>
              )}
            </FormField>
            <FormField css={marketingCloudInputStyle}>
              <FormField.Label requirement="required">Subdomain</FormField.Label>
              <FormField.HelperText>Subdomain of the Connected App</FormField.HelperText>
              <TextInput
                state={!state.appInfo.subDomain ? 'error' : 'normal'}
                value={state.appInfo.subDomain || ''}
                onChange={(e) =>
                  dispatch({
                    type: MarketingCloudActions.SET_SUBDOMAIN,
                    payload: e.target.value,
                  })
                }
              />
              {!state.appInfo.subDomain && (
                <FormField.ErrorText>This field is required</FormField.ErrorText>
              )}
            </FormField>
          </Box>
        )}
        <Button
          css={{ width: '250px' }}
          loading={state.isLoading}
          onClick={submit}
          disabled={!isFormValid()}
        >
          Complete Setup
        </Button>
      </FooterLayout.Content>
    </FooterLayout>
  );
};

export default SalesforceMarketingCloudPage;
