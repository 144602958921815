import React from 'react';
import { Box, Checkbox, Heading, Text, TextInput } from '@attentive/picnic';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

interface ParticipantAttributeNameProps {
  poll: PollType;
  updateRequestField: (field: string, value: string | boolean) => void;
}

const ParticipantAttributeName: React.FunctionComponent<ParticipantAttributeNameProps> = ({
  poll,
  updateRequestField,
}) => {
  const [participantAttributeName, setParticipantAttributeName] = React.useState<string>(
    poll.participantAttributeName || ''
  );
  const [isChecked, setIsChecked] = React.useState<boolean>(participantAttributeName !== '');

  const handleUpdate = (val: string) => {
    if (isChecked) {
      setParticipantAttributeName(val);
      updateRequestField('participantAttributeName', val);
      updateRequestField('enableParticipantAttributeName', true);
    }
  };

  const handleIsChecked = () => {
    if (isChecked) {
      updateRequestField('participantAttributeName', '');
      updateRequestField('enableParticipantAttributeName', false);
    } else {
      updateRequestField('enableParticipantAttributeName', true);
    }
    setIsChecked(!isChecked);
  };

  return (
    <Box css={{ mt: '$space2' }}>
      <Heading css={{ mb: '$space4' }} variant="lg">
        Custom Attribute
      </Heading>
      <Text css={{ mb: '$space4' }}>
        Set an optional Custom Attribute for each participant when entering a poll.
      </Text>
      <Checkbox onChange={() => handleIsChecked()} checked={isChecked}>
        Store Custom Attribute
      </Checkbox>
      {isChecked ? (
        <TextInput
          css={{ mt: '$space4' }}
          value={participantAttributeName}
          onChange={(e) => handleUpdate(e.target.value)}
        />
      ) : null}
    </Box>
  );
};

export default ParticipantAttributeName;
