import React from 'react';
import { Grid, TabGroup } from '@attentive/picnic';

import { JobPageTabType, JobRecord } from '../../../../../constants/EventDestinations/jobTypes';
import InfoCell from './InfoCell';
import JsonDetails from './JsonDetails';

import { getSource, getTarget } from './utils';
import { jsonContainerStyling } from './styles';

interface JobConfigTabProps {
  job?: JobRecord;
}

const ConfigInfo: React.FunctionComponent<JobConfigTabProps> = ({ job }) => {
  return (
    <Grid columns={4}>
      <InfoCell label="SOURCE" value={getSource(job)} />
      <InfoCell label="TARGET" value={getTarget(job)} />
      <InfoCell label="TYPE" value={job?.type} />
      <InfoCell label="COMPANY" value={job?.company} />
    </Grid>
  );
};

const JobConfigTab: React.FunctionComponent<JobConfigTabProps> = ({ job }) => {
  return (
    <TabGroup.Panel value={JobPageTabType.CONFIG}>
      <ConfigInfo job={job} />
      <Grid columns={2} css={jsonContainerStyling}>
        <JsonDetails title="SOURCE" json={job?.sourceInfo} />
        <JsonDetails title="TARGET" json={job?.targetInfo} />
      </Grid>
    </TabGroup.Panel>
  );
};

export default JobConfigTab;
