import { Box, Breadcrumbs } from '@attentive/picnic';
import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../constants/routes';

export interface TacticalBreadcrumbType {
  id: number;
  path: string;
  text: string;
}

export const HOME_BREADCRUMB: TacticalBreadcrumbType = {
  id: 1,
  path: Paths.Home,
  text: 'Tactical',
};

export const EVENT_DESTINATION_BREADCRUMB: TacticalBreadcrumbType = {
  id: 2,
  path: Paths.EventDestinations,
  text: 'Event Destinations',
};

export const EVENT_DESTINATION_JOBS_BREADCRUMB: TacticalBreadcrumbType = {
  id: 3,
  path: Paths.EventDestinationsJobs,
  text: 'Jobs',
};

export const EVENT_DESTINATION_DESTINATION_BREADCRUMB: TacticalBreadcrumbType = {
  id: 4,
  path: Paths.EventDestinationsDestinations,
  text: 'Destinations',
};

export const EVENT_DESTINATION_NODES_BREADCRUMB: TacticalBreadcrumbType = {
  id: 5,
  path: Paths.EventDestinationsNodes,
  text: 'Endpoints',
};

export const PRIVACY_REQUESTS_BREADCRUMB: TacticalBreadcrumbType = {
  id: 6,
  path: Paths.PrivacyRequests,
  text: 'Privacy Request',
};

export const POLL_SURVEY_BREADCRUMB: TacticalBreadcrumbType = {
  id: 7,
  path: Paths.PollSurveys,
  text: 'Polls',
};

export const POLL_NEW_BREADCRUMB: TacticalBreadcrumbType = {
  id: 8,
  path: Paths.PollCreateNew,
  text: 'New',
};

export const CUSTOM_COMPANY_KEYWORDS_BREADCRUMB: TacticalBreadcrumbType = {
  id: 9,
  path: Paths.CustomCompanyKeywords,
  text: 'Keywords',
};

export const MOBILE_WALLET_BREADCRUMB: TacticalBreadcrumbType = {
  id: 10,
  path: Paths.MobileWallet,
  text: 'Mobile Wallet',
};

export const createBreadcrumb: (path: string, text: string) => TacticalBreadcrumbType = (
  path,
  text
) => {
  return {
    id: 99,
    path,
    text,
  };
};

interface TacticalBreadcrumbsProps {
  breadcrumbs: TacticalBreadcrumbType[];
}

const TacticalBreadcrumbs: React.FunctionComponent<TacticalBreadcrumbsProps> = ({
  breadcrumbs,
}) => {
  return (
    <Box css={{ mb: '$space4' }}>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb: TacticalBreadcrumbType) => (
          <Breadcrumbs.Item key={breadcrumb.id} as={Link} to={breadcrumb.path}>
            {breadcrumb.text}
          </Breadcrumbs.Item>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default TacticalBreadcrumbs;
