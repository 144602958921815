import React from 'react';
import { IconCircle, StandardDialog, Table, Text, Box, Link } from '@attentive/picnic';
import { JobHistory } from '../../../../constants/EventDestinations/jobTypes';
import { format } from 'date-fns';

const parseDate = (d: Date): string => {
  try {
    return format(d, 'MM/d/yy hh:mm a');
  } catch (e) {
    return '';
  }
};

const RunDetailsDialog: React.FunctionComponent<{
  open: boolean;
  handleOpenChange: () => void;
  run: JobHistory;
  requests: number;
  success: number;
  failure: number;
  start: Date;
  end: Date;
}> = ({ open, handleOpenChange, run, requests, success, failure, start, end }) => {
  const copyToClipboard = (val: string) => {
    navigator.clipboard.writeText(val);
  };

  const ddLogTemplate = `https://app.datadoghq.com/logs?query=service%3Aevent-destination-service%20%40contextMap.TASK_UUID%3A${run.taskId}%20%40contextMap.RUN_UUID%3A${run.uuid}%20&agg_q=%40contextMap.RESPONSE_STATUS_CODE&cols=host%2Cservice%2CcontextMap.MESSAGE_ID&index=&messageDisplay=inline&sort_m=&sort_t=&stream_sort=desc&top_n=10&top_o=top&viz=stream&x_missing=true&live=true`;

  const temporalLogTemplate = `https://temporal-web.prod.attentivemobile.com/namespaces/tactical-eds/workflows?query=WorkflowId%3D%22${run.taskId}_${run.uuid}%22`;

  return (
    <StandardDialog open={open} onOpenChange={handleOpenChange}>
      <StandardDialog.Content>
        <StandardDialog.Header>
          <StandardDialog.Heading>Run Details</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <Table columnSizes={['auto', 'auto']}>
            <Table.Body>
              <Table.BodyRow>
                <Table.BodyCell>Task</Table.BodyCell>
                <Table.BodyCell>
                  <Box css={{ cursor: 'pointer', marginRight: '$space2' }}>
                    <IconCircle
                      iconName="Copy"
                      size="extraSmall"
                      color="decorative2"
                      onClick={() => copyToClipboard(run.taskId)}
                    />
                  </Box>
                  <Text>{run.taskId}</Text>
                </Table.BodyCell>
              </Table.BodyRow>
              <Table.BodyRow>
                <Table.BodyCell>Run</Table.BodyCell>
                <Table.BodyCell>
                  <Box css={{ cursor: 'pointer', marginRight: '$space2' }}>
                    <IconCircle
                      iconName="Copy"
                      size="extraSmall"
                      color="decorative2"
                      onClick={() => copyToClipboard(run.uuid)}
                    />
                  </Box>
                  <Text>{run.uuid}</Text>
                </Table.BodyCell>
              </Table.BodyRow>

              <Table.BodyRow>
                <Table.BodyCell>DataDog Link</Table.BodyCell>
                <Table.BodyCell>
                  <Link href={ddLogTemplate} target="_blank">
                    Click Here
                  </Link>
                </Table.BodyCell>
              </Table.BodyRow>
              <Table.BodyRow>
                <Table.BodyCell>Temporal Link</Table.BodyCell>
                <Table.BodyCell>
                  <Link href={temporalLogTemplate} target="_blank">
                    Click Here
                  </Link>
                </Table.BodyCell>
              </Table.BodyRow>

              <Table.BodyRow>
                <Table.BodyCell>Requests</Table.BodyCell>
                <Table.BodyCell>{requests}</Table.BodyCell>
              </Table.BodyRow>
              <Table.BodyRow>
                <Table.BodyCell>Success</Table.BodyCell>
                <Table.BodyCell>{success}</Table.BodyCell>
              </Table.BodyRow>
              <Table.BodyRow>
                <Table.BodyCell>Failure</Table.BodyCell>
                <Table.BodyCell>{failure}</Table.BodyCell>
              </Table.BodyRow>
              <Table.BodyRow>
                <Table.BodyCell>Start</Table.BodyCell>
                <Table.BodyCell>{parseDate(start)}</Table.BodyCell>
              </Table.BodyRow>
              <Table.BodyRow>
                <Table.BodyCell>End</Table.BodyCell>
                <Table.BodyCell>{parseDate(end)}</Table.BodyCell>
              </Table.BodyRow>
            </Table.Body>
          </Table>
        </StandardDialog.Body>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

export default RunDetailsDialog;
