import _SetWinnerFilterMutation from "./__generated__/SetWinnerFilterMutation.graphql";
import { graphql } from '@attentive/data';
import { SelectorType, SubscriberType } from '../types';
_SetWinnerFilterMutation;

const convertSelectorType = selectorType => {
  switch (selectorType) {
    case SelectorType.FIRST:
      return 'SWEEPSTAKES_SELECTOR_TYPE_FIRST';

    case SelectorType.LAST:
      return 'SWEEPSTAKES_SELECTOR_TYPE_LAST';

    case SelectorType.RANDOM:
      return 'SWEEPSTAKES_SELECTOR_TYPE_RANDOM';

    case SelectorType.MOST_VOTES:
      return 'SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES';
  }
};

const convertSubType = subscriberType => {
  switch (subscriberType) {
    case SubscriberType.ANY:
      return 'SWEEPSTAKES_SUBSCRIBER_TYPE_ANY';

    case SubscriberType.MARKETING:
      return 'SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING';
  }
};

export const buildSetSweepstakesFilterInput = values => {
  const {
    numToSelect,
    selectorType,
    subscriberType
  } = values;
  const sweepsSelectorType = convertSelectorType(selectorType);
  const sweepsSubType = convertSubType(subscriberType);
  return {
    input: { ...values,
      numToSelect: Number(numToSelect),
      selectorType: sweepsSelectorType,
      subscriberType: sweepsSubType
    }
  };
};