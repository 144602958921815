/**
 * @generated SignedSource<<d633f2aba97316d3fc7b3387ccd9cf32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PerformanceQuery$variables = {
  jobType: string;
  lastXRuns: number;
  taskId: string;
};
export type PerformanceQuery$data = {
  readonly edsLatestRunReports: {
    readonly reports: ReadonlyArray<{
      readonly end: string;
      readonly runUuid: string;
      readonly start: string;
      readonly statusCodeCounts: ReadonlyArray<{
        readonly key: string;
        readonly value: number;
      }>;
      readonly taskId: string;
    }>;
  } | null;
};
export type PerformanceQuery$rawResponse = {
  readonly edsLatestRunReports: {
    readonly reports: ReadonlyArray<{
      readonly end: string;
      readonly runUuid: string;
      readonly start: string;
      readonly statusCodeCounts: ReadonlyArray<{
        readonly key: string;
        readonly value: number;
      }>;
      readonly taskId: string;
    }>;
  } | null;
};
export type PerformanceQuery = {
  rawResponse: PerformanceQuery$rawResponse;
  response: PerformanceQuery$data;
  variables: PerformanceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastXRuns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lastXRuns",
        "variableName": "lastXRuns"
      },
      {
        "kind": "Variable",
        "name": "taskUuid",
        "variableName": "taskId"
      },
      {
        "kind": "Variable",
        "name": "topicType",
        "variableName": "jobType"
      }
    ],
    "concreteType": "GetRunReportsResponse",
    "kind": "LinkedField",
    "name": "edsLatestRunReports",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RunReport",
        "kind": "LinkedField",
        "name": "reports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "runUuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusCodeCountsEntry",
            "kind": "LinkedField",
            "name": "statusCodeCounts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PerformanceQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f6463049f7ecbbb72daa0ffb0d7ac950",
    "id": null,
    "metadata": {},
    "name": "PerformanceQuery",
    "operationKind": "query",
    "text": "query PerformanceQuery(\n  $taskId: String!\n  $lastXRuns: Int!\n  $jobType: String!\n) {\n  edsLatestRunReports(lastXRuns: $lastXRuns, topicType: $jobType, taskUuid: $taskId) {\n    reports {\n      taskId\n      runUuid\n      start\n      end\n      statusCodeCounts {\n        key\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b19dfaf9e659cd9da18a54ad513a4dd4";

export default node;
