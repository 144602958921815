import React from 'react';
import { Box, Heading, TextInput } from '@attentive/picnic';
import { PollType } from '../../../../constants/PollSurveys/pollTypes';

interface DisplayOptionsProps {
  poll: PollType;
  updateRequestField: (field: string, value: string) => void;
}

const DisplayOptions: React.FunctionComponent<DisplayOptionsProps> = ({
  poll,
  updateRequestField,
}) => {
  const [name, setName] = React.useState<string>(poll.name);
  const [description, setDescription] = React.useState<string>(poll.description);

  const handleNameChange = (value: string) => {
    updateRequestField('name', value);
    setName(value);
  };

  const handleDescriptionChange = (value: string) => {
    updateRequestField('description', value);
    setDescription(value);
  };

  return (
    <Box css={{ mb: '$space2' }}>
      <Heading css={{ mb: '$space6' }} variant="lg">
        Display Options
      </Heading>
      <Heading css={{ mb: '$space2' }} variant="sm">
        Display Name
      </Heading>
      <TextInput value={name} onChange={(e) => handleNameChange(e.target.value)} />
      <Heading css={{ mt: '$space4', mb: '$space2' }} variant="sm">
        Display Description
      </Heading>
      <TextInput value={description} onChange={(e) => handleDescriptionChange(e.target.value)} />
    </Box>
  );
};

export default DisplayOptions;
