import React, { useState } from 'react';
import { Box, Button, Heading, Text } from '@attentive/picnic';
import { RunJobNowChild } from './RunJobNowChild';

const RunJobNow: React.FunctionComponent<{
  taskId: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
}> = ({ taskId, setErrMsg }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Heading as="h2" variant="lg" css={{ mb: '$space6' }}>
        Trigger Job
      </Heading>
      <Text css={{ mb: '$space4' }}>Run the selected job immediately.</Text>
      <Box>
        <Button onClick={() => setIsOpen(true)}>Run Now</Button>
      </Box>
      {isOpen && (
        <RunJobNowChild
          taskId={taskId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setErrMsg={setErrMsg}
        />
      )}
    </>
  );
};

export default RunJobNow;
