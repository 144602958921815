import React from 'react';
import { useParams } from 'react-router-dom';

import EventReplayDetails from '../../components/EventReplay/EventReplayDetails';
import { EventReplaysPresenter } from './EventReplayByCompanyIdsTacticalMfeQuery';

const EventReplayDetailsPage: React.FunctionComponent = () => {
  const { companyId, id } = useParams<{ companyId: string; id?: string }>();

  return (
    <EventReplaysPresenter vars={{ companyIds: [Number(companyId)] }}>
      <EventReplayDetails requestId={id} />
    </EventReplaysPresenter>
  );
};

export default EventReplayDetailsPage;
