/**
 * @generated SignedSource<<b08dc6963190018b0f7af50acf04a6f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadV1CustomKeywordsFragment$data = {
  readonly customKeywords: ReadonlyArray<{
    readonly id: string;
    readonly isActive: boolean | null;
    readonly isTransactional: boolean | null;
    readonly keyword: string;
    readonly response: string;
    readonly subscriberTag: string;
  }> | null;
  readonly internalId: number;
  readonly name: string;
  readonly " $fragmentType": "LoadV1CustomKeywordsFragment";
};
export type LoadV1CustomKeywordsFragment$key = {
  readonly " $data"?: LoadV1CustomKeywordsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoadV1CustomKeywordsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoadV1CustomKeywordsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomKeyword",
      "kind": "LinkedField",
      "name": "customKeywords",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyword",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "response",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriberTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTransactional",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "2d7ad6fb8b1e14a3274675668e07402c";

export default node;
